import React, { useMemo, useCallback } from "react";

import * as yup from "yup";
import toLower from "lodash/toLower";
import startCase from "lodash/startCase";

import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";

import { useEntity } from "../../../../context/Entity";

import { FRAGMENT_ENTITY } from "../../../../config/graphql/fragment";

import Input from "../../../../components/Input";
import RoleBlock from "../../../../components/RoleBlock";
import CodeEditor from "../../../../components/CodeEditor";

type FieldValues = {
  subtype: string;
  json: string;
};

const MetadataRoute = React.memo(() => {
  const entity = useEntity();

  const entityType = entity?.type || EntityType.Building;

  const { t } = useTranslation(["entities", "common"]);

  const schema = useMemo(
    () =>
      yup.object().shape({
        subtype: yup.string(),
        json: yup.object().typeError(t("entities:entity.metadata.yup.json")),
      }),
    [],
  );

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {
      subtype: entity?.metadata?.subtype,
      json: JSON.stringify(entity?.metadata?.json || {}, null, "\t"),
    },
  });

  const mutation = useMemo(() => {
    const typeString = startCase(toLower(entityType));

    return gql`
      mutation update${typeString}($input: Update${typeString}Input!) {
        update${typeString}(input: $input) {
          ...FragmentEntity
        }
      }
      ${FRAGMENT_ENTITY}
  `;
  }, [entityType]);

  const [onUpdate] = useMutation(mutation);

  const onSubmit = useCallback(
    (variables: FieldValues) => {
      onUpdate({
        variables: {
          input: {
            id: entity?.id,
            metadata: { ...variables },
          },
        },
      })
        .then(() => {
          toast.success<string>(t("entities:entity.metadata.toast.updated"));
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [onUpdate, entity?.id, t],
  );

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-6">
            <div className="form-group" style={{ width: "500px" }}>
              <label htmlFor="subtype">
                {t("entities:entity.metadata.form.subtype")}
              </label>
              <Input name="subtype" className="form-control" />
            </div>
            <RoleBlock roles={["ADMIN"]}>
              <div className="form-group">
                <label htmlFor="json">
                  {t("entities:entity.metadata.form.json")}
                </label>
                <Controller
                  name="json"
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <CodeEditor {...field} />
                      {!!error && (
                        <div style={{ color: "red", marginTop: "0.5rem" }}>
                          {error?.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
            </RoleBlock>
          </div>
          <div className="col-6">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </FormProvider>
    </>
  );
});

export default MetadataRoute;
