import React from "react";

import { Switch, useRouteMatch, Redirect } from "react-router-dom";

import Nameboard from "./Nameboard";
import NameboardsRoute from "./Nameboards";

import Route from "../../components/Route";

const NameboardsRouter = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route.Base exact path={path}>
        <NameboardsRoute />
      </Route.Base>
      <Route.Role exact path={`${path}/new`} roles={["ADMIN"]} redirect={path}>
        <Nameboard />
      </Route.Role>
      <Route.Base path={`${path}/:screenId`}>
        <Nameboard />
      </Route.Base>
      <Redirect to={path} />
    </Switch>
  );
});

export default NameboardsRouter;
