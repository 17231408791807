import React, { useMemo, useCallback, useEffect, useRef } from "react";
import * as yup from "yup";

import map from "lodash/map";

import clsx from "clsx";
import useCopyToClipboard from "react-use/lib/useCopyToClipboard";

import Select from "react-select";

import { Link, useParams, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";

import { useTranslation } from "react-i18next";
import Input from "../../../components/Input";

import {
  QUERY_SCREENS,
  QUERY_SCREEN,
  QUERY_ENTITIES,
} from "../../../config/graphql/query";

import {
  CREATE_POSTBOX,
  UPDATE_POSTBOX,
} from "../../../config/graphql/mutation";

import {
  POSTBOX_TEMPLATES,
  THE_BASE_LETTERS,
} from "../../../config/const/common";

const Information = React.memo(() => {
  const { screenId } = useParams<{ screenId: string }>();

  const [, copyToClipboard] = useCopyToClipboard();

  const history = useHistory();

  const { t } = useTranslation(["screens", "common"]);

  const boardFrame = useRef(null);

  const schema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required(t("screens:screen.screenRoute.yup.title")),
        template: yup
          .string()
          .required(t("screens:screen.screenRoute.yup.template")),
        buildings: yup
          .array()
          .of(
            yup.object().shape({
              id: yup.string(),
            }),
          )
          .required(t("postbox:information.yup.required.buildings")),
      }),
    [t],
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
  });

  const { data: screenData } = useQuery<{ screen: IPostbox }>(QUERY_SCREEN, {
    skip: !screenId,
    variables: {
      id: screenId,
    },
    onError: () => history.replace("/postbox"),
  });

  useEffect(() => {
    if (screenData?.screen) {
      methods.reset(screenData?.screen);
    }
  }, [screenData?.screen]);

  const { data: entitiesData } = useQuery<{ entities: IEntity[] }>(
    QUERY_ENTITIES,
    {
      variables: {
        filter: { type: "Building" },
      },
      nextFetchPolicy: "network-only",
    },
  );

  const entities = entitiesData?.entities ?? [];

  const [onSave] = useMutation(screenId ? UPDATE_POSTBOX : CREATE_POSTBOX, {
    refetchQueries: [
      {
        query: QUERY_SCREENS,
        variables: {
          filter: { type: { EQ: "Postbox" } },
          sort: { title: "ASC" },
        },
      },
    ],
  });

  const onSubmit = useCallback(
    (values: any) => {
      const input = {
        ...values,
        ...(screenId && { id: screenId }),
        buildings: map(values.buildings, ({ id }) => id),
      };

      return onSave({ variables: { input } })
        .then(({ data }) => {
          if (data?.addPostbox?.id) {
            history.replace(`/postbox/${screenId}`);

            toast.success<string>(
              t("screens:screen.screenRoute.toast.created"),
            );
            return;
          }

          toast.success<string>(t("screens:screen.screenRoute.toast.updated"));
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [history, screenId, onSave, t],
  );

  return (
    <FormProvider {...methods}>
      <div className="tab-pane active" role="tabpanel">
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="title">
                {t("screens:screen.screenRoute.form.title")}
              </label>
              <Input name="title" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="type">
                {t("screens:screen.screenRoute.form.template")}
              </label>
              <select
                {...methods.register("template")}
                className="custom-select"
              >
                {POSTBOX_TEMPLATES.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="entities">
                {t("postbox:information.form.entities")}
              </label>
              <Controller
                name="buildings"
                render={({
                  field: { onChange, ...props },
                  fieldState: { error },
                }) => (
                  <>
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      getOptionLabel={({ title, parent }) =>
                        parent ? `${title} (${parent.title})` : title
                      }
                      getOptionValue={({ id }) => id}
                      options={entities}
                      className={clsx({
                        "is-invalid": !!error,
                      })}
                      {...props}
                      onChange={(value) => onChange(value)}
                    />
                    {!!error && (
                      <div className="invalid-feedback">{error.message}</div>
                    )}
                  </>
                )}
              />
            </div>

            <div className="form-group">
              <label htmlFor="type">
                {t("screens:screen.floorInfo.form.logoNumber")}
              </label>
              <select
                {...methods.register("metadata.letter")}
                className="custom-select"
              >
                {THE_BASE_LETTERS.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <h6 className="my-4">
              {t("screens:screen.screenRoute.rangeHelper")}
            </h6>
            <div className="row">
              <div className="col form-group">
                <label htmlFor="metadata.from">
                  {t("screens:screen.screenRoute.form.metadata.from")}
                </label>
                <Input
                  name="metadata.from"
                  placeholder="1-01"
                  className="form-control"
                />
                <small id="title" className="form-text text-muted">
                  {t("screens:screen.screenRoute.form.metadata.toHelper")}
                </small>
              </div>
              <div className="col form-group">
                <label htmlFor="metadata.to">
                  {t("screens:screen.screenRoute.form.metadata.to")}
                </label>
                <Input
                  name="metadata.to"
                  placeholder="1-10"
                  className="form-control"
                />
                <small id="title" className="form-text text-muted">
                  {t("screens:screen.screenRoute.form.metadata.toHelper")}
                </small>
              </div>
            </div>
          </div>
          <div className="col-lg-8 row">
            <div className="col-12 mb-2">
              <iframe
                key={methods.getValues("template")}
                ref={boardFrame}
                title={`screen-${screenId}`}
                className="border border-dark"
                src={screenData?.screen?.previewUrl}
                style={{
                  width: 600 / (4 / 3),
                  height: 600,
                }}
                frameBorder={0}
                marginHeight={0}
                marginWidth={0}
              />
            </div>
            <div className="col-12">
              <a
                href={screenData?.screen?.previewUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary mr-2"
              >
                {t("screens:screen.screenRoute.form.preview")}
              </a>
              <a
                href={screenData?.screen?.playerUrl}
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();

                  copyToClipboard(e.currentTarget.href);

                  toast.success<string>(
                    t("screens:screen.screenRoute.toast.linkCopied"),
                  );
                }}
              >
                {t("screens:screen.screenRoute.form.copyURL")}
              </a>
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </div>
    </FormProvider>
  );
});

const PostboxRoute = React.memo(() => {
  const { t } = useTranslation(["screens", "common"]);

  const { screenId } = useParams<{ screenId: string }>();

  const { data } = useQuery(QUERY_SCREEN, {
    skip: !screenId,
    variables: { id: screenId },
    nextFetchPolicy: "network-only",
  });

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/postbox">
              {t("screens:screen.screenRoute.nav.postboxes")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {data?.screen?.title ?? t("screens:screen.screenRoute.nav.postbox")}
          </li>
        </ol>
      </nav>

      <div className="tab-content">
        <Information />
      </div>
    </div>
  );
});

export default PostboxRoute;
