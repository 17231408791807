import React from "react";

import { Route, Switch, useRouteMatch } from "react-router-dom";

import FileUploadProvider from "../../../../components/FileUpload";

import PathRoute from "./Path";
import PathsRoute from "./Paths";

const PathRouter = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <PathsRoute />
      </Route>
      <Route exact path={`${path}/new`}>
        <FileUploadProvider>
          <PathRoute />
        </FileUploadProvider>
      </Route>
      <Route exact path={`${path}/:id`}>
        <FileUploadProvider>
          <PathRoute />
        </FileUploadProvider>
      </Route>
    </Switch>
  );
});

export default PathRouter;
