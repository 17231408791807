import React, { useCallback, useEffect, useRef } from "react";

import useCopyToClipboard from "react-use/lib/useCopyToClipboard";

import { useParams, useHistory } from "react-router-dom";

import map from "lodash/map";

import { toast } from "react-toastify";

import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";

import { useTranslation } from "react-i18next";
import Input from "../../../../components/Input";

import {
  QUERY_SCREENS,
  QUERY_BOARD_DATA,
  QUERY_NAMEBOARD_TEMPLATES,
} from "../../../../config/graphql/query";

import {
  CREATE_NAMEBOARD,
  UPDATE_NAMEBOARD,
} from "../../../../config/graphql/mutation";

import RoleBlock from "../../../../components/RoleBlock";

interface FieldValues {
  title: string;
  template: string;
}

const Information = React.memo(() => {
  const { screenId } = useParams<{ screenId: string }>();

  const [, copyToClipboard] = useCopyToClipboard();

  const history = useHistory();

  const { t } = useTranslation(["screens", "common"]);

  const boardFrame = useRef(null);

  const methods = useForm<FieldValues>({
    shouldFocusError: false,
    shouldUnregister: true,
  });

  const { data: templatesData } = useQuery<{
    nameboardTemplates: string[];
  }>(QUERY_NAMEBOARD_TEMPLATES, {
    onError: () => history.replace("/nameboards"),
  });

  const { data: screenData } = useQuery<{
    nameboard: INameboard;
  }>(QUERY_BOARD_DATA, {
    skip: !screenId,
    variables: {
      id: screenId,
    },
    onError: () => history.replace("/nameboards"),
  });

  useEffect(() => {
    methods.reset(screenData?.nameboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenData]);

  const [onSave] = useMutation(screenId ? UPDATE_NAMEBOARD : CREATE_NAMEBOARD, {
    refetchQueries: [
      {
        query: QUERY_SCREENS,
        variables: {
          filter: { type: { EQ: "Nameboard" } },
          sort: { title: "ASC" },
        },
      },
    ],
  });

  const onSubmit = useCallback(
    (values: FieldValues) => {
      const input = {
        ...values,
        ...(screenId && { id: screenId }),
      };

      return onSave({ variables: { input } })
        .then(({ data }) => {
          if (data?.addNameboard?.id) {
            history.replace(`/nameboards/${screenId}`);

            toast.success<string>(
              t("screens:screen.screenRoute.toast.created"),
            );
            return;
          }

          toast.success<string>(t("screens:screen.screenRoute.toast.updated"));
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [history, screenId, onSave, t],
  );

  return (
    <FormProvider {...methods}>
      <div className="tab-pane active" role="tabpanel">
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="title">
                {t("screens:screen.screenRoute.form.title")}
              </label>
              <Input
                name="title"
                className="form-control"
                rules={{
                  required: {
                    value: true,
                    message: t("screens:screen.screenRoute.yup.title"),
                  },
                }}
              />
            </div>
            <RoleBlock roles={["ADMIN"]}>
              <div className="form-group">
                <label htmlFor="type">
                  {t("screens:screen.screenRoute.form.template")}
                </label>
                <select
                  {...methods.register("template", {
                    required: {
                      value: true,
                      message: t("screens:screen.screenRoute.yup.template"),
                    },
                  })}
                  className="custom-select"
                >
                  {map(templatesData?.nameboardTemplates, (value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </RoleBlock>
          </div>
          <div className="col-lg-8 row">
            <div className="col-12 mb-2">
              <iframe
                key={`${screenData?.nameboard?.template}`}
                ref={boardFrame}
                title={`screen-${screenId}`}
                className="border border-dark"
                src={screenData?.nameboard?.previewUrl}
                style={{
                  width: 600 / (4 / 3),
                  height: 600,
                }}
                frameBorder={0}
                marginHeight={0}
                marginWidth={0}
              />
            </div>
            <div className="col-12">
              <a
                href={screenData?.nameboard?.previewUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary mr-2"
              >
                {t("screens:screen.screenRoute.form.preview")}
              </a>
              <a
                href={screenData?.nameboard?.playerUrl}
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();

                  copyToClipboard(e.currentTarget.href);

                  toast.success<string>(
                    t("screens:screen.screenRoute.toast.linkCopied"),
                  );
                }}
              >
                {t("screens:screen.screenRoute.form.copyURL")}
              </a>
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </div>
    </FormProvider>
  );
});

export default Information;
