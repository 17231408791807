import React, { useMemo, useCallback } from "react";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import map from "lodash/map";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { FormProvider, useForm, Controller } from "react-hook-form";

import { useEntity } from "../../../../context/Entity";

import {
  QUERY_ENTITY,
  QUERY_ENTITIES,
  QUERY_COMPANIES,
} from "../../../../config/graphql/query";
import {
  CREATE_COMPANY,
  CREATE_ENTITY_PROPERTIES,
  UPDATE_SPACE,
} from "../../../../config/graphql/mutation";

import { reactSelectCustomStyles } from "../../../Employees/Employee/Information";

import { PropertyType } from "../../../../config/const/property";

type FieldValues = {
  attachedEntity: ISpace;
  properties?: Pick<ICompany, "id" | "title">[];
};

const EntityForm = React.memo(
  ({
    entity,
    onHide,
    onCreate,
  }: {
    entity: Partial<ISpace>;
    onHide: () => void;
    onCreate: () => void;
  }) => {
    const { t } = useTranslation(["entities", "common"]);

    const parent = useEntity();

    const methods = useForm<FieldValues>();

    const { data: entitiesData, loading: loadingEntities } = useQuery<{
      entities: ISpace[];
    }>(QUERY_ENTITIES, {
      variables: {
        filter: {
          parent: parent.id || null,
        },
      },
    });

    const options = useMemo(
      () =>
        entitiesData?.entities
          .filter((entity) => !entity.geoJSON)
          .map(({ id, title, parent, properties }) => ({
            id,
            title,
            properties,
            parent: parent.id,
          })) ?? [],
      [entitiesData],
    );

    const { data: propertiesData, loading: loadingProperties } = useQuery<{
      companies: ICompany[];
    }>(QUERY_COMPANIES);

    const propertyOptions = useMemo(
      () => propertiesData?.companies ?? [],
      [propertiesData],
    );

    const [onCreateCompany, { loading: creatingCompany }] =
      useMutation(CREATE_COMPANY);

    const refetchQueries = useMemo(
      () => [
        {
          query: QUERY_ENTITIES,
          variables: {
            filter: {
              parent: parent?.id || null,
            },
          },
        },
        {
          query: QUERY_ENTITY,
          variables: {
            id: parent?.id,
          },
        },
      ],
      [parent],
    );

    const [onUpdateSpace, { loading: updatingSpace }] = useMutation(
      UPDATE_SPACE,
      {
        awaitRefetchQueries: true,
        refetchQueries,
      },
    );

    const [onEntityAttachProperties, { loading: updatingProperties }] =
      useMutation(CREATE_ENTITY_PROPERTIES, {
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: QUERY_ENTITIES,
            variables: {
              filter: {
                parent: parent?.id || null,
              },
            },
          },
        ],
      });

    const onCreateOption = useCallback((inputValue: any) => {
      onCreateCompany({
        variables: {
          input: {
            type: PropertyType.Company,
            title: inputValue,
          },
        },
      })
        .then(({ data: { addCompany } }) => {
          // @ts-ignore
          const prevProperties = methods.getValues("properties") || [];
          // @ts-ignore
          methods.setValue("properties", [...prevProperties, addCompany]);
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = useCallback(
      ({ attachedEntity, properties }: FieldValues) => {
        if (updatingSpace || updatingProperties || creatingCompany) {
          return;
        }
        onUpdateSpace({
          variables: {
            input: {
              id: attachedEntity.id,
              title: attachedEntity.title,
              parent: attachedEntity.parent,
              geoJSON: entity.geoJSON,
            },
          },
        })
          .then(() => {
            return onEntityAttachProperties({
              variables: {
                input: {
                  id: attachedEntity.id,
                  properties: map(properties, "id"),
                },
              },
            });
          })
          .then(() => {
            toast.success<string>(
              t("entities:floorPlan.entity.toast.entityAttached"),
            );

            return onHide();
          })
          .catch((error) => {
            toast.error<string>(
              error?.networkError?.result?.errors?.[0]?.message ??
                error?.message,
            );
          });
      },
      [entity, onUpdateSpace, onHide, t],
    );

    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Body>
            <Controller
              name="attachedEntity"
              rules={{
                required: true,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div className="form-group">
                  <label htmlFor="attachedEntity">
                    {t("entities:floorPlan.entity.form.entity")}
                  </label>
                  <div className="d-flex">
                    <Select
                      isClearable
                      isLoading={loadingEntities}
                      getOptionLabel={({ title }) => title}
                      getOptionValue={({ id }) => `${id}`}
                      options={options}
                      onChange={(value) => {
                        methods.reset({
                          attachedEntity: value,
                          properties: value.properties,
                        });
                        onChange(value);
                      }}
                      value={value}
                      styles={reactSelectCustomStyles(!!error)}
                      className="mr-2 flex-grow-1"
                    />
                    <Button variant="primary" onClick={onCreate}>
                      &#x271A; Create new entity
                    </Button>
                  </div>
                </div>
              )}
            />
            <div className="form-group mb-4">
              <label htmlFor="properties">
                {t("entities:floorPlan.entity.form.company")}
              </label>
              <Controller
                name="properties"
                render={({
                  field: { onChange, ...props },
                  fieldState: { error },
                }) => (
                  <CreatableSelect
                    isMulti
                    isLoading={loadingProperties}
                    aria-describedby="properties-help"
                    getOptionLabel={({ title }) => title}
                    getOptionValue={({ id }) => `${id}`}
                    getNewOptionData={(_inputValue, optionLabel) => ({
                      id: "new",
                      title: optionLabel,
                    })}
                    options={propertyOptions}
                    onCreateOption={onCreateOption}
                    onChange={(value) => onChange(value)}
                    styles={reactSelectCustomStyles(!!error)}
                    {...props}
                  />
                )}
              />
              <small
                id="properties-help"
                className="form-text text-muted small"
              >
                {t("entities:floorPlan.entity.form.companyInfo")}
              </small>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              {t("common:cancel")}
            </Button>
            <input
              type="submit"
              className="btn btn-primary ml-2"
              value={t("common:save")}
            />
          </Modal.Footer>
        </form>
      </FormProvider>
    );
  },
);

export default EntityForm;
