import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFieldArray,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory, useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as yup from "yup";
import { isValidPhoneNumber } from "libphonenumber-js/min";
import clsx from "clsx";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import omit from "lodash/omit";
import TextArea from "../../../components/Textarea";
import Input from "../../../components/Input";
import PlanBlock from "../../../components/PlanBlock";
import { reactSelectCustomStyles } from "../../Employees/Employee/Information";

import {
  QUERY_EMPLOYEES,
  QUERY_MEETING,
  QUERY_MEETINGS,
  QUERY_VISITORS,
} from "../../../config/graphql/query";
import {
  MUTATION_CREATE_MEETING,
  MUTATION_UPDATE_MEETING,
  MUTATION_RESEND_MEMBER_ACCESS,
} from "../../../config/graphql/mutation";
import CodeBlock from "../../../components/CodeBlock";

type Attendee = {
  fullName: string;
  email: string;
  phone?: string;
  member?: string;
  access?: IAccess;
};

interface FieldValues {
  subject?: string;
  content?: string;
  dtstart: Date;
  dtend?: Date;
  organizer: Pick<IMeetingAttendee, "id" | "fullName" | "email">;
  attendees: Attendee[];
}

interface IModalWrapper {
  id: string;
  activeAttendee: Attendee | undefined;
  setActiveAttendee: (nextValue?: any) => void;
  onSaveAttendee: (attendee: Attendee) => void;
}

interface ModalFieldValues {
  fullName: string;
  email: string;
  phone: string;
}

const ModalWrapper = React.memo(
  ({
    id,
    activeAttendee,
    setActiveAttendee,
    onSaveAttendee,
  }: IModalWrapper) => {
    const { t } = useTranslation(["meeting", "common"]);

    const schema = useMemo(() => {
      return yup.object().shape({
        fullName: yup
          .string()
          .required(t("meeting:meeting.modal.yup.fullName.required")),
        email: yup
          .string()
          .email(t("meeting:meeting.modal.yup.email.invalid"))
          .required(t("meeting:meeting.modal.yup.email.required")),
        phone: yup
          .string()
          .nullable()
          .transform((v, o) => (o === "" ? undefined : v))
          .test(
            "validPhone",
            t("meeting:meeting.modal.yup.phone.invalid"),
            function validate(value) {
              if (value) {
                if (isValidPhoneNumber(value)) {
                  return true;
                }
                return false;
              }
              return true;
            },
          ),
      });
    }, [t]);

    const methods = useForm<ModalFieldValues>({
      resolver: yupResolver(schema),
      shouldFocusError: false,
      shouldUnregister: true,
      defaultValues: {
        fullName: "",
        email: "",
        phone: "",
      },
    });

    useEffect(() => {
      methods.reset(activeAttendee);
    }, [methods, activeAttendee]);

    const [resendMemberAccess, { loading }] = useMutation(
      MUTATION_RESEND_MEMBER_ACCESS,
      {
        onCompleted: () => {
          toast.success<string>(t("meeting:meeting.toast.resendCredentials"));
        },
        onError() {
          toast.success<string>(
            t("meeting:meeting.toast.resendCredentialsError"),
          );
        },
      },
    );

    const onSendCredentials = (accessId: string) => {
      resendMemberAccess({ variables: { id: accessId } });
    };

    const onSubmit = (input: ModalFieldValues) => {
      onSaveAttendee({ ...activeAttendee, ...input });
      setActiveAttendee();
    };

    return (
      <Modal
        show={!!activeAttendee}
        onHide={() => setActiveAttendee()}
        backdrop="static"
        keyboard={false}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title>{t("meeting:meeting.modal.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label htmlFor="fullName">
                  {t("meeting:meeting.form.attendee.fullName")}
                </label>
                <Input name="fullName" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="email">
                  {t("meeting:meeting.form.attendee.email")}
                </label>
                <Input
                  name="email"
                  className="form-control"
                  autoComplete="email"
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">
                  {t("meeting:meeting.form.attendee.phone")}
                </label>
                <Input name="phone" className="form-control" />
                <small className="form-text text-muted small">
                  {t("meeting:meeting.form.attendee.phone-help")}
                </small>
              </div>
              {id && activeAttendee?.access && (
                <div>
                  <div>{t("meeting:meeting.modal.pinCode")}</div>
                  <div className="mb-3">
                    {activeAttendee?.access?.fallbackCode}
                  </div>
                  <PlanBlock exclude={["free"]}>
                    <div>{t("meeting:meeting.modal.qrCode")}</div>
                    <div
                      style={{
                        width: "200px",
                        height: "200px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={activeAttendee?.access?.file?.absolutePath}
                        width="270px"
                        height="270px"
                        style={{ transform: "translate(-35px,-35px)" }}
                        alt={t("meeting:meeting.modal.qrCode")}
                      />
                    </div>
                  </PlanBlock>
                  <div
                    className="btn btn-primary mt-3"
                    aria-disabled={!!loading}
                    onClick={() =>
                      activeAttendee?.access?.id &&
                      onSendCredentials(activeAttendee?.access?.id)
                    }
                  >
                    {t("meeting:meeting.modal.resend")}
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setActiveAttendee();
                }}
              >
                {t("common:cancel")}
              </Button>
              <Button type="submit" variant="primary">
                {t("common:save")}
              </Button>
            </Modal.Footer>
          </form>
        </FormProvider>
      </Modal>
    );
  },
);

const Meeting = React.memo(() => {
  const [activeAttendee, setActiveAttendee] = useState<Attendee>();

  const [attendeeIndex, setAttendeeIndex] = useState<number>(0);

  const { t } = useTranslation(["meeting", "common"]);

  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const schema = useMemo(() => {
    return yup.object().shape({
      organizer: yup
        .object()
        .shape({
          id: yup.string(),
        })
        .default(undefined)
        .required(t("meeting:meeting.yup.host.required")),

      dtstart: yup
        .date()
        .transform((v, o) => (o === null ? undefined : v))
        .test(
          "allow",
          t("meeting:meeting.yup.dtstart.invalid"),
          function validate(value) {
            if (value instanceof Date) {
              return dayjs(value).isAfter(dayjs(new Date(Date.now())));
            }
            if (!value) {
              return true;
            }
            return false;
          },
        ),
      dtend: yup
        .mixed()
        .transform((v, o) => (o === null ? undefined : v))
        .test(
          "allow",
          t("meeting:meeting.yup.dtend.invalid"),
          function validate(value) {
            if (value instanceof Date) {
              return (
                this.parent.dtstart &&
                dayjs.utc(value).isAfter(dayjs(this.parent.dtstart))
              );
            }
            return true;
          },
        ),
    });
  }, [t]);

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {
      organizer: undefined,
      subject: "",
      content: "",
      // dtstart: dayjs()
      //   .set("minutes", Math.ceil(new Date().getMinutes() / 30) * 30)
      //   .toDate(),
      dtstart: undefined,
      dtend: undefined,
      attendees: [],
    },
  });

  const {
    fields: attendees,
    update,
    append,
    replace,
  } = useFieldArray<FieldValues, "attendees">({
    control: methods.control,
    name: "attendees",
  });

  useQuery(QUERY_MEETING, {
    skip: !id,
    variables: { id },
    nextFetchPolicy: "network-only",
    onCompleted: ({
      meeting: { dtstart, dtend, attendees, subject, content, organizer },
    }: {
      meeting: IMeeting;
    }) => {
      methods.reset({
        organizer: {
          id: organizer.member?.id,
          fullName: organizer.fullName,
          email: organizer.email,
        },
        subject: subject ?? "",
        content: content ?? "",
        dtstart: new Date(dayjs(dtstart).local().format()),
        dtend: dtend ? new Date(dayjs(dtend).local().format()) : undefined,
      });
      replace(
        attendees.map((attendee) => ({
          fullName: attendee.fullName,
          email: attendee.email,
          phone: attendee.phone,
          member: attendee.member?.id,
          access: attendee.access,
        })),
      );
    },
  });

  const { data: employeesData } = useQuery<{ employees: IMember[] }>(
    QUERY_EMPLOYEES,
    {
      nextFetchPolicy: "network-only",
      variables: {
        filter: {
          preferences: {
            meetingAttendee: {
              enabled: {
                EQ: true,
              },
            },
          },
        },
        pagination: {
          limit: 10000,
        },
      },
    },
  );

  const organizerOptions = useMemo(
    () => employeesData?.employees ?? [],
    [employeesData],
  );

  const { data: visitorsData } = useQuery<{ visitors: IMember[] }>(
    QUERY_VISITORS,
    {
      nextFetchPolicy: "network-only",
    },
  );

  const visitorOptions = useMemo(
    () =>
      visitorsData?.visitors
        .filter(
          (visitor) =>
            !attendees.some((attendee) => attendee.member === visitor.id),
        )
        .map((visitor: IMember) => {
          return {
            ...visitor,
            label: `${visitor.fullName} (${visitor.email})`,
          };
        }) ?? [],
    [visitorsData, attendees],
  );

  const handleCreate = useCallback(
    (inputValue: string) => {
      setAttendeeIndex(attendees.length);
      setActiveAttendee({ fullName: inputValue, email: "", phone: "" });
    },
    [attendees.length],
  );

  const [onCreateMeeting] = useMutation(MUTATION_CREATE_MEETING, {
    refetchQueries: [
      {
        query: QUERY_MEETINGS,
      },
    ],
  });
  const [onUpdateMeeting] = useMutation(MUTATION_UPDATE_MEETING, {
    refetchQueries: [
      {
        query: QUERY_MEETINGS,
      },
    ],
  });

  const onSaveAttendee = useCallback(
    (attendee: Attendee) => {
      update(attendeeIndex, attendee);
    },
    [attendeeIndex, update],
  );

  const onSubmit = async (variables: FieldValues) => {
    const { organizer, dtstart, dtend, attendees, ...values } = variables;

    let input: {
      id?: string;
      organizer: { employee: string };
      subject?: string;
      content?: string;
      dtstart: string;
      dtend?: string;
      attendees: Attendee[];
    } = {
      ...values,
      organizer: { employee: organizer.id },
      dtstart: dayjs(dtstart).utc().format(),
      dtend: dtend ? dayjs(dtend).utc().format() : "",
      attendees: attendees.map(({ fullName, email, phone, member }) => {
        return {
          fullName,
          email,
          ...(phone && { phone }),
          ...(member && { member }),
        };
      }),
      ...(id && { id }),
    };

    if (input?.dtend === "") {
      input = omit(input, "dtend");
    }

    if (id) {
      return onUpdateMeeting({ variables: { input } })
        .then(() => {
          toast.success<string>(t("meeting:meeting.toast.updated"));
          history.replace(`/meetings`);
        })
        .catch(() => {
          toast.error<string>(t("meeting:meeting.toast.updatedError"));
        });
    }

    return onCreateMeeting({ variables: { input } })
      .then(
        ({
          data: {
            createMeeting: { id },
          },
        }) => {
          toast.success<string>(t("meeting:meeting.toast.created"));

          history.replace(`/meetings/${id}`);
        },
      )
      .catch(() => {
        toast.error<string>("meeting:meeting.toast.createdError");
      });
  };

  const renderAttendee = useCallback(
    (attendee: Attendee, index: number) => (
      <div key={index} className="py-2 border-bottom d-flex flex-column">
        <div className="d-flex justify-content-between py-2">
          {attendee.fullName} / {attendee.email}
        </div>
        <div className="align-self-end">
          <div
            className="btn btn-primary"
            onClick={() => {
              setAttendeeIndex(index);
              setActiveAttendee(attendee);
            }}
          >
            {t("common:view")}
          </div>

          <div
            className="btn btn-primary ml-3"
            onClick={() => {
              methods.setValue(
                `attendees`,
                attendees.filter((_, i: number) => i !== index),
              );
            }}
          >
            {t("common:remove")}
          </div>
        </div>
      </div>
    ),
    [t, attendees, methods],
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/meetings">
              {t("meeting:meeting.nav.meeting", { count: 2 })}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("meeting:meeting.nav.meeting", { count: 1 })}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form className="row mt-2" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <h6>{t("meeting:meeting.heading.primary")}</h6>
            <Controller
              name="organizer"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div className="form-group">
                  <label htmlFor="organizer">
                    {t("meeting:meeting.form.host")}
                  </label>
                  <Select
                    getOptionLabel={({ fullName, email }) =>
                      `${fullName} (${email})`
                    }
                    getOptionValue={({ id }) => id}
                    options={organizerOptions}
                    onChange={(value) => onChange(value || [])}
                    value={value}
                    className={clsx({
                      "is-invalid": !!error,
                    })}
                    styles={reactSelectCustomStyles(!!error)}
                  />
                  <div className="invalid-feedback">{error?.message}</div>
                </div>
              )}
            />
            <CodeBlock exclude={["mmmr"]}>
              <div className="form-group">
                <label htmlFor="subject">
                  {t("meeting:meeting.form.title")}
                </label>
                <Input name="subject" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="content">
                  {t("meeting:meeting.form.description")}
                </label>
                <TextArea name="content" className="form-control" />
              </div>
            </CodeBlock>
            <h6>{t("meeting:meeting.heading.secondary")}</h6>
            <div>
              <label htmlFor="dtstart">
                {t("meeting:meeting.form.dtstart")}
              </label>
              <div className="mb-2">
                <Controller
                  name="dtstart"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <div
                        className={clsx("", {
                          "is-invalid": !!error,
                        })}
                      >
                        <DatePicker
                          className={clsx("form-control", {
                            "is-invalid": !!error,
                          })}
                          showTimeSelect
                          selected={value}
                          // onChange={onChange}
                          onChange={(date) => {
                            onChange(date);
                            if (!!date) {
                              methods.setValue(
                                "dtend",
                                dayjs(date).add(1, "hour").toDate(),
                              );
                            }
                          }}
                          filterDate={(date) =>
                            dayjs() <= dayjs(date).add(1, "day")
                          }
                          filterTime={(time) => dayjs() <= dayjs(time)}
                          timeIntervals={30}
                          dateFormat="dd/MM/yyyy p"
                        />
                      </div>

                      <div className="invalid-feedback">
                        {error?.message ?? "Invalid"}
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
            <div>
              <label htmlFor="dtend">{t("meeting:meeting.form.dtend")}</label>
              <div>
                <Controller
                  name="dtend"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <div
                        className={clsx("mb3", {
                          "is-invalid": !!error,
                        })}
                      >
                        <DatePicker
                          className={clsx("form-control", {
                            "is-invalid": !!error,
                          })}
                          showTimeSelect
                          selected={value}
                          onChange={onChange}
                          filterDate={(date) =>
                            dayjs(methods.getValues("dtstart")) <=
                            dayjs(date).add(1, "day")
                          }
                          filterTime={(time) =>
                            dayjs(methods.getValues("dtstart")) < dayjs(time)
                          }
                          timeIntervals={30}
                          dateFormat="dd/MM/yyyy p"
                        />
                      </div>

                      <div className="invalid-feedback">
                        {error?.message ?? "Invalid"}
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <h6>{t("meeting:meeting.heading.tertiary")}</h6>
            <div className="form-group">
              <label htmlFor="organizer">
                {t("meeting:meeting.form.attendee.add")}
              </label>
              <CreatableSelect
                controlShouldRenderValue={false}
                getOptionValue={({ id }) => id}
                onChange={(newValue) => {
                  if (newValue) {
                    append({
                      fullName: newValue.fullName,
                      email: newValue.email,
                      phone: newValue.phone,
                      member: newValue.id,
                    });
                  }
                }}
                placeholder={t("meeting:meeting.form.attendee.placeholder")}
                onCreateOption={handleCreate}
                options={visitorOptions}
                styles={reactSelectCustomStyles(false)}
              />
            </div>
            {attendees?.map(renderAttendee)}
            <button
              type="submit"
              className="btn btn-primary mt-3"
              disabled={methods.formState.isSubmitting}
            >
              {t("meeting:meeting.form.submitBtn")}
            </button>
          </div>
        </form>
      </FormProvider>
      <ModalWrapper
        id={id}
        activeAttendee={activeAttendee}
        setActiveAttendee={setActiveAttendee}
        onSaveAttendee={onSaveAttendee}
      />
    </div>
  );
});

export default Meeting;
