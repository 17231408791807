import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import some from "lodash/some";

import { QUERY_CURRENT_USER } from "../../config/graphql/query";

export const useUserHasRole = (user: IUser | undefined, roles: UserRole[]) => {
  const hasRole = useMemo(
    () => some(roles, (role) => user?.roles.includes(role)),
    [roles, user],
  );

  return hasRole;
};

export const useHasRole = (roles: UserRole[]) => {
  const { data } = useQuery<{ me: IUser }>(QUERY_CURRENT_USER);

  const hasRole = useUserHasRole(data?.me, roles);

  return hasRole;
};

export default useHasRole;
