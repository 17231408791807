import React, { useCallback, useContext, useState } from "react";

import clsx from "clsx";

import { useTranslation } from "react-i18next";

import { useDropzone, DropzoneOptions } from "react-dropzone";

import useSocket from "../lib/hooks/useSocket";
import { readFileAsDataURL, FileUpload } from "../lib/common/readFileAsDataURL";

type UploadedFile = {
  error: boolean;
  file: {
    filename: string;
    id: string;
    md5: string;
    path: string;
  };
  message: string;
};

const UploadContext = React.createContext<{
  results: { [key: string]: Array<FileUpload> };
  onChange: (files: { [key: string]: Array<FileUpload> }) => void;
}>({
  results: {},
  onChange: () => {
    //
  },
});

const { Provider } = UploadContext;

export const useUploadProvider = () => useContext(UploadContext);

export const useFiles = (
  name: string,
): [
  undefined | Array<FileUpload>,
  { onUpload: () => Promise<Array<UploadedFile>> },
] => {
  const socket = useSocket("/upload");

  const { results } = useUploadProvider();

  const files = results[name];

  const onUpload = useCallback((): Promise<Array<UploadedFile>> => {
    return new Promise((resolve, reject) => {
      if (!socket) {
        throw new Error("Socket not ready");
      }

      socket.send(
        JSON.stringify({
          filename: files[0].file.name,
          file: files[0].data,
        }),
      );

      socket.onerror = reject;
      socket.onmessage = (e) => {
        let message:
          | undefined
          | {
              error: boolean;
              file: {
                filename: string;
                id: string;
                md5: string;
                path: string;
              };
              message: string;
            };

        try {
          message = JSON.parse(e.data);
        } catch {
          message = e.data;
        }

        if (!message) {
          return reject(new Error("Failed to upload the file"));
        }

        return resolve([message]);
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return [files, { onUpload }];
};

const UploadProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [results, setResults] = useState<{ [key: string]: Array<FileUpload> }>(
    {},
  );

  const onChange = useCallback(
    (nextResults: { [key: string]: Array<FileUpload> }) => {
      setResults((prev) => ({
        ...prev,
        ...nextResults,
      }));
    },
    [],
  );

  return <Provider value={{ results, onChange }}>{children}</Provider>;
};

interface ControllerUploadInputProps<IsMulti extends boolean = false> {
  name: string;
  multiple: IsMulti;
  onChange: (files: IsMulti extends true ? File[] : File) => void;
  dropZoneOptions?: DropzoneOptions;
}

export const UncontrolledUploadInput = <IsMulti extends boolean = false>(
  props: ControllerUploadInputProps<IsMulti>,
) => {
  const { t } = useTranslation(["common"]);

  const { name, dropZoneOptions, multiple, onChange } = props;

  const state = useDropzone({
    ...dropZoneOptions,
    onDrop: (files) => {
      // @ts-ignore
      return onChange(multiple === true ? files : files[0]);
    },
    multiple,
  });

  return (
    <div
      className={clsx("bg-light border rounded py-4  position-relative", {
        "border-dark": !state.isDragActive,
        "border-primary": state.isDragActive,
      })}
      {...state.getRootProps()}
    >
      <input {...state.getInputProps()} name={name} />
      <div className="d-flex flex-column align-items-center">
        <i className="fa fa-upload text-muted h2" />
        {t("common:fileUpload")}
      </div>
    </div>
  );
};

export const UploadInput: React.FC<{
  name: string;
  options?: DropzoneOptions;
}> = (
  { options, name } = {
    name: "",
    options: {
      accept: {
        image: ["*"],
      },
      multiple: false,
    },
  },
) => {
  const { t } = useTranslation(["common"]);

  const { onChange } = useUploadProvider();

  const onDrop = useCallback(
    async (files: File[]) => {
      const results = await Promise.all(files.map(readFileAsDataURL));

      onChange({
        [name]: results,
      });
    },
    [name, onChange],
  );

  const state = useDropzone({ ...options, onDrop });

  return (
    <div
      className={clsx("bg-light border rounded py-4  position-relative", {
        "border-dark": !state.isDragActive,
        "border-primary": state.isDragActive,
      })}
      {...state.getRootProps()}
    >
      <input {...state.getInputProps()} name={name} />
      <div className="d-flex flex-column align-items-center text-center">
        <i className="fa fa-upload text-muted h2" />
        {t("common:fileUpload")}
      </div>
    </div>
  );
};

export const UploadPreview: React.FC<{
  name: string;
  placeholder?: string;
  isPdf?: boolean;
}> = ({ name, placeholder, isPdf }) => {
  const { results } = useUploadProvider();

  if (
    !((Array.isArray(results[name]) && results[name].length > 0) || placeholder)
  ) {
    return null;
  }

  if (isPdf) {
    return (
      <iframe
        title="pdf"
        src={`${results[name]?.[0]?.data || placeholder}#toolbar=0`}
        style={{ width: "100%", height: "35.5rem" }}
      />
    );
  }

  return (
    <div
      className="background-image bg-16-9 border border-dark"
      style={{
        backgroundImage: `url("${results[name]?.[0]?.data || placeholder}")`,
      }}
    />
  );
};

export default UploadProvider;
