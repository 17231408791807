import React from "react";

import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import UserRoute from "./User";
import UserEmailRoute from "./UserEmail";
import UsersRoute from "./Users";

const UsersRouter = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <UsersRoute />
      </Route>
      <Route exact path={`${path}/new`}>
        <UserRoute />
      </Route>
      <Route exact path={`${path}/:id`}>
        <UserRoute />
      </Route>
      <Route exact path={`${path}/:id/email`}>
        <UserEmailRoute />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default UsersRouter;
