import React, { useCallback, useMemo, useRef } from "react";

import orderBy from "lodash/orderBy";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";

import Button from "react-bootstrap/Button";

import { toast } from "react-toastify";
import { useForm, FormProvider } from "react-hook-form";

import Table from "../../../components/Table";
import TextArea from "../../../components/Textarea";

import {
  QUERY_ENTITIES_DECEASED,
  QUERY_WAYFINDING_SCREENS,
} from "../../../config/graphql/query";
import { UPDATE_WAYFINDING_SCREEN } from "../../../config/graphql/mutation";
import { useCurrentLanguage } from "../../../lib/hooks/useCurrentLanguage";
import { useCurrentClient } from "../../../context/Client";

type FuneralFieldValues = {
  announcement: string;
};

const TableRow = ({ item }: { item: IEntityWithDeceased }) => {
  const { id, title, properties } = item;

  const { t } = useTranslation(["common"]);

  return (
    <>
      <tr>
        <td>{title}</td>
        <td>{properties[0]?.title}</td>
        <td>{properties[0]?.gender}</td>
        <td className="text-nowrap">
          <div className="d-flex">
            <Link to={`/deceased/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
    </>
  );
};

const DeceasedList = React.memo(() => {
  const { t } = useTranslation(["deceasedList", "common"]);

  const funeralGroningenId = useRef<string>();

  const methods = useForm<FuneralFieldValues>();

  const language = useCurrentLanguage();

  const currentClient = useCurrentClient();

  useQuery<{
    wayfindingScreens: IWayfindingScreen[];
  }>(QUERY_WAYFINDING_SCREENS, {
    variables: { filter: { template: "funeral-groningen-multi" } },
    onCompleted: ({ wayfindingScreens }) => {
      funeralGroningenId.current = wayfindingScreens[0].id;
      methods.reset({
        announcement: wayfindingScreens[0].metadata.announcement[language],
      });
    },
    notifyOnNetworkStatusChange: true,
  });

  const variables = useMemo(
    () => ({
      filter: {
        type: "Space",
      },
    }),
    [],
  );

  const { data: entitiesDeceasedData } = useQuery<{
    entities?: Array<IEntityWithDeceased>;
  }>(QUERY_ENTITIES_DECEASED, {
    fetchPolicy: "no-cache",
    variables,
  });

  const entities: Array<IEntityWithDeceased> = orderBy(
    entitiesDeceasedData?.entities,
    [
      (item) => {
        const value = parseInt(item.title, 10);

        return value;
      },
    ],
    ["asc"],
  );

  const renderEntity = useCallback(
    (item: IEntityWithDeceased) => <TableRow key={item.id} item={item} />,
    [],
  );

  const [onUpdate] = useMutation(UPDATE_WAYFINDING_SCREEN);

  const onSubmit = async ({ announcement }: FuneralFieldValues) => {
    onUpdate({
      variables: {
        input: {
          id: funeralGroningenId.current,
          metadata: { announcement },
        },
      },
    })
      .then(() => {
        toast.success<string>(t("deceasedList:toast.updatedAnnouncement"));
      })
      .catch(() => {
        toast.error<string>(t("deceasedList:toast.updatedAnnouncementError"));
      });
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb" className="mb-4">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("deceasedList:nav")}
          </li>
        </ol>
      </nav>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th scope="col">{t("deceasedList:th.chapelName")}</th>
            <th scope="col">{t("deceasedList:th.deceasedName")}</th>
            <th scope="col">{t("deceasedList:th.deceasedGender")}</th>
            <th scope="col">{t("deceasedList:th.actions")}</th>
          </tr>
        </thead>
        <tbody>{entities.map(renderEntity)}</tbody>
      </Table>
      {currentClient?.title !== "Ename" && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-5 col-md-8 col-sm-12">
                <div className="form-group">
                  <label htmlFor="chapelName" className="h6 text-uppercase">
                    {t("deceasedList:form.announcement")}
                  </label>
                  <TextArea name="announcement" className="form-control" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5 col-md-8 col-sm-12 d-flex justify-content-end">
                <input
                  type="submit"
                  className="btn btn-primary mb-3"
                  value={t("deceasedList:button.updateAnnouncement")}
                />
              </div>
            </div>
          </form>
        </FormProvider>
      )}
    </div>
  );
});

export default DeceasedList;
