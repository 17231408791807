import React, { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";

import * as yup from "yup";

import map from "lodash/map";

import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { useRouteMatch, useHistory, Link } from "react-router-dom";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "../../components/Input";

import {
  QUERY_VISIONECT_DEVICE,
  QUERY_VISIONECT_DEVICES_EXTERNAL,
} from "../../config/graphql/query";
import {
  CREATE_VISIONECT_DEVICE,
  UPDATE_VISIONECT_DEVICE,
} from "../../config/graphql/mutation";

type FormVariables = {
  name: string;
  deviceId: string;
};

const Visionect = React.memo(() => {
  const { t } = useTranslation(["client", "common"]);

  const history = useHistory();
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string(),
        deviceId: yup.string(),
      }),
    [],
  );

  const methods = useForm<FormVariables>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
  });

  const { data } = useQuery<{ visionectDevice: IVisionectDevice }>(
    QUERY_VISIONECT_DEVICE,
    {
      skip: !id,
      variables: {
        id,
      },
      onError: () => history.replace("/visionects"),
    },
  );

  useEffect(() => {
    if (data?.visionectDevice) {
      methods.reset({
        ...data?.visionectDevice,
        deviceId: data?.visionectDevice?.deviceData?.Uuid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [onCreate] = useMutation(CREATE_VISIONECT_DEVICE);
  const [onUpdate] = useMutation(UPDATE_VISIONECT_DEVICE);

  const { data: visionectDevicesData } = useQuery<{
    visionectDevicesAPI: IVisionectDeviceData[];
  }>(QUERY_VISIONECT_DEVICES_EXTERNAL);

  const devices = useMemo(
    () =>
      map(visionectDevicesData?.visionectDevicesAPI, (item) => ({
        value: item?.Uuid,
        label: item?.Options?.Name,
      })),
    [visionectDevicesData],
  );

  const onSubmit = (input: FormVariables) => {
    if (id) {
      return onUpdate({ variables: { input: { ...input, id } } })
        .then(() => {
          toast.success<string>(t("client:client.token.toast.updated"));
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    }

    return onCreate({ variables: { input } })
      .then(
        ({
          data: {
            addVisionectDevice: { id },
          },
        }) => {
          history.replace(`/visionects/${id}`);

          toast.success<string>("Visionect device created");
        },
      )
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );
      });
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/visionects">{t("visionects:title")}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("visionect:title")}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="title">{t("visionects:input.label.name")}</label>
              <Input name="name" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="deviceId">
                {t("visionects:input.label.deviceId")}
              </label>
              <select
                {...methods.register("deviceId")}
                className="custom-select"
              >
                {devices.map((device) => (
                  <option key={device.value} value={device.value}>
                    {device.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </FormProvider>
    </div>
  );
});

export default Visionect;
