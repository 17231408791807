import ReactDOM from "react-dom";
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";

import * as L from "leaflet";

import "leaflet-draw";

import qs from "query-string";

import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";

import Modal from "react-bootstrap/Modal";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";

import {
  drawControlOptions,
  polygonStyle,
  polygonActiveStyle,
  polygonDisabledStyle,
  polygonDisabledActiveStyle,
} from "../../../../config/const/leaflet";

import { useEntity, EntityProvider } from "../../../../context/Entity";

import getImageDimensions from "../../../../lib/common/getImageDimensions";

import {
  DELETE_ENTITIES,
  UPDATE_SPACE,
} from "../../../../config/graphql/mutation";
import { QUERY_ENTITY, QUERY_ENTITIES } from "../../../../config/graphql/query";

import EntityForm from "./EntityForm";
import AttachEntityForm from "./AttachEntityForm";
import EntityPopup from "./EntityPopup";
import useSearch from "../../../../lib/hooks/useSearch";

const FloorplanContext = React.createContext<{
  floorplan: React.MutableRefObject<L.Map | null>;
}>({
  floorplan: React.createRef(),
});

const { Provider: FloorplanProvider } = FloorplanContext;

export const useFloorplan = (): React.MutableRefObject<L.Map | null> => {
  const { floorplan } = useContext(FloorplanContext);

  return floorplan;
};

const Floorplan = React.memo(() => {
  const {
    // @ts-ignore
    params: { id },
  } = useRouteMatch();

  const history = useHistory();

  const { t } = useTranslation(["entities"]);

  const search: { view?: string; edit?: string } = useSearch();

  // * Double check if map was initialized
  const [initialized, setInitialized] = useState(false);

  const entity = useEntity() as IFloor;

  // * Leaflet floorplan reference
  const floorplan = useRef<L.Map | null>(null);
  const controls = useRef<L.Control.Draw | null>(null);
  const entities = useRef<L.FeatureGroup<any> | null>(null);

  const [entityJSON, setEntityJSON] = useState<Partial<ISpace> | null>(null);

  const [modalType, setModalType] = useState<
    "create" | "attach" | "update" | null
  >(null);

  const [onDeleteEntities] = useMutation(DELETE_ENTITIES, {
    refetchQueries: [
      {
        query: QUERY_ENTITIES,
        variables: {
          filter: {
            parent: entity?.id || null,
          },
        },
      },
    ],
  });

  const [onUpdateEntity] = useMutation(UPDATE_SPACE, {
    refetchQueries: [
      {
        query: QUERY_ENTITIES,
        variables: {
          filter: {
            parent: entity?.id || null,
          },
        },
      },
    ],
  });

  /**
   * Remove entity from floorplan, in cases when user cancels creation
   */
  const onRemoveEntity = useCallback(() => {
    if (entityJSON?.geoJSON?.id) {
      entities.current?.removeLayer(entityJSON?.geoJSON?.id);
    }

    history.push({
      search: "",
    });

    setEntityJSON(null);
    setModalType(null);
  }, [entityJSON, setEntityJSON]);

  /**
   * Load the entity we are trying to edit based on query params
   */
  useQuery(QUERY_ENTITY, {
    skip: !(initialized && !!search?.edit),
    fetchPolicy: "network-only",
    variables: {
      id: search?.edit,
    },
    onError: (error) => {
      toast.error<string>(error.message);

      return history.push({ search: "" });
    },
    onCompleted: ({ entity }) => {
      // console.log(entity);
      // if (!(entity?.parent?.id === entity?.id)) {
      //   toast.error<string>("Could not find entity");

      //   return history.push({ search: "" });
      // }

      setEntityJSON(entity);
    },
  });

  /**
   * Get all entities for current floor entity
   */
  const { data: entityData, loading: loadingEntities } = useQuery(
    QUERY_ENTITY,
    {
      skip: !initialized,
      variables: {
        id,
      },
      // onError: console.log,
    },
  );

  const entityEntities = useMemo(
    () => get(entityData, "entity.entities") || [],
    [entityData],
  );

  const hasEntityForAttach = useMemo(
    () => entityEntities.some((entity: ISpace) => !entity.geoJSON),
    [entityEntities],
  );

  useEffect(() => {
    if (entityJSON && entityJSON.id) {
      setModalType("update");
    }

    if (entityJSON && !entityJSON.id && hasEntityForAttach) {
      setModalType("attach");
    }

    if (entityJSON && !entityJSON.id && !hasEntityForAttach) {
      setModalType("create");
    }
  }, [entityJSON]);

  const onAttach = useCallback(() => {
    setModalType("attach");
  }, []);

  const onCreate = useCallback(() => {
    setModalType("create");
  }, []);

  useEffect(() => {
    const onInitializeEntities = () => {
      if (
        !(initialized && Array.isArray(entityEntities) && entityEntities.length)
      ) {
        return;
      }

      // @ts-ignore
      entities.current?.clearLayers();

      const GeoJSON = map(
        map(
          filter(entityEntities, "geoJSON"),
          ({
            geoJSON: { properties, ...rest },
            properties: entityProperties,
            id,
          }) => ({
            ...rest,
            id,
            properties: { ...properties, properties: entityProperties },
          }),
        ),
      );

      // Array<geojson.Feature<geojson.Point, P>>
      // @ts-ignore
      L.geoJSON(GeoJSON, {
        onEachFeature(feature: TSpaceMetaFeature, layer) {
          if (get(layer, "feature.properties.type") === "rectangle") {
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            layer = L.rectangle(layer.getBounds());

            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            layer.feature = feature;
          }

          if (!entities.current) {
            return;
          }

          const { id } = feature;

          const isEmpty = !!feature?.properties?.properties?.length ?? false;

          // @ts-ignore
          layer?.setStyle(isEmpty ? polygonStyle : polygonDisabledStyle);
          layer.addTo(entities.current);

          const el = document.createElement("div");

          /**
           * ! Very important.
           * Based on this set of attributes we are able to render a popup as reactPortal in order to preserve context
           * of the application
           */
          el.setAttribute("id", "space-popup");
          el.setAttribute("data-id", `${id}`);

          const popup = layer.bindPopup(el, { minWidth: 150 });

          popup.on("popupclose", () => {
            /**
             * Reset the query param when popup closes but preserve
             * the query since we can go from popup to edit.
             */
            history.push({
              search: qs.stringify(
                { ...qs.parse(window.location.search), view: null },
                { skipNull: true },
              ),
            });

            // @ts-ignore
            layer?.setStyle(isEmpty ? polygonStyle : polygonDisabledStyle);
          });

          popup.on("popupopen", () => {
            history.push({
              search: qs.stringify({ view: id }),
            });

            // @ts-ignore
            layer?.setStyle(
              isEmpty ? polygonActiveStyle : polygonDisabledActiveStyle,
            );
          });

          /**
           * Open view popup after load if there is an appropriate query param
           */
          if (`${id}` === search?.view) {
            layer.openPopup();
          }
        },
      });
    };

    onInitializeEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityEntities, initialized]);

  const initializeDrawControls = useCallback(() => {
    if (!(floorplan.current && entities.current)) {
      return;
    }

    controls.current = new L.Control.Draw({
      ...drawControlOptions,
      edit: {
        edit: {
          selectedPathOptions: {
            ...polygonStyle,
          },
        },
        featureGroup: entities.current,
      },
    });

    floorplan.current.addControl(controls.current);

    // ? http://leaflet.github.io/Leaflet.draw/docs/leaflet-draw-latest.html#l-draw-event-draw:created

    // @ts-ignore
    floorplan.current.on(L.Draw.Event.CREATED, (e: L.DrawEvents.Created) => {
      const type = e.layerType as unknown as LayerType;
      const { layer } = e;

      setEntityJSON({
        title: "",
        geoJSON: {
          ...layer.toGeoJSON(),
          // @ts-ignore
          id: L.stamp(layer),
          properties: {
            type,
          },
        },
      });

      // * Do not add layer until it is saved, save us the haste of removing a layer if user cancels the entry
      // @ts-ignore
      return entities.current?.addLayer(layer);
    });

    // ? http://leaflet.github.io/Leaflet.draw/docs/leaflet-draw-latest.html#l-draw-event-draw:edited
    // @ts-ignore
    floorplan.current.on(L.Draw.Event.EDITED, (e: L.DrawEvents.Edited) => {
      const { layers } = e;

      const updated: Array<Promise<any>> = [];

      layers.eachLayer((layer) => {
        // @ts-ignore
        const { id, ...rest }: Feature = layer.toGeoJSON();

        updated.push(
          onUpdateEntity({
            variables: {
              input: { id, geoJSON: rest },
            },
          }),
        );
      });

      // * Await list of promises to update the floorplan items
      // * @todo - Replace with bulk update action with loading
      return Promise.all(updated)
        .then(() => {
          toast.success<string>("Entities updates.");
        })
        .catch((error) => {
          toast.error<string>(error.message);
        });
    });

    // @ts-ignore
    floorplan.current.on(L.Draw.Event.DELETED, (e: L.DrawEvents.Deleted) => {
      const { layers } = e;

      let removed: string[] = [];

      layers.eachLayer((layer) => {
        // @ts-ignore
        removed.push(layer.toGeoJSON()?.id);
      });

      removed = removed.filter(Boolean);

      onDeleteEntities({ variables: { ids: removed } })
        .then(() => {
          toast.success<string>("Entities removed successfully");
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    (async () => {
      floorplan.current = L.map("map", {
        zoom: 4,
        crs: L.CRS.Simple,
        attributionControl: false,
        dragging: !L.Browser.mobile,
        tap: !L.Browser.mobile,
      });

      entities.current = new L.FeatureGroup();

      floorplan.current.addLayer(entities.current);

      initializeDrawControls();

      floorplan.current.whenReady(() => setInitialized(true));
      floorplan.current.setView([0, 0], 4);

      const url = entity?.plan?.absolutePath;

      if (typeof url === "string" && url.length > 0) {
        const { width: w, height: h } = await getImageDimensions(url).catch(
          () => ({
            width: 1080,
            height: 720,
          }),
        );

        // calculate the edges of the image, in coordinate space
        const southWest = floorplan.current.unproject([0, h], 4);

        const northEast = floorplan.current.unproject([w, 0], 4);

        const bounds = new L.LatLngBounds(southWest, northEast);

        const overlay = L.imageOverlay(url, bounds);

        // * Fit map to image size
        floorplan.current.fitBounds(overlay.getBounds());
        // floorplan.current.setMaxBounds(overlay.getBounds());

        overlay.addTo(floorplan.current);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPopup = () => {
    if (!(search.view && initialized && !loadingEntities)) {
      return null;
    }

    // * Find element with specific selector that represents a leaflet popup
    const node = document.querySelector(
      `#space-popup[data-id="${search.view}"]`,
    );

    if (!node) {
      return null;
    }

    // * Render popup content inside already existing leaflet popup container
    return ReactDOM.createPortal(
      <EntityProvider value={{ id: search.view }}>
        <EntityPopup />
      </EntityProvider>,
      node,
    );
  };

  return (
    <FloorplanProvider value={{ floorplan }}>
      <div className="row p-3">
        <div className="col">
          <div
            id="map"
            style={{
              height: 720,
              zIndex: 1, // ! Much important
            }}
          />
          {renderPopup()}
        </div>
        <Modal
          show={!!entityJSON}
          centered
          onHide={onRemoveEntity}
          size="lg"
          contentClassName="py-3 px-5"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              {modalType === "create" && t("entities:floorPlan.modal.create")}
              {modalType === "attach" && t("entities:floorPlan.modal.attach")}
              {modalType === "update" && t("entities:floorPlan.modal.update")}
            </Modal.Title>
          </Modal.Header>
          {(modalType === "update" || modalType === "create") &&
            !!entityJSON && (
              <EntityForm
                type={modalType}
                entity={entityJSON}
                onHide={onRemoveEntity}
                onAttach={onAttach}
                hasEntityForAttach={hasEntityForAttach}
              />
            )}
          {modalType === "attach" && !!entityJSON && (
            <AttachEntityForm
              entity={entityJSON}
              onHide={onRemoveEntity}
              onCreate={onCreate}
            />
          )}
        </Modal>
      </div>
    </FloorplanProvider>
  );
});

export default Floorplan;
