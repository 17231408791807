import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRouteMatch, Link, useLocation, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import { useDebounce } from "react-use";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import qs from "query-string";
import clsx from "clsx";
import dayjs from "dayjs";
import useToggle from "react-use/lib/useToggle";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";

import Table from "../../../components/Table";
import Pagination from "../../../components/Pagination";

import { QUERY_MEETINGS } from "../../../config/graphql/query";
import {
  MUTATION_DELETE_MEETING,
  MUTATION_DELETE_MEETINGS,
} from "../../../config/graphql/mutation";

type Meeting = Pick<
  IMeeting,
  "id" | "subject" | "organizer" | "attendeesCount" | "dtstart"
>;

const limit = 10;

const TableRow = ({
  item,
  variables,
  index,
  checked,
  onCheck,
}: {
  item: Meeting;
  variables: any;
  index: number;
  checked: boolean;
  onCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const {
    id,
    subject,
    organizer: { fullName, email },
    attendeesCount,
    dtstart,
  } = item;

  const [visible, setVisible] = useToggle(false);

  const { t } = useTranslation(["meeting", "common"]);

  const [onDelete, { loading }] = useMutation(MUTATION_DELETE_MEETING, {
    refetchQueries: [
      { query: QUERY_MEETINGS, variables },
      { query: QUERY_MEETINGS },
    ],
    variables: { id },
  });

  const onBeforeDelete = () => {
    onDelete()
      .then(() => {
        toast.success<string>(
          t("meeting:meetings.toast.deleted", { count: 1 }),
        );
        setVisible(false);
      })
      .catch(() => {
        toast.error<string>(
          t("meeting:meetings.toast.deletedError", { count: 1 }),
        );
        setVisible(false);
      });
  };

  return (
    <>
      <tr>
        <td className="text-center">
          <input
            value={id}
            checked={checked}
            type="checkbox"
            onChange={onCheck}
          />
        </td>
        <td className="text-center">{index + 1}</td>
        <td className="h6">{subject}</td>
        <td>{`${fullName} (${email})`}</td>
        <td>{attendeesCount}</td>
        <td>{dayjs(dtstart).local().format("DD-MM-YYYY / HH:mm")}</td>
        <td className="text-nowrap">
          <Button
            size="sm"
            variant="danger"
            className="mr-2"
            onClick={() => setVisible(true)}
          >
            {t("common:delete")}
          </Button>
          <Link to={`/meetings/${id}`}>
            <Button size="sm" variant="primary">
              {t("common:view")}
            </Button>
          </Link>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("meeting:meetings.modal.title", { count: 1 })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("meeting:meetings.modal.body", { count: 1, id })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button
            size="sm"
            variant="danger"
            onClick={onBeforeDelete}
            disabled={loading}
          >
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Meetings = React.memo(() => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const { t } = useTranslation(["meeting", "common"]);

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
        startDate?: Date;
        endDate?: Date;
      },
    [location.search],
  );

  const [startDate, setStartDate] = useState(
    query.startDate
      ? new Date(dayjs(query.startDate).local().format())
      : undefined,
  );
  const [endDate, setEndDate] = useState(
    query.endDate ? new Date(dayjs(query.endDate).local().format()) : undefined,
  );

  const [dateError, setDateError] = useState(false);

  const [search, setSearch] = useState(query.search);

  const [modal, setModal] = useToggle(false);

  const [checked, setChecked] = useState<string[]>([]);

  const onCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let updatedList = [...checked];

      if (event.target.checked) {
        updatedList = [...checked, event.target.value];
      } else {
        updatedList.splice(checked.indexOf(event.target.value), 1);
      }

      setChecked(updatedList);
    },
    [checked],
  );

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);

  const variables = useMemo(() => {
    return {
      sort: {
        dtstart: "DESC",
      },
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
      ...(query.startDate || query.endDate || query.search
        ? {
            filter: {
              createdAt: {
                ...(query.startDate && { GTE: dayjs(query.startDate) }),
                ...(query.endDate && {
                  LTE: dayjs(query.endDate).add(1, "day"),
                }),
              },
              subject: {
                REGEX: query.search,
                OPTIONS: "i",
              },
            },
          }
        : {}),
    };
  }, [page, query.startDate, query.endDate, query.search]);

  const { data } = useQuery<{
    meetings?: Array<Meeting>;
    meetingsCount: number;
  }>(QUERY_MEETINGS, {
    fetchPolicy: "network-only",
    variables,
  });

  const meetings: Array<Meeting> = useMemo(
    () => data?.meetings ?? [],
    [data?.meetings],
  );
  const meetingsCount: number = data?.meetingsCount ?? 0;

  const renderMeeting = useCallback(
    (item: Meeting, index: number) => (
      <TableRow
        key={item.id}
        item={item}
        variables={variables}
        index={(page - 1) * limit + index}
        checked={checked.includes(item.id)}
        onCheck={onCheck}
      />
    ),
    [variables, page, checked, onCheck],
  );

  const handleAllCheck = useCallback(() => {
    const checkList = meetings.map((meeting) => meeting.id);

    setChecked(checkList);
  }, [meetings]);

  const handleAllUncheck = useCallback(() => {
    setChecked([]);
  }, []);

  useDebounce(
    () => {
      if (
        search !== undefined ||
        startDate !== undefined ||
        endDate !== undefined
      ) {
        history.push({
          search: qs.stringify({
            page: 1,
            ...(search && { search }),
            ...(startDate && { startDate }),
            ...(endDate && { endDate }),
          }),
        });
      }
    },
    500,
    [search, startDate, endDate],
  );

  useEffect(() => {
    setDateError(false);
    if (dayjs(startDate).isAfter(dayjs(endDate))) {
      setDateError(true);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    setChecked([]);
  }, [page]);

  const [onDelete] = useMutation(MUTATION_DELETE_MEETINGS, {
    refetchQueries: [{ query: QUERY_MEETINGS, variables }],
  });

  const onDeleteSelected = () => {
    onDelete({ variables: { ids: checked } })
      .then(() => {
        toast.success<string>(
          t("meeting:meetings.toast.deleted", { count: 2 }),
        );
        setModal(false);
        setChecked([]);
      })
      .catch(() => {
        toast.error<string>(
          t("meeting:meetings.toast.deletedError", { count: 2 }),
        );
        setModal(false);
      });
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("meeting:meetings.nav.meetings")}
          </li>
        </ol>
      </nav>
      <div className="d-flex flex-wrap justify-content-between mt-4">
        <div>
          <Button className="mr-2 mb-4" onClick={handleAllCheck}>
            {t("meeting:meetings.button.selectAll")}
          </Button>
          <Button className="mr-2 mb-4" onClick={handleAllUncheck}>
            {t("meeting:meetings.button.unselectAll")}
          </Button>
          <Button
            className="mr-2 mb-4"
            variant="danger"
            disabled={!checked.length}
            onClick={() => {
              setModal(true);
            }}
          >
            {t("meeting:meetings.button.deleteSelected")}
          </Button>
        </div>
        <form className="form-inline mr-2">
          <div className="mr-5 mb-4">
            <label htmlFor="search" className="sr-only">
              {t("common:search")}
            </label>
            <input
              id="search"
              type="search"
              className="form-control"
              placeholder={t("meeting:meetings.placeholder.search")}
              value={search}
              onChange={({ target: { value } }) => {
                setSearch(value);
              }}
            />
          </div>
          <div className="d-flex mb-4">
            <div className="d-flex mr-4">
              <div className="mr-2 d-flex align-items-center">
                {t("meeting:meetings.date.from")}
              </div>
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => {
                  setStartDate(date);
                  if (date.toDateString() === new Date().toDateString()) {
                    setEndDate(date);
                  }
                }}
                placeholderText="Start Date"
                dateFormat="MM/dd/yyyy"
                className="form-control"
                todayButton="Today"
              />
            </div>
            <div className="d-flex">
              <div className="mr-2 d-flex align-items-center">
                {t("meeting:meetings.date.to")}
              </div>
              <DatePicker
                selected={endDate}
                onChange={(date: Date) => {
                  setEndDate(date);
                }}
                filterDate={(date) => {
                  if (!!startDate) {
                    return dayjs(date) >= dayjs(startDate);
                  }
                  return true;
                }}
                placeholderText="End Date"
                dateFormat="MM/dd/yyyy"
                className={clsx("form-control", {
                  "is-invalid": dateError,
                })}
              />
            </div>
          </div>
        </form>
        <Link
          to={`${match.path}/new`}
          type="button"
          className="btn btn-primary mb-4"
        >
          {t("meeting:meetings.button.add")}
        </Link>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th scope="col" style={{ width: "3.5rem" }}>
              {" "}
            </th>
            <th scope="col" style={{ width: "3.5rem", textAlign: "center" }}>
              #
            </th>
            <th scope="col">{t("meeting:meetings.th.title")}</th>
            <th scope="col">{t("meeting:meetings.th.host")}</th>
            <th scope="col">{t("meeting:meetings.th.attendees")}</th>
            <th scope="col">{t("meeting:meetings.th.time")}</th>
            <th scope="col">{t("meeting:meetings.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{meetings.map(renderMeeting)}</tbody>
      </Table>
      <Pagination documentsCount={meetingsCount} limit={limit} />
      <Modal
        show={modal}
        onHide={setModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("meeting:meetings.modal.title", { count: 2 })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("meeting:meetings.modal.body", { count: 2 })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setModal(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onDeleteSelected}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default Meetings;
