import React, { useCallback, useMemo } from "react";
import {
  Route,
  Redirect,
  useLocation,
  RouteProps,
  RouteComponentProps,
} from "react-router-dom";

import some from "lodash/some";

import { useQuery } from "@apollo/client";

import { QUERY_AUTH, QUERY_CURRENT_USER } from "../config/graphql/query";

const PublicRoute = React.memo(({ children, ...rest }: RouteProps) => {
  const location: {
    state: { from?: { pathname?: string } };
  } = useLocation();

  const { data } = useQuery(QUERY_AUTH);

  const pathname = useMemo(
    () => location.state?.from?.pathname ?? "/",
    [location.state],
  );

  const render = useCallback((): React.ReactNode => {
    if (data?.token) {
      return (
        <Redirect
          to={{
            pathname,
          }}
        />
      );
    }

    return <>{children}</>;
  }, [children, data, pathname]);

  return <Route {...rest} render={render} />;
});

const PrivateRoute = React.memo(({ children, ...rest }: RouteProps) => {
  const { data } = useQuery(QUERY_AUTH);

  const render = useCallback(
    ({ location }: RouteComponentProps) => {
      if (!data?.token) {
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        );
      }

      return <>{children}</>;
    },
    [children, data],
  );

  return <Route {...rest} render={render} />;
});

interface RoleRouteProps extends RouteProps {
  roles: UserRole[];
  redirect?: string;
}

const RoleRoute = React.memo((props: RoleRouteProps) => {
  const { children, redirect = "/", roles = ["ADMIN"], ...rest } = props;

  const { data: authData, loading } = useQuery(QUERY_AUTH);

  const { data: userData } = useQuery(QUERY_CURRENT_USER, {
    skip: !authData?.token,
  });

  const hasRole = useMemo(
    () => some(roles, (role) => userData?.me?.roles.includes(role)),
    [roles, userData],
  );

  const render = useCallback(
    ({ location }: RouteComponentProps) => {
      if (loading) {
        return null;
      }

      if (!authData?.token) {
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        );
      }

      if (!hasRole) {
        return (
          <Redirect
            to={{
              pathname: redirect,
            }}
          />
        );
      }

      return <>{children}</>;
    },
    [authData, children, hasRole, loading, redirect],
  );

  return <Route {...rest} render={render} />;
});

export default {
  Base: Route,
  Role: RoleRoute,
  Public: PublicRoute,
  Private: PrivateRoute,
};
