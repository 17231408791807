import React, { useMemo, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import useToggle from "react-use/lib/useToggle";

import * as yup from "yup";

import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory, Link, useParams } from "react-router-dom";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import TextArea from "../../../../components/Textarea";
import Input from "../../../../components/Input";

import { QUERY_OUTLOOK_SUBSCRIPTION } from "../../../../config/graphql/query";
import {
  UPDATE_OUTLOOK_SUBSCRIPTION,
  DELETE_OUTLOOK_SUBSCRIPTION,
} from "../../../../config/graphql/mutation";

import { useClientId } from "../hooks/useClient";

const OutlookIntegration = React.memo(() => {
  const [show, setShow] = useToggle(false);
  const { t } = useTranslation(["client", "common"]);

  const history = useHistory();
  const { subscriptionId } = useParams<{ subscriptionId: string }>();

  const clientId = useClientId();

  const schema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().nullable(),
        description: yup.string().nullable(),
      }),
    [],
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {
      title: "",
      description: "",
    },
  });

  const [onUpdate] = useMutation(UPDATE_OUTLOOK_SUBSCRIPTION);
  const [onDelete] = useMutation(DELETE_OUTLOOK_SUBSCRIPTION);

  const { data: outlookSubscriptionData } = useQuery(
    QUERY_OUTLOOK_SUBSCRIPTION,
    {
      skip: !subscriptionId,
      variables: { id: subscriptionId },
      onCompleted: ({ outlookSubscription: { ...outlookSubscription } }) => {
        methods.reset({
          ...outlookSubscription,
        });
      },
    },
  );

  const onRemoveClientIntegration = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      return onDelete({ variables: { id: subscriptionId } })
        .then(() => {
          history.replace(`/clients/${clientId}/integration`);
          toast.success<string>(
            t("client:client.integrationForm.toast.deleted"),
          );
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [clientId, history, onDelete, subscriptionId, t],
  );

  const onBeforeRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      setShow(true);
    },
    [setShow],
  );

  const onSubmit = ({ ...rest }) => {
    if (!subscriptionId) {
      return;
    }

    onUpdate({
      variables: { input: { ...rest, id: subscriptionId } },
    })
      .then(() => {
        toast.success<string>(t("client:client.integrationForm.toast.updated"));
      })
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );
      });
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/clients">
              {t("client:client.integrationForm.nav.clients")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to={`/clients/${clientId}`}>
              {outlookSubscriptionData?.outlookSubscription?.client?.title ??
                "Client"}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to={`/clients/${clientId}/integrations/outlook`}>
              {t("client:client.integrationForm.nav.integration")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {outlookSubscriptionData?.outlookSubscription?.title ?? "Title"}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form className="row mb-3" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col">
            <div className="form-group">
              <label htmlFor="title">
                {t("client:client.integrationForm.form.title")}
              </label>
              <Input name="title" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="description">
                {t("client:client.integrationForm.form.description")}
              </label>
              <TextArea name="description" className="form-control" />
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
            {subscriptionId && (
              <button onClick={onBeforeRemove} className="btn btn-danger ml-3">
                {t("common:delete")}
              </button>
            )}
          </div>
        </form>
        <Accordion defaultActiveKey="0" className="mb-3">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              {t("client:client.integrationForm.form.information")}
            </Accordion.Toggle>

            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {t("client:client.integrationForm.form.email")}
                {outlookSubscriptionData?.outlookSubscription?.email ?? ""}
                <hr />
                {t("client:client.integrationForm.form.expiration")}
                {outlookSubscriptionData?.outlookSubscription
                  ?.expirationDateTime ?? ""}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              {t("client:client.integrationForm.form.details")}
            </Accordion.Toggle>

            <Accordion.Collapse eventKey="1">
              <Card.Body>
                {t("client:client.integrationForm.form.subscription")}
                {outlookSubscriptionData?.outlookSubscription?.subscriptionId ??
                  ""}
                <hr />
                {t("client:client.integrationForm.form.created")}
                {outlookSubscriptionData?.outlookSubscription?.createdAt ?? ""}
                <hr />
                {t("client:client.integrationForm.form.tenant")}
                {outlookSubscriptionData?.outlookSubscription?.homeTenantId ??
                  ""}
                <hr />
                {t("client:client.integrationForm.form.resource")}
                {outlookSubscriptionData?.outlookSubscription?.resource ?? ""}
                <hr />
                {t("client:client.integrationForm.form.change")}
                {outlookSubscriptionData?.outlookSubscription?.changeType ?? ""}
                <hr />
                {t("client:client.integrationForm.form.building")}
                {outlookSubscriptionData?.outlookSubscription?.building
                  ?.title ?? ""}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </FormProvider>
      <Modal show={show} onHide={setShow} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("client:client.integrationForm.modal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("client:client.integrationForm.modal.body")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setShow}>
            {t("common:cancel")}
          </Button>
          <Button variant="danger" onClick={onRemoveClientIntegration}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default OutlookIntegration;
