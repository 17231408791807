import { useMemo, useState } from "react";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { useDebounce } from "react-use";
import { useTranslation } from "react-i18next";

import qs from "query-string";
import dayjs from "dayjs";

import DatePicker from "react-datepicker";

import { QUERY_ACTIVITY } from "../../../config/graphql/query";
import { useCurrentClient } from "../../../context/Client";

const ActivityDashboard = () => {
  const location = useLocation();
  const history = useHistory();
  const { path } = useRouteMatch();

  const client = useCurrentClient();

  const isFreePlan = client?.plan === "free";

  const { t } = useTranslation(["activity"]);

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        startDate?: Date;
        endDate?: Date;
      },
    [location.search],
  );

  const [startDate, setStartDate] = useState(
    query.startDate
      ? new Date(dayjs(query.startDate).local().format())
      : new Date(dayjs().startOf("month").local().format()),
  );

  const [endDate, setEndDate] = useState(
    new Date(dayjs(query.endDate).local().format()),
  );

  const variables = useMemo(() => {
    return {
      filter: {
        ...((startDate || endDate) && {
          createdAt: {
            ...(startDate && {
              GTE: dayjs(startDate).hour(0).minute(0).second(0).format(),
            }),
            ...(endDate && {
              LTE: dayjs(endDate).hour(23).minute(59).second(59).format(),
            }),
          },
        }),
      },
    };
  }, [startDate, endDate]);

  const { data: activityData } = useQuery<{
    activity: IActivity;
  }>(QUERY_ACTIVITY, {
    fetchPolicy: "network-only",
    variables,
  });

  useDebounce(
    () => {
      history.push({
        search: qs.stringify({
          ...(startDate && {
            startDate: dayjs(startDate).format("YYYY-MM-DD"),
          }),
          ...(endDate && {
            endDate: dayjs(endDate).format("YYYY-MM-DD"),
          }),
        }),
      });
    },
    500,
    [startDate, endDate],
  );

  return (
    <div className="container-fluid">
      <div className="d-flex flex-wrap justify-content-between mt-4 ml-3">
        <form className="form-inline">
          <div className="d-flex mb-3">
            <div className="d-flex mr-4">
              <div className="mr-2 d-flex align-items-center">
                {t("activity:dashboard.date.from")}
              </div>
              <DatePicker
                isClearable
                selected={startDate}
                onChange={(date: Date) => {
                  setStartDate(date);
                  if (
                    date.toDateString() === new Date().toDateString() ||
                    dayjs(date).isAfter(dayjs(endDate))
                  ) {
                    setEndDate(date);
                  }
                }}
                filterDate={(date) => dayjs() >= dayjs(date)}
                placeholderText="Start Date"
                dateFormat="MM/dd/yyyy"
                className="form-control"
                todayButton="Today"
              />
            </div>
            <div className="d-flex">
              <div className="mr-2 d-flex align-items-center">
                {t("activity:dashboard.date.to")}
              </div>
              <DatePicker
                isClearable
                selected={endDate}
                onChange={(date: Date) => {
                  setEndDate(date);
                }}
                filterDate={(date) => {
                  if (!startDate) {
                    return dayjs() > dayjs(date);
                  }
                  return (
                    dayjs() > dayjs(date) && dayjs(date) >= dayjs(startDate)
                  );
                }}
                placeholderText="End Date"
                dateFormat="MM/dd/yyyy"
                className="form-control"
              />
            </div>
          </div>
        </form>
      </div>
      <div className="col-12 mt-2">
        <div className="row py-3 pt-0">
          {Object.entries(activityData?.activity || []).map(
            ([accessEvent, count]) => {
              const accessType = accessEvent.split("_")[0];
              const status = accessEvent.split("_")[1];

              if (!accessType || count == null) {
                return null;
              }

              const cardBody = (
                <div className="card-body p-3">
                  <h6 className="card-title text-center">
                    {t(`activity:dashboard.card.${accessEvent}`)}
                  </h6>
                  <p className="h6 card-text text-center ">
                    {count >= 0 ? count : t("activity:dashboard.card.error")}
                  </p>
                </div>
              );

              if (isFreePlan) {
                return (
                  <div
                    key={accessEvent}
                    className="col-lg-3 col-md-6 col-sm-12"
                  >
                    <div className="card mb-3">{cardBody}</div>
                  </div>
                );
              }

              const url = qs.stringifyUrl(
                {
                  url: `${path}/accessEvents`,
                  query: {
                    accessType,
                    status,
                    startDate: startDate
                      ? dayjs(startDate).format("YYYY-MM-DD")
                      : undefined,
                    endDate: endDate
                      ? dayjs(endDate).format("YYYY-MM-DD")
                      : undefined,
                  },
                },
                { encode: true },
              );

              return (
                <Link
                  key={accessEvent}
                  to={url}
                  className="col-lg-3 col-md-6 col-sm-12 text-decoration-none text-secondary"
                >
                  <div className="card mb-3 activity-card">{cardBody}</div>
                </Link>
              );
            },
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivityDashboard;
