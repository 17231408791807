import { gql } from "@apollo/client";

import {
  FRAGMENT_ENTITY,
  FRAGMENT_COMPANY,
  FRAGMENT_CLIENT,
  FRAGMENT_NAMEBOARD,
  FRAGMENT_VISIONECT,
  FRAGMENT_USER,
  FRAGMENT_ENTITY_DESK,
  FRAGMENT_CLIENT_TOKEN,
  FRAGMENT_OUTLOOK_SUBSCRIPTION,
  FRAGMENT_LOG,
  FRAGMENT_ERROR,
  FRAGMENT_NAMEBOARD_SETTINGS,
  FRAGMENT_CLIENT_SENDGRID,
  FRAGMENT_CLIENT_SENDGRID_TEMPLATE,
  FRAGMENT_SCREEN,
  FRAGMENT_EMPLOYEE,
  FRAGMENT_VISITOR,
  FRAGMENT_MEETING,
  FRAGMENT_ACTIVE_DIRECTORY,
  FRAGMENT_ACTIVE_DIRECTORY_SYNC,
  FRAGMENT_DIGITAL_RECEPTION,
  FRAGMENT_CLIENT_PREFERENCES,
  FRAGMENT_SCREEN_SCHEDULE,
  FRAGMENT_MEMBER_GROUP,
  FRAGMENT_PROPERTY,
  FRAGMENT_WAYFINDING_SCREEN,
  FRAGMENT_ENTRANCE_AGREEMENT,
  FRAGMENT_ENTRANCE_AGREEMENT_FILE,
  MINEW_DEVICE_FIELDS,
  FRAGMENT_ENTITY_BASIC,
  FRAGMENT_MANUAL_REGISTRATION,
  FRAGMENT_MANUAL_REGISTRATION_FIELD,
} from "../fragment";

export const CREATE_COMPANY = gql`
  mutation addCompany($input: AddCompanyInput!) {
    addCompany(input: $input) {
      ...FragmentCompany
    }
  }
  ${FRAGMENT_COMPANY}
`;

export const DELETE_COMPANY = gql`
  mutation deleteCompany($id: ID!) {
    deleteCompany(id: $id) {
      ...FragmentCompany
    }
  }
  ${FRAGMENT_COMPANY}
`;

export const DELETE_COMPANIES = gql`
  mutation deleteCompanies($ids: [ID]!) {
    deleteCompanies(ids: $ids) {
      ...FragmentCompany
    }
  }
  ${FRAGMENT_COMPANY}
`;

export const UPDATE_PROPERTY_COMPANY = gql`
  mutation updateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      ...FragmentCompany
    }
  }
  ${FRAGMENT_COMPANY}
`;

export const UPDATE_CLIENT_PREFERENCES = gql`
  mutation updateClientPreferences($input: UpdateClientPreferencesInput!) {
    updateClientPreferences(input: $input) {
      ...FragmentClientPreferences
    }
  }
  ${FRAGMENT_CLIENT_PREFERENCES}
`;

export const CREATE_SPACE = gql`
  mutation addSpace($input: AddSpaceInput!) {
    addSpace(input: $input) {
      ...FragmentEntity
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const UPDATE_SPACE = gql`
  mutation updateSpace($input: UpdateSpaceInput!) {
    updateSpace(input: $input) {
      ...FragmentEntity
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const DELETE_SPACE = gql`
  mutation deleteSpace($id: ID!) {
    deleteSpace(id: $id) {
      ...FragmentEntity
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const DELETE_FLOOR = gql`
  mutation deleteFloor($id: ID!) {
    deleteFloor(id: $id) {
      ...FragmentEntity
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const DELETE_SECTOR = gql`
  mutation deleteSector($id: ID!) {
    deleteSector(id: $id) {
      ...FragmentEntity
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const DELETE_BUILDING = gql`
  mutation deleteBuilding($id: ID!) {
    deleteBuilding(id: $id) {
      ...FragmentEntity
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const UPDATE_CLIENT = gql`
  mutation updateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      ...FragmentClient
    }
  }
  ${FRAGMENT_CLIENT}
`;

export const CREATE_CLIENT = gql`
  mutation addClient($input: AddClientInput!) {
    addClient(input: $input) {
      ...FragmentClient
    }
  }
  ${FRAGMENT_CLIENT}
`;

export const DELETE_CLIENT = gql`
  mutation deleteClient($id: ID!) {
    deleteClient(id: $id) {
      ...FragmentClient
    }
  }
  ${FRAGMENT_CLIENT}
`;

export const ADD_CLIENT_TOKEN = gql`
  mutation addClientToken($input: AddClientTokenInput!) {
    addClientToken(input: $input) {
      ...FragmentClientToken
    }
  }
  ${FRAGMENT_CLIENT_TOKEN}
`;

export const UPDATE_CLIENT_TOKEN = gql`
  mutation updateClientToken($input: UpdateClientTokenInput!) {
    updateClientToken(input: $input) {
      ...FragmentClientToken
    }
  }
  ${FRAGMENT_CLIENT_TOKEN}
`;

export const DELETE_CLIENT_TOKEN = gql`
  mutation deleteClientToken($input: DeleteClientTokenInput!) {
    deleteClientToken(input: $input) {
      id
    }
  }
`;

export const CREATE_CLIENT_SENDGRID = gql`
  mutation addSendGridClient($input: AddSendGridClientInput!) {
    addSendGridClient(input: $input) {
      ...FragmentClientSendGrid
    }
  }
  ${FRAGMENT_CLIENT_SENDGRID}
`;

export const UPDATE_CLIENT_SENDGRID = gql`
  mutation updateSendGridClient($input: UpdateSendGridClientInput!) {
    updateSendGridClient(input: $input) {
      ...FragmentClientSendGrid
    }
  }
  ${FRAGMENT_CLIENT_SENDGRID}
`;

export const DELETE_CLIENT_TWILIO = gql`
  mutation deleteTwilioClient($id: ID!) {
    deleteTwilioClient(id: $id) {
      id
    }
  }
`;

export const DELETE_CLIENT_SENDGRID = gql`
  mutation deleteSendGridClient($input: DeleteSendGridClientInput!) {
    deleteSendGridClient(input: $input) {
      id
    }
  }
`;

export const CREATE_CLIENT_SENDGRID_TEMPLATE = gql`
  mutation addEmailTemplate($input: AddEmailTemplateInput!) {
    addEmailTemplate(input: $input) {
      ...FragmentClientSendGridTemplate
    }
  }
  ${FRAGMENT_CLIENT_SENDGRID_TEMPLATE}
`;

export const UPDATE_CLIENT_SENDGRID_TEMPLATE = gql`
  mutation updateEmailTemplate($input: UpdateEmailTemplateInput!) {
    updateEmailTemplate(input: $input) {
      ...FragmentClientSendGridTemplate
    }
  }
  ${FRAGMENT_CLIENT_SENDGRID_TEMPLATE}
`;

export const DELETE_CLIENT_SENDGRID_TEMPLATE = gql`
  mutation deleteEmailTemplate($input: DeleteEmailTemplateInput!) {
    deleteEmailTemplate(input: $input) {
      id
    }
  }
`;

export const CREATE_MESSAGE_TEMPLATE = gql`
  mutation createMessageTemplate($input: CreateMessageTemplateInput!) {
    createMessageTemplate(input: $input) {
      id
    }
  }
`;

export const UPDATE_MESSAGE_TEMPLATE = gql`
  mutation updateMessageTemplate($input: UpdateMessageTemplateInput!) {
    updateMessageTemplate(input: $input) {
      id
    }
  }
`;

export const DELETE_MESSAGE_TEMPLATE = gql`
  mutation deleteMessageTemplate($id: ID!) {
    deleteMessageTemplate(id: $id) {
      id
    }
  }
`;

export const CREATE_NAMEBOARD = gql`
  mutation addNameboard($input: AddNameboardInput!) {
    addNameboard(input: $input) {
      ...FragmentNameboard
    }
  }
  ${FRAGMENT_NAMEBOARD}
`;

export const UPDATE_NAMEBOARD = gql`
  mutation updateNameboard($input: UpdateNameboardInput!) {
    updateNameboard(input: $input) {
      ...FragmentNameboard
    }
  }
  ${FRAGMENT_NAMEBOARD}
`;

export const DELETE_SCREEN = gql`
  mutation deleteScreen($id: ID!) {
    deleteScreen(id: $id) {
      ...FragmentScreen
    }
  }
  ${FRAGMENT_SCREEN}
`;

export const DELETE_DIGITAL_RECEPTION = gql`
  mutation deleteDigitalReception($id: ID!) {
    deleteDigitalReception(id: $id) {
      ...FragmentDigitalReception
    }
  }
  ${FRAGMENT_DIGITAL_RECEPTION}
`;

export const UPDATE_NAMEBOARD_SETTINGS = gql`
  mutation updateNameboard($input: UpdateNameboardInput!) {
    updateNameboard(input: $input) {
      id
      metadata
      settings {
        ...FragmentNameboardSettings
      }
    }
  }
  ${FRAGMENT_NAMEBOARD_SETTINGS}
`;

export const CREATE_DIGITAL_RECEPTION = gql`
  mutation createDigitalReception($input: CreateDigitalReceptionInput!) {
    createDigitalReception(input: $input) {
      ...FragmentDigitalReception
    }
  }
  ${FRAGMENT_DIGITAL_RECEPTION}
`;

export const UPDATE_DIGITAL_RECEPTION = gql`
  mutation updateDigitalReception($input: UpdateDigitalReceptionInput!) {
    updateDigitalReception(input: $input) {
      ...FragmentDigitalReception
    }
  }
  ${FRAGMENT_DIGITAL_RECEPTION}
`;

export const MUTATION_GENERATE_DIGITAL_RECEPTION_CODE = gql`
  mutation generateDigitalReceptionCode($id: ID!) {
    generateDigitalReceptionCode(id: $id) {
      id
      code
    }
  }
`;

export const UPDATE_DIGITAL_RECEPTION_THEME = gql`
  mutation updateDigitalReceptionTheme(
    $input: UpdateDigitalReceptionThemeInput!
  ) {
    updateDigitalReceptionTheme(input: $input) {
      ...FragmentDigitalReception
    }
  }
  ${FRAGMENT_DIGITAL_RECEPTION}
`;

export const UPDATE_DIGITAL_RECEPTION_SETTINGS = gql`
  mutation updateDigitalReceptionSettings(
    $input: UpdateDigitalReceptionSettingsInput!
  ) {
    updateDigitalReceptionSettings(input: $input) {
      ...FragmentDigitalReception
    }
  }
  ${FRAGMENT_DIGITAL_RECEPTION}
`;

export const CREATE_ENTITY_PROPERTIES = gql`
  mutation addProperties($input: AddPropertiesInput!) {
    addProperties(input: $input) {
      id
      properties {
        id
        type
        ... on Company {
          title
        }
      }
    }
  }
`;

export const ADD_ENTITY_PROPERTY = gql`
  mutation addProperty($input: AddPropertyInput!) {
    addProperty(input: $input) {
      id
      properties {
        id
        type
        ... on Company {
          title
        }
      }
    }
  }
`;

export const CREATE_NAMEBOARD_ITEM = gql`
  mutation addNameboardItem($input: AddNameboardItemInput!) {
    addNameboardItem(input: $input) {
      id
      entity {
        ...FragmentEntity
      }
      nameboard {
        id
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const UPDATE_NAMEBOARD_ITEM = gql`
  mutation updateNameboardItem($input: UpdateNameboardItemInput!) {
    updateNameboardItem(input: $input) {
      id
      entity {
        ...FragmentEntity
      }
      nameboard {
        id
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const DELETE_NAMEBOARD_ITEM = gql`
  mutation deleteNameboardItem($id: ID!) {
    deleteNameboardItem(id: $id) {
      nameboard {
        id
      }
    }
  }
`;

export const DELETE_ENTITIES = gql`
  mutation deleteEntities($ids: [ID]!) {
    deleteEntities(ids: $ids) {
      ...FragmentEntity
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const CREATE_NAMEBOARD_PATH = gql`
  mutation addNameboardPath($input: AddNameboardPathInput!) {
    addNameboardPath(input: $input) {
      id
      entity {
        ...FragmentEntity
      }
      nameboard {
        id
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const UPDATE_NAMEBOARD_PATH = gql`
  mutation updateNameboardPath($input: UpdateNameboardPathInput!) {
    updateNameboardPath(input: $input) {
      id
      entity {
        ...FragmentEntity
      }
      nameboard {
        id
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const DELETE_NAMEBOARD_PATH = gql`
  mutation deleteNameboardPath($id: ID!) {
    deleteNameboardPath(id: $id) {
      nameboard {
        id
      }
    }
  }
`;

export const CREATE_VISIONECT = gql`
  mutation createVisionect($input: CreateVisionect!) {
    createVisionect(input: $input) {
      ...FragmentVisionect
    }
  }
  ${FRAGMENT_VISIONECT}
`;

export const UPDATE_VISIONECT = gql`
  mutation updateVisionect($input: UpdateVisionect!) {
    updateVisionect(input: $input) {
      ...FragmentVisionect
    }
  }
  ${FRAGMENT_VISIONECT}
`;

export const CREATE_MINEW = gql`
  mutation createMinew($input: CreateMinew!) {
    createMinew(input: $input) {
      id
      title
      device {
        mac
      }
    }
  }
`;

export const UPDATE_MINEW = gql`
  mutation updateMinew($input: UpdateMinew!) {
    updateMinew(input: $input) {
      id
      title
      device {
        mac
      }
    }
  }
`;
export const DELETE_MINEW = gql`
  mutation deleteMinew($id: ID!) {
    deleteMinew(id: $id) {
      id
    }
  }
`;

export const RESTART_MINEW = gql`
  mutation restartMinew($id: ID!) {
    restartMinew(id: $id) {
      id
    }
  }
`;

export const RESTART_VISIONECT = gql`
  mutation restartVisionect($id: ID!) {
    restartVisionect(id: $id) {
      id
    }
  }
`;

export const DELETE_VISIONECT = gql`
  mutation deleteVisionect($id: ID!) {
    deleteVisionect(id: $id) {
      ...FragmentVisionect
    }
  }
  ${FRAGMENT_VISIONECT}
`;

export const CREATE_VISIONECT_ITEM = gql`
  mutation createVisionectItem($input: CreateVisionectItemInput!) {
    createVisionectItem(input: $input) {
      id
      direction
      entity {
        ...FragmentEntity
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const UPDATE_VISIONECT_ITEM = gql`
  mutation updateVisionectItem($input: UpdateVisionectItemInput!) {
    updateVisionectItem(input: $input) {
      id
      direction
      entity {
        ...FragmentEntity
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const DELETE_VISIONECT_ITEM = gql`
  mutation deleteVisionectItem($id: ID!) {
    deleteVisionectItem(id: $id) {
      direction
      entity {
        ...FragmentEntity
      }
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const CREATE_MINEW_ITEM = gql`
  mutation createMinewItem($input: CreateMinewItemInput!) {
    createMinewItem(input: $input) {
      id
      entity {
        ...FragmentEntityBasic
      }
    }
  }
  ${FRAGMENT_ENTITY_BASIC}
`;

export const UPDATE_MINEW_ITEM = gql`
  mutation updateMinewItem($input: UpdateMinewItemInput!) {
    updateMinewItem(input: $input) {
      id
      entity {
        ...FragmentEntityBasic
      }
    }
  }
  ${FRAGMENT_ENTITY_BASIC}
`;

export const DELETE_MINEW_ITEM = gql`
  mutation deleteMinewItem($id: ID!) {
    deleteMinewItem(id: $id) {
      entity {
        ...FragmentEntityBasic
      }
    }
  }
  ${FRAGMENT_ENTITY_BASIC}
`;

export const CREATE_VISIONECT_DEVICE = gql`
  mutation addVisionectDevice($input: AddVisionectDeviceInput!) {
    addVisionectDevice(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_VISIONECT_DEVICE = gql`
  mutation updateVisionectDevice($input: UpdateVisionectDeviceInput!) {
    updateVisionectDevice(input: $input) {
      id
      name
    }
  }
`;

export const DELETE_VISIONECT_DEVICE = gql`
  mutation deleteVisionectDevice($id: ID!) {
    deleteVisionectDevice(id: $id) {
      id
      name
    }
  }
  ${FRAGMENT_ENTITY}
`;

export const CREATE_MINEW_DEVICE = gql`
  mutation addMinewDevice($input: AddMinewDeviceInput!) {
    addMinewDevice(input: $input) {
      ...MinewDeviceFields
    }
  }
  ${MINEW_DEVICE_FIELDS}
`;

export const UPDATE_MINEW_DEVICE = gql`
  mutation updateMinewDevice($input: UpdateMinewDeviceInput!) {
    updateMinewDevice(input: $input) {
      ...MinewDeviceFields
    }
  }
  ${MINEW_DEVICE_FIELDS}
`;

export const DELETE_MINEW_DEVICE = gql`
  mutation deleteMinewDevice($id: ID!) {
    deleteMinewDevice(id: $id) {
      ...MinewDeviceFields
    }
  }
  ${MINEW_DEVICE_FIELDS}
`;

export const CREATE_USER = gql`
  mutation addUser($input: AddUserInput!) {
    addUser(input: $input) {
      ...FragmentUser
    }
  }
  ${FRAGMENT_USER}
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ...FragmentUser
    }
  }
  ${FRAGMENT_USER}
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      ...FragmentUser
    }
  }
  ${FRAGMENT_USER}
`;

export const ADD_ENTITY_DESK = gql`
  mutation addEntityDesk($input: AddEntityDeskInput!) {
    addEntityDesk(input: $input) {
      ...FragmentEntityDesk
    }
  }
  ${FRAGMENT_ENTITY_DESK}
`;

export const UPDATE_ENTITY_DESK = gql`
  mutation updateEntityDesk($input: UpdateEntityDeskInput!) {
    updateEntityDesk(input: $input) {
      ...FragmentEntityDesk
    }
  }
  ${FRAGMENT_ENTITY_DESK}
`;

export const DELETE_ENTITY_DESK = gql`
  mutation deleteEntityDesk($id: ID!) {
    deleteEntityDesk(id: $id) {
      ...FragmentEntityDesk
    }
  }
  ${FRAGMENT_ENTITY_DESK}
`;

export const UPDATE_OUTLOOK_SUBSCRIPTION = gql`
  mutation updateOutlookSubscription($input: UpdateOutlookSubscriptionInput!) {
    updateOutlookSubscription(input: $input) {
      ...FragmentOutlookSubscription
    }
  }
  ${FRAGMENT_OUTLOOK_SUBSCRIPTION}
`;

export const DELETE_OUTLOOK_SUBSCRIPTION = gql`
  mutation deleteOutlookSubscription($id: ID!) {
    deleteOutlookSubscription(id: $id) {
      ...FragmentOutlookSubscription
    }
  }
  ${FRAGMENT_OUTLOOK_SUBSCRIPTION}
`;

export const MUTATION_DELETE_ACTIVE_DIRECTORY_INTEGRATION = gql`
  mutation deleteActiveDirectory($id: ID!) {
    deleteActiveDirectory(id: $id) {
      ...FragmentActiveDirectory
    }
  }
  ${FRAGMENT_ACTIVE_DIRECTORY}
`;

export const MUTATION_CREATE_ACTIVE_DIRECTORY_SYNC = gql`
  mutation createActiveDirectorySync($input: CreateActiveDirectorySyncInput!) {
    createActiveDirectorySync(input: $input) {
      ...FragmentActiveDirectorySync
    }
  }
  ${FRAGMENT_ACTIVE_DIRECTORY_SYNC}
`;

export const MUTATION_UPDATE_ACTIVE_DIRECTORY_SYNC = gql`
  mutation updateActiveDirectorySync($input: UpdateActiveDirectorySyncInput!) {
    updateActiveDirectorySync(input: $input) {
      ...FragmentActiveDirectorySync
    }
  }
  ${FRAGMENT_ACTIVE_DIRECTORY_SYNC}
`;

export const MUTATION_DELETE_ACTIVE_DIRECTORY_SYNC = gql`
  mutation deleteActiveDirectorySync($id: ID!) {
    deleteActiveDirectorySync(id: $id) {
      ...FragmentActiveDirectorySync
    }
  }
  ${FRAGMENT_ACTIVE_DIRECTORY_SYNC}
`;

export const UPDATE_TWILIO_CLIENT = gql`
  mutation updateTwilioClient($input: UpdateTwilioClientInput!) {
    updateTwilioClient(input: $input) {
      id
    }
  }
`;

export const CREATE_TWILIO_CLIENT = gql`
  mutation addTwilioClient($input: AddTwilioClientInput!) {
    addTwilioClient(input: $input) {
      id
    }
  }
`;

export const CREATE_POSTBOX = gql`
  mutation addPostbox($input: AddPostboxInput!) {
    addPostbox(input: $input) {
      ...FragmentScreen
    }
  }
  ${FRAGMENT_SCREEN}
`;

export const UPDATE_POSTBOX = gql`
  mutation updatePostbox($input: UpdatePostboxInput!) {
    updatePostbox(input: $input) {
      ...FragmentScreen
    }
  }
  ${FRAGMENT_SCREEN}
`;

export const CREATE_FLOOR_INFO = gql`
  mutation addFloorInfo($input: AddFloorInfoInput!) {
    addFloorInfo(input: $input) {
      ...FragmentScreen
    }
  }
  ${FRAGMENT_SCREEN}
`;

export const UPDATE_FLOOR_INFO = gql`
  mutation updateFloorInfo($input: UpdateFloorInfoInput!) {
    updateFloorInfo(input: $input) {
      ...FragmentScreen
    }
  }
  ${FRAGMENT_SCREEN}
`;

export const MUTATION_CREATE_EMPLOYEE = gql`
  mutation createEmployee($input: CreateEmployeeInput!) {
    createEmployee(input: $input) {
      ...FragmentEmployee
    }
  }
  ${FRAGMENT_EMPLOYEE}
`;

export const MUTATION_UPDATE_EMPLOYEE = gql`
  mutation updateEmployee($input: UpdateEmployeeInput!) {
    updateEmployee(input: $input) {
      ...FragmentEmployee
    }
  }
  ${FRAGMENT_EMPLOYEE}
`;

export const MUTATION_DELETE_EMPLOYEE = gql`
  mutation deleteEmployee($id: ID!) {
    deleteEmployee(id: $id) {
      id
      fullName
    }
  }
`;

export const MUTATION_DELETE_EMPLOYEES = gql`
  mutation deleteEmployees($ids: [ID]!) {
    deleteEmployees(ids: $ids) {
      id
    }
  }
`;

export const MUTATION_CREATE_VISITOR = gql`
  mutation createVisitor($input: CreateVisitorInput!) {
    createVisitor(input: $input) {
      ...FragmentVisitor
    }
  }
  ${FRAGMENT_VISITOR}
`;
export const MUTATION_UPDATE_VISITOR = gql`
  mutation updateVisitor($input: UpdateVisitorInput!) {
    updateVisitor(input: $input) {
      ...FragmentVisitor
    }
  }
  ${FRAGMENT_VISITOR}
`;

export const MUTATION_DELETE_VISITOR = gql`
  mutation deleteVisitor($id: ID!) {
    deleteVisitor(id: $id) {
      id
      fullName
    }
  }
`;

export const MUTATION_DELETE_VISITORS = gql`
  mutation deleteVisitors($ids: [ID]!) {
    deleteVisitors(ids: $ids) {
      id
    }
  }
`;

export const MUTATION_CREATE_MEETING = gql`
  mutation createMeeting($input: CreateMeetingInput!) {
    createMeeting(input: $input) {
      ...FragmentMeeting
    }
  }
  ${FRAGMENT_MEETING}
`;

export const MUTATION_UPDATE_MEETING = gql`
  mutation updateMeeting($input: UpdateMeetingInput!) {
    updateMeeting(input: $input) {
      ...FragmentMeeting
    }
  }
  ${FRAGMENT_MEETING}
`;

export const MUTATION_DELETE_MEETING = gql`
  mutation deleteMeeting($id: ID!) {
    deleteMeeting(id: $id) {
      id
    }
  }
`;

export const MUTATION_DELETE_MEETINGS = gql`
  mutation deleteMeetings($ids: [ID]!) {
    deleteMeetings(ids: $ids) {
      id
    }
  }
`;

export const MUTATION_RESEND_MEMBER_ACCESS = gql`
  mutation resendMemberAccess($id: ID!) {
    resendMemberAccess(id: $id) {
      id
    }
  }
`;

export const MUTATION_CREATE_MEMBER_ACCESS = gql`
  mutation createMemberAccess($input: CreateMemberAccessInput!) {
    createMemberAccess(input: $input) {
      id
    }
  }
`;

export const MUTATION_FORGOT_PASSWORD = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input)
  }
`;

export const MUTATION_RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;

export const MUTATION_VERIFY_EMAIL = gql`
  mutation verifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input) {
      token
      expiresIn
    }
  }
`;

export const MUTATION_CREATE_SCREEN_SCHEDULE = gql`
  mutation createScreenSchedule($input: CreateScreenScheduleInput!) {
    createScreenSchedule(input: $input) {
      ...FragmentScreenSchedule
    }
  }
  ${FRAGMENT_SCREEN_SCHEDULE}
`;

export const MUTATION_UPDATE_SCREEN_SCHEDULE = gql`
  mutation updateScreenSchedule($input: UpdateScreenScheduleInput!) {
    updateScreenSchedule(input: $input) {
      ...FragmentScreenSchedule
    }
  }
  ${FRAGMENT_SCREEN_SCHEDULE}
`;

export const MUTATION_DELETE_SCREEN_SCHEDULE = gql`
  mutation deleteScreenSchedule($id: ID!) {
    deleteScreenSchedule(id: $id) {
      id
    }
  }
`;

export const MUTATION_CREATE_MEMBER_GROUP = gql`
  mutation createMemberGroup($input: CreateMemberGroupInput!) {
    createMemberGroup(input: $input) {
      ...FragmentMemberGroup
    }
  }
  ${FRAGMENT_MEMBER_GROUP}
`;

export const MUTATION_UPDATE_MEMBER_GROUP = gql`
  mutation updateMemberGroup($input: UpdateMemberGroupInput!) {
    updateMemberGroup(input: $input) {
      message
    }
  }
`;

export const MUTATION_DELETE_MEMBER_GROUP = gql`
  mutation deleteMemberGroup($id: ID!) {
    deleteMemberGroup(id: $id) {
      message
    }
  }
`;

export const CREATE_DECEASED = gql`
  mutation createDeceased($input: DeceasedAdd!) {
    createDeceased(input: $input) {
      ...FragmentProperty
    }
  }
  ${FRAGMENT_PROPERTY}
`;

export const UPDATE_DECEASED = gql`
  mutation updateDeceased($input: DeceasedUpdate!) {
    updateDeceased(input: $input) {
      ...FragmentProperty
    }
  }
  ${FRAGMENT_PROPERTY}
`;

export const DELETE_DECEASED = gql`
  mutation deleteDeceased($id: ID!) {
    deleteDeceased(id: $id) {
      ...FragmentProperty
    }
  }
  ${FRAGMENT_PROPERTY}
`;

export const UPDATE_WAYFINDING_SCREEN = gql`
  mutation updateWayfindingScreen($input: WayfindingScreenUpdate!) {
    updateWayfindingScreen(input: $input) {
      ...FragmentWayfindingScreen
    }
  }
  ${FRAGMENT_WAYFINDING_SCREEN}
`;

export const MUTATION_RESEND_VERIFICATION_EMAIL = gql`
  mutation resendVerificationEmail($input: ResendVerificationEmailInput!) {
    resendVerificationEmail(input: $input)
  }
`;

export const MUTATION_EMAIL_CHANGE_REQUEST = gql`
  mutation emailChangeRequest($input: EmailChangeRequestInput!) {
    emailChangeRequest(input: $input)
  }
`;

export const MUTATION_RESEND_VERIFICATION_EMAIL_CHANGE = gql`
  mutation resendVerificationEmailChange(
    $input: ResendVerificationEmailInput!
  ) {
    resendVerificationEmailChange(input: $input)
  }
`;

export const MUTATION_GENERATE_2FA_QR_CODE = gql`
  mutation generate2faQrCode($input: VerifyPasswordInput!) {
    generate2faQrCode(input: $input) {
      qrCode
    }
  }
`;

export const MUTATION_ENABLE_2FA_AUTH = gql`
  mutation enable2faAuth($input: User2faInput!) {
    enable2faAuth(input: $input) {
      enabled
    }
  }
`;

export const MUTATION_DISABLE_2FA_AUTH = gql`
  mutation disable2faAuth($input: VerifyPasswordInput!) {
    disable2faAuth(input: $input) {
      enabled
    }
  }
`;

export const MUTATION_USER_LOGIN_2FA = gql`
  mutation login2faCode($input: User2faInput!) {
    login2faCode(input: $input) {
      token
      expiresIn
    }
  }
`;

export const MUTATION_CREATE_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT = gql`
  mutation createEntranceAgreement($input: CreateEntranceAgreementInput!) {
    createEntranceAgreement(input: $input) {
      ...FragmentEntranceAgreement
    }
  }
  ${FRAGMENT_ENTRANCE_AGREEMENT}
`;

export const MUTATION_UPDATE_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT = gql`
  mutation updateEntranceAgreement($input: UpdateEntranceAgreementInput!) {
    updateEntranceAgreement(input: $input) {
      ...FragmentEntranceAgreement
    }
  }
  ${FRAGMENT_ENTRANCE_AGREEMENT}
`;

export const MUTATION_DELETE_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT = gql`
  mutation deleteEntranceAgreement($id: ID!) {
    deleteEntranceAgreement(id: $id) {
      id
    }
  }
`;

export const MUTATION_CREATE_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT_FILE = gql`
  mutation createEntranceAgreementFile(
    $input: CreateEntranceAgreementFileInput!
  ) {
    createEntranceAgreementFile(input: $input) {
      ...FragmentEntranceAgreementFile
    }
  }
  ${FRAGMENT_ENTRANCE_AGREEMENT_FILE}
`;

export const MUTATION_UPDATE_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT_FILE = gql`
  mutation updateEntranceAgreementFile(
    $input: UpdateEntranceAgreementFileInput!
  ) {
    updateEntranceAgreementFile(input: $input) {
      ...FragmentEntranceAgreementFile
    }
  }
  ${FRAGMENT_ENTRANCE_AGREEMENT_FILE}
`;

export const MUTATION_DELETE_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT_FILE = gql`
  mutation deleteEntranceAgreementFile($id: ID!) {
    deleteEntranceAgreementFile(id: $id) {
      id
    }
  }
`;

export const MUTATION_CREATE_DIGITAL_RECEPTION_MANUAL_REGISTRATION = gql`
  mutation createManualRegistration($input: CreateManualRegistrationInput!) {
    createManualRegistration(input: $input) {
      ...FragmentManualRegistration
    }
  }
  ${FRAGMENT_MANUAL_REGISTRATION}
`;

export const MUTATION_UPDATE_DIGITAL_RECEPTION_MANUAL_REGISTRATION = gql`
  mutation updateManualRegistration($input: UpdateManualRegistrationInput!) {
    updateManualRegistration(input: $input) {
      ...FragmentManualRegistration
    }
  }
  ${FRAGMENT_MANUAL_REGISTRATION}
`;

export const MUTATION_DELETE_DIGITAL_RECEPTION_MANUAL_REGISTRATION = gql`
  mutation deleteManualRegistration($id: ID!) {
    deleteManualRegistration(id: $id) {
      id
    }
  }
`;

export const MUTATION_CREATE_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD = gql`
  mutation createManualRegistrationField(
    $input: CreateManualRegistrationFieldInput!
  ) {
    createManualRegistrationField(input: $input) {
      ...FragmentManualRegistrationField
    }
  }
  ${FRAGMENT_MANUAL_REGISTRATION_FIELD}
`;

export const MUTATION_UPDATE_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD = gql`
  mutation updateManualRegistrationField(
    $input: UpdateManualRegistrationFieldInput!
  ) {
    updateManualRegistrationField(input: $input) {
      ...FragmentManualRegistrationField
    }
  }
  ${FRAGMENT_MANUAL_REGISTRATION_FIELD}
`;

export const MUTATION_DELETE_DIGITAL_RECEPTION_MANUAL_REGISTRATION_FIELD = gql`
  mutation deleteManualRegistrationField($id: ID!) {
    deleteManualRegistrationField(id: $id) {
      id
    }
  }
`;
