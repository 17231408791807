import React from "react";

import { useTranslation } from "react-i18next";
import clsx from "clsx";

import {
  useRouteMatch,
  useParams,
  NavLink,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import { useQuery } from "@apollo/client";

import Button from "react-bootstrap/Button";

import FileUploadProvider from "../../../components/FileUpload";
import { QUERY_PROPERTY_COMPANY } from "../../../config/graphql/query";

import CompanySocialForm from "./Social";
import CompanyContactForm from "./Contact";
import CompanyInformationForm from "./Information";

import BoardsRoute from "./Boards";
import EntitiesRoute from "./Entities";
import WayfindingsRoute from "./Wayfindings";
import SettingsRoute from "./Settings";
import IntegrationsRoute from "./Integrations";

import Route from "../../../components/Route";
import CodeBlock from "../../../components/CodeBlock";
import RoleBlock from "../../../components/RoleBlock";
import PlanBlock, { useCurrentHasPlan } from "../../../components/PlanBlock";

const CompanyCreate = React.memo(() => {
  const match = useRouteMatch();

  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(["company"]);

  const { data } = useQuery(QUERY_PROPERTY_COMPANY, {
    skip: !id,
    variables: { id },
    nextFetchPolicy: "network-only",
  });

  const isFree = useCurrentHasPlan(["free"]);

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/company">{t("company:nav.companies")}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {data?.property?.title ?? t("company:nav.company")}
          </li>
        </ol>
        {!!id && (
          <div className="d-flex justify-content-end mb-3 mb-lg-0">
            <Link to={`/company/${data?.propertyPrev?.id}`} className="mr-3">
              <Button type="primary" disabled={!data?.propertyPrev?.id}>
                {t("company:nav.previous")}
              </Button>
            </Link>
            <Link to={`/company/${data?.propertyNext?.id}`}>
              <Button type="primary" disabled={!data?.propertyNext?.id}>
                {t("company:nav.next")}
              </Button>
            </Link>
          </div>
        )}
      </nav>

      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <NavLink exact className="nav-link" to={match.url}>
            {t("company:nav.information")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            exact
            className={clsx("nav-link", {
              disabled: !id,
            })}
            to={`${match.url}/contact`}
          >
            {t("company:nav.contact")}
          </NavLink>
        </li>
        <PlanBlock exclude={["free"]}>
          <CodeBlock exclude={["the-base"]}>
            <li className="nav-item">
              <NavLink
                className={clsx("nav-link", {
                  disabled: !id,
                })}
                to={`${match.url}/social`}
              >
                {t("company:nav.social")}
              </NavLink>
            </li>
          </CodeBlock>
        </PlanBlock>
        {!isFree && (
          <>
            <li className="nav-item">
              <NavLink
                className={clsx("nav-link", {
                  disabled: !id,
                })}
                to={`${match.url}/integrations`}
              >
                {t("company:nav.integrations")}
              </NavLink>
            </li>
          </>
        )}
        <RoleBlock roles={["ADMIN"]}>
          <li className="nav-item">
            <NavLink
              className={clsx("nav-link", {
                disabled: !id,
              })}
              to={`${match.url}/settings`}
            >
              {t("company:nav.settings")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={clsx("nav-link", {
                disabled: !id,
              })}
              to={`${match.url}/entity`}
            >
              {t("company:nav.entities")}
            </NavLink>
          </li>
        </RoleBlock>
        <RoleBlock roles={["ADMIN", "CLIENT_ADMIN"]}>
          <li className="nav-item">
            <NavLink
              className={clsx("nav-link", {
                disabled: !id,
              })}
              to={`${match.url}/screens`}
            >
              {t("company:nav.screens")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={clsx("nav-link", {
                disabled: !id,
              })}
              to={`${match.url}/wayfinding`}
            >
              {t("company:nav.wayfindings")}
            </NavLink>
          </li>
        </RoleBlock>
      </ul>

      <div className="tab-content">
        <Switch>
          <Route.Private exact path={match.path}>
            <div className="tab-pane active" role="tabpanel">
              <FileUploadProvider>
                <CompanyInformationForm />
              </FileUploadProvider>
            </div>
          </Route.Private>
          <Route.Private exact path={`${match.path}/contact`}>
            <div className="tab-pane active" role="tabpanel">
              <CompanyContactForm />
            </div>
          </Route.Private>
          <Route.Base exact path={`${match.path}/social`}>
            <div className="tab-pane active" role="tabpanel">
              <CompanySocialForm />
            </div>
          </Route.Base>
          {!isFree && (
            <Route.Private exact path={`${match.path}/integrations`}>
              <div className="tab-pane active" role="tabpanel">
                <IntegrationsRoute />
              </div>
            </Route.Private>
          )}
          <Route.Role
            exact
            path={`${match.path}/settings`}
            roles={["ADMIN"]}
            redirect={`/company/${id}`}
          >
            <div className="tab-pane active" role="tabpanel">
              <SettingsRoute />
            </div>
          </Route.Role>
          <Route.Role
            exact
            path={`${match.path}/entity`}
            roles={["ADMIN"]}
            redirect={`/company/${id}`}
          >
            <div className="tab-pane active" role="tabpanel">
              <EntitiesRoute />
            </div>
          </Route.Role>
          <Route.Private exact path={`${match.path}/screens`}>
            <div className="tab-pane active" role="tabpanel">
              <BoardsRoute />
            </div>
          </Route.Private>
          <Route.Private exact path={`${match.path}/wayfinding`}>
            <div className="tab-pane active" role="tabpanel">
              <WayfindingsRoute />
            </div>
          </Route.Private>
          <Redirect to={`/company/${id}`} />
        </Switch>
      </div>
    </div>
  );
});

export default CompanyCreate;
