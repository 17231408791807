import React, { useMemo } from "react";

import { useCurrentClient } from "../context/Client";

type PlanType = ClientPlan[] | ((plan: ClientPlan) => boolean);

export type PlanBlockProps = {
  include?: PlanType;
  exclude?: PlanType;
};

export const useHasPlan = (client: IClient | undefined, plan: PlanType) => {
  const clientPlan = client?.plan;

  return useMemo((): boolean => {
    if (typeof clientPlan !== "string") {
      return false;
    }

    if (typeof plan === "function") {
      return plan(clientPlan);
    }

    if (Array.isArray(plan) && plan.length > 0) {
      return plan.includes(clientPlan);
    }

    return true;
  }, [clientPlan, plan]);
};

export const useCurrentHasPlan = (plan: PlanType) => {
  const client = useCurrentClient();

  return useHasPlan(client, plan);
};

const PlanBlock = React.memo(
  (props: React.PropsWithChildren<PlanBlockProps>) => {
    const { children } = props;

    const client = useCurrentClient();

    const rules = useMemo(
      () => ("include" in props ? props.include : props.exclude),
      [props],
    );

    const hasPlan = useHasPlan(client, rules ?? []);

    if (!rules) {
      return <>{children}</>;
    }

    if ("include" in props && hasPlan) {
      return <>{children}</>;
    }

    if ("exclude" in props && !hasPlan) {
      return <>{children}</>;
    }

    return null;
  },
);

export default PlanBlock;
