import React, { useCallback, useEffect, useMemo, useState } from "react";

import qs from "query-string";
import dayjs from "dayjs";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";

import { useMutation, useQuery } from "@apollo/client";

import {
  Link,
  Route,
  Switch,
  Redirect,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { QUERY_COMPANIES } from "../../config/graphql/query";
import {
  DELETE_COMPANY,
  DELETE_COMPANIES,
} from "../../config/graphql/mutation";

import CompanyPage from "./Company";
import IntegrationsOutlook from "./Company/Integrations/ActiveDirectorySync";

import RoleBlock from "../../components/RoleBlock";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";

const limit = 10;

const TableRow = ({
  item,
  index,
  variables,
  checked,
  onCheck,
}: {
  item: ICompany;
  index: number;
  variables: any;
  checked: boolean;
  onCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { t } = useTranslation(["company", "common"]);

  const { id, createdAt, updatedAt, title } = item;

  const [visible, setVisible] = useToggle(false);

  const [onDelete, { loading }] = useMutation(DELETE_COMPANY, {
    refetchQueries: [
      { query: QUERY_COMPANIES, variables },
      { query: QUERY_COMPANIES },
    ],
    variables: { id },
  });

  const onBeforeDelete = () => {
    onDelete()
      .then(
        ({
          data: {
            deleteCompany: { title },
          },
        }) => {
          toast.success<string>(
            t("company:companies.toast.deleted", { count: 1, title }),
          );
          setVisible(false);
        },
      )
      .catch(() => {
        toast.error<string>(
          t("company:companies.toast.deletedError", { count: 1 }),
        );
        setVisible(false);
      });
  };

  return (
    <>
      <tr>
        <td className="text-center">
          <input
            value={id}
            checked={checked}
            type="checkbox"
            onChange={onCheck}
          />
        </td>
        <td className="text-center">{index + 1}</td>
        <td className="h6 text-nowrap">{title}</td>
        <td>{dayjs(createdAt).format("DD-MM-YYYY")}</td>
        <td>{dayjs(updatedAt).format("DD-MM-YYYY")}</td>
        <td className="text-nowrap">
          <RoleBlock roles={["ADMIN", "CLIENT_ADMIN"]}>
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>
          </RoleBlock>
          <Link to={`/company/${id}`}>
            <Button
              size="sm"
              variant="primary"
              onClick={() => setVisible(false)}
            >
              {t("common:view")}
            </Button>
          </Link>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("company:companies.modal.title", { count: 1 })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("company:companies.modal.body", { count: 1, title: item.title })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button
            size="sm"
            variant="danger"
            onClick={onBeforeDelete}
            disabled={loading}
          >
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Companies = React.memo(() => {
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation(["company", "common"]);

  const query = useMemo(
    () =>
      qs.parse(history.location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
      },
    [history.location.search],
  );

  const [modal, setModal] = useToggle(false);

  const [checked, setChecked] = useState<string[]>([]);

  const onCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let updatedList = [...checked];
      if (event.target.checked) {
        updatedList = [...checked, event.target.value];
      } else {
        updatedList.splice(checked.indexOf(event.target.value), 1);
      }
      setChecked(updatedList);
    },
    [checked],
  );

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);

  const variables = useMemo(
    () => ({
      sort: {
        title: "ASC",
      },
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
      ...(query.search
        ? {
            filter: {
              title: {
                REGEX: query.search,
                OPTIONS: "i",
              },
            },
          }
        : {}),
    }),
    [page, query.search],
  );

  const { data } = useQuery<{
    companies?: Array<ICompany>;
    companiesCount: number;
  }>(QUERY_COMPANIES, {
    fetchPolicy: "network-only",
    variables,
  });

  const companies = useMemo(() => data?.companies ?? [], [data]);
  const companiesCount = data?.companiesCount ?? 0;

  const renderCompany = useCallback(
    (item: ICompany, index: number) => (
      <TableRow
        key={item.id}
        item={item}
        variables={variables}
        index={(page - 1) * limit + index}
        checked={checked.includes(item.id)}
        onCheck={onCheck}
      />
    ),
    [page, variables, checked, onCheck],
  );

  const onAllCheck = useCallback(() => {
    const checkList = companies.map((company) => company.id);
    setChecked(checkList);
  }, [companies]);

  const onAllUncheck = useCallback(() => {
    setChecked([]);
  }, []);

  useEffect(() => {
    setChecked([]);
  }, [page]);

  const [onDelete] = useMutation(DELETE_COMPANIES, {
    refetchQueries: [{ query: QUERY_COMPANIES, variables }],
  });

  const onDeleteSelected = () => {
    onDelete({ variables: { ids: checked } })
      .then(() => {
        toast.success<string>(
          t("company:companies.toast.deleted", { count: 2 }),
        );
        setModal(false);
        setChecked([]);
      })
      .catch(() => {
        toast.error<string>(
          t("company:companies.toast.deletedError", { count: 2 }),
        );
        setModal(false);
      });
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("company:companies.companies")}
          </li>
        </ol>
      </nav>

      <div className="d-flex flex-wrap justify-content-between mt-4">
        <RoleBlock roles={["ADMIN", "CLIENT_ADMIN"]}>
          <div>
            <Button className="mr-2 mb-4" onClick={onAllCheck}>
              {t("company:companies.button.selectAll")}
            </Button>
            <Button className="mr-2 mb-4" onClick={onAllUncheck}>
              {t("company:companies.button.unselectAll")}
            </Button>
            <Button
              className="mr-2 mb-4"
              variant="danger"
              disabled={!checked.length}
              onClick={() => {
                setModal(true);
              }}
            >
              {t("company:companies.button.deleteSelected")}
            </Button>
          </div>
        </RoleBlock>
        <form className="form-inline mr-2">
          <div className="mr-5 mb-4">
            <label htmlFor="search" className="sr-only">
              {t("common:search")}
            </label>
            <input
              id="search"
              type="search"
              className="form-control"
              placeholder={t("company:companies.input.search.placeholder")}
              onChange={({ target: { value } }) =>
                history.push({
                  search: qs.stringify({ search: value }),
                })
              }
            />
          </div>
        </form>
        <RoleBlock roles={["ADMIN", "CLIENT_ADMIN"]}>
          <Link
            to={`${match.path}/new`}
            type="button"
            className="btn btn-primary mb-4"
          >
            {t("company:companies.button.create")}
          </Link>
        </RoleBlock>
      </div>

      <Table>
        <thead>
          <tr>
            <th scope="col" style={{ width: "3.5rem" }}>
              {" "}
            </th>
            <th scope="col" style={{ width: "3.5rem", textAlign: "center" }}>
              #
            </th>
            <th scope="col">{t("company:companies.th.title")}</th>
            <th scope="col">{t("company:companies.th.created")}</th>
            <th scope="col">{t("company:companies.th.updated")}</th>
            <th scope="col">{t("company:companies.th.action")}</th>
          </tr>
        </thead>
        <tbody>{companies.map(renderCompany)}</tbody>
      </Table>
      <Pagination documentsCount={companiesCount} limit={limit} />
      <Modal
        show={modal}
        onHide={setModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("company:companies.modal.title", { count: 2 })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("company:companies.modal.body", { count: 2 })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setModal(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onDeleteSelected}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

const BuildingRoute = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Companies />
      </Route>
      <Route exact path={`${path}/new`}>
        <CompanyPage />
      </Route>
      <Route exact path={`${path}/:companyId/integrations/activedirectory`}>
        <IntegrationsOutlook />
      </Route>
      <Route
        exact
        path={`${path}/:companyId/integrations/activedirectory/:integrationId`}
      >
        <IntegrationsOutlook />
      </Route>
      <Route path={`${path}/:id`}>
        <CompanyPage />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default BuildingRoute;
