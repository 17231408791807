import React, { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { useMutation, useQuery } from "@apollo/client";

import { toast } from "react-toastify";
import { Link, useHistory, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

import { useClientId } from "../../Clients/Client/hooks/useClient";

import {
  QUERY_MEMBER_GROUP,
  QUERY_MEMBER_GROUPS,
  QUERY_CLIENT_PREFERENCES,
} from "../../../config/graphql/query";
import {
  MUTATION_CREATE_MEMBER_GROUP,
  MUTATION_UPDATE_MEMBER_GROUP,
} from "../../../config/graphql/mutation";

import Input from "../../../components/Input";
import { PreferencesController } from "../../../components/Preferences";

export const MemberGroupPreferences = (props: { name: string }) => {
  const { name } = props;

  const { t } = useTranslation(["employeePreferences", "memberGroup"]);

  const clientId = useClientId();

  const { data: clientPreferencesData } = useQuery<{
    client: {
      preferences: IClientPreferences;
    };
  }>(QUERY_CLIENT_PREFERENCES, {
    variables: { id: clientId },
    fetchPolicy: "network-only",
  });

  const parent = useMemo(
    () => clientPreferencesData?.client?.preferences?.company?.employee,
    [clientPreferencesData],
  );

  return (
    <>
      <PreferencesController
        name={`${name}.visitor`}
        label={t(`employeePreferences:input.label.${name}.visitor.enabled`)}
        parent={parent?.visitor}
      />
      <PreferencesController
        name={`${name}.meetingAttendee`}
        label={t(
          `employeePreferences:input.label.${name}.meetingAttendee.enabled`,
        )}
        parent={parent?.meetingAttendee}
      />
      <PreferencesController
        name={`${name}.foodDelivery`}
        label={t(
          `employeePreferences:input.label.${name}.foodDelivery.enabled`,
        )}
        parent={parent?.foodDelivery}
      />
      <PreferencesController
        name={`${name}.packageDelivery`}
        label={t(
          `employeePreferences:input.label.${name}.packageDelivery.enabled`,
        )}
        parent={parent?.packageDelivery}
      />
    </>
  );
};

type FieldValues = {
  title: string;
  preferences: IMemberGroupPreferences;
};

const MemberGroup = React.memo(() => {
  const history = useHistory();

  const { t } = useTranslation(["memberGroup", "common"]);

  const { id } = useParams<{ id: string }>();

  const { data: memberGroupData } = useQuery<{ memberGroup: IMemberGroup }>(
    QUERY_MEMBER_GROUP,
    {
      skip: !id,
      variables: { id },
      fetchPolicy: "network-only",
    },
  );

  const [onCreate] = useMutation(MUTATION_CREATE_MEMBER_GROUP, {
    refetchQueries: [
      {
        query: QUERY_MEMBER_GROUPS,
      },
    ],
  });

  const [onUpdate] = useMutation(MUTATION_UPDATE_MEMBER_GROUP);

  const methods = useForm<FieldValues>({
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {
      title: "",
      preferences: undefined,
    },
  });

  useEffect(() => {
    if (memberGroupData?.memberGroup) {
      methods.reset({
        title: memberGroupData?.memberGroup?.title,
        preferences: memberGroupData?.memberGroup?.preferences,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberGroupData]);

  const onSubmit = async (variables: FieldValues) => {
    if (id) {
      return onUpdate({
        variables: {
          input: { id, ...variables },
        },
      })
        .then(() => {
          toast.success<string>(t("memberGroup:toast.updated"));
        })
        .catch(() => {
          toast.error<string>(t("memberGroup:toast.updatedError"));
        });
    }

    return onCreate({ variables: { input: variables } })
      .then(
        ({
          data: {
            createMemberGroup: { title },
          },
        }) => {
          toast.success<string>(t("memberGroup:toast.created", { title }));
          history.replace("/employees/groups");
        },
      )
      .catch(() => {
        toast.error<string>(t("memberGroup:toast.createdError"));
      });
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/employees">{t("employees:title")}</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/employees/groups">{t("memberGroups:title")}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {memberGroupData?.memberGroup?.title ?? t("memberGroup:title")}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="title">
                  <h6>{t("memberGroup:input.label.title")}</h6>
                </label>
                <Input
                  name="title"
                  className="form-control"
                  rules={{
                    required: {
                      value: true,
                      message: t("memberGroup:input.error.title.required"),
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <MemberGroupPreferences name="preferences" />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary mt-2 mb-4"
                disabled={methods.formState.isSubmitting}
              >
                {t("employeePreferences:button.submit")}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
});

export default MemberGroup;
