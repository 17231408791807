import React, { useCallback, useMemo } from "react";

import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";

import { QUERY_NAMEBOARD_PATHS } from "../../../../config/graphql/query";

const PathsRoute = React.memo(() => {
  const history = useHistory();
  const { t } = useTranslation(["screens", "common"]);

  const {
    url,
    params: { screenId },
  }: {
    url: string;
    path: string;
    params: { screenId: string };
  } = useRouteMatch();

  const { data } = useQuery<{ nameboardPaths: INameboardPath[] }>(
    QUERY_NAMEBOARD_PATHS,
    {
      variables: {
        filter: {
          nameboard: screenId,
        },
      },
      nextFetchPolicy: "network-only",
    },
  );
  const paths = useMemo(() => data?.nameboardPaths ?? [], [data]);

  const renderPath = useCallback(
    ({ id, plan, entity }: INameboardPath) => (
      <div
        className="col-6 col-sm-3 pt-4"
        style={{ width: "18rem" }}
        onClick={() => history.push(`${url}/${id}`)}
        key={entity.id}
      >
        <div className="card">
          <img
            className="card-img-top"
            src={plan.absolutePath}
            alt={t("screens:screen.paths.paths.img")}
          />
          <div className="card-body">
            <h5 className="card-title">{entity.title}</h5>
            <h5>{entity.type}</h5>
          </div>
        </div>
      </div>
    ),
    [history, url, t],
  );

  return (
    <>
      <div className="d-flex justify-content-between align-items-center my-4">
        <Link to={`${url}/new`} type="button" className="btn btn-primary">
          {t("screens:screen.paths.paths.button.addPath")}
        </Link>
      </div>
      <div className="row">
        {[...paths]
          .sort((a, b) =>
            a.entity.title.localeCompare(b.entity.title, "en", {
              caseFirst: "lower",
            }),
          )
          .map(renderPath)}
      </div>
    </>
  );
});

export default PathsRoute;
