import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import axios from "axios";

import {
  NavLink,
  Switch,
  Redirect,
  useRouteMatch,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";

import clsx from "clsx";
import qs from "query-string";
import useToggle from "react-use/lib/useToggle";

import { toast } from "react-toastify";
import { useQuery, useMutation } from "@apollo/client";

import { useDebounce } from "react-use";

import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Controller, FormProvider, useForm } from "react-hook-form";

import Route from "../../../components/Route";
import Table from "../../../components/Table";
import Pagination from "../../../components/Pagination";
import { useCurrentHasPlan } from "../../../components/PlanBlock";

import MemberGroups from "../../MemberGroups/MemberGroups";

import {
  QUERY_COMPANIES,
  QUERY_EMPLOYEES,
} from "../../../config/graphql/query";
import {
  MUTATION_DELETE_EMPLOYEE,
  MUTATION_DELETE_EMPLOYEES,
} from "../../../config/graphql/mutation";

import { reactSelectCustomStyles } from "../Employee/Information";
import { useCurrentClient } from "../../../context/Client";

const limit = 10;

interface ImportFieldValues {
  company: ICompany;
  file: File;
}

const TableRow = ({
  item,
  variables,
  index,
  checked,
  onCheck,
}: {
  item: IMemberEmployee;
  variables: any;
  index: number;
  checked: boolean;
  onCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { id, fullName, email, phone, company, externalSource } = item;

  const [visible, setVisible] = useToggle(false);

  const { t } = useTranslation(["employee", "common"]);

  const [onDelete, { loading }] = useMutation(MUTATION_DELETE_EMPLOYEE, {
    refetchQueries: [
      { query: QUERY_EMPLOYEES, variables },
      { query: QUERY_EMPLOYEES },
    ],
    variables: { id },
  });

  const onBeforeDelete = async () => {
    onDelete()
      .then(
        ({
          data: {
            deleteEmployee: { fullName },
          },
        }) => {
          toast.success<string>(
            t("employees:toast.deleted", { count: 1, fullName }),
          );

          setVisible(false);
        },
      )
      .catch(() => {
        toast.error<string>(t("employees:toast.deletedError", { count: 1 }));
        setVisible(false);
      });
  };

  return (
    <>
      <tr>
        <td className="text-center">
          <input
            value={id}
            checked={checked}
            type="checkbox"
            onChange={onCheck}
          />
        </td>
        <td className="text-center">{index + 1}</td>
        <td className="h6">{fullName}</td>
        <td>{email}</td>
        <td>{phone}</td>
        <td>{company?.title}</td>
        <td className="text-nowrap">
          <div className="d-flex">
            {!externalSource?.id && (
              <Button
                size="sm"
                variant="danger"
                className="mr-2"
                onClick={() => setVisible(true)}
              >
                {t("common:delete")}
              </Button>
            )}
            <Link to={`/employees/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("employees:dialog.beforeDelete.title", { count: 1 })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("employees:dialog.beforeDelete.description", {
            count: 1,
            fullName,
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setVisible(false)}>
            {t("employees:dialog.beforeDelete.button.dismiss")}
          </Button>
          <Button variant="danger" onClick={onBeforeDelete} disabled={loading}>
            {t("employees:dialog.beforeDelete.button.submit")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Employees = React.memo(() => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const { t } = useTranslation(["employees", "common"]);

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
        companyId?: string;
      },
    [location.search],
  );

  const [isImporting, setIsImporting] = useState(false);
  const [importModalVisible, setImportModalVisible] = useToggle(false);

  const [search, setSearch] = useState(query.search);
  const [companyId, setCompanyId] = useState(query.companyId);

  const [modal, setModal] = useToggle(false);

  const [checked, setChecked] = useState<string[]>([]);

  const { data: companiesData } = useQuery<{ companies: ICompany[] }>(
    QUERY_COMPANIES,
    {
      nextFetchPolicy: "network-only",
    },
  );

  const companyOptions = useMemo(
    () => companiesData?.companies ?? [],
    [companiesData],
  );

  const methods = useForm<ImportFieldValues>({
    shouldFocusError: false,
    shouldUnregister: false,
    defaultValues: {
      company: companyOptions?.[0],
    },
  });

  const onCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let updatedList = [...checked];
      if (event.target.checked) {
        updatedList = [...checked, event.target.value];
      } else {
        updatedList.splice(checked.indexOf(event.target.value), 1);
      }
      setChecked(updatedList);
    },
    [checked],
  );

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);

  const regex = useMemo(() => {
    return {
      REGEX: query.search ? query.search.toString().replace(/\+/g, "[+]") : "",
      OPTIONS: "i",
    };
  }, [query.search]);

  const variables = useMemo(
    () => ({
      sort: {
        fullName: "ASC",
      },
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
      filter: {
        ...(regex.REGEX
          ? {
              OR: [
                {
                  fullName: regex,
                },
                {
                  email: regex,
                },
                {
                  phone: regex,
                },
              ],
            }
          : {}),
        ...(query.companyId
          ? {
              company: {
                id: {
                  EQ: query.companyId,
                },
              },
            }
          : {}),
      },
    }),
    [page, query.companyId, regex],
  );

  useEffect(() => {
    if (companyOptions.length === 1) {
      setCompanyId(companyOptions[0].id);

      methods.reset({
        company: companyOptions[0],
      });
    }
  }, [companyOptions, methods]);

  const { data, refetch: refetchEmployees } = useQuery<{
    employees: IMemberEmployee[];
    employeesCount: number;
  }>(QUERY_EMPLOYEES, {
    fetchPolicy: "network-only",
    variables,
  });

  const employees = useMemo(() => data?.employees ?? [], [data]);
  const employeesCount = data?.employeesCount ?? 0;

  const renderEmployee = useCallback(
    (item: IMemberEmployee, index: number) => (
      <TableRow
        key={item.id}
        item={item}
        variables={variables}
        index={(page - 1) * limit + index}
        checked={checked.includes(item.id)}
        onCheck={onCheck}
      />
    ),
    [variables, page, checked, onCheck],
  );

  const onAllCheck = useCallback(() => {
    const checkList = employees.map((employee) => employee.id);
    setChecked(checkList);
  }, [employees]);

  const onAllUncheck = useCallback(() => {
    setChecked([]);
  }, []);

  useEffect(() => {
    setChecked([]);
  }, [page]);

  useDebounce(
    () => {
      if (
        search !== undefined ||
        (companyId !== undefined && companyOptions.length > 1)
      ) {
        history.push({
          search: qs.stringify({
            page: 1,
            ...(search && { search }),
            ...(companyId && companyOptions.length > 1 && { companyId }),
          }),
        });
      }
    },
    500,
    [search, companyId],
  );

  const [onDelete] = useMutation(MUTATION_DELETE_EMPLOYEES, {
    refetchQueries: [{ query: QUERY_EMPLOYEES, variables }],
  });

  const client = useCurrentClient();

  const handleFormSubmit = async (data: ImportFieldValues) => {
    setIsImporting(true);

    const { company, file } = data;

    const formData = new FormData();

    formData.append("file", file);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/client/${client?.id}/import/company/${company.id}/employees`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
          },
        },
      );

      await refetchEmployees(variables);

      toast.success<string>(t("employees:toast.imported"));

      setIsImporting(false);
      setImportModalVisible(false);
    } catch (error) {
      toast.error<string>(t("employees:toast.importedError"));

      setIsImporting(false);
      setImportModalVisible(false);
    }
  };

  const onDeleteSelected = () => {
    onDelete({ variables: { ids: checked } })
      .then(() => {
        toast.success<string>(t("employees:toast.deleted", { count: 2 }));
        setModal(false);
        setChecked([]);
      })
      .catch(() => {
        toast.error<string>(t("employees:toast.deletedError", { count: 2 }));
        setModal(false);
      });
  };

  return (
    <div className="container-fluid">
      <div className="d-flex flex-wrap justify-content-between mt-4">
        <div>
          <Button className="mr-2 mb-4" onClick={onAllCheck}>
            {t("employees:button.selectAll")}
          </Button>
          <Button className="mr-2 mb-4" onClick={onAllUncheck}>
            {t("employees:button.unselectAll")}
          </Button>
          <Button
            className="mr-2 mb-4"
            variant="danger"
            disabled={!checked.length}
            onClick={() => {
              setModal(true);
            }}
          >
            {t("employees:button.deleteSelected")}
          </Button>
        </div>
        <form className="form-inline mr-2">
          <div className="mr-5 mb-4">
            <label htmlFor="search" className="sr-only">
              {t("employees:input.label.search")}
            </label>
            <input
              id="search"
              type="search"
              className="form-control"
              placeholder={t("employees:input.placeholder.search")}
              value={search}
              onChange={({ target: { value } }) => {
                setSearch(value);
              }}
            />
          </div>
          <div className="d-flex mb-4">
            <div className="mr-1">
              <Select
                getOptionLabel={({ title }) => title}
                getOptionValue={({ id }) => id}
                options={companyOptions}
                onChange={(value) => {
                  setCompanyId(value?.id ?? "");
                }}
                value={companyOptions.find(
                  (company) => company.id === companyId,
                )}
                styles={reactSelectCustomStyles(false)}
                isDisabled={companyOptions.length === 1}
                placeholder={t("employees:input.placeholder.company")}
                isClearable
              />
            </div>
          </div>
        </form>
        <div>
          <Button
            className="mr-2 mb-4"
            onClick={() => setImportModalVisible(true)}
          >
            {t("employees:button.import")}
          </Button>
          <Link
            to={`${match.path}/new`}
            type="button"
            className="btn btn-primary mb-4"
          >
            {t("employees:button.create")}
          </Link>
        </div>
      </div>

      <Table striped bordered hover responsive size="lg">
        <thead>
          <tr>
            <th scope="col" style={{ width: "3.5rem" }}>
              {" "}
            </th>
            <th scope="col" style={{ width: "3.5rem", textAlign: "center" }}>
              #
            </th>
            <th scope="col">{t("employees:list.th.fullName")}</th>
            <th scope="col">{t("employees:list.th.email")}</th>
            <th scope="col">{t("employees:list.th.phone")}</th>
            <th scope="col">{t("employees:list.th.company")}</th>
            <th scope="col">{t("employees:list.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{employees.map(renderEmployee)}</tbody>
      </Table>
      <Pagination documentsCount={employeesCount} limit={limit} />

      <Modal
        show={importModalVisible}
        onHide={() => setImportModalVisible(false)}
      >
        <FormProvider {...methods}>
          <Modal.Header>
            <Modal.Title style={{ width: "100%", textAlign: "center" }}>
              {t("employees:import.title")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {companyOptions.length > 1 && (
              <Controller
                name="company"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className="form-group">
                    <label htmlFor="company">
                      {t("employees:import.input.label.company")}
                    </label>
                    <Select
                      getOptionLabel={({ title }) => title}
                      getOptionValue={({ id }) => id}
                      options={companyOptions}
                      onChange={(value) => onChange(value)}
                      value={value}
                      className={clsx({
                        "is-invalid": !!error,
                      })}
                      styles={reactSelectCustomStyles(!!error)}
                    />
                    <div className="invalid-feedback">{error?.message}</div>
                  </div>
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("employees:import.input.error.company.required"),
                  },
                }}
              />
            )}
            <Controller
              name="file"
              rules={{
                required: {
                  value: true,
                  message: t("employees:import.input.error.file.required"),
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div className="form-group ">
                  <label htmlFor="file">
                    {t("employees:import.input.label.file")}
                  </label>
                  <div className="bg-light border rounded py-4 border-dark d-flex flex-column align-items-center position-relative">
                    <input
                      name="employee-company-import"
                      type="file"
                      accept=".csv"
                      style={{ opacity: 0 }}
                      onChange={(e) => {
                        const {
                          target: { files },
                        } = e;

                        if (files === null) {
                          return;
                        }

                        onChange(files[0]);
                      }}
                      className={clsx(
                        "position-absolute top-0 bottom-0 right-0 left-0",
                        {
                          "is-invalid ": !!error,
                        },
                      )}
                    />
                    <div className="d-flex flex-column align-items-center">
                      <i className="fa fa-upload text-muted h2" />
                      {t("common:fileUpload")}
                    </div>
                  </div>

                  <div className="mt-2 text-left">
                    <Trans
                      i18nKey="employees:import.input.helper.file"
                      components={[
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          key="0"
                          href="https://api.nameboards.castit.nl/assets/examples/import_employees_example.csv"
                          target="_blank"
                          rel="noopener noreferrer"
                        />,
                      ]}
                    />
                  </div>
                  <div
                    className="invalid-feedback"
                    style={{ display: error ? "block" : "none" }}
                  >
                    {error?.message}
                  </div>
                  {value instanceof File && <span>{value.name}</span>}
                </div>
              )}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => setImportModalVisible(false)}
            >
              {t("common:cancel")}
            </Button>
            <Button
              size="sm"
              disabled={isImporting}
              onClick={methods.handleSubmit(handleFormSubmit)}
            >
              {t("common:save")}
            </Button>
          </Modal.Footer>
        </FormProvider>
      </Modal>

      <Modal
        show={modal}
        onHide={setModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("employees:dialog.beforeDelete.title", { count: 2 })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("employees:dialog.beforeDelete.description", {
            count: 2,
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setModal(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onDeleteSelected}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

const EmployeesRoute = React.memo(() => {
  const { t } = useTranslation(["employees"]);

  const { path } = useRouteMatch();

  const isFree = useCurrentHasPlan(["free"]);

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          {path.endsWith("groups") ? (
            <>
              <li className="breadcrumb-item">
                <Link to="/employees">{t("employees:title")}</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {t("memberGroups:title")}
              </li>
            </>
          ) : (
            <li className="breadcrumb-item">{t("employees:title")}</li>
          )}
        </ol>
      </nav>
      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <NavLink exact className="nav-link" to="/employees">
            {t("employees:tab.list")}
          </NavLink>
        </li>
        {!isFree && (
          <li className="nav-item">
            <NavLink exact className="nav-link" to="/employees/groups">
              {t("employees:tab.groups")}
            </NavLink>
          </li>
        )}
      </ul>

      <div className="tab-content">
        <Switch>
          <Route.Private exact path="/employees">
            <div className="tab-pane active" role="tabpanel">
              <Employees />
            </div>
          </Route.Private>
          {!isFree && (
            <Route.Private exact path="/employees/groups">
              <div className="tab-pane active" role="tabpanel">
                <MemberGroups />
              </div>
            </Route.Private>
          )}
          <Redirect to="/employees" />
        </Switch>
      </div>
    </div>
  );
});

export default EmployeesRoute;
