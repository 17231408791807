import React from "react";
import { Route, Switch, useRouteMatch, NavLink } from "react-router-dom";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Today from "./Today/Today";
import All from "./All/All";
import Overdue from "./Overdue/Overdue";

const VisitorLog = React.memo(() => {
  const { t } = useTranslation(["toro_visitorLogs", "common"]);

  const match = useRouteMatch();

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("toro_visitorLogs:visitorLogs.nav.visitorLogs")}
          </li>
        </ol>
      </nav>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <NavLink exact className="nav-link" to={match.url}>
            {t("toro_visitorLogs:visitorLogs.nav.today")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            exact
            className={clsx("nav-link")}
            to={`${match.url}/overdue`}
          >
            {t("toro_visitorLogs:visitorLogs.nav.overdue")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className={clsx("nav-link")} to={`${match.url}/all`}>
            {t("toro_visitorLogs:visitorLogs.nav.all")}
          </NavLink>
        </li>
      </ul>

      <div className="tab-content">
        <Switch>
          <Route path={`${match.path}/overdue`}>
            <div className="tab-pane active" role="tabpanel">
              <Overdue />
            </div>
          </Route>
          <Route path={`${match.path}/all`}>
            <div className="tab-pane active" role="tabpanel">
              <All />
            </div>
          </Route>
          <Route path={match.path}>
            <div className="tab-pane active" role="tabpanel">
              <Today />
            </div>
          </Route>
        </Switch>
      </div>
    </div>
  );
});

export default VisitorLog;
