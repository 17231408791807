import React from "react";

import { useTranslation } from "react-i18next";
import clsx from "clsx";

import {
  useRouteMatch,
  useParams,
  NavLink,
  Switch,
  Link,
  Redirect,
  useHistory,
} from "react-router-dom";
import { useQuery } from "@apollo/client";

import { QUERY_CLIENT_TWILIO_CLIENT } from "../../../../../config/graphql/query";

import InformationRoute from "./Information";

import Route from "../../../../../components/Route";

import { useClientId } from "../../hooks/useClient";
import TwilioClientUsage from "./Usage";

const TwilioRoute = React.memo(() => {
  const { t } = useTranslation(["client"]);

  const history = useHistory();

  const match = useRouteMatch();

  const { id } = useParams<{ id: string }>();

  const clientId = useClientId();

  const { data } = useQuery<{ twilioClient: IClientTwilio }>(
    QUERY_CLIENT_TWILIO_CLIENT,
    {
      skip: !id,
      variables: { id },
      nextFetchPolicy: "network-only",
      onError: () => {
        history.replace("/client/twilio");
      },
      context: {
        headers: {
          ClientId: clientId,
        },
      },
    },
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/clients">{t("client:client.token.nav.clients")}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to={`/clients/${clientId}`}>
              {data?.twilioClient?.client?.title ?? "Client"}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to={`/clients/${clientId}/twilio`}>
              {t("client:client.token.nav.twilio")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {data?.twilioClient?.title ?? "Title"}
          </li>
        </ol>
      </nav>

      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <NavLink exact className="nav-link" to={match.url}>
            {t("client:client.twilio.tab.information")}
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            exact
            className={clsx("nav-link", {
              disabled: !id,
            })}
            to={`${match.url}/calls`}
          >
            {t("client:client.twilio.tab.calls")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            exact
            className={clsx("nav-link", {
              disabled: !id,
            })}
            to={`${match.url}/sms`}
          >
            {t("client:client.twilio.tab.sms")}
          </NavLink>
        </li>
      </ul>

      <div className="tab-content">
        <Switch>
          <Route.Private exact path={match.path}>
            <div className="tab-pane active" role="tabpanel">
              <InformationRoute />
            </div>
          </Route.Private>

          <Route.Private exact path={`${match.path}/:category`}>
            <div className="tab-pane active" role="tabpanel">
              <TwilioClientUsage />
            </div>
          </Route.Private>
          <Redirect to={match.url} />
        </Switch>
      </div>
    </div>
  );
});

export default TwilioRoute;
