import { InMemoryCache, makeVar } from "@apollo/client";

import possibleTypes from "./possibleTypes.json";

export const getPossibleTypes = () => {
  return fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      variables: {},
      query: `
        {
          __schema {
            types {
              kind
              name
              possibleTypes {
                name
              }
            }
          }
        }
      `,
    }),
  })
    .then((result) => result.json())
    .then((result) => {
      const possibleTypes = {};

      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      result.data.__schema.types.forEach((supertype) => {
        if (supertype.possibleTypes) {
          // @ts-ignore
          possibleTypes[supertype.name] = supertype.possibleTypes.map(
            // @ts-ignore
            (subtype) => subtype.name,
          );
        }
      });

      return possibleTypes;
    });
};

// getPossibleTypes()
//   .then((value) => console.log(JSON.stringify(value)))
//   .catch(console.log);

export const currentLocale = makeVar(
  window.localStorage.getItem("Locale") || "en",
);

export const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        token: {
          read() {
            return localStorage.getItem("Authorization");
          },
          merge: (existing, incoming) => {
            console.log("InMemoryCache: merge token", existing, incoming);

            localStorage.setItem("Authorization", incoming);
          },
        },
        clientId: {
          read() {
            return localStorage.getItem("Client");
          },
          merge: (existing, incoming) => {
            console.log("InMemoryCache: merge clientId", existing, incoming);

            localStorage.setItem("Client", incoming);
          },
        },
        locale: {
          read() {
            return currentLocale();
          },
        },
      },
    },
  },
  // addTypename: false,
});
