import React, { useCallback, useEffect, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";

import clsx from "clsx";

import map from "lodash/map";
import find from "lodash/find";

import Select from "react-select";

import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory, useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FormProvider, Controller, useForm } from "react-hook-form";

import Input from "../../../../components/Input";

import {
  QUERY_MESSAGE_TEMPLATE,
  QUERY_MESSAGE_TEMPLATE_OPTIONS,
} from "../../../../config/graphql/query";
import {
  CREATE_MESSAGE_TEMPLATE,
  UPDATE_MESSAGE_TEMPLATE,
  DELETE_MESSAGE_TEMPLATE,
} from "../../../../config/graphql/mutation";

import { MESSAGE_TEMPLATES } from "../../../../config/const/common";
import { useCurrentLanguage } from "../../../../lib/hooks/useCurrentLanguage";

import { useClientId } from "../hooks/useClient";

interface FieldValues
  extends Pick<IMessageTemplate, "message" | "template" | "title"> {}

const MessageTemplate = React.memo(() => {
  const [show, setShow] = useToggle(false);

  const { t } = useTranslation(["client", "common"]);

  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const clientId = useClientId();

  const language = useCurrentLanguage();

  const methods = useForm<FieldValues>({
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {},
  });

  const apolloContent = useMemo(
    () => ({
      headers: {
        ClientId: clientId,
      },
    }),
    [clientId],
  );

  const { data } = useQuery<{
    messageTemplate: IMessageTemplate;
  }>(QUERY_MESSAGE_TEMPLATE, {
    skip: !id,
    variables: { id },
    context: apolloContent,
  });

  const { data: messageTemplateOptionsData, loading: isLoadingOptions } =
    useQuery<{
      messageTemplateOptions: string[];
      availableMessageTemplateOptions: string[];
    }>(QUERY_MESSAGE_TEMPLATE_OPTIONS, {
      context: {
        headers: {
          ClientId: clientId,
        },
      },
    });

  const messageTemplateOptions = useMemo(
    () =>
      map(messageTemplateOptionsData?.messageTemplateOptions, (key) => ({
        value: key,
        label: key,
      })),
    [messageTemplateOptionsData?.messageTemplateOptions],
  );

  useEffect(() => {
    if (data?.messageTemplate) {
      methods.reset(data?.messageTemplate);
    }
  }, [data, methods]);

  const [onUpdate] = useMutation<{ updateMessageTemplate: IMessageTemplate }>(
    UPDATE_MESSAGE_TEMPLATE,
    {
      refetchQueries: [
        {
          query: QUERY_MESSAGE_TEMPLATE,
          variables: { id },
          context: apolloContent,
        },
      ],
      context: apolloContent,
    },
  );

  const [onCreate] = useMutation<{ createMessageTemplate: IMessageTemplate }>(
    CREATE_MESSAGE_TEMPLATE,
    {
      context: apolloContent,
    },
  );

  const [onDelete] = useMutation(DELETE_MESSAGE_TEMPLATE, {
    context: apolloContent,
  });

  const onBeforeDelete = useCallback(() => {
    return onDelete({ variables: { id } })
      .then(() => {
        toast.success<string>(t("client:client.information.toast.deleted"));

        history.replace(`/clients/${clientId}/message-templates`);
      })
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );

        history.replace(`/clients/${clientId}/message-templates`);
      });
  }, [history, onDelete, clientId, id, t]);

  const onSubmit = useCallback(
    (values: FieldValues) => {
      const input = {
        ...(id ? { id } : {}),
        ...values,
      };

      if (id) {
        return onUpdate({ variables: { input } })
          .then(({ data }) => {
            methods.reset(data?.updateMessageTemplate);
          })
          .then(() => {
            toast.success<string>(t("client:client.information.toast.updated"));
          })
          .catch((error) => {
            toast.error<string>(
              error?.networkError?.result?.errors?.[0]?.message ??
                error?.message,
            );
          });
      }

      return onCreate({ variables: { input } })
        .then(({ data }) => {
          toast.success<string>(t("client:client.information.toast.created"));

          history.replace(
            `/clients/${clientId}/message-templates/${data?.createMessageTemplate?.id}`,
          );
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [clientId, history, id, methods, onCreate, onUpdate, t],
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/clients">{t("client:client.token.nav.clients")}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to={`/clients/${clientId}`}>
              {data?.messageTemplate?.client?.title ?? "Client"}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to={`/clients/${clientId}/twilio-message-templates`}>
              {t("client:client.twilioMessage.nav.twilioMessageTemplates")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {data?.messageTemplate?.title ?? "Title"}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-12 col-md-6 col-lg-8">
            <div className="form-group">
              <label htmlFor="title">
                {t("client:client.twilioMessage.form.title")}
              </label>
              <Input
                name="title"
                rules={{
                  required: {
                    value: true,
                    message: t("client:client.twilioMessage.error.required"),
                  },
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="type">
                {t("client:client.twilioMessage.form.template")}
              </label>
              <Controller
                name="template"
                render={({
                  field: { onChange, value: propsValue, ...props },
                }) => {
                  const value = propsValue
                    ? find(messageTemplateOptions, { value: propsValue })
                    : undefined;

                  return (
                    <Select
                      options={messageTemplateOptions}
                      value={value}
                      onChange={(value) => onChange(value?.value)}
                      isLoading={isLoadingOptions}
                      {...props}
                    />
                  );
                }}
                rules={{
                  required: {
                    value: true,
                    message: t("client:client.twilioMessage.error.required"),
                  },
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="templateId">
                {t("client:client.twilioMessage.form.message")}
              </label>
              <Controller
                name="message"
                rules={{
                  required: {
                    value: true,
                    message: t("client:client.twilioMessage.error.required"),
                  },
                }}
                render={({
                  field: { name, ...rest },
                  fieldState: { error },
                }) => (
                  <>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id={`translate-${name}`}
                        >
                          {`${language}`}
                        </span>
                      </div>
                      <textarea
                        {...rest}
                        rows={5}
                        aria-describedby={`translate-${name}`}
                        className={clsx("form-control", {
                          "is-invalid": !!error,
                        })}
                      />
                    </div>
                    <div className="feedback">{`Available variables: {{name}}, {{attendeeName}}`}</div>
                    {!!error && (
                      <div className="invalid-feedback">{error?.message}</div>
                    )}
                  </>
                )}
              />
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
            {id && (
              <button
                onClick={(e) => {
                  e.preventDefault();

                  setShow(true);
                }}
                className="btn btn-danger ml-3"
              >
                {t("common:delete")}
              </button>
            )}
          </div>
        </form>
      </FormProvider>
      {id && (
        <Modal
          size="sm"
          show={show}
          onHide={setShow}
          centered
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {t("client:client.information.modal.title")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t("client:client.information.modal.title", {
              title: data?.messageTemplate?.client?.title,
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" onClick={() => setShow(false)}>
              {t("common:cancel")}
            </Button>
            <Button size="sm" variant="danger" onClick={onBeforeDelete}>
              {t("common:delete")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
});

export default MessageTemplate;
