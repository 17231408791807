import React, { useCallback, useMemo } from "react";

import qs from "query-string";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import useToggle from "react-use/lib/useToggle";
import { useTranslation } from "react-i18next";

import { Link, useLocation, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";

import { QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATIONS } from "../../../../../../config/graphql/query";
import { MUTATION_DELETE_DIGITAL_RECEPTION_MANUAL_REGISTRATION } from "../../../../../../config/graphql/mutation";

import Table from "../../../../../../components/Table";
import RoleBlock from "../../../../../../components/RoleBlock";
import Pagination from "../../../../../../components/Pagination";

const limit = 10;

const TableRow = ({
  item,
  index,
  variables,
  screenId,
}: {
  item: IManualRegistration;
  index: number;
  variables: any;
  screenId: string;
}) => {
  const { id, title, fields } = item;

  const [visible, setVisible] = useToggle(false);

  const { t } = useTranslation(["manualRegistration", "common"]);

  const [onDelete] = useMutation(
    MUTATION_DELETE_DIGITAL_RECEPTION_MANUAL_REGISTRATION,
    {
      variables: {
        id,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATIONS, variables },
      ],
    },
  );

  const tableFieldsText = fields.map((field) => field.label).join(" , ");

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr>
        <th scope="row">{index + 1}</th>
        <th scope="row">{title}</th>
        <th scope="row">{tableFieldsText}</th>

        <td>
          <div className="d-flex">
            <RoleBlock roles={["ADMIN"]}>
              <Button
                size="sm"
                variant="danger"
                className="mr-2"
                onClick={() => setVisible(true)}
              >
                {t("common:delete")}
              </Button>
            </RoleBlock>
            <Link
              to={`/digital-receptions/${screenId}/settings/manual-registrations/${id}`}
            >
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>

      <Modal
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        contentClassName="p-2"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("manualRegistration:manualRegistrations.modal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("manualRegistration:manualRegistrations.modal.body", { title })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const ManualRegistrations = React.memo(() => {
  const location = useLocation();

  const { screenId } = useParams<{
    screenId: string;
  }>();

  const { t } = useTranslation(["manualRegistration", "common"]);

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        page?: number;
      },
    [location.search],
  );

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);

  const variables = useMemo(
    () => ({
      filter: {
        screen: screenId,
      },
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
    }),
    [page, screenId],
  );

  const { data } = useQuery<{
    manualRegistrations: IManualRegistration[];
    manualRegistrationsCount: number;
  }>(QUERY_DIGITAL_RECEPTION_MANUAL_REGISTRATIONS, {
    variables,
    fetchPolicy: "network-only",
  });

  const manualRegistrations = useMemo(
    () => data?.manualRegistrations ?? [],
    [data],
  );
  const manualRegistrationsCount = data?.manualRegistrationsCount ?? 0;

  const renderManualRegistration = useCallback(
    (item: IManualRegistration, index: number) => (
      <TableRow
        key={item.id}
        item={item}
        index={(page - 1) * limit + index}
        variables={variables}
        screenId={screenId}
      />
    ),
    [page, variables],
  );

  return (
    <div className="container-fluid">
      <div className="d-flex flex-wrap justify-content-end mt-4">
        <Link
          to={`/digital-receptions/${screenId}/settings/manual-registrations/new`}
          type="button"
          className="btn btn-primary mb-4"
        >
          {t("manualRegistration:manualRegistrations.button.add")}
        </Link>
      </div>
      {manualRegistrationsCount > 0 && (
        <>
          <Table>
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">
                  {t("manualRegistration:manualRegistrations.table.th.title")}
                </th>
                <th scope="col">
                  {t("manualRegistration:manualRegistrations.table.th.fields")}
                </th>
                <th scope="col">
                  {t("manualRegistration:manualRegistrations.table.th.actions")}
                </th>
              </tr>
            </thead>
            <tbody>{manualRegistrations.map(renderManualRegistration)}</tbody>
          </Table>
          {manualRegistrationsCount > limit && (
            <Pagination
              documentsCount={manualRegistrationsCount}
              limit={limit}
            />
          )}
        </>
      )}
    </div>
  );
});

export default ManualRegistrations;
