import React, { useMemo, useCallback, useEffect, useRef } from "react";

import * as yup from "yup";

import map from "lodash/map";

import CreatableSelect from "react-select/creatable";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { FormProvider, useForm, Controller } from "react-hook-form";

import Input from "../../../../components/Input";

import { useEntity } from "../../../../context/Entity";
import { PropertyType } from "../../../../config/const/property";

/* config */
import {
  QUERY_ENTITY,
  QUERY_ENTITIES,
  QUERY_COMPANIES,
} from "../../../../config/graphql/query";
import {
  CREATE_SPACE,
  CREATE_COMPANY,
  CREATE_ENTITY_PROPERTIES,
  UPDATE_SPACE,
} from "../../../../config/graphql/mutation";

type FieldValues = {
  title: string;
  type: string;
  parent?: string;
  geoJSON?: any;
  properties?: Pick<ICompany, "id" | "title">[];
};

const EntityForm = React.memo(
  ({
    type,
    entity,
    onHide,
    onAttach,
    hasEntityForAttach,
  }: {
    type: "create" | "update";
    entity: Partial<ISpace>;
    onHide: () => void;
    onAttach: () => void;
    hasEntityForAttach: boolean;
  }) => {
    const titleInput = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
      titleInput?.current?.focus?.();
    }, []);

    const parent = useEntity();

    const { t } = useTranslation(["entities", "common"]);

    const schema = useMemo(
      () =>
        yup.object().shape({
          parent: yup
            .string()
            .required(t("entities:floorPlan.entity.yup.parent")),
          title: yup
            .string()
            .required(t("entities:floorPlan.entity.yup.title")),
          geoJSON: yup.object(),
          properties: yup.array().of(
            yup.object().shape({
              id: yup.string(),

              title: yup.string(),
            }),
          ),
        }),
      [t],
    );

    const [onCreateCompany, { loading: creatingCompany }] =
      useMutation(CREATE_COMPANY);

    const refetchQueries = useMemo(
      () => [
        {
          query: QUERY_ENTITIES,
          variables: {
            filter: {
              parent: parent?.id || null,
            },
          },
        },
        {
          query: QUERY_ENTITY,
          variables: {
            id: parent?.id,
          },
        },
      ],
      [parent],
    );

    const [onUpdateSpace] = useMutation(UPDATE_SPACE, {
      awaitRefetchQueries: true,
      refetchQueries,
    });

    const [onCreateSpace, { loading: creatingEntity }] = useMutation(
      CREATE_SPACE,
      {
        awaitRefetchQueries: true,
        refetchQueries,
      },
    );

    const [onEntityAttachProperties, { loading: updatingProperties }] =
      useMutation(CREATE_ENTITY_PROPERTIES, {
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: QUERY_ENTITIES,
            variables: {
              filter: {
                parent: parent?.id || null,
              },
            },
          },
        ],
      });

    const { data: propertiesData, loading: loadingProperties } = useQuery<{
      companies: ICompany[];
    }>(QUERY_COMPANIES);

    const options = useMemo(
      () => propertiesData?.companies ?? [],
      [propertiesData],
    );

    const methods = useForm<FieldValues>({
      resolver: yupResolver(schema),
      shouldFocusError: false,
      shouldUnregister: true,
      defaultValues: {
        properties: [],
        ...entity,
        parent: parent.id,
      },
    });

    // ! Register inputs that are required
    useEffect(() => {
      methods.register("geoJSON");
      methods.setValue("geoJSON", entity?.geoJSON);
      methods.register("parent");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCreateOption = useCallback((inputValue: any) => {
      onCreateCompany({
        variables: {
          input: {
            type: PropertyType.Company,
            title: inputValue,
          },
        },
      })
        .then(({ data: { addCompany } }) => {
          const prevProperties = methods.getValues("properties") || [];

          methods.setValue("properties", [
            // @ts-ignore
            ...prevProperties,
            addCompany,
          ]);
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = useCallback(
      (values: FieldValues) => {
        if (creatingCompany || creatingEntity || updatingProperties) {
          return;
        }

        const { properties, ...rest } = values;

        if (entity?.id) {
          onUpdateSpace({
            variables: {
              input: { ...rest, id: entity.id },
            },
          })
            .then(() => {
              return onEntityAttachProperties({
                variables: {
                  input: {
                    id: entity.id,
                    properties: map(properties, "id"),
                  },
                },
              })
                .then(() => {
                  toast.success<string>(
                    t("entities:floorPlan.entity.toast.updated"),
                  );

                  return onHide();
                })
                .catch((error) => {
                  toast.error<string>(
                    error?.networkError?.result?.errors?.[0]?.message ??
                      error?.message,
                  );
                });
            })
            .catch((error) => {
              toast.error<string>(
                error?.networkError?.result?.errors?.[0]?.message ??
                  error?.message,
              );
            });

          return;
        }

        onCreateSpace({ variables: { input: rest } })
          .then(
            ({
              data: {
                addSpace: { id: createdEntityId },
              },
            }) => {
              if (!(properties && !!properties.length)) {
                toast.success<string>(
                  t("entities:floorPlan.entity.toast.created"),
                );

                return onHide();
              }

              return onEntityAttachProperties({
                variables: {
                  input: {
                    id: createdEntityId,
                    properties: map(properties, "id"),
                  },
                },
              })
                .then(() => {
                  toast.success<string>(
                    t(
                      "entities:floorPlan.entity.toast.createdAndPropertiesAttached",
                    ),
                  );

                  return onHide();
                })
                .catch((error) => {
                  toast.error<string>(
                    error?.networkError?.result?.errors?.[0]?.message ??
                      error?.message,
                  );
                });
            },
          )
          .catch((error) => {
            toast.error<string>(
              error?.networkError?.result?.errors?.[0]?.message ??
                error?.message,
            );
          });
      },
      [
        creatingCompany,
        creatingEntity,
        updatingProperties,
        entity,
        onCreateSpace,
        onUpdateSpace,
        onEntityAttachProperties,
        onHide,
        t,
      ],
    );

    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="title">
                {t("entities:floorPlan.entity.form.title")}
              </label>
              <div className="d-flex">
                <div className="mr-2 flex-grow-1">
                  <Input name="title" ref={titleInput} />
                </div>
                {hasEntityForAttach && type === "create" && (
                  <Button variant="primary" onClick={onAttach}>
                    {t("entities:floorPlan.modal.attach")}
                  </Button>
                )}
              </div>
            </div>
            <div className="form-group mb-4">
              <label htmlFor="properties">
                {t("entities:floorPlan.entity.form.company")}
              </label>
              <Controller
                name="properties"
                render={({ field: { onChange, ...props } }) => (
                  <CreatableSelect
                    isMulti
                    isLoading={loadingProperties}
                    aria-describedby="properties-help"
                    getOptionLabel={({ title }) => title}
                    getOptionValue={({ id }) => `${id}`}
                    getNewOptionData={(_inputValue, optionLabel) => ({
                      id: "new",
                      title: optionLabel,
                    })}
                    options={options}
                    onCreateOption={onCreateOption}
                    onChange={(value) => onChange(value)}
                    {...props}
                  />
                )}
              />
              <small
                id="properties-help"
                className="form-text text-muted small"
              >
                {t("entities:floorPlan.entity.form.companyInfo")}
              </small>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              {t("common:cancel")}
            </Button>
            <input
              disabled={creatingCompany || creatingEntity || updatingProperties}
              type="submit"
              className="btn btn-primary ml-2"
              value={t("common:save")}
            />
          </Modal.Footer>
        </form>
      </FormProvider>
    );
  },
);

export default EntityForm;
