const getImageDimensions = (
  url = "",
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;

    img.onload = function () {
      // @ts-ignore
      const width = this.naturalWidth * 2;
      // @ts-ignore
      const height = this.naturalHeight * 2;

      resolve({ width, height });
    };

    img.onerror = function (error) {
      reject(error);
    };
  });
};

export default getImageDimensions;
