/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { useMutation, useQuery } from "@apollo/client";

import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

import {
  QUERY_EMPLOYEES,
  QUERY_EMPLOYEE_PREFERENCES,
} from "../../../../config/graphql/query";
import { MUTATION_UPDATE_EMPLOYEE } from "../../../../config/graphql/mutation";
import { PreferencesController } from "../../../../components/Preferences";

type FieldValues = {
  preferences?: IMemberGroupPreferences;
};

export const EmployeePreferences = (props: {
  name: string;
  parent: IMemberGroupPreferences | undefined;
}) => {
  const { name, parent } = props;

  const { t } = useTranslation(["employeePreferences"]);

  return (
    <>
      <PreferencesController
        name={`${name}.visitor`}
        label={t(`employeePreferences:input.label.${name}.visitor.enabled`)}
        parent={parent?.visitor}
      />
      <PreferencesController
        name={`${name}.meetingAttendee`}
        label={t(
          `employeePreferences:input.label.${name}.meetingAttendee.enabled`,
        )}
        parent={parent?.meetingAttendee}
      />
      <PreferencesController
        name={`${name}.foodDelivery`}
        label={t(
          `employeePreferences:input.label.${name}.foodDelivery.enabled`,
        )}
        parent={parent?.foodDelivery}
      />
      <PreferencesController
        name={`${name}.packageDelivery`}
        label={t(
          `employeePreferences:input.label.${name}.packageDelivery.enabled`,
        )}
        parent={parent?.packageDelivery}
      />
    </>
  );
};

const Employee = React.memo(() => {
  const { t } = useTranslation(["employee", "common"]);

  const { id } = useParams<{ id: string }>();

  const { data: employeeData } = useQuery<{ employee: IMemberEmployee }>(
    QUERY_EMPLOYEE_PREFERENCES,
    {
      skip: !id,
      variables: { id },
      fetchPolicy: "network-only",
    },
  );

  const groupEmployeePreferences = useMemo(
    () => employeeData?.employee?.groupPreferences,
    [employeeData],
  );

  const clientPreferences = useMemo(
    () => employeeData?.employee?.client?.preferences?.company?.employee,
    [employeeData],
  );

  const parent =
    groupEmployeePreferences?.visitor === null
      ? clientPreferences
      : groupEmployeePreferences;

  const [onUpdate] = useMutation(MUTATION_UPDATE_EMPLOYEE, {
    refetchQueries: [
      {
        query: QUERY_EMPLOYEES,
      },
    ],
  });

  const methods = useForm<FieldValues>({
    shouldFocusError: false,
    shouldUnregister: true,
  });

  useEffect(() => {
    if (employeeData?.employee) {
      methods.reset({
        preferences: employeeData?.employee?.availablePreferences,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeData]);

  const onSubmit = async (variables: FieldValues) => {
    const variablesForUpdate = variables;

    if (parent?.foodDelivery?.enabled === false) {
      delete variablesForUpdate.preferences?.foodDelivery;
    }
    if (parent?.packageDelivery?.enabled === false) {
      delete variablesForUpdate.preferences?.packageDelivery;
    }
    if (parent?.visitor?.enabled === false) {
      delete variablesForUpdate.preferences?.visitor;
    }
    if (parent?.meetingAttendee?.enabled === false) {
      delete variablesForUpdate.preferences?.meetingAttendee;
    }

    return onUpdate({
      variables: {
        input: { id, ...variablesForUpdate },
      },
    })
      .then(
        ({
          data: {
            updateEmployee: { fullName },
          },
        }) => {
          toast.success<string>(t("employee:toast.updated", { fullName }));
        },
      )
      .catch(() => {
        toast.error<string>(t("employee:toast.updatedError"));
      });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <h6 className="mb-4">
                {t("employeePreferences:availability.title")}
              </h6>
              <EmployeePreferences name="preferences" parent={parent} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary mt-2 mb-4"
                disabled={methods.formState.isSubmitting}
              >
                {t("employeePreferences:button.submit")}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
});

export default Employee;
