import { useMutation, useQuery } from "@apollo/client";
import React, { useCallback, useMemo } from "react";

import dayjs from "dayjs";

import map from "lodash/map";
import useToggle from "react-use/lib/useToggle";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import Table from "../../../../../../components/Table";

import { QUERY_DIGITAL_RECEPTION_SCHEDULES } from "../../../../../../config/graphql/query";
import { MUTATION_DELETE_SCREEN_SCHEDULE } from "../../../../../../config/graphql/mutation";

const TableRow = (props: { item: IScreenSchedule }) => {
  const { item } = props;

  const { t } = useTranslation(["digitalReceptionSettings"]);

  const {
    id,
    schedule: { weekdays, startTime, endTime, startDate, endDate, disabled },
  } = item;

  const { screenId } = useParams<{ screenId: string }>();

  const allDay = !(startTime && endTime);
  const allDates = !(startDate && endDate);

  const allDayValue = useMemo(() => {
    if (allDay) {
      return t("digitalReceptionSettings:section.schedule.table.tr.time.every");
    }

    const start = dayjs(startTime, "HH:mm").format("LT");
    const end = dayjs(endTime, "HH:mm").format("LT");

    return t("digitalReceptionSettings:section.schedule.table.tr.time.range", {
      start,
      end,
    });
  }, [allDay, endTime, startTime, t]);

  const allDatesValue = useMemo(() => {
    if (allDates) {
      return t("digitalReceptionSettings:section.schedule.table.tr.date.every");
    }

    const start = dayjs(startDate, "YYYY-MM-DD").format("LL");
    const end = dayjs(endDate, "YYYY-MM-DD").format("LL");

    return t("digitalReceptionSettings:section.schedule.table.tr.date.range", {
      start,
      end,
    });
  }, [allDates, endDate, startDate, t]);

  const [visible, setVisible] = useToggle(false);

  const [onDelete] = useMutation(MUTATION_DELETE_SCREEN_SCHEDULE, {
    refetchQueries: [
      {
        query: QUERY_DIGITAL_RECEPTION_SCHEDULES,
        variables: { id: screenId },
      },
    ],
    variables: { id },
  });

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr>
        <td>
          {weekdays.map((d) => dayjs().weekday(d).format("ddd")).join(", ")}
        </td>
        <td>{allDayValue}</td>
        <td>{allDatesValue}</td>
        <td>{disabled ? "Paused" : "Active"}</td>
        <td>
          <div className="d-flex">
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>
            <Link to={`schedules/${id}`}>
              <Button
                size="sm"
                variant="primary"
                onClick={() => setVisible(false)}
              >
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t(
              "digitalReceptionSettings:section.schedule.dialog.beforeDelete.title",
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(
            "digitalReceptionSettings:section.schedule.dialog.beforeDelete.description",
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t(
              "digitalReceptionSettings:section.schedule.dialog.beforeDelete.button.dismiss",
            )}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t(
              "digitalReceptionSettings:section.schedule.dialog.beforeDelete.button.submit",
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Schedules = React.memo(() => {
  const { t } = useTranslation(["screens", "common"]);

  const { screenId } = useParams<{ screenId: string }>();

  const { data } = useQuery<{
    digitalReception: { schedules: IScreenSchedule[] };
  }>(QUERY_DIGITAL_RECEPTION_SCHEDULES, {
    variables: {
      id: screenId,
    },
  });

  const renderItem = useCallback((item: IScreenSchedule) => {
    return <TableRow key={item.id} item={item} />;
  }, []);

  return (
    <>
      <div className="col-lg-12">
        <div className="d-flex justify-content-between align-items-center my-4">
          <h6>{t("digitalReceptionSettings:section.schedule.title")}</h6>
          <div>
            <Link to="schedules/new" type="button" className="btn btn-primary">
              {t("digitalReceptionSettings:section.schedule.button.create")}
            </Link>
          </div>
        </div>
        <Table>
          <thead>
            <tr>
              <th scope="col">
                {t(
                  "digitalReceptionSettings:section.schedule.table.th.weekdays",
                )}
              </th>
              <th scope="col">
                {t("digitalReceptionSettings:section.schedule.table.th.time")}
              </th>
              <th scope="col">
                {t("digitalReceptionSettings:section.schedule.table.th.date")}
              </th>
              <th scope="col">
                {t("digitalReceptionSettings:section.schedule.table.th.status")}
              </th>
              <th scope="col">
                {t(
                  "digitalReceptionSettings:section.schedule.table.th.actions",
                )}
              </th>
            </tr>
          </thead>
          <tbody>{map(data?.digitalReception?.schedules, renderItem)}</tbody>
        </Table>
      </div>
    </>
  );
});

export default Schedules;
