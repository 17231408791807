import React, { useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useToggle } from "react-use";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useRouteMatch, Link, useHistory } from "react-router-dom";

import Table from "../../../../../components/Table";

import { QUERY_CLIENT_TWILIO_CLIENTS } from "../../../../../config/graphql/query";

import { useClientId } from "../../hooks/useClient";
import { DELETE_CLIENT_TWILIO } from "../../../../../config/graphql/mutation";

export const useQueryTwilioClients = () => {
  const clientId = useClientId();

  return useQuery<{ twilioClients?: IClientTwilio[] }>(
    QUERY_CLIENT_TWILIO_CLIENTS,
    {
      context: {
        headers: {
          ClientId: clientId,
        },
      },
      fetchPolicy: "network-only",
    },
  );
};

const TableRow = ({ item }: { item: IClientTwilio }) => {
  const { url } = useRouteMatch();

  const { t } = useTranslation(["client", "common"]);

  const clientId = useClientId();

  const history = useHistory();

  const { id, title, description } = item;

  const [visible, setVisible] = useToggle(false);

  const [onDelete] = useMutation(DELETE_CLIENT_TWILIO, {
    variables: { id },
    refetchQueries: [
      {
        query: QUERY_CLIENT_TWILIO_CLIENTS,
      },
    ],
  });

  const onBeforeDelete = () => {
    onDelete()
      .then(() => {
        toast.success<string>(t("client:client.twilio.toast.deleted"));
        history.replace(`/clients/${clientId}/twilio`);
      })
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );

        history.replace(`/clients/${clientId}/twilio`);
      });

    setVisible(false);
  };

  return (
    <>
      <tr>
        <td>{id}</td>
        <td>{title}</td>
        <td>{description}</td>
        <td>
          <div className="d-flex">
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>
            <Link to={`${url}/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("client:client.twilio.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("client:client.twilio.modal.body", { id })}</Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const TwilioClients = React.memo(() => {
  const { url } = useRouteMatch();

  const { t } = useTranslation(["client", "common"]);

  const { data } = useQueryTwilioClients();

  const twilioClients = data?.twilioClients ?? [];

  const renderListItem = useCallback(
    (item: IClientTwilio) => <TableRow key={item.id} item={item} />,
    [],
  );

  return (
    <div className="container-fluid">
      {!(Array.isArray(twilioClients) && twilioClients.length > 0) && (
        <div className="d-flex justify-content-end align-items-center my-4">
          <div>
            <Link to={`${url}/new`} type="button" className="btn btn-primary">
              {t("client:client.tokens.button.add")}
            </Link>
          </div>
        </div>
      )}
      <>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{t("client:client.tokens.th.title")}</th>
              <th scope="col">{t("client:client.tokens.th.development")}</th>
              <th scope="col">{t("client:client.tokens.th.actions")}</th>
            </tr>
          </thead>
          <tbody>{twilioClients.map(renderListItem)}</tbody>
        </Table>
      </>
    </div>
  );
});

export default TwilioClients;
