export enum EntityType {
  Building = "Building",
  Floor = "Floor",
  Space = "Space",
  Sector = "Sector",
}

export const EntityTypes: EntityType[] = [
  EntityType.Building,
  EntityType.Sector,
  EntityType.Floor,
  EntityType.Space,
];
