import React from "react";

import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import Employee from "./Employee";
import Employees from "./Employees";

import { EmployeeCreate } from "./Employee/Information";
import MemberGroup from "../MemberGroups/MemberGroup";

import { useCurrentHasPlan } from "../../components/PlanBlock";

const EmployeesRouter = React.memo(() => {
  const { path } = useRouteMatch();

  const isFree = useCurrentHasPlan(["free"]);

  return (
    <Switch>
      <Route exact path={[path, `${path}/groups`]}>
        <Employees />
      </Route>
      {!isFree && (
        <Route exact path={`${path}/groups/new`}>
          <MemberGroup />
        </Route>
      )}
      {!isFree && (
        <Route path={`${path}/groups/:id`}>
          <MemberGroup />
        </Route>
      )}
      <Route exact path={`${path}/new`}>
        <EmployeeCreate />
      </Route>
      <Route path={`${path}/:id`}>
        <Employee />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default EmployeesRouter;
