import dayjs from "dayjs";
import React, { useEffect, useMemo } from "react";

import clsx from "clsx";
import * as yup from "yup";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useParams, useHistory, Link } from "react-router-dom";

import DatePicker from "react-datepicker";

import Input from "../../../../../components/Input";
import {
  UploadInput,
  UploadPreview,
  useFiles,
} from "../../../../../components/FileUpload";

import {
  MUTATION_CREATE_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT_FILE,
  MUTATION_UPDATE_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT_FILE,
} from "../../../../../config/graphql/mutation";

import {
  QUERY_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT,
  QUERY_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT_FILE,
} from "../../../../../config/graphql/query";

interface FieldValues {
  title?: string;
  text?: string;
  disabled: boolean;
  approvalRequired: boolean;
  validFrom?: Date;
  validUntil?: Date;
}

const EntranceAgreementFile = React.memo(() => {
  const history = useHistory();

  const { t } = useTranslation(["digitalReceptionEntranceAgreement", "common"]);

  const { screenId, agreementId, agreementFileId } = useParams<{
    screenId?: string;
    agreementId?: string;
    agreementFileId?: string;
  }>();

  const [files, { onUpload: onUploadDocument }] = useFiles("Document");

  const schema = useMemo(() => {
    return yup.object().shape({
      title: yup.string(),
      text: yup.string(),
      disabled: yup.boolean().required(),
      approvalRequired: yup.boolean().required(),
      validFrom: yup
        .date()
        .transform((v, o) => (o === null ? undefined : v))
        .test(
          "allow",
          t(
            "digitalReceptionEntranceAgreement:entranceAgreementFile.yup.invalid",
          ),
          function validate(value) {
            if (value instanceof Date) {
              return dayjs(value).isAfter(dayjs(new Date(Date.now())));
            }
            if (!value) {
              return true;
            }
            return false;
          },
        ),
      validUntil: yup
        .mixed()
        .transform((v, o) => (o === null ? undefined : v))
        .test(
          "allow",
          t(
            "digitalReceptionEntranceAgreement:entranceAgreementFile.yup.invalid",
          ),
          function validate(value) {
            if (value instanceof Date) {
              return (
                this.parent.validFrom &&
                dayjs.utc(value).isAfter(dayjs(this.parent.validFrom))
              );
            }
            return true;
          },
        ),
    });
  }, [t]);

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      title: "",
      text: "",
      disabled: false,
      approvalRequired: true,
      validFrom: undefined,
      validUntil: undefined,
    },
  });

  const { data: entranceAgreementFileData, loading } = useQuery<{
    entranceAgreementFile: IEntranceAgreementFile;
  }>(QUERY_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT_FILE, {
    variables: {
      id: agreementFileId,
    },
    skip: !agreementFileId,
    onError: () => {
      history.replace(
        `/digital-receptions/${screenId}/settings/entrance-agreements/${agreementId}`,
      );
    },
  });

  useEffect(() => {
    if (entranceAgreementFileData?.entranceAgreementFile) {
      const {
        entranceAgreementFile: { validFrom, validUntil, ...rest },
      } = entranceAgreementFileData;

      methods.reset({
        ...rest,
        validFrom: validFrom ? new Date(validFrom) : undefined,
        validUntil: validUntil ? new Date(validUntil) : undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entranceAgreementFileData]);

  const [onUpdate] = useMutation(
    MUTATION_UPDATE_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT_FILE,
    {
      refetchQueries: [
        {
          query: QUERY_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT_FILE,
          variables: { id: agreementFileId },
        },
      ],
    },
  );
  const [onCreate] = useMutation(
    MUTATION_CREATE_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT_FILE,
    {
      refetchQueries: [
        {
          query: QUERY_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT,
          variables: { id: screenId },
        },
      ],
    },
  );

  const onSubmit = async (values: FieldValues) => {
    const { title, text, disabled, approvalRequired, validFrom, validUntil } =
      values;

    let input = {
      title,
      disabled,
      text,
      approvalRequired,
      validFrom: validFrom ?? null,
      validUntil: validUntil ?? null,
    };

    if (Array.isArray(files) && files.length > 0) {
      const uploadedFiles = await onUploadDocument();

      if (uploadedFiles && uploadedFiles[0]) {
        input = {
          ...input,
          // @ts-ignore
          file: uploadedFiles[0]?.file?.id,
        };
      }
    }

    if (!!agreementFileId) {
      return onUpdate({
        variables: {
          input: {
            id: agreementFileId,
            ...input,
          },
        },
      })
        .then(() => {
          toast.success<string>(
            t(
              "digitalReceptionEntranceAgreement:entranceAgreementFile.toast.updated",
            ),
          );

          history.replace(
            `/digital-receptions/${screenId}/settings/entrance-agreements/${agreementId}`,
          );
        })
        .catch((error) => {
          toast.error<string>(
            t(
              error?.networkError?.result?.errors?.[0]?.message ??
                error?.message,
            ),
          );
        });
    }

    return onCreate({
      variables: {
        input: {
          entranceAgreement: agreementId,
          ...input,
        },
      },
    })
      .then(() => {
        toast.success<string>(
          t(
            "digitalReceptionEntranceAgreement:entranceAgreementFile.toast.created",
          ),
        );

        history.replace(
          `/digital-receptions/${screenId}/settings/entrance-agreements/${agreementId}`,
        );
      })
      .catch((error) => {
        toast.error<string>(
          t(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          ),
        );
      });
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/digital-receptions">
              {t("screens:screensRouter.nav.screens")}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/digital-receptions/${screenId}`}>
              {t("screens:screen.screenRoute.nav.screen")}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/digital-receptions/${screenId}/settings`}>
              {t("screens:screen.screenRoute.nav.settings")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link
              to={`/digital-receptions/${screenId}/settings/entrance-agreements/${agreementId}`}
            >
              {t("screens:screen.screenRoute.nav.entranceAgreement")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("screens:screen.screenRoute.nav.entranceAgreementFile")}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="title">
                  {t(
                    "digitalReceptionEntranceAgreement:entranceAgreementFile.form.title",
                  )}
                </label>
                <Input.Translatable
                  name="title"
                  className="form-control"
                  disabled={methods.watch("disabled")}
                />
              </div>
              <div className="form-group">
                <label htmlFor="text">
                  {t(
                    "digitalReceptionEntranceAgreement:entranceAgreementFile.form.text",
                  )}
                </label>
                <Input.Translatable
                  name="text"
                  className="form-control"
                  disabled={methods.watch("disabled")}
                />
              </div>
              <Controller
                name="approvalRequired"
                render={({ field: { value, name, onChange, ...rest } }) => {
                  return (
                    <div className="form-check my-3">
                      <input
                        id={name}
                        {...rest}
                        type="checkbox"
                        className="form-check-input"
                        checked={value === true}
                        onChange={() => onChange(!value)}
                        disabled={methods.watch("disabled")}
                      />
                      <label className="form-check-label" htmlFor={name}>
                        {t(
                          "digitalReceptionEntranceAgreement:entranceAgreementFile.form.approvalRequired",
                        )}
                      </label>
                    </div>
                  );
                }}
              />
              <div>
                <label htmlFor="validFrom">
                  {t(
                    "digitalReceptionEntranceAgreement:entranceAgreementFile.form.validFrom",
                  )}
                </label>
                <div className="mb-2">
                  <Controller
                    name="validFrom"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <div
                          className={clsx("", {
                            "is-invalid": !!error,
                          })}
                        >
                          <DatePicker
                            className={clsx("form-control", {
                              "is-invalid": !!error,
                            })}
                            // showTimeSelect
                            selected={value}
                            onChange={onChange}
                            filterDate={(date) =>
                              dayjs() <= dayjs(date).add(1, "day")
                            }
                            // filterTime={(time) => dayjs() <= dayjs(time)}
                            // timeIntervals={30}
                            // dateFormat="dd/MM/yyyy p"
                            dateFormat="dd/MM/yyyy"
                            shouldCloseOnSelect
                            disabled={methods.watch("disabled")}
                          />
                        </div>

                        <div className="invalid-feedback">
                          {error?.message ?? "Invalid"}
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="validUntil">
                  {t(
                    "digitalReceptionEntranceAgreement:entranceAgreementFile.form.validUntil",
                  )}
                </label>
                <div>
                  <Controller
                    name="validUntil"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <div
                          className={clsx("mb3", {
                            "is-invalid": !!error,
                          })}
                        >
                          <DatePicker
                            className={clsx("form-control", {
                              "is-invalid": !!error,
                            })}
                            // showTimeSelect
                            selected={value}
                            onChange={onChange}
                            filterDate={(date) =>
                              dayjs(methods.getValues("validFrom")) <=
                              dayjs(date).add(1, "day")
                            }
                            // filterTime={(time) =>
                            //   dayjs(methods.getValues("validFrom")) < dayjs(time)
                            // }
                            // timeIntervals={30}
                            // dateFormat="dd/MM/yyyy p"
                            dateFormat="dd/MM/yyyy"
                            shouldCloseOnSelect
                            disabled={methods.watch("disabled")}
                          />
                        </div>

                        <div className="invalid-feedback">
                          {error?.message ?? "Invalid"}
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
              <Controller
                name="disabled"
                render={({ field: { value, name, onChange, ...rest } }) => {
                  return (
                    <div className="form-check my-3">
                      <input
                        id={name}
                        {...rest}
                        type="checkbox"
                        className="form-check-input"
                        checked={value === true}
                        onChange={() => onChange(!value)}
                      />
                      <label className="form-check-label" htmlFor={name}>
                        {t(
                          "digitalReceptionEntranceAgreement:entranceAgreementFile.form.disabled",
                        )}
                      </label>
                    </div>
                  );
                }}
              />
              <button
                type="submit"
                className="btn btn-primary mt-3"
                disabled={loading}
              >
                {t(
                  `digitalReceptionEntranceAgreement:entranceAgreementFile.form.button.${
                    !agreementFileId ? "create" : "update"
                  }`,
                )}
              </button>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="file">
                  {t(
                    "digitalReceptionEntranceAgreement:entranceAgreementFile.form.document",
                  )}
                </label>
                <UploadInput
                  name="Document"
                  options={{
                    accept: {
                      "application/pdf": [".pdf"],
                      "image/png": [".png"],
                      "image/jpeg": [".jpg", ".jpeg"],
                    },
                    multiple: false,
                  }}
                />
                <UploadPreview
                  name="Document"
                  placeholder={
                    entranceAgreementFileData?.entranceAgreementFile?.file
                      ?.absolutePath
                  }
                  isPdf={
                    !!entranceAgreementFileData?.entranceAgreementFile?.file?.absolutePath.endsWith(
                      ".pdf",
                    )
                  }
                />
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
});

export default EntranceAgreementFile;
