import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  useRouteMatch,
  Route,
  Switch,
  useLocation,
  useHistory,
  Link,
} from "react-router-dom";

import clsx from "clsx";
import qs from "query-string";
import dayjs from "dayjs";

import Button from "react-bootstrap/Button";
import Select from "react-select";
import DatePicker from "react-datepicker";

import useDebounce from "react-use/lib/useDebounce";
import { useTranslation } from "react-i18next";
import { QUERY_LOGS } from "../../config/graphql/query";

import Log from "./Log/index";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import { reactSelectCustomStyles } from "../Employees/Employee/Information";

const LogInfoTypes = ["info", "request", "static"] as const;

type TLogInfoType = typeof LogInfoTypes[number];

const limit = 10;

const TableRow = ({ item }: { item: ILog }) => {
  const { id, createdAt, responseDuration, endpoint, level, message } = item;

  const { t } = useTranslation(["logs", "common"]);

  return (
    <>
      <tr>
        <th>{endpoint}</th>
        {level === "info" ? (
          <td>{message}</td>
        ) : (
          <td
            className={clsx("", {
              "bg-warning fw-bold": parseInt(responseDuration, 10) >= 1000,
              "bg-danger fw-bold": parseInt(responseDuration, 10) >= 3000,
            })}
          >
            {responseDuration}
          </td>
        )}
        <th className="text-nowrap">
          {dayjs(createdAt).local().format("DD-MM-YYYY / HH:mm")}
        </th>
        <td className="text-nowrap">
          <div className="d-flex">
            <Link to={`logs/${level}/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
    </>
  );
};

const Logs = React.memo(() => {
  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation(["logs", "common"]);

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
        startDate?: Date;
        endDate?: Date;
        logInfoType?: TLogInfoType;
      },
    [location.search],
  );
  const [search, setSearch] = useState(query.search);

  const [startDate, setStartDate] = useState(
    query.startDate
      ? new Date(dayjs(query.startDate).local().format())
      : new Date(dayjs().subtract(3, "day").local().format()),
  );
  const [endDate, setEndDate] = useState(
    query.endDate
      ? new Date(dayjs(query.endDate).local().format())
      : new Date(),
  );

  const [dateError, setDateError] = useState(false);

  const [logInfoType, setLogInfoType] = useState<TLogInfoType>(
    query.logInfoType || "request",
  );

  const page = useMemo(() => Math.max(query?.page || 0, 1), [query]);

  const variables = useMemo(() => {
    const nextVariables = {
      type: logInfoType,
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
      ...(startDate || endDate || search
        ? {
            filter: {
              ...((startDate || endDate) && {
                createdAt: {
                  ...(startDate && {
                    GTE: dayjs(startDate).hour(0).minute(0).second(0).format(),
                  }),
                  ...(endDate && {
                    LTE: dayjs(endDate).hour(23).minute(59).second(59).format(),
                  }),
                },
              }),
              search: search?.toString(),
            },
          }
        : {}),
    };

    return nextVariables;
  }, [page, startDate, endDate, search, logInfoType]);

  const { data } = useQuery<{
    logs?: Array<ILog>;
    logsCount: number;
  }>(QUERY_LOGS, {
    fetchPolicy: "network-only",
    variables,
  });
  const logs = data?.logs ?? [];
  const logsCount = data?.logsCount ?? 0;

  const logInfoTypeOptions = LogInfoTypes.map((type) => ({
    value: type,
    label: type,
  }));

  const renderLog = useCallback(
    (item: ILog) => <TableRow key={item.id} item={item} />,
    [],
  );

  useDebounce(
    () => {
      if (
        search !== undefined ||
        startDate !== undefined ||
        endDate !== undefined ||
        logInfoType !== undefined
      ) {
        history.push({
          search: qs.stringify({
            page: 1,
            ...(search && { search }),
            ...(startDate && { startDate }),
            ...(endDate && { endDate }),
            ...(logInfoType && { logInfoType }),
          }),
        });
      }
    },
    500,
    [search, startDate, endDate, logInfoType],
  );

  useEffect(() => {
    setDateError(false);
    if (dayjs(startDate).isAfter(dayjs(endDate))) {
      setDateError(true);
    }
  }, [startDate, endDate]);

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("logs:logs.nav.logs")}
          </li>
        </ol>
      </nav>

      <div className="d-flex mb-4">
        <div className="d-flex mr-4">
          <input
            className="form-control mr-5"
            placeholder={t("common:search")}
            value={search}
            onChange={({ target: { value } }) => {
              setSearch(value);
            }}
          />
          <div className="mx-2 d-flex align-items-center">
            {t("logs:logs.date.from")}
          </div>
          <DatePicker
            isClearable
            selected={startDate}
            onChange={(date: Date) => {
              setStartDate(date);
              if (date.toDateString() === new Date().toDateString()) {
                setEndDate(date);
              }
            }}
            placeholderText="Start Date"
            dateFormat="dd/MM/yyyy"
            className="form-control"
            todayButton="Today"
          />
        </div>
        <div className="d-flex">
          <div className="mr-2 d-flex align-items-center">
            {t("logs:logs.date.to")}
          </div>
          <DatePicker
            isClearable
            selected={endDate}
            onChange={(date: Date) => {
              setEndDate(date);
            }}
            filterDate={(date) => {
              if (!!startDate) {
                return dayjs(date) >= dayjs(startDate);
              }
              return true;
            }}
            placeholderText="End Date"
            dateFormat="dd/MM/yyyy"
            className={clsx("form-control", {
              "is-invalid": dateError,
            })}
          />
        </div>
        <div className="d-flex ml-5">
          <label htmlFor="roles" className="mb-0 mr-2 align-self-center">
            {t("logs:logs.type")}
          </label>
          <div style={{ width: "13rem" }}>
            <Select
              isClearable
              options={logInfoTypeOptions}
              value={logInfoTypeOptions.find(
                (option) => option.value === logInfoType,
              )}
              onChange={(value) => {
                if (value) {
                  setLogInfoType(value.value);
                }
              }}
              styles={reactSelectCustomStyles(false)}
            />
          </div>
        </div>
      </div>
      <Table>
        <thead>
          <tr>
            <th>{t("logs:logs.th.endpoint")}</th>
            {logInfoType === "info" ? (
              <th>{t("logs:logs.th.message")}</th>
            ) : (
              <th>{t("logs:logs.th.response")}</th>
            )}
            <th>{t("logs:logs.th.created")}</th>
            <th scope="col">{t("logs:logs.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{logs.map(renderLog)}</tbody>
      </Table>
      <Pagination documentsCount={logsCount} limit={limit} />
    </div>
  );
});

const LogsRoute = React.memo(() => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Logs />
      </Route>
      <Route path={`${path}/:type/:id`}>
        <Log />
      </Route>
    </Switch>
  );
});

export default LogsRoute;
