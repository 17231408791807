import React from "react";

import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import AssetsRoute from "./Assets";

const AssetsRouter = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <AssetsRoute />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default AssetsRouter;
