export type FileUpload = {
  file: File;
  data: string;
};

export const readFileAsDataURL = (file: File): Promise<FileUpload> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      if (!e.target?.result) {
        return reject(new Error("Failed to read the file."));
      }

      return resolve({
        file,
        data: e.target.result as string,
      });
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};
