import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

// Context
import { toast } from "react-toastify";
import { useAuth } from "../../context/Auth";

const Login = React.memo(() => {
  const [email, setEmail] = useState(
    process.env.NODE_ENV === "development" ? "webmaster@futureforward.rs" : "",
  );
  const [password, setPassword] = useState(
    process.env.NODE_ENV === "development" ? "admin" : "",
  );

  const { replace } = useHistory();

  const { login } = useAuth();

  const { t } = useTranslation(["login", "common"]);

  const onSubmit = useCallback(
    (e: any) => {
      e.preventDefault();

      return login(email, password)
        .then((data) => {
          const {
            data: {
              login: { requires2fa, token },
            },
          } = data;

          if (!requires2fa) {
            return;
          }

          replace("/verify-2fa", { token });
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [email, login, password, replace],
  );

  return (
    <div className="row vh-100 justify-content-center align-items-center">
      <div className="col-6">
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="email">{t("login:form.email.label")}</label>
            <input
              id="email"
              // type="email"
              className="form-control"
              placeholder={t("login:form.email.placeholder")}
              value={email}
              onChange={({ target: { value } }) => setEmail(value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">{t("login:form.password.label")}</label>
            <input
              id="password"
              type="password"
              className="form-control"
              placeholder={t("login:form.password.placeholder")}
              value={password}
              onChange={({ target: { value } }) => setPassword(value)}
            />
          </div>
          <div className="d-flex justify-content-between">
            <button type="submit" className="btn btn-primary ">
              {t("login:button.submit")}
            </button>
            <Link to="/forgot-password" className="btn btn-secondary">
              {t("login:button.forgotPassword")}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
});

export default Login;
