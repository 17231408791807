import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { useMutation } from "@apollo/client";
import { MUTATION_FORGOT_PASSWORD } from "../../config/graphql/mutation";

import Input from "../../components/Input";

type FieldValues = {
  email: string;
};

const ForgotPassword = React.memo(() => {
  const history = useHistory();
  const { t } = useTranslation("forgotPassword");

  const schema = useMemo(() => {
    return yup.object().shape({
      email: yup
        .string()
        .required(t("forgotPassword:yup.email.required"))
        .email(t("forgotPassword:yup.email.pattern")),
    });
  }, [t]);

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: { email: "" },
  });

  const [onForgotPassword, { loading }] = useMutation(MUTATION_FORGOT_PASSWORD);

  const onSubmit = useCallback(
    (input: FieldValues) => {
      return onForgotPassword({ variables: { input } })
        .then(() => {
          toast.success<string>(t("forgotPassword:toast.success"));
          history.push("/login");
        })
        .catch(() => {
          toast.error<string>(t("forgotPassword:toast.error"));
          history.push("/login");
        });
    },
    [onForgotPassword, t, history],
  );

  return (
    <FormProvider {...methods}>
      <div className="row vh-100 justify-content-center align-items-center">
        <div className="col-6">
          <h5 className="text-center mb-3">{t("forgotPassword:title")}</h5>
          <p>{t("forgotPassword:subtitle")}</p>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="email">
                {t("forgotPassword:form.email.label")}
              </label>
              <Input
                name="email"
                className="form-control"
                placeholder={t("forgotPassword:form.email.placeholder")}
                autoComplete="email"
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {t("forgotPassword:button.submit")}
            </button>
          </form>
        </div>
      </div>
    </FormProvider>
  );
});

export default ForgotPassword;
