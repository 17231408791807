import React, { useCallback, useEffect, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";
import { useTranslation } from "react-i18next";

import * as yup from "yup";

import get from "lodash/get";
import find from "lodash/find";

import clsx from "clsx";

import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import {
  useRouteMatch,
  useHistory,
  NavLink,
  Route,
  Link,
  Switch,
} from "react-router-dom";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "../../../components/Input";

import {
  QUERY_VISIONECT,
  QUERY_AVAILABLE_VISIONECT_DEVICES,
  QUERY_VISIONECT_AVAILABLE_TEMPLATES,
} from "../../../config/graphql/query";
import {
  CREATE_VISIONECT,
  UPDATE_VISIONECT,
  DELETE_VISIONECT,
} from "../../../config/graphql/mutation";

import WayfindingItems from "./Items";
import RoleBlock from "../../../components/RoleBlock";
import { useCurrentLanguage } from "../../../lib/hooks/useCurrentLanguage";

interface FieldValues {
  title?: string;
  template?: string;
  rotation?: number;
  device?: string;
}

const WayfindingForm = React.memo(() => {
  const [show, setShow] = useToggle(false);

  const history = useHistory();

  const { t } = useTranslation(["visionect, wayfinding, common"]);

  const { replace } = useHistory();
  const {
    // @ts-ignore
    params: { wayfindingId: id },
  } = useRouteMatch();

  const schema = useMemo(
    () =>
      yup.object().shape({
        title: yup
          .string()
          .required(t("wayfinding:wayfinding.wayfindingForm.yup.title")),
        device: yup
          .string()
          .required(t("wayfinding:wayfinding.wayfindingForm.yup.device")),
        template: yup
          .string()
          .required(t("wayfinding:wayfinding.wayfindingForm.yup.template")),
        rotation: yup
          .number()
          .required(t("wayfinding:wayfinding.wayfindingForm.yup.rotation")),
      }),
    [t],
  );

  const [onSave, { loading: saving }] = useMutation(
    id ? UPDATE_VISIONECT : CREATE_VISIONECT,
  );

  const [onDelete] = useMutation(DELETE_VISIONECT);

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
  });

  const {
    formState: { errors },
  } = methods;

  const { data: visionectDevicesData } = useQuery(
    QUERY_AVAILABLE_VISIONECT_DEVICES,
  );

  const { data: visionectData } = useQuery<{ visionect: IVisionect }>(
    QUERY_VISIONECT,
    {
      skip: !(id && !!visionectDevicesData),
      variables: { id },
    },
  );

  const devices = useMemo(() => {
    const available = visionectDevicesData?.availableVisionectDevices ?? [];

    if (visionectData?.visionect.device) {
      return [visionectData?.visionect.device, ...available];
    }

    return available;
  }, [visionectDevicesData, visionectData]);

  const { data: visionectTemplatesData } = useQuery(
    QUERY_VISIONECT_AVAILABLE_TEMPLATES,
  );

  const visionectAvailableTemplates = useMemo(
    () => visionectTemplatesData?.visionectAvailableTemplates ?? [],
    [visionectTemplatesData],
  );

  useEffect(() => {
    if (visionectData?.visionect) {
      methods.reset({
        ...visionectData?.visionect,
        device: visionectData?.visionect?.device?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visionectData]);

  const onRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      return onDelete({ variables: { id } })
        .then(() => {
          history.replace("/wayfinding");
          toast.success<string>(
            t("wayfinding:wayfinding.wayfindingForm.toast.deleted"),
          );
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [onDelete, id, history, t],
  );

  const onBeforeRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      setShow(true);
    },
    [setShow],
  );

  const onSubmit = (values: FieldValues) => {
    const input = {
      ...(id ? { id } : {}),
      ...values,
    };

    onSave({
      variables: {
        input,
      },
    })
      .then(({ data }) => {
        if (data?.createVisionect?.id) {
          replace(`/wayfinding/${data?.createVisionect?.id}`);

          toast.success<string>(
            t("wayfinding:wayfinding.wayfindingForm.toast.created"),
          );
          return;
        }

        toast.success<string>(
          t("wayfinding:wayfinding.wayfindingForm.toast.updated"),
        );
      })
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );
      });
  };

  const watchDeviceId = methods.watch("device");
  const watchRotation = methods.watch("rotation");
  const watchTemplate = get(visionectData, "visionect.template");

  const deviceId = find(devices, { id: watchDeviceId })?.deviceId;

  const language = useCurrentLanguage();

  return (
    <>
      <FormProvider {...methods}>
        <div className="tab-pane active" role="tabpanel">
          <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="title">
                  {t("wayfinding:wayfinding.wayfindingForm.form.name")}
                </label>
                <Input name="title" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="device">
                  {t("wayfinding:wayfinding.wayfindingForm.form.device")}
                </label>
                <select
                  {...methods.register("device")}
                  className="custom-select"
                >
                  {devices.map((device: IVisionectDevice) => (
                    <option key={device.id} value={device.id}>
                      {device.name}
                    </option>
                  ))}
                </select>
                {!!errors.device && (
                  <div className="invalid-feedback">
                    {errors.device.message}
                  </div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="template">
                  {t(
                    "wayfinding:wayfinding.wayfindingForm.form.template.label",
                  )}
                </label>
                <select
                  {...methods.register("template")}
                  className="custom-select"
                >
                  {visionectAvailableTemplates.map((template: string) => (
                    <option key={template} value={template}>
                      {t(
                        `wayfinding:wayfinding.wayfindingForm.form.template.${template}`,
                      )}
                    </option>
                  ))}
                </select>
                {!!errors.template && (
                  <div className="invalid-feedback">
                    {errors.template.message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="type">
                  {t(
                    "wayfinding:wayfinding.wayfindingForm.form.rotation.label",
                  )}
                </label>
                <select
                  {...methods.register("rotation")}
                  className="custom-select"
                >
                  {[0, 1, 2, 3].map((value) => (
                    <option key={value} value={value}>
                      {t(
                        `wayfinding:wayfinding.wayfindingForm.form.rotation.${value}`,
                      )}
                    </option>
                  ))}
                </select>
                {!!errors.rotation && (
                  <div className="invalid-feedback">
                    {errors.rotation.message}
                  </div>
                )}
              </div>
            </div>

            <div className="col-lg-8 row">
              <div className="col-6">
                <h3>
                  {t("wayfinding:wayfinding.wayfindingForm.form.preview.h3")}
                </h3>
                <div
                  className={clsx("border border-dark embed-responsive", {
                    "embed-responsive-16by9": !!(
                      `${watchRotation}` === "0" || `${watchRotation}` === "2"
                    ),
                    "embed-responsive-9by16": !!(
                      `${watchRotation}` === "1" || `${watchRotation}` === "3"
                    ),
                  })}
                >
                  <iframe
                    key={`${watchTemplate}-${watchDeviceId}`}
                    title={t(
                      "wayfinding:wayfinding.wayfindingForm.form.preview.title",
                    )}
                    className="embed-responsive-item"
                    src={`${process.env.REACT_APP_API_URL}/screen/${id}?lng=${language}`}
                    frameBorder={0}
                    marginHeight={0}
                    marginWidth={0}
                  />
                </div>

                <a
                  href={`${process.env.REACT_APP_API_URL}/screen/${id}?lng=${language}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary mr-2"
                >
                  {t(
                    "wayfinding:wayfinding.wayfindingForm.form.preview.preview",
                  )}
                </a>
              </div>

              <div className="col-6">
                <h3>
                  {t("wayfinding:wayfinding.wayfindingForm.form.live.h3")}
                </h3>
                <div
                  className={clsx("border border-dark embed-responsive", {
                    "embed-responsive-16by9": !!(
                      `${watchRotation}` === "0" || `${watchRotation}` === "2"
                    ),
                    "embed-responsive-9by16": !!(
                      `${watchRotation}` === "1" || `${watchRotation}` === "3"
                    ),
                  })}
                >
                  <iframe
                    key={`${watchTemplate}-${deviceId}`}
                    title={t(
                      "wayfinding:wayfinding.wayfindingForm.form.live.title",
                    )}
                    className="border border-dark visionect-preview"
                    src={`${process.env.REACT_APP_API_URL}/api/v1/visionect/live/device/${deviceId}`}
                    frameBorder={0}
                    marginHeight={0}
                    marginWidth={0}
                  />
                </div>
                <a
                  href={`https://eink.castit.nl/devices/${deviceId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary mr-2"
                >
                  {t("wayfinding:wayfinding.wayfindingForm.form.live.preview")}
                </a>
              </div>
            </div>

            <div className="col-12">
              <input
                type="submit"
                className="btn btn-primary"
                disabled={saving}
              />
              <RoleBlock roles={["ADMIN"]}>
                {id && (
                  <button
                    onClick={onBeforeRemove}
                    className="btn btn-danger ml-3"
                  >
                    {t("common:delete")}
                  </button>
                )}
              </RoleBlock>
            </div>
          </form>
        </div>
      </FormProvider>
      <Modal show={show} onHide={setShow} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("wayfinding:wayfinding.wayfindingForm.modal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("wayfinding:wayfinding.wayfindingForm.modal.body")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setShow}>
            {t("common:cancel")}
          </Button>
          <Button variant="danger" onClick={onRemove}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

const WayfindingPage = React.memo(() => {
  const {
    url,
    path,
    // @ts-ignore
    params: { wayfindingId: id },
  } = useRouteMatch();

  const { t } = useTranslation(["wayfinding"]);

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/wayfinding">
              {t("wayfinding:wayfinding.wayfindingPage.nav.wayfinding")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("wayfinding:wayfinding.wayfindingPage.nav.item")}
          </li>
        </ol>
      </nav>

      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <NavLink exact className="nav-link" to={url}>
            {t("wayfinding:wayfinding.wayfindingPage.nav.information")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              // @ts-ignore
              disabled: !id,
            })}
            to={`${url}/item`}
          >
            {t("wayfinding:wayfinding.wayfindingPage.nav.items")}
          </NavLink>
        </li>
      </ul>

      <div className="tab-content">
        <Switch>
          <Route exact path={path}>
            <WayfindingForm />
          </Route>
          <Route path={`${path}/item`}>
            <WayfindingItems />
          </Route>
        </Switch>
      </div>
    </div>
  );
});

export default WayfindingPage;
