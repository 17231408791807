import React from "react";
import AceEditor, { IAceEditorProps } from "react-ace";
// import ace from "brace";

import "brace/mode/json";
import "brace/theme/monokai";
import "brace/snippets/json";
import "brace/ext/language_tools";

const Editor = (props: IAceEditorProps) => {
  return (
    <AceEditor
      theme="monokai"
      {...{
        mode: "json",
        fontSize: 14,
        showGutter: true,
        showPrintMargin: false,
        highlightActiveLine: true,
        value: "",
        setOptions: {
          useWorker: false,
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 2,
        },
        editorProps: {
          $blockScrolling: true,
        },
      }}
      {...props}
    />
  );
};

export default Editor;
