import { useState } from "react";

import Button from "react-bootstrap/Button";
import { SketchPicker } from "react-color";

interface ColorPickerProps {
  value?: string;
  label?: string;
  onChange: (value: string) => void;
}

const ColorPicker = (props: ColorPickerProps) => {
  const { value = "#000000", label, onChange } = props;

  const [visible, setVisible] = useState(false);

  return (
    <div className="d-flex flex-column">
      <Button onClick={() => setVisible(true)}>{label}</Button>
      <div className="d-flex flex-row align-items-center my-3">
        <label className="mb-0 mr-3">{`Hex: ${value}`}</label>
        <div
          style={{
            backgroundColor: value,
            width: "1.5rem",
            height: "1.5rem",
            border: ".1rem solid",
          }}
        />
      </div>
      {visible && (
        <div className="position-absolute z-index-2">
          <div
            className="position-fixed top-0 bottom-0 left-0 right-0"
            onClick={() => setVisible(false)}
          />
          <SketchPicker
            color={value}
            onChangeComplete={({ hex }) => {
              onChange(hex);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
