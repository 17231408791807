import React, { useMemo } from "react";

import * as yup from "yup";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import Select from "react-select";

import map from "lodash/map";
import union from "lodash/union";
import sortBy from "lodash/sortBy";

import { toast } from "react-toastify";
import { useRouteMatch } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { useQuery, useMutation } from "@apollo/client";

import { useEntityParent } from "../../../context/Entity";
import {
  QUERY_PROPERTIES,
  QUERY_ENTITY_PROPERTIES,
  QUERY_ENTITIES,
  QUERY_ENTITIES_PARENTS,
} from "../../../config/graphql/query";
import { CREATE_ENTITY_PROPERTIES } from "../../../config/graphql/mutation";

const PropertyRoute = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const { t } = useTranslation(["entities", "common"]);

  const parent = useEntityParent();

  const schema = useMemo(
    () =>
      yup.object().shape({
        properties: yup.array().of(
          yup.object().shape({
            id: yup.string(),
          }),
        ),
      }),
    [],
  );

  const methods = useForm<{ properties: IProperty[] }>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
  });

  const { data: propertiesData } = useQuery<{ properties: IProperty[] }>(
    QUERY_PROPERTIES,
    {
      nextFetchPolicy: "network-only",
    },
  );

  const [onAttach] = useMutation(CREATE_ENTITY_PROPERTIES, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: QUERY_PROPERTIES },
      {
        query: QUERY_ENTITIES,
        variables: {
          filter: {
            parent: parent?.id || null,
          },
        },
      },
      ...union(
        // @ts-ignore
        methods.getValues("properties"),
        propertiesData?.properties,
      ).map(({ id }) => ({
        query: QUERY_ENTITIES_PARENTS,
        variables: {
          id,
          sort: {
            title: "ASC",
          },
          filter: { properties: id },
        },
      })),
    ],
  });

  const properties = propertiesData?.properties ?? [];

  useQuery(QUERY_ENTITY_PROPERTIES, {
    variables: {
      id,
    },
    onCompleted: ({ entity: { properties } }) => methods.reset({ properties }),
  });

  const onSubmit = (values: { properties: IProperty[] }) => {
    onAttach({
      variables: { input: { id, properties: map(values.properties, "id") } },
    })
      .then(
        ({
          data: {
            addProperties: { properties },
          },
        }) => {
          methods.reset({ properties });

          toast.success<string>(t("entities:floorPlan.property.toast.updated"));
        },
      )
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );
      });
  };

  return (
    <div className="row p-3">
      <div className="col-12 col-lg-6">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="properties">
                {t("entities:floorPlan.property.form.properties")}
              </label>
              <Controller
                name="properties"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      getOptionLabel={({ title, type }) =>
                        `(${type}) - ${title}`
                      }
                      getOptionValue={({ id }) => id}
                      options={sortBy(properties, "title")}
                      filterOption={(item, searchString) =>
                        item.data.title
                          .toLowerCase()
                          .includes(searchString.toLowerCase())
                      }
                      value={value}
                      className={clsx({
                        "is-invalid": !!error,
                      })}
                      onChange={onChange}
                    />
                    {!!error && (
                      <div className="invalid-feedback">{error.message}</div>
                    )}
                  </>
                )}
              />
            </div>
            <input
              type="submit"
              className="btn btn-primary mb-3"
              value="Save"
            />
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default PropertyRoute;
