import React, { useMemo, useCallback } from "react";

import * as yup from "yup";

import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";

import { useParams } from "react-router-dom";

import { UPDATE_DIGITAL_RECEPTION } from "../../../../config/graphql/mutation";
import { QUERY_DIGITAL_RECEPTION_METADATA } from "../../../../config/graphql/query";

import CodeEditor from "../../../../components/CodeEditor";

const MetadataRoute = React.memo(() => {
  const { screenId } = useParams<{ screenId: string }>();

  const { t } = useTranslation(["screens", "common"]);

  const schema = useMemo(
    () =>
      yup.object().shape({
        metadata: yup.object(),
      }),
    [],
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
  });

  const [onUpdate] = useMutation(UPDATE_DIGITAL_RECEPTION);

  useQuery<{ digitalReception: IDigitalReception }>(
    QUERY_DIGITAL_RECEPTION_METADATA,
    {
      variables: {
        id: screenId,
      },
      onCompleted: ({ digitalReception }) => {
        methods.reset({
          metadata: JSON.stringify(
            digitalReception?.metadata || {},
            null,
            "\t",
          ),
        });
      },
    },
  );

  const onSubmit = useCallback(
    (variables: INameboardSettings) => {
      onUpdate({
        variables: {
          input: {
            id: screenId,
            ...variables,
          },
        },
      })
        .then(() => {
          toast.success<string>(t("screens:screen.screenRoute.toast.updated"));
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [onUpdate, screenId, t],
  );

  return (
    <>
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-12 ">
            <label htmlFor="hardware">
              {t("screens:screen.screenRoute.form.metadata.label")}
            </label>
            <Controller
              name="metadata"
              render={({ field }) => <CodeEditor {...field} />}
            />
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </FormProvider>
    </>
  );
});

export default MetadataRoute;
