import React from "react";

import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import ToroMeeting from "./ToroMeeting";
import ToroMeetings from "./ToroMeetings";

const MeetingRoute = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ToroMeetings />
      </Route>
      <Route exact path={`${path}/new`}>
        <ToroMeeting />
      </Route>
      <Route path={`${path}/:id`}>
        <ToroMeeting />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default MeetingRoute;
