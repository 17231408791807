import React from "react";

import dayjs from "dayjs";
import { useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import { Card, Accordion } from "react-bootstrap";

import { QUERY_TORO_VISITOR } from "../../../config/graphql/toro";

const VisitorLog = React.memo(() => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const { data } = useQuery<{ toroVisitor?: IToroVisitorLog }>(
    QUERY_TORO_VISITOR,
    {
      skip: !id,
      variables: { id },
    },
  );

  const visitor = data?.toroVisitor;

  return (
    <div className="container-fluid">
      <Card className="m-3 p-3">
        Created at:{" "}
        {dayjs(visitor?.createdAt).format(" DD-MM-YYYY / hh:mm:ss") ?? ""}
      </Card>
      <Accordion defaultActiveKey="0" className="m-3">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            Visitor details
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Card.Text>
                <strong>Name:</strong> {visitor?.name ?? ""}
              </Card.Text>
              <Card.Text>
                <strong>Email:</strong> {visitor?.email ?? ""}
              </Card.Text>
              <Card.Text>
                <strong>Phone:</strong> {visitor?.phone ?? "/"}
              </Card.Text>
              <Card.Text>
                <strong>Checked in at:</strong>{" "}
                {visitor?.checkedInAt
                  ? dayjs(visitor?.checkedInAt).format(" DD-MM-YYYY / hh:mm")
                  : "/"}
              </Card.Text>
              <Card.Text>
                <strong>Checked out at:</strong>{" "}
                {visitor?.checkedInAt
                  ? dayjs(visitor?.checkedOutAt).format(" DD-MM-YYYY / hh:mm")
                  : "/"}
              </Card.Text>
              <Card.Text>
                <strong>All day:</strong> {visitor?.allDay ?? "/"}
              </Card.Text>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            Host details
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <Card.Text>
                <strong>Name:</strong> {visitor?.host?.name ?? ""}
              </Card.Text>
              <Card.Text>
                <strong>Email:</strong> {visitor?.host?.email ?? ""}
              </Card.Text>
              <Card.Text>
                <strong>Invited by:</strong>{" "}
                {visitor?.invitedBy?.firstName ?? ""}{" "}
                {visitor?.invitedBy?.lastName ?? "/"}
              </Card.Text>
              <Card.Text>
                <strong>Edited by:</strong> {visitor?.editedBy?.firstName ?? ""}{" "}
                {visitor?.editedBy?.lastName ?? "/"}
              </Card.Text>
              <Card.Text>
                <strong>Company:</strong> {visitor?.company?.title ?? ""}
              </Card.Text>
              <Card.Text>
                <strong>Building:</strong> {visitor?.building?.title ?? ""}
              </Card.Text>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            Meeting details
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <Card.Text>
                <strong>Title:</strong> {visitor?.meeting?.title ?? "/"}
              </Card.Text>
              <Card.Text>
                <strong>Description:</strong>{" "}
                {visitor?.meeting?.description ?? "/"}
              </Card.Text>
              <Card.Text>
                <strong>Start date:</strong>
                {visitor?.dateStart
                  ? dayjs(visitor?.dateStart).format(" DD-MM-YYYY / hh:mm")
                  : "/"}
              </Card.Text>
              <Card.Text>
                <strong>End date:</strong>
                {visitor?.meeting?.dateEnd
                  ? dayjs(visitor?.meeting?.dateEnd).format(
                      " DD-MM-YYYY / hh:mm",
                    )
                  : " /"}
              </Card.Text>
              <Card.Text>
                <strong>Attendees:</strong>
                {visitor?.meeting?.attendees.map((attendee) => (
                  <span key={attendee.id}>{attendee.name}</span>
                )) ?? "/"}
              </Card.Text>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            Badge details
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <Card.Text>
                <strong>Badge id:</strong> {visitor?.badge ?? "/"}
              </Card.Text>
              <Card.Text>
                <strong>Returned:</strong> {visitor?.badge ?? "/"}
              </Card.Text>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
});

export default VisitorLog;
