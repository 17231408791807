import React from "react";

import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import Postbox from "./Postbox";
import PostboxRoutes from "./Postboxes";

import AppRoute from "../../components/Route";

const ScreensRouter = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <PostboxRoutes />
      </Route>
      <AppRoute.Role
        exact
        path={`${path}/new`}
        roles={["ADMIN"]}
        redirect={path}
      >
        <Postbox />
      </AppRoute.Role>
      <Route path={`${path}/:screenId`}>
        <Postbox />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default ScreensRouter;
