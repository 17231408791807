import { useParams } from "react-router-dom";
import { useCurrentClient } from "../../../../context/Client";

export function useClientId(): string {
  const currentClient = useCurrentClient();

  const { clientId } = useParams<{ clientId?: string }>();

  if (clientId) {
    return clientId;
  }

  if (!currentClient?.id) {
    throw new Error("No client");
  }

  return currentClient.id;
}

export function useIsCurrentClient(): boolean {
  const clientId = useClientId();
  const currentClient = useCurrentClient();

  if (!currentClient?.id) {
    throw new Error("No client");
  }

  return currentClient.id === clientId;
}

export function useClientParentCode(): string | undefined {
  const currentClient = useCurrentClient();

  return currentClient?.parent?.code;
}
