import React from "react";

import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import Meeting from "./Meeting";
import Meetings from "./Meetings";

const MeetingsRoute = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Meetings />
      </Route>
      <Route exact path={`${path}/new`}>
        <Meeting />
      </Route>
      <Route path={`${path}/:id`}>
        <Meeting />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default MeetingsRoute;
