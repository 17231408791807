import { useQuery } from "@apollo/client";
import { QUERY_LOCALE } from "../../config/graphql/query";

function useCurrentLanguage() {
  const { data } = useQuery(QUERY_LOCALE);

  return data?.locale ?? "en";
}

export { useCurrentLanguage };
