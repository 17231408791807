import React from "react";
import { useController } from "react-hook-form";

import clsx from "clsx";

import { useQuery } from "@apollo/client";

import { QUERY_LOCALE } from "../config/graphql/query";

type Ref = HTMLTextAreaElement;

interface StaticComponents
  extends React.ForwardRefExoticComponent<Props & React.RefAttributes<Ref>> {
  Translatable: React.FC<Props>;
}
interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
}

// @ts-ignore
const TextArea: React.FC<Props> & StaticComponents = React.forwardRef<
  Ref,
  Props
>((props: Props, ref) => {
  const { name } = props;

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
  });

  return (
    <>
      <textarea
        rows={5}
        {...props}
        {...field}
        ref={ref}
        className={clsx("form-control", {
          "is-invalid": !!error,
        })}
      />
      {!!error && <div className="invalid-feedback">{error.message}</div>}
    </>
  );
});

const Translatable: React.FC<Props> = React.forwardRef<Ref, Props>(
  (props: Props, ref) => {
    const { name } = props;

    const {
      field,
      fieldState: { error },
    } = useController({
      name,
    });

    const { data } = useQuery(QUERY_LOCALE);

    const locale = data?.locale ?? "en";

    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text" id={`translate-${name}`}>
            {`${locale}`}
          </span>
        </div>
        <textarea
          rows={5}
          {...props}
          {...field}
          aria-describedby={`translate-${name}`}
          ref={ref}
          className={clsx("form-control", {
            "is-invalid": !!error,
          })}
        />
        {!!error && <div className="invalid-feedback">{error?.message}</div>}
      </div>
    );
  },
);

TextArea.Translatable = Translatable;

export default TextArea;
