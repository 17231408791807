import React, { useMemo, useCallback } from "react";
import {
  useRouteMatch,
  useHistory,
  Link,
  Switch,
  Route,
} from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Table, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import qs from "query-string";
import dayjs from "dayjs";

import { QUERY_TORO_VISITORS } from "../../../config/graphql/toro";
import VisitorLog from "../VisitorLog/VisitorLog";
import Pagination from "../../../components/Pagination";

const limit = 10;

const Today = React.memo(() => {
  const match = useRouteMatch();
  const history = useHistory();

  const { t } = useTranslation(["visitorLogs", "common"]);

  const query = useMemo(
    () =>
      qs.parse(history.location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
      },
    [history.location.search],
  );

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);

  const { data } = useQuery<{
    visitors?: Array<IToroVisitorLog>;
    visitorsCount: number;
  }>(QUERY_TORO_VISITORS, {
    nextFetchPolicy: "network-only",
    variables: {
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
      sort: {
        dateStart: "DESC",
      },
      filter: {
        dateStart: {
          GTE: dayjs().startOf("day").toDate(),
          LTE: dayjs().endOf("day").toDate(),
        },
      },
    },
  });

  const visitorsArr = data?.visitors ?? [];
  const visitorsCount = data?.visitorsCount ?? 0;

  const renderVisitorLog = useCallback(
    ({
      id,
      name,
      meeting,
      company,
      checkedOutAt,
      checkedInAt,
      host,
    }: IToroVisitorLog) => {
      return (
        <tr>
          <td>{name}</td>
          <td>{meeting ? meeting.id : "/"}</td>
          <td>{id}</td>
          <td>{host.name}</td>
          <td>{company.title}</td>
          <td>{checkedInAt ? dayjs(checkedInAt).format("DD-MM-YYYY") : "/"}</td>
          <td>
            {checkedOutAt ? dayjs(checkedOutAt).format("DD-MM-YYYY") : "/"}
          </td>
          <td>
            <Link to={`${match.path}/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </td>
        </tr>
      );
    },
    [match.path, t],
  );

  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>{t("toro_visitorLogs:all.th.name")}</th>
            <th>{t("toro_visitorLogs:all.th.meeting")}</th>
            <th>{t("toro_visitorLogs:all.th.guest")}</th>
            <th>{t("toro_visitorLogs:all.th.host")}</th>
            <th>{t("toro_visitorLogs:all.th.company")}</th>
            <th>{t("toro_visitorLogs:all.th.checkIn")}</th>
            <th>{t("toro_visitorLogs:all.th.checkOut")}</th>
            <th>{t("toro_visitorLogs:all.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{visitorsArr.map(renderVisitorLog)}</tbody>
      </Table>
      <div className="d-flex">
        <Pagination documentsCount={visitorsCount} limit={limit} />
        {/** @todo create button for export table data */}
        {/* <Button className="btn btn-outline-light">Export</Button> */}
      </div>
    </>
  );
});

const TodayRoute = React.memo(() => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Today />
      </Route>
      <Route path={`${path}/:id`}>
        <VisitorLog />
      </Route>
    </Switch>
  );
});

export default TodayRoute;
