import React from "react";
import BTTable, { TableProps } from "react-bootstrap/Table";

import useMedia from "react-use/lib/useMedia";

const Table = React.memo((props: React.PropsWithChildren<TableProps>) => {
  const { children } = props;

  const isSmall = useMedia("(max-width: 768px)");

  return (
    <BTTable
      striped
      bordered
      hover
      responsive
      size={isSmall ? "sm" : "lg"}
      {...props}
    >
      {children}
    </BTTable>
  );
});

export default Table;
