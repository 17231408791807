import React from "react";

import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import Visitors from "./Visitors";
import Visitor from "./Visitor";

const VisitorsRouter = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Visitors />
      </Route>
      <Route exact path={`${path}/new`}>
        <Visitor />
      </Route>
      <Route path={`${path}/:id`}>
        <Visitor />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default VisitorsRouter;
