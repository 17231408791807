import React from "react";

import { useTranslation } from "react-i18next";
import Input from "../../../../components/Input";

const BuildingForm = () => {
  const { t } = useTranslation(["entities"]);

  return (
    <>
      <div className="form-group">
        <label htmlFor="country">
          {t("entities:entity.building.form.country")}
        </label>
        <Input name="country" className="form-control" />
      </div>
      <div className="form-group">
        <label htmlFor="city">{t("entities:entity.building.form.city")}</label>
        <Input name="city" className="form-control" />
      </div>
      <div className="form-row">
        <div className="col-md-9 mb-3">
          <label htmlFor="address">
            {t("entities:entity.building.form.address")}
          </label>
          <Input name="address" className="form-control" />
        </div>
        <div className="col-md-3 mb-3">
          <label htmlFor="zipCode">
            {t("entities:entity.building.form.zipCode")}
          </label>
          <Input type="text" name="zipCode" className="form-control" />
        </div>
      </div>
    </>
  );
};

export default BuildingForm;
