import React, { useCallback, useState } from "react";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { Switch, useRouteMatch, Redirect, Link } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { QUERY_VISIONECTS } from "../../config/graphql/query";
import {
  DELETE_VISIONECT,
  RESTART_VISIONECT,
} from "../../config/graphql/mutation";

import Wayfinding from "./Wayfinding";

import Table from "../../components/Table";
import Route from "../../components/Route";
import RoleBlock from "../../components/RoleBlock";

const TableRow = ({ item }: { item: IVisionect }) => {
  const { id, title, template, device } = item;
  const { deviceData } = device;

  const [modal, setModal] = useState<"remove" | "reload" | null>(null);

  const { t } = useTranslation(["wayfinding", "common"]);

  const [onDelete, { loading: deleteLoading }] = useMutation(DELETE_VISIONECT, {
    refetchQueries: [
      { query: QUERY_VISIONECTS, variables: { sort: { title: "ASC" } } },
    ],
    variables: { id },
  });

  const onRemove = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();

      try {
        await onDelete();

        toast.success<string>(t("wayfinding:wayfindings:toast.deleted"));
      } catch (error) {
        toast.error<string>(t("wayfinding:wayfindings:toast.deletedError"));
      }
      setModal(null);
    },
    [onDelete, t],
  );

  const onBeforeRemove = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();

      setModal("remove");
    },
    [],
  );

  const [onRestart, { loading: restartLoading }] = useMutation(
    RESTART_VISIONECT,
    { variables: { id } },
  );

  const onReload = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();

      try {
        await onRestart();

        toast.success<string>(t("wayfinding:wayfindings:toast.reloaded"));
      } catch (error) {
        toast.error<string>(t("wayfinding:wayfindings:toast.reloadedError"));
      }
      setModal(null);
    },
    [onRestart, t],
  );

  const onBeforeReload = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();

      setModal("reload");
    },
    [],
  );

  return (
    <>
      <tr>
        <th scope="row" className="text-nowrap">
          {id}
        </th>
        <td>{title}</td>
        <td>{template}</td>
        <td>{deviceData?.Options?.Name || deviceData?.Uuid}</td>
        <td>{deviceData?.State}</td>
        <td>{deviceData?.Status.Battery} %</td>
        <td className="text-nowrap">
          <RoleBlock roles={["ADMIN"]}>
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={onBeforeRemove}
            >
              {t("common:delete")}
            </Button>
          </RoleBlock>
          <Link to={`/wayfinding/${id}`}>
            <Button size="sm" variant="primary" className="mr-2">
              {t("common:view")}
            </Button>
          </Link>
          <Button size="sm" variant="secondary" onClick={onBeforeReload}>
            {t("common:reload")}
          </Button>
        </td>
      </tr>
      <Modal
        show={!!modal}
        onHide={() => setModal(null)}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t(`wayfinding:wayfindings.modal.${modal}.title`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(`wayfinding:wayfindings.modal.${modal}.body`, {
            title: item.title,
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setModal(null)}>
            {t("common:cancel")}
          </Button>
          <Button
            size="sm"
            variant="danger"
            disabled={restartLoading || deleteLoading}
            onClick={(e) => {
              if (modal === "reload") {
                onReload(e);
              }
              if (modal === "remove") {
                onRemove(e);
              }
            }}
          >
            {modal === "remove" && t("common:delete")}
            {modal === "reload" && t("common:reload")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Wayfindings = React.memo(() => {
  const match = useRouteMatch();

  const { t } = useTranslation(["wayfinding", "common"]);

  const { data } = useQuery(QUERY_VISIONECTS, {
    variables: { sort: { title: "ASC" } },
  });

  const visionects: Array<IVisionect> = data?.visionects ?? [];

  const renderWayfinding = useCallback(
    (item: IVisionect) => <TableRow key={item.id} item={item} />,
    [],
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("wayfinding:wayfindings.nav.wayfinding")}
          </li>
        </ol>
      </nav>
      <RoleBlock roles={["ADMIN"]}>
        <div className="d-flex justify-content-end align-items-center mb-3">
          <Link
            to={`${match.path}/new`}
            type="button"
            className="btn btn-primary"
          >
            {t("wayfinding:wayfindings.button.add")}
          </Link>
        </div>
      </RoleBlock>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{t("wayfinding:wayfindings.th.title")}</th>
            <th scope="col">{t("wayfinding:wayfindings.th.template")}</th>
            <th scope="col">{t("wayfinding:wayfindings.th.device")}</th>
            <th scope="col">{t("wayfinding:wayfindings.th.status")}</th>
            <th scope="col">{t("wayfinding:wayfindings.th.battery")}</th>
            <th scope="col">{t("wayfinding:wayfindings.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{visionects.map(renderWayfinding)}</tbody>
      </Table>
    </div>
  );
});

const WayfindingsRouter = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route.Private exact path={path}>
        <Wayfindings />
      </Route.Private>
      <Route.Role exact path={`${path}/new`} roles={["ADMIN"]} redirect={path}>
        <Wayfinding />
      </Route.Role>
      <Route.Private path={`${path}/:wayfindingId`}>
        <Wayfinding />
      </Route.Private>
      <Redirect to={path} />
    </Switch>
  );
});

export default WayfindingsRouter;
