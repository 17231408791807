import React, { useMemo } from "react";

import {
  Switch,
  NavLink,
  useParams,
  useRouteMatch,
  Redirect,
} from "react-router-dom";

import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import Layout from "./Layout";
import Schedules from "./Schedules/Schedules";
import GeneralSettings from "./GeneralSettings";
import EntranceAgreement from "./EntranceAgreement";
import ManualRegistrations from "./ManualRegistrations/ManualRegistrations";

import Route from "../../../../components/Route";
import RoleBlock from "../../../../components/RoleBlock";

import { QUERY_DIGITAL_RECEPTION } from "../../../../config/graphql/query";

const SettingsRoute = React.memo(() => {
  const { url, path } = useRouteMatch();

  const { t } = useTranslation(["screens", "common"]);

  const { screenId } = useParams<{ screenId: string }>();

  const { data } = useQuery<{
    digitalReception: {
      template: string;
      entranceAgreement: IEntranceAgreement;
    };
  }>(QUERY_DIGITAL_RECEPTION, {
    variables: {
      id: screenId,
    },
    skip: !screenId,
  });

  const entranceAgreementId = useMemo(
    () => data?.digitalReception?.entranceAgreement?.id,
    [data],
  );

  const template = useMemo(() => data?.digitalReception?.template, [data]);

  return (
    <div className="container-fluid">
      <ul className="nav nav-tabs mb-4">
        <RoleBlock roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}>
          <li className="nav-item">
            <NavLink exact className="nav-link" to={`${url}`}>
              {t("screens:screen.screenRoute.nav.generalSettings")}
            </NavLink>
          </li>
        </RoleBlock>
        <RoleBlock roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}>
          <li className="nav-item">
            <NavLink exact className="nav-link" to={`${url}/schedules`}>
              {t("screens:screen.screenRoute.nav.schedules")}
            </NavLink>
          </li>
        </RoleBlock>
        {template === "digitalreception" && (
          <>
            <RoleBlock roles={["ADMIN", "CLIENT_ADMIN"]}>
              <li className="nav-item">
                <NavLink exact className="nav-link" to={`${url}/layout`}>
                  {t("screens:screen.screenRoute.nav.layout")}
                </NavLink>
              </li>
            </RoleBlock>
            <RoleBlock roles={["ADMIN", "CLIENT_ADMIN"]}>
              <li className="nav-item">
                <NavLink
                  exact
                  className="nav-link"
                  to={`${url}/manual-registrations`}
                >
                  {t("screens:screen.screenRoute.nav.manualRegistrations")}
                </NavLink>
              </li>
            </RoleBlock>
          </>
        )}
        <RoleBlock roles={["ADMIN"]}>
          <li className="nav-item">
            <NavLink
              className="nav-link"
              to={`${url}/entrance-agreements/${entranceAgreementId ?? "new"}`}
            >
              {t("screens:screen.screenRoute.nav.entranceAgreement")}
            </NavLink>
          </li>
        </RoleBlock>
      </ul>

      <div className="tab-content">
        <Switch>
          <Route.Role
            path={`${path}/schedules`}
            roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
            redirect={`/digital-receptions/${screenId}`}
          >
            <Schedules />
          </Route.Role>
          <Route.Role
            path={`${path}/layout`}
            roles={["ADMIN", "CLIENT_ADMIN"]}
            redirect={`/digital-receptions/${screenId}`}
          >
            <Layout />
          </Route.Role>
          <Route.Role
            path={`${path}/manual-registrations`}
            roles={["ADMIN", "CLIENT_ADMIN"]}
            redirect={`/digital-receptions/${screenId}`}
          >
            <ManualRegistrations />
          </Route.Role>
          <Route.Role
            path={`${path}/entrance-agreements/new`}
            roles={["ADMIN", "CLIENT_ADMIN"]}
            redirect={`/digital-receptions/${screenId}`}
          >
            <EntranceAgreement />
          </Route.Role>
          <Route.Role
            path={`${path}/entrance-agreements/:agreementId`}
            roles={["ADMIN", "CLIENT_ADMIN"]}
            redirect={`/digital-receptions/${screenId}`}
          >
            <EntranceAgreement />
          </Route.Role>
          <Route.Role
            path={path}
            roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
            redirect={`/digital-receptions/${screenId}`}
          >
            <GeneralSettings />
          </Route.Role>
          <Redirect to={`${path}`} />
        </Switch>
      </div>
    </div>
  );
});

export default SettingsRoute;
