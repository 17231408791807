import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";

import * as yup from "yup";

import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "../../../../../../components/Input";

import { QUERY_CLIENT_TWILIO_CLIENT } from "../../../../../../config/graphql/query";
import {
  CREATE_TWILIO_CLIENT,
  UPDATE_TWILIO_CLIENT,
} from "../../../../../../config/graphql/mutation";

import { useClientId } from "../../../hooks/useClient";

type FormVariables = IClientTwilio;

const TwilioClient = React.memo(() => {
  const { t } = useTranslation(["client", "common"]);

  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const clientId = useClientId();

  const schema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required(t("client:client.twilio.error.required")),
        description: yup.string().nullable(),
        apiKey: yup.string().required(t("client:client.twilio.error.required")),
        apiSecret: yup
          .string()
          .required(t("client:client.twilio.error.required")),
        applicationId: yup
          .string()
          .required(t("client:client.twilio.error.required")),
        accountSid: yup
          .string()
          .required(t("client:client.twilio.error.required")),
        callerId: yup
          .string()
          .required(t("client:client.twilio.error.required")),
      }),
    [t],
  );

  const methods = useForm<FormVariables>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {},
  });

  const [onCreate] = useMutation(CREATE_TWILIO_CLIENT);
  const [onUpdate] = useMutation(UPDATE_TWILIO_CLIENT);

  useQuery<{ twilioClient: IClientTwilio }>(QUERY_CLIENT_TWILIO_CLIENT, {
    skip: !id,
    variables: { id },
    onCompleted: ({ twilioClient }) => {
      methods.reset(twilioClient);
    },
    context: {
      headers: {
        ClientId: clientId,
      },
    },
  });

  const onSubmit = (input: FormVariables) => {
    if (id) {
      return onUpdate({
        variables: {
          input: {
            ...input,
            id,
          },
        },
      })
        .then(() => {
          toast.success<string>(t("client:client.token.toast.updated"));
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    }

    return onCreate({
      variables: {
        input: {
          ...input,
          client: clientId,
        },
      },
    })
      .then(
        ({
          data: {
            addTwilioClient: { id },
          },
        }) => {
          history.replace(`s/${clientId}/twilio/${id}`);
        },
      )
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );
      });
  };

  return (
    <div className="container-fluid">
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="title">
                {t("client:client.twilio.form.title")}
              </label>
              <Input name="title" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="description">
                {t("client:client.twilio.form.description")}
              </label>
              <Input name="description" className="form-control" />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="apiKey">
                {t("client:client.twilio.form.apiKey")}
              </label>
              <Input
                name="apiKey"
                className="form-control"
                autoComplete="off"
              />
              <small id="title" className="form-text text-muted">
                {"SK".padEnd(32, "*")}
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="apiSecret">
                {t("client:client.twilio.form.apiSecret")}
              </label>
              <Input
                name="apiSecret"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="accountSid">
                {t("client:client.twilio.form.accountSID")}
              </label>
              <Input
                name="accountSid"
                className="form-control"
                autoComplete="off"
              />
              <small id="title" className="form-text text-muted">
                {"AC".padEnd(32, "*")}
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="authToken">
                {t("client:client.twilio.form.token")}
              </label>
              <Input
                name="authToken"
                className="form-control"
                autoComplete="off"
              />
              <a
                href="https://console.twilio.com/?frameUrl=/console"
                target="_blank"
                rel="noreferrer"
                className="small form-text text-muted"
              >
                {t("client:client.twilio.form.tokenLink")}
              </a>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="applicationId">
                {t("client:client.twilio.form.twiMLAppSID")}
              </label>
              <Input
                name="applicationId"
                className="form-control"
                autoComplete="off"
              />
              <small id="title" className="form-text text-muted">
                {"AP".padEnd(32, "*")}
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="callerId">
                {t("client:client.twilio.form.twiMLCallerID")}
              </label>
              <Input name="callerId" className="form-control" />
              <small id="title" className="form-text text-muted">
                {t("client:client.twilio.form.usedBy")}
              </small>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="messagingServiceSid">
                {t("client:client.twilio.form.messagingServiceSID")}
              </label>
              <Input
                name="messagingServiceSid"
                className="form-control"
                autoComplete="off"
              />
              <a
                href="https://console.twilio.com/us1/develop/sms/services?frameUrl=/console/sms/services"
                target="_blank"
                rel="noreferrer"
                className="small form-text text-muted"
              >
                {"MG".padEnd(32, "*")}
              </a>
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </FormProvider>
    </div>
  );
});

export default TwilioClient;
