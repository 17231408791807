import React from "react";

import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import SettingsRouter from "./Settings";
import Settings2faRouter from "./Setup2fa";

const ProfileRouter = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/settings`}>
        <SettingsRouter />
      </Route>
      <Route exact path={`${path}/setup-2fa`}>
        <Settings2faRouter />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default ProfileRouter;
