import React, { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";

import * as yup from "yup";

// import map from "lodash/map";

import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { useRouteMatch, useHistory, Link } from "react-router-dom";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "../../components/Input";

import {
  QUERY_MINEW_DEVICE,
  QUERY_MINEW_DEVICES,
} from "../../config/graphql/query";
import {
  CREATE_MINEW_DEVICE,
  UPDATE_MINEW_DEVICE,
} from "../../config/graphql/mutation";

type FormVariables = {
  id: string;
  name: string;
  mac: string;
  storeId: string;
  productId: string;
};

const Minew = React.memo(() => {
  const { t } = useTranslation(["client", "common"]);

  const history = useHistory();
  const {
    params: { minewId: id },
  } = useRouteMatch<{ minewId: string }>();

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string(),
        mac: yup.string(),
        storeId: yup.string(),
        productId: yup.string(),
      }),
    [],
  );

  const methods = useForm<FormVariables>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
  });

  const { data } = useQuery<{ minewDevice: IMinewDevice }>(QUERY_MINEW_DEVICE, {
    skip: !id,
    variables: {
      id,
    },
    onError: () => history.replace("/minews"),
  });

  useEffect(() => {
    if (data?.minewDevice) {
      methods.reset({
        ...data?.minewDevice,
        id: data?.minewDevice?.deviceData?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [onCreate] = useMutation(CREATE_MINEW_DEVICE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: QUERY_MINEW_DEVICES,
      },
    ],
  });

  const [onUpdate] = useMutation(UPDATE_MINEW_DEVICE);

  const onSubmit = (input: FormVariables) => {
    if (id) {
      return onUpdate({ variables: { input: { ...input, id } } })
        .then(() => {
          toast.success<string>(t("client:client.token.toast.updated"));
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    }

    return onCreate({ variables: { input } })
      .then(
        ({
          data: {
            addMinewDevice: { id },
          },
        }) => {
          history.replace(`/minews/${id}`);

          toast.success<string>("Minew device created");
        },
      )
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );
      });
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/minews">{t("minews:title")}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("minew:title")}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="title">{t("minews:input.label.name")}</label>
              <Input name="name" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="mac">{t("minews:input.label.mac")}</label>
              <Input name="mac" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="storeId">{t("minews:input.label.storeId")}</label>
              <Input name="storeId" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="productId">
                {t("minews:input.label.productId")}
              </label>
              <Input name="productId" className="form-control" />
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </FormProvider>
    </div>
  );
});

export default Minew;
