import React from "react";

import useHasRole from "../lib/hooks/useHasRole";

const RoleBlock = React.memo(
  ({
    children,
    roles = [],
  }: React.PropsWithChildren<{ roles: UserRole[] }>) => {
    const hasRole = useHasRole(roles);

    if (!hasRole) {
      return null;
    }

    return <>{children}</>;
  },
);

export default RoleBlock;
