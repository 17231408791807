import React, { useCallback, useMemo, useState } from "react";

import qs from "query-string";
import map from "lodash/map";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import useToggle from "react-use/lib/useToggle";
import { useDebounce } from "react-use";
import { useTranslation } from "react-i18next";

import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";

import { QUERY_CLIENTS } from "../../../config/graphql/query";
import { DELETE_CLIENT } from "../../../config/graphql/mutation";

import Table from "../../../components/Table";
import RoleBlock from "../../../components/RoleBlock";
import Pagination from "../../../components/Pagination";

import { reactSelectCustomStyles } from "../../Employees/Employee/Information";

const limit = 10;

const planOptions: { value: ClientPlan; label: string }[] = [
  { label: "free", value: "free" },
  { label: "basic", value: "basic" },
  { label: "enterprise", value: "enterprise" },
];

const TableRow = ({
  item,
  index,
  variables,
}: {
  item: IClient;
  index: number;
  variables: any;
}) => {
  const { id: clientId, title, code, parent } = item;

  const [visible, setVisible] = useToggle(false);

  const { t } = useTranslation(["client", "common"]);

  const [onDelete] = useMutation(DELETE_CLIENT, {
    variables: {
      id: clientId,
    },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: QUERY_CLIENTS, variables }],
  });

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr>
        <th scope="row">{index + 1}</th>
        <th scope="row">{title}</th>
        <th scope="row">{code}</th>
        <th scope="row">{parent?.title ?? "n/a"}</th>
        <th scope="row">{item?.plan}</th>

        <td>
          <div className="d-flex">
            <RoleBlock roles={["ADMIN"]}>
              <Button
                size="sm"
                variant="danger"
                className="mr-2"
                onClick={() => setVisible(true)}
              >
                {t("common:delete")}
              </Button>
            </RoleBlock>
            <Link to={`/clients/${clientId}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <RoleBlock roles={["ADMIN"]}>
        <Modal
          size="sm"
          show={visible}
          onHide={setVisible}
          centered
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("client:clients.modal.title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t("client:clients.modal.body", { title })}</Modal.Body>
          <Modal.Footer>
            <Button size="sm" onClick={() => setVisible(false)}>
              {t("common:cancel")}
            </Button>
            <Button size="sm" variant="danger" onClick={onBeforeDelete}>
              {t("common:delete")}
            </Button>
          </Modal.Footer>
        </Modal>
      </RoleBlock>
    </>
  );
};

const ClientsRoute = React.memo(() => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const { t } = useTranslation(["client", "common"]);

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
        plan?: ClientPlan[];
        parent?: string;
      },
    [location.search],
  );

  if (!Array.isArray(query.plan)) {
    query.plan = !!query.plan ? [query.plan] : undefined;
  }

  const [search, setSearch] = useState(query.search);

  const [plan, setPlan] = useState<ClientPlan[] | undefined>(query.plan);

  const [parent, setParent] = useState(query.parent);

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);

  const regex = useMemo(() => {
    return {
      REGEX: query.search ? query.search.toString() : "",
      OPTIONS: "i",
    };
  }, [query.search]);

  const variables = useMemo(
    () => ({
      sort: {
        title: "ASC",
      },
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
      filter: {
        ...(regex.REGEX
          ? {
              OR: [
                {
                  title: regex,
                },
                {
                  code: regex,
                },
              ],
            }
          : {}),
        ...(query.plan && {
          plan: query.plan,
        }),
        ...(query.parent && {
          parentTitle: query.parent ? query.parent.toString() : "",
        }),
      },
    }),
    [page, regex, query.plan, query.parent],
  );

  const { data } = useQuery<{
    clients: IClient[];
    clientsCount: number;
  }>(QUERY_CLIENTS, {
    variables,
    fetchPolicy: "network-only",
  });

  const clients = useMemo(() => data?.clients ?? [], [data]);
  const clientsCount = data?.clientsCount ?? 0;

  const renderClient = useCallback(
    (item: IClient, index: number) => (
      <TableRow
        key={item.id}
        item={item}
        index={(page - 1) * limit + index}
        variables={variables}
      />
    ),
    [page, variables],
  );

  useDebounce(
    () => {
      history.push({
        search: qs.stringify({
          page: 1,
          ...(search && { search }),
          ...(plan && { plan }),
          ...(parent && { parent }),
        }),
      });
    },
    500,
    [search, plan, parent],
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("client:clients.nav.clients")}
          </li>
        </ol>
      </nav>
      <div className="d-flex flex-wrap justify-content-between mt-4">
        <form className="form-inline mr-2">
          <div className="mr-0 mr-sm-5 mb-4">
            <input
              id="search"
              type="search"
              className="form-control"
              placeholder={t("client:clients.input.placeholder.search")}
              value={search}
              onChange={({ target: { value } }) => {
                setSearch(value);
              }}
            />
          </div>
          <div className="mr-0 mr-sm-5 mb-4" style={{ width: "20rem" }}>
            <Select
              isClearable
              isMulti
              placeholder={t("client:clients.input.placeholder.plan")}
              options={planOptions}
              onChange={(nextValue) => {
                setPlan(map(nextValue, "value"));
              }}
              value={map(plan, (value) => ({
                value,
                label: planOptions.find((option) => option.value === value)
                  ?.label,
              }))}
              styles={reactSelectCustomStyles(false)}
            />
          </div>
          <div className="mb-4">
            <input
              id="parent"
              type="search"
              className="form-control"
              placeholder={t("client:clients.input.placeholder.parent")}
              value={parent}
              onChange={({ target: { value } }) => {
                setParent(value);
              }}
            />
          </div>
        </form>

        <RoleBlock roles={["ADMIN"]}>
          <Link
            to={`${match.path}/new`}
            type="button"
            className="btn btn-primary mb-4"
          >
            {t("client:clients.button.add")}
          </Link>
        </RoleBlock>
      </div>

      <Table>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{t("client:clients.th.title")}</th>
            <th scope="col">{t("client:clients.th.code")}</th>
            <th scope="col">{t("client:clients.th.parent")}</th>
            <th scope="col">{t("client:clients.th.plan")}</th>
            <th scope="col">{t("client:clients.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{clients.map(renderClient)}</tbody>
      </Table>
      <Pagination documentsCount={clientsCount} limit={limit} />
    </div>
  );
});

export default ClientsRoute;
