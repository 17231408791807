import { useMutation, useQuery } from "@apollo/client";
import React, { useCallback, useMemo } from "react";
import { useToggle } from "react-use";
import { toast } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Trans, useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

import { QUERY_CURRENT_USER } from "../../../config/graphql/query";
import {
  MUTATION_DISABLE_2FA_AUTH,
  MUTATION_ENABLE_2FA_AUTH,
  MUTATION_GENERATE_2FA_QR_CODE,
} from "../../../config/graphql/mutation";
import Input from "../../../components/Input";
// import { UserFieldValues } from "../../Users/User";

const Settings2faRouter = React.memo(() => {
  const [show, setShow] = useToggle(false);
  const { t } = useTranslation(["user", "common"]);

  const { data: userData } = useQuery<{ me: IUser }>(QUERY_CURRENT_USER);

  const user = useMemo(() => userData?.me, [userData]);

  const methods = useForm<{ password: string }>({});

  const methods2faCode = useForm<{ code: string }>({});

  const [generate2faQrCode, { data: qrCode, reset: resetQrCode }] =
    useMutation<{
      generate2faQrCode: { qrCode: string };
    }>(MUTATION_GENERATE_2FA_QR_CODE);

  const onGenerate2faQRCode = useCallback(
    (variables: { password: string }) => {
      const { password } = variables;
      return generate2faQrCode({
        variables: {
          input: {
            password,
          },
        },
      })
        .then(() => {
          toast.success<string>(t("profile:setup.2fa.initialized"));

          methods.reset({ password: "" });
          setShow(false);
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [generate2faQrCode, t, methods, setShow],
  );

  const [enable2faAuth] = useMutation<{
    enable2faAuth: { enabled: boolean };
  }>(MUTATION_ENABLE_2FA_AUTH, {
    refetchQueries: [{ query: QUERY_CURRENT_USER }],
  });

  const onEnable2faAuth = useCallback(
    (variables: { code: string }) => {
      const { code } = variables;
      return enable2faAuth({
        variables: {
          input: {
            code,
          },
        },
      })
        .then(() => {
          resetQrCode();
          toast.success<string>(t("profile:setup.2fa.code.valid"));
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [enable2faAuth, t, resetQrCode],
  );

  const [disable2faAuth] = useMutation<{
    disable2faAuth: { enabled: boolean };
  }>(MUTATION_DISABLE_2FA_AUTH, {
    refetchQueries: [{ query: QUERY_CURRENT_USER }],
  });

  const onDisable2faAuth = useCallback(
    (variables: { password: string }) => {
      const { password } = variables;
      return disable2faAuth({
        variables: {
          input: {
            password,
          },
        },
      })
        .then(() => {
          resetQrCode();

          methods.reset({ password: "" });
          setShow(false);

          toast.success<string>(t("user:user.toast.updated"));
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [disable2faAuth, resetQrCode, t, methods, setShow],
  );

  const toggle2faAuth = useCallback(() => {
    if (user?.enabled2fa) {
      return onDisable2faAuth(methods.getValues());
    }
    return onGenerate2faQRCode(methods.getValues());
  }, [user, methods, onDisable2faAuth, onGenerate2faQRCode]);

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("profile:nav.profile")}
          </li>
        </ol>
      </nav>

      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <NavLink exact className="nav-link" to="/profile/settings">
            {t("profile:tab.profile")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink exact className="nav-link" to="/profile/setup-2fa">
            {t("activity:tab.twofactor")}
          </NavLink>
        </li>
      </ul>
      <div>
        <h2>{t("profile:setup.2fa.title")}</h2>
        {!!user?.enabled2fa && (
          <>
            <p>
              <Trans i18nKey="profile:setup.2fa.enabled" />
            </p>
            <Button onClick={setShow}>{t("common:disable")}</Button>
          </>
        )}
        {!user?.enabled2fa && (
          <>
            <p>
              <Trans i18nKey="profile:setup.2fa.disabled" />
            </p>
            <p>
              <Trans i18nKey="profile:setup.2fa.protectText" />
            </p>
            {!qrCode?.generate2faQrCode?.qrCode && (
              <Button onClick={setShow}>{t("common:enable")}</Button>
            )}
          </>
        )}
        {qrCode?.generate2faQrCode?.qrCode && (
          <>
            <img
              src={qrCode?.generate2faQrCode?.qrCode}
              alt="QR Code"
              className="img-fluid"
              width={200}
              height={200}
            />
            <FormProvider {...methods2faCode}>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="title">
                      {t("profile:setup.2fa.code.label")}
                    </label>
                    <Input type="number" name="code" />
                  </div>
                </div>
              </div>
              <Button
                variant="primary"
                onClick={methods2faCode.handleSubmit(onEnable2faAuth)}
              >
                {t("common:submit")}
              </Button>
            </FormProvider>
          </>
        )}
      </div>
      <FormProvider {...methods}>
        <Modal
          show={show}
          onHide={() => {
            methods.reset({ password: "" });
            setShow(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("profile:setup.2fa.enterPassword")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <p>{t("profile:setup.2fa.passwordText")}</p>
              <Input
                name="password"
                type="password"
                autoComplete="new-password"
                rules={{
                  required: {
                    value: true,
                    message: "Password is required",
                  },
                }}
              />
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                methods.reset({ password: "" });
                setShow(false);
              }}
            >
              {t("common:cancel")}
            </Button>
            <Button
              variant="danger"
              onClick={methods.handleSubmit(toggle2faAuth)}
            >
              {t("common:submit")}
            </Button>
          </Modal.Footer>
        </Modal>
      </FormProvider>
    </div>
  );
});

export default Settings2faRouter;
