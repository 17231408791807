import React from "react";
import { useController, ControllerProps } from "react-hook-form";

import clsx from "clsx";

import { useQuery } from "@apollo/client";

import { QUERY_LOCALE } from "../config/graphql/query";

type Ref = HTMLInputElement;

interface StaticComponents
  extends React.ForwardRefExoticComponent<Props & React.RefAttributes<Ref>> {
  Translatable: React.FC<Props>;
}

interface Props
  extends React.InputHTMLAttributes<HTMLInputElement>,
    Pick<ControllerProps, "rules"> {
  name: string;
}

// @ts-ignore
const Input: React.FC<Props> & StaticComponents = React.forwardRef<Ref, Props>(
  (props: Props, ref) => {
    const { name, rules, ...restOfProps } = props;

    const {
      field,
      fieldState: { error },
    } = useController({
      name,
      rules,
    });

    return (
      <>
        <input
          {...restOfProps}
          {...field}
          ref={ref}
          className={clsx("form-control", {
            "is-invalid": !!error,
          })}
        />
        {!!error && <div className="invalid-feedback">{error?.message}</div>}
      </>
    );
  },
);

const Translatable: React.FC<Props> = React.forwardRef<Ref, Props>(
  (props: Props, ref) => {
    const { name } = props;

    const {
      field,
      fieldState: { error },
    } = useController({
      name,
    });

    const { data } = useQuery(QUERY_LOCALE);

    const locale = data?.locale ?? "en";

    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text" id={`translate-${name}`}>
            {`${locale}`}
          </span>
        </div>
        <input
          {...props}
          {...field}
          ref={ref}
          aria-describedby={`translate-${name}`}
          className={clsx("form-control", {
            "is-invalid": !!error,
          })}
        />
        {!!error && <div className="invalid-feedback">{error?.message}</div>}
      </div>
    );
  },
);

Input.Translatable = Translatable;

export default Input;
