import React, { useMemo, useCallback, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";

import { useTranslation } from "react-i18next";
import * as yup from "yup";

import get from "lodash/get";
import toLower from "lodash/toLower";
import startCase from "lodash/startCase";
import findIndex from "lodash/findIndex";

import { toast } from "react-toastify";
import { useRouteMatch, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { gql, useMutation, useQuery } from "@apollo/client";

/* components */
import Input from "../../../../components/Input";
import {
  UploadInput,
  UploadPreview,
  useFiles,
} from "../../../../components/FileUpload";

/* const */
import { EntityTypes, EntityType } from "../../../../config/const/entity";

/* query */
import { QUERY_ENTITIES, QUERY_ENTITY } from "../../../../config/graphql/query";
import { FRAGMENT_ENTITY } from "../../../../config/graphql/fragment";

/* context */
import { useEntity, useEntityParent } from "../../../../context/Entity";

import FloorForm from "./Floor";
import BuildingForm from "./Building";
import RoleBlock from "../../../../components/RoleBlock";
import PlanBlock from "../../../../components/PlanBlock";

interface FieldValues {
  title: string;
  type: string;
  parent?: string;
}

const EntityRoute = React.memo(() => {
  const [show, setShow] = useToggle(false);
  const [files, { onUpload: onUploadLogo }] = useFiles("logo");

  const { t } = useTranslation(["entities", "common"]);

  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const history = useHistory();

  const entity = useEntity();
  const parent = useEntityParent();

  const sibling = entity?.entities?.[0];

  const schema = useMemo(
    () =>
      yup.object().shape({
        type: yup.string(),
        title: yup
          .string()
          .required(t("entities:entity.entityRoute.yup.title")),
        // ...(type === "BUILDING" && {
        //   address: yup.string().default(""),
        //   city: yup.string().default(""),
        //   zipCode: yup.string().default(""),
        //   country: yup.string().default(""),
        // }),
        // ...(type === "FLOOR" && {
        //   fileId: yup.number(),
        // }),
      }),
    [t],
  );

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: { type: entity?.type || EntityType.Building },
  });

  const { data: entityData } = useQuery(QUERY_ENTITY, {
    skip: !id,
    variables: {
      id,
    },
  });

  useEffect(() => {
    if (entityData?.entity) {
      methods.reset(entityData?.entity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityData?.entity]);

  const watchType = methods.watch("type");

  const options: EntityType[] = useMemo(() => {
    const parentType = parent?.type;
    const siblingType = sibling?.type;

    let index = -1;

    if (siblingType && !id) {
      index = findIndex(EntityTypes, (v) => v === siblingType);
    } else if (parentType) {
      index = findIndex(EntityTypes, (v) => v === parentType);

      if (index !== -1 && !id) {
        index = +1;
      }
    }

    if (index === -1) {
      return EntityTypes;
    }

    return EntityTypes.slice(index);
  }, [id, parent, sibling]);

  useEffect(() => {
    if (!id && options && options.length) {
      methods.reset({ type: options[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, id]);

  const mutationDelete = useMemo(() => {
    const typeString = startCase(toLower(watchType));
    const mutationType = "delete";

    return gql`
      mutation ${mutationType}${typeString}($id: ID!) {
        ${mutationType}${typeString}(id: $id) {
          ...FragmentEntity
        }
      }
      ${FRAGMENT_ENTITY}
  `;
  }, [id, watchType]);

  const mutation = useMemo(() => {
    const typeString = startCase(toLower(watchType));
    const mutationType = id ? "update" : "add";

    return gql`
      mutation ${mutationType}${typeString}($input: ${startCase(
      mutationType,
    )}${typeString}Input!) {
        ${mutationType}${typeString}(input: $input) {
          ...FragmentEntity
        }
      }
      ${FRAGMENT_ENTITY}
  `;
  }, [id, watchType]);

  const [onSave] = useMutation(mutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: QUERY_ENTITIES,
        variables: {
          filter: {
            parent: parent?.id || null,
          },
        },
      },
    ],
  });

  const [onDelete] = useMutation(mutationDelete, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: QUERY_ENTITIES,
        variables: {
          filter: {
            parent: parent?.id || null,
          },
        },
      },
    ],
  });

  const onRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      return onDelete({ variables: { id: entity.id } })
        .then(() => {
          history.replace(history.location.pathname.replace(`/${id}`, ""));
          toast.success<string>(t("entities:entity.entityRoute.toast.deleted"));
        })
        .then(() => {
          setShow();
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [onDelete, entity.id, id, history, setShow, t],
  );

  const onBeforeRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      setShow(true);
    },
    [setShow],
  );

  const onSubmit = useCallback(
    async (variables: FieldValues) => {
      const { type: _type, ...values } = variables;

      let input: any = {
        ...values,
      };

      if (parent?.id) {
        input = {
          ...input,
          parent: parent.id,
        };
      }

      if (
        Array.isArray(files) &&
        files.length > 0 &&
        entity.type === "Building"
      ) {
        const uploadedFiles = await onUploadLogo();
        if (uploadedFiles && uploadedFiles[0]) {
          input = {
            ...input,
            logo: uploadedFiles?.[0].file.id,
          };
        }
      }

      if (id) {
        input = {
          ...input,
          id,
        };
      }

      onSave({ variables: { input } })
        .then(({ data }) => {
          if (!id) {
            const createdEntityId = get(
              data,
              `add${startCase(toLower(watchType))}.id`,
            );

            history.replace(
              history.location.pathname.replace(
                "new",
                `${createdEntityId}/view`,
              ),
            );

            return toast.success<string>(
              t("entities:entity.entityRoute.toast.created"),
            );
          }

          return toast.success<string>(
            t("entities:entity.entityRoute.toast.updated"),
          );
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [id, files, parent, entity, watchType, history, onSave, onUploadLogo, t],
  );

  return (
    <>
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-12 col-md-8 col-xl-6">
            <PlanBlock include={["enterprise"]}>
              <div className="form-group">
                <label htmlFor="type">
                  {t("entities:entity.entityRoute.form.type")}
                </label>
                <select
                  {...methods.register("type")}
                  name="type"
                  className="custom-select"
                  disabled={!!id}
                >
                  {options.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </PlanBlock>
            <div className="form-group">
              <label htmlFor="title">
                {t("entities:entity.entityRoute.form.title")}
              </label>
              <Input.Translatable
                name="title"
                className="form-control"
                autoFocus={!id}
              />
            </div>
            {watchType === EntityType.Building && (
              <div className="form-group">
                <BuildingForm />
              </div>
            )}

            {watchType === EntityType.Floor && <FloorForm />}
          </div>
          <div className="col-12 col-md-4 col-xl-2">
            {watchType === EntityType.Building && (
              <div className="form-group">
                <label htmlFor="logo">
                  {t("entities:entity.entityRoute.form.logo")}
                </label>
                <UploadInput name="logo" />
                <UploadPreview
                  name="logo"
                  placeholder={entityData?.entity?.logo?.absolutePath}
                  //   placeholder={entityData?.logo?.absolutePath}
                />
              </div>
            )}
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
            <RoleBlock roles={["ADMIN", "CLIENT_ADMIN"]}>
              {id && (
                <button
                  className="btn btn-danger ml-3"
                  onClick={onBeforeRemove}
                >
                  {t("entities:entity.entityRoute.form.delete")}
                </button>
              )}
            </RoleBlock>
          </div>
        </form>
      </FormProvider>
      <Modal show={show} onHide={setShow} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("entities:entity.entityRoute.modal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("entities:entity.entityRoute.modal.body")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setShow}>
            {t("common:cancel")}
          </Button>
          <Button variant="danger" onClick={onRemove}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default EntityRoute;
