import { Controller } from "react-hook-form";

export const EmailPreferencesController = (props: {
  name: string;
  label: string;
  disabled?: boolean;
}) => {
  const { name, label, disabled } = props;

  return (
    <Controller
      name={name}
      render={({ field: { value, name, onChange }, fieldState: { error } }) => (
        <div className="form-group form-check mb-3">
          <input
            id={name}
            type="checkbox"
            className="form-check-input"
            checked={value}
            onChange={onChange}
            disabled={disabled}
          />
          <label className="form-check-label user-select-none" htmlFor={name}>
            {label}
          </label>
          {!!error && <div className="invalid-feedback">{error.message}</div>}
        </div>
      )}
    />
  );
};
