import React from "react";

import clsx from "clsx";

import {
  Link,
  Switch,
  NavLink,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { useQuery } from "@apollo/client";

import { useTranslation } from "react-i18next";

import { QUERY_BOARD_DATA } from "../../../config/graphql/query";

import Route from "../../../components/Route";
import RoleBlock from "../../../components/RoleBlock";

import PathsRouter from "./Paths";
import ItemsRouter from "./Items";
import SettingsRoute from "./Settings";
import InformationRoute from "./Information";

const ScreenRoute = React.memo(() => {
  const { url, path } = useRouteMatch();

  const { t } = useTranslation(["screens", "common"]);

  const { screenId } = useParams<{ screenId: string }>();

  const { data } = useQuery(QUERY_BOARD_DATA, {
    skip: !screenId,
    variables: { id: screenId },
    nextFetchPolicy: "network-only",
  });

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/nameboards">{t("nameboards:title")}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {data?.nameboard?.title ?? t("nameboard:title")}
          </li>
        </ol>
      </nav>

      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <NavLink exact className="nav-link" to={url}>
            {t("nameboard:tab:information")}
          </NavLink>
        </li>

        <RoleBlock roles={["ADMIN", "CLIENT", "CLIENT_ADMIN"]}>
          <li className="nav-item">
            <NavLink
              className={clsx("nav-link", {
                disabled: !screenId,
              })}
              to={`${url}/settings`}
            >
              {t("nameboard:tab:settings")}
            </NavLink>
          </li>
        </RoleBlock>

        <RoleBlock roles={["ADMIN", "CLIENT_ADMIN"]}>
          <li className="nav-item">
            <NavLink
              className={clsx("nav-link", {
                disabled: !screenId,
              })}
              to={`${url}/item`}
            >
              {t("nameboard:tab:items")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`${url}/path`}>
              {t("nameboard:tab:paths")}
            </NavLink>
          </li>
        </RoleBlock>
      </ul>

      <div className="tab-content">
        <Switch>
          <Route.Base exact path={path}>
            <InformationRoute />
          </Route.Base>
          <Route.Base path={`${path}/item`}>
            <ItemsRouter />
          </Route.Base>
          <Route.Role
            path={`${path}/settings`}
            roles={["ADMIN"]}
            redirect={`/nameboards/${screenId}`}
          >
            <SettingsRoute />
          </Route.Role>
          <Route.Role
            path={`${path}/path`}
            roles={["ADMIN", "CLIENT_ADMIN"]}
            redirect={`/nameboards/${screenId}`}
          >
            <PathsRouter />
          </Route.Role>
        </Switch>
      </div>
    </div>
  );
});

export default ScreenRoute;
