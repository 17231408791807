import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  useRouteMatch,
  Route,
  Switch,
  useLocation,
  Link,
  useHistory,
} from "react-router-dom";

import qs from "query-string";
import dayjs from "dayjs";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import useDebounce from "react-use/lib/useDebounce";

import Button from "react-bootstrap/Button";
import Select from "react-select";
import DatePicker from "react-datepicker";

import { QUERY_ERROR_LOGS } from "../../config/graphql/query";

import ErrorLog from "./ErrorLog/index";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";

import { reactSelectCustomStyles } from "../Employees/Employee/Information";

const LogErrorTypes = ["error", "rejection"] as const;

type TLogErrorType = typeof LogErrorTypes[number];

const limit = 10;

const TableRow = ({ item }: { item: IErrorLog }) => {
  const { id, createdAt, errorMessage, level } = item;

  const { t } = useTranslation(["errorLogs", "common"]);

  return (
    <>
      <tr>
        <th scope="row" className="text-nowrap">
          {dayjs(createdAt).local().format("DD-MM-YYYY / HH:mm")}
        </th>
        <td>{errorMessage}</td>
        <td className="text-nowrap">
          <div className="d-flex">
            <Link to={`errorLogs/${level}/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
    </>
  );
};

const ErrorsLog = React.memo(() => {
  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation(["errorLogs", "common"]);

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
        startDate?: Date;
        endDate?: Date;
        logErrorType?: TLogErrorType;
      },
    [location.search],
  );
  const [search, setSearch] = useState(query.search);

  const [startDate, setStartDate] = useState(
    query.startDate
      ? new Date(dayjs(query.startDate).local().format())
      : new Date(dayjs().subtract(3, "day").local().format()),
  );
  const [endDate, setEndDate] = useState(
    query.endDate
      ? new Date(dayjs(query.endDate).local().format())
      : new Date(),
  );

  const [dateError, setDateError] = useState(false);

  const [logErrorType, setLogErrorType] = useState<TLogErrorType>(
    query.logErrorType || "error",
  );

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);

  const variables = useMemo(() => {
    const nextVariables = {
      type: logErrorType,
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
      ...(startDate || endDate || search
        ? {
            filter: {
              ...((startDate || endDate) && {
                createdAt: {
                  ...(startDate && {
                    GTE: dayjs(startDate).hour(0).minute(0).second(0).format(),
                  }),
                  ...(endDate && {
                    LTE: dayjs(endDate).hour(23).minute(59).second(59).format(),
                  }),
                },
              }),
              search: search?.toString(),
            },
          }
        : {}),
    };

    return nextVariables;
  }, [page, startDate, endDate, search, logErrorType]);

  const { data } = useQuery<{
    errorLogs?: Array<IErrorLog>;
    errorLogsCount: number;
  }>(QUERY_ERROR_LOGS, {
    fetchPolicy: "network-only",
    variables,
  });

  const logs = data?.errorLogs ?? [];
  const errorsCount = data?.errorLogsCount ?? 0;

  const logErrorTypeOptions = LogErrorTypes.map((type) => ({
    value: type,
    label: type,
  }));

  const renderLog = useCallback(
    (item: IErrorLog) => <TableRow key={item.id} item={item} />,
    [],
  );

  useDebounce(
    () => {
      if (
        search !== undefined ||
        startDate !== undefined ||
        endDate !== undefined ||
        logErrorType !== undefined
      ) {
        history.push({
          search: qs.stringify({
            page: 1,
            ...(search && { search }),
            ...(startDate && { startDate }),
            ...(endDate && { endDate }),
            ...(logErrorType && { logErrorType }),
          }),
        });
      }
    },
    500,
    [search, startDate, endDate],
  );

  useEffect(() => {
    setDateError(false);
    if (dayjs(startDate).isAfter(dayjs(endDate))) {
      setDateError(true);
    }
  }, [startDate, endDate]);

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("errorLogs:errorLogs.nav.errorLogs")}
          </li>
        </ol>
      </nav>
      <div className="d-flex mb-4">
        <div className="d-flex mr-4">
          <input
            className="form-control mr-5"
            placeholder={t("common:search")}
            value={search}
            onChange={({ target: { value } }) => {
              setSearch(value);
            }}
          />
          <div className="mx-2 d-flex align-items-center">
            {t("logs:logs.date.from")}
          </div>
          <DatePicker
            isClearable
            selected={startDate}
            onChange={(date: Date) => {
              setStartDate(date);
              if (date.toDateString() === new Date().toDateString()) {
                setEndDate(date);
              }
            }}
            placeholderText="Start Date"
            dateFormat="dd/MM/yyyy"
            className="form-control"
            todayButton="Today"
          />
        </div>
        <div className="d-flex">
          <div className="mr-2 d-flex align-items-center">
            {t("logs:logs.date.to")}
          </div>
          <DatePicker
            isClearable
            selected={endDate}
            onChange={(date: Date) => {
              setEndDate(date);
            }}
            filterDate={(date) => {
              if (!!startDate) {
                return dayjs(date) >= dayjs(startDate);
              }
              return true;
            }}
            placeholderText="End Date"
            dateFormat="dd/MM/yyyy"
            className={clsx("form-control", {
              "is-invalid": dateError,
            })}
          />
        </div>
        <div className="d-flex ml-5">
          <label htmlFor="roles" className="mb-0 mr-2 align-self-center">
            {t("logs:logs.type")}
          </label>
          <div style={{ width: "13rem" }}>
            <Select
              isClearable
              options={logErrorTypeOptions}
              value={logErrorTypeOptions.find(
                (option) => option.value === logErrorType,
              )}
              onChange={(value) => {
                if (value) {
                  setLogErrorType(value.value);
                }
              }}
              styles={reactSelectCustomStyles(false)}
            />
          </div>
        </div>
      </div>
      <Table>
        <thead>
          <tr>
            <th scope="col">{t("errorLogs:errorLogs.th.created")}</th>
            <th scope="col">{t("errorLogs:errorLogs.th.errorMessage")}</th>
            <th scope="col">{t("errorLogs:errorLogs.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{logs.map(renderLog)}</tbody>
      </Table>
      <Pagination documentsCount={errorsCount} limit={limit} />
    </div>
  );
});

const ErrorsRoute = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ErrorsLog />
      </Route>
      <Route path={`${path}/:type/:id`}>
        <ErrorLog />
      </Route>
    </Switch>
  );
});

export default ErrorsRoute;
