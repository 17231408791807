import React, { useCallback, useEffect, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";
import { useTranslation } from "react-i18next";

import * as yup from "yup";

import get from "lodash/get";
// import find from "lodash/find";

import clsx from "clsx";

import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import {
  useRouteMatch,
  useHistory,
  NavLink,
  Route,
  Link,
  Switch,
} from "react-router-dom";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "../../../components/Input";

import {
  QUERY_AVAILABLE_MINEW_DEVICES,
  QUERY_MINEWS,
  QUERY_MINEW_AVAILABLE_TEMPLATES,
  QUERY_MINEW_FILTERED,
} from "../../../config/graphql/query";
import {
  UPDATE_MINEW,
  CREATE_MINEW,
  DELETE_MINEW,
} from "../../../config/graphql/mutation";

import RoleBlock from "../../../components/RoleBlock";
import MinewItems from "./Items";

interface FieldValues {
  title?: string;
  template?: string;
  device?: string;
  previewUrl?: string;
}

const MinewForm = React.memo(() => {
  const [show, setShow] = useToggle(false);

  const history = useHistory();

  const { t } = useTranslation(["visionect, wayfinding, common, minew"]);

  const { replace } = useHistory();
  const {
    // @ts-ignore
    params: { minewId: id },
  } = useRouteMatch();
  const schema = useMemo(
    () =>
      yup.object().shape({
        title: yup
          .string()
          .required(t("wayfinding:wayfinding.wayfindingForm.yup.title")),
        device: yup
          .string()
          .required(t("wayfinding:wayfinding.wayfindingForm.yup.device")),
        template: yup
          .string()
          .required(t("wayfinding:wayfinding.wayfindingForm.yup.template")),
      }),
    [t],
  );

  const [onSave, { loading: saving }] = useMutation(
    id ? UPDATE_MINEW : CREATE_MINEW,
  );

  const [onDelete] = useMutation(DELETE_MINEW);

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
  });

  const {
    formState: { errors },
  } = methods;

  const { data: minewDevicesData } = useQuery(QUERY_AVAILABLE_MINEW_DEVICES);

  const { data: minewData } = useQuery<{ minew: IMinew }>(
    QUERY_MINEW_FILTERED,
    {
      skip: !(id && !!minewDevicesData),
      variables: { id },
    },
  );

  const devices = useMemo(() => {
    const available = minewDevicesData?.availableMinewDevices ?? [];

    if (minewData?.minew && minewData.minew.device) {
      return [minewData.minew.device, ...available];
    }

    return available;
  }, [minewDevicesData, minewData]);

  const { data: minewTemplatesData } = useQuery<{
    minewAvailableTemplates: string[];
  }>(QUERY_MINEW_AVAILABLE_TEMPLATES);

  const minewAvailableTemplates = useMemo(
    () => minewTemplatesData?.minewAvailableTemplates ?? [],
    [minewTemplatesData],
  );

  useEffect(() => {
    if (minewData?.minew) {
      methods.reset({
        ...minewData?.minew,
        device: minewData?.minew?.device?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minewData]);

  useEffect(() => {
    if (!id && devices) {
      methods.setValue("device", devices[0]?.id);
    }
  }, [devices, id]);

  useEffect(() => {
    if (!id && minewAvailableTemplates) {
      methods.setValue("template", minewAvailableTemplates[0]);
    }
  }, [minewAvailableTemplates, id]);

  const onRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      return onDelete({ variables: { id } })
        .then(() => {
          history.replace("/minew-device");
          toast.success<string>(t("minew:minew.minewForm.toast.deleted"));
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [onDelete, id, history, t],
  );

  const onBeforeRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      setShow(true);
    },
    [setShow],
  );

  const onSubmit = (values: FieldValues) => {
    const input = {
      ...(id ? { id } : {}),
      ...values,
    };

    onSave({
      variables: {
        input,
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: QUERY_MINEWS }],
    })
      .then(({ data }) => {
        if (data?.createMinew?.id) {
          replace(`/minew-device/${data?.createMinew?.id}`);

          toast.success<string>(t("minew:minew.minewForm.toast.created"));
          return;
        }

        toast.success<string>(t("minew:minew.minewForm.toast.updated"));
      })
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );
      });
  };

  const previewUrl = get(minewData, "minew.previewUrl");

  return (
    <>
      <FormProvider {...methods}>
        <div className="tab-pane active" role="tabpanel">
          <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="title">
                  {t("minew:minew.minewForm.form.name")}
                </label>
                <Input name="title" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="device">
                  {t("minew:minew.minewForm.form.device")}
                </label>
                <select
                  {...methods.register("device")}
                  className="custom-select"
                >
                  {devices.map((device: IMinewDevice) => (
                    <option key={device.id} value={device.id}>
                      {device.name}
                    </option>
                  ))}
                </select>
                {!!errors.device && (
                  <div className="invalid-feedback">
                    {errors.device.message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="template">
                  {t(
                    "wayfinding:wayfinding.wayfindingForm.form.template.label",
                  )}
                </label>
                <select
                  {...methods.register("template")}
                  className="custom-select"
                >
                  {minewAvailableTemplates.map((template: string) => (
                    <option key={template} value={template}>
                      {t(`${template}`)}
                    </option>
                  ))}
                </select>
                {!!errors.template && (
                  <div className="invalid-feedback">
                    {errors.template.message}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-8 row pb-3">
              <div className="col-6">
                <h3>{t("minew:minew.minewForm.form.preview.h3")}</h3>
                <div className="border border-dark embed-responsive embed-responsive-10by6">
                  <iframe
                    title="preview"
                    className="embed-responsive-item"
                    // src={`data:image/jpeg;base64,${watchDeviceId}`}
                    src={previewUrl}
                  />
                </div>
                <sup>
                  <strong>
                    {t(
                      "wayfinding:wayfinding.wayfindingForm.form.preview.notice",
                    )}
                  </strong>
                </sup>
              </div>
            </div>

            <div className="col-12">
              <input
                type="submit"
                className="btn btn-primary"
                disabled={saving}
              />
              <RoleBlock roles={["ADMIN"]}>
                {id && (
                  <button
                    onClick={onBeforeRemove}
                    className="btn btn-danger ml-3"
                  >
                    {t("common:delete")}
                  </button>
                )}
              </RoleBlock>
            </div>
          </form>
        </div>
      </FormProvider>
      <Modal show={show} onHide={setShow} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t("minew:minew.minewForm.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("minew:minew.minewForm.modal.body")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setShow}>
            {t("common:cancel")}
          </Button>
          <Button variant="danger" onClick={onRemove}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

const MinewPage = React.memo(() => {
  const {
    url,
    path,
    params: { minewId: id },
  } = useRouteMatch<{ minewId: string }>();

  const { t } = useTranslation(["minew"]);

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/minew-device">
              {t("minew:minew.minewPage.nav.minew")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("minew:minew.minewPage.nav.item")}
          </li>
        </ol>
      </nav>

      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <NavLink exact className="nav-link" to={url}>
            {t("minew:minew.minewPage.nav.information")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              // @ts-ignore
              disabled: !id,
            })}
            to={`${url}/item`}
          >
            {t("minew:minew.minewPage.nav.items")}
          </NavLink>
        </li>
      </ul>

      <div className="tab-content">
        <Switch>
          <Route exact path={path}>
            <MinewForm />
          </Route>
          <Route path={`${path}/item`}>
            <MinewItems />
          </Route>
        </Switch>
      </div>
    </div>
  );
});

export default MinewPage;
