import React from "react";
import { useQuery } from "@apollo/client";
import { useParams, Link } from "react-router-dom";

import map from "lodash/map";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import { QUERY_ERROR_LOG } from "../../../config/graphql/query";

const ErrorLog = React.memo(() => {
  const { id, type } = useParams<{ id: string; type: LevelErrorType }>();

  const { t } = useTranslation(["errorLogs", "common"]);

  const { data } = useQuery<{ errorLog?: IErrorLog }>(QUERY_ERROR_LOG, {
    skip: !id,
    variables: { id, type },
  });

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/errorLog">{t("errorLogs:errorLog.nav.errorLogs")}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("errorLogs:errorLog.nav.errorLog")}
          </li>
        </ol>
      </nav>
      <Card className="mb-3 p-3">
        {t("errorLogs:errorLog.card.created")}
        {dayjs(data?.errorLog?.createdAt)
          .local()
          .format("DD-MM-YYYY / HH:mm") ?? ""}
      </Card>
      <Card className="mb-3 p-3">
        {t("errorLogs:errorLog.card.errorMessage")}
        {data?.errorLog?.errorMessage ?? ""}
      </Card>
      <Card className="mb-3 p-3">IP: {data?.errorLog?.ip ?? ""}</Card>

      {data?.errorLog?.user && (
        <>
          <Card className="mb-3 p-3">
            {t("errorLogs:errorLog.card.firstName")}
            {data?.errorLog?.user?.firstName ?? ""}
          </Card>
          <Card className="mb-3 p-3">
            {t("errorLogs:errorLog.card.lastName")}
            {data?.errorLog?.user?.lastName ?? ""}
          </Card>
        </>
      )}

      <Accordion defaultActiveKey="0" className="mb-3">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            {t("errorLogs:errorLog.card.endpoint")}
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="0">
            <Card.Body>{data?.errorLog?.endpoint ?? ""}</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            {t("errorLogs:errorLog.card.rawHeaders")}
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="1">
            <Card.Body>
              {map(
                JSON.parse(data?.errorLog?.rawHeaders ?? "[]"),
                (header, index: number) => (
                  <p className={index % 2 === 0 ? "mb-0" : ""}>{header}</p>
                ),
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            {t("errorLogs:errorLog.card.payload")}
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <pre>
                {JSON.stringify(data?.errorLog?.payload ?? "{}", null, 2)}
              </pre>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            {t("errorLogs:errorLog.card.errorStack")}
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="3">
            <Card.Body>{data?.errorLog?.errorStack ?? ""}</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
});

export default ErrorLog;
