import React from "react";

import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import Minew from "./Minew";
import Minews from "./Minews";

const MinewsRouter = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Minews />
      </Route>
      <Route exact path={`${path}/new`}>
        <Minew />
      </Route>
      <Route path={`${path}/:minewId`}>
        <Minew />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default MinewsRouter;
