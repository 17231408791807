import { useCallback, useEffect, useRef } from "react";

import { useQuery } from "@apollo/client";

import { QUERY_AUTH } from "../../config/graphql/query";

const API_URL = process.env.REACT_APP_API_URL as string;

const [protocol, host] = API_URL.split("//");

const BASE_URL = `${protocol === "https:" ? "wss" : "ws"}://${host}`;

type EventParams<T> = {
  open?: () => void;
  close?: () => void;
  message?: (message: T) => void;
};

const useSocket = <T,>(url: string, events?: EventParams<T>) => {
  const {
    // @ts-ignore
    data: { clientId, token },
  } = useQuery(QUERY_AUTH);

  const socket = useRef<WebSocket>();

  const timeout = useRef<NodeJS.Timeout>();

  const start = useCallback(() => {
    if (!(url && token && clientId)) {
      return;
    }

    socket.current = new WebSocket([BASE_URL, url].join(""), [token, clientId]);

    socket.current.onmessage = (error) => {
      let message: any;

      try {
        message = JSON.parse(error.data);
      } catch {
        message = error.data;
      }

      events?.message?.(message);
    };

    socket.current.onopen = () => {
      events?.open?.();
    };

    socket.current.onclose = () => {
      timeout.current = setTimeout(() => {
        clearTimeout(timeout.current);
        timeout.current = undefined;

        start();
      }, 2000);

      events?.close?.();
    };
  }, [clientId, events, token, url]);

  useEffect(() => {
    // prettier-ignore
    start()

    return () => {
      if (socket.current && socket.current.readyState === socket.current.OPEN) {
        socket.current.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, clientId, token]);

  return socket.current;
};

type UploadedFile = {
  filename: string;
  id: string;
  md5: string;
  path: string;
};

type UploadResponse = {
  file: UploadedFile;
  error: boolean;
  message: string;
};

export const upload = (
  files: { file: string; filename: string }[],
): Promise<UploadedFile[]> => {
  return new Promise((resolve, reject) => {
    const uploadedFiles: UploadedFile[] = [];

    const socket = new WebSocket(`${BASE_URL}/upload`, [
      window.localStorage.getItem("Authorization") ?? "",
      window.localStorage.getItem("Client") ?? "",
    ]);

    socket.onopen = function () {
      files.forEach((file) => {
        socket.send(JSON.stringify(file));
      });
    };

    socket.onmessage = function (e) {
      let message: UploadResponse | undefined;

      try {
        message = JSON.parse(e.data);
      } catch (error) {
        reject(error);
        return;
      }

      if (message?.file) {
        uploadedFiles.push(message?.file);
      }

      if (uploadedFiles.length !== files.length) {
        return;
      }

      socket.close();

      resolve(uploadedFiles);
    };

    socket.onerror = function (error) {
      socket.close();

      reject(error);
    };
  });
};

export default useSocket;
