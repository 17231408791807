import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo } from "react";

import clsx from "clsx";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useToggle } from "react-use";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useParams, useHistory, Link } from "react-router-dom";

import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Table from "../../../../../components/Table";

import Input from "../../../../../components/Input";

import {
  MUTATION_CREATE_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT,
  MUTATION_DELETE_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT,
  MUTATION_DELETE_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT_FILE,
  MUTATION_UPDATE_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT,
} from "../../../../../config/graphql/mutation";

import { QUERY_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT } from "../../../../../config/graphql/query";

const TableRow = ({
  item,
  screenId,
  agreementId,
}: {
  item: IEntranceAgreementFile;
  screenId: string;
  agreementId: string;
}) => {
  const { id, title, text, approvalRequired, disabled } = item;

  const [visible, setVisible] = useToggle(false);

  const { t } = useTranslation(["digitalReceptionEntranceAgreement", "common"]);

  const [onDelete] = useMutation(
    MUTATION_DELETE_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT_FILE,
    {
      refetchQueries: [
        {
          query: QUERY_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT,
          variables: { id: screenId },
        },
      ],
      variables: { id },
    },
  );

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr>
        <th scope="row">{title}</th>
        <td>{text}</td>
        <td>{approvalRequired.toString()}</td>
        <td>{disabled.toString()}</td>
        <td className="text-nowrap">
          <div className="d-flex">
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>

            <Link
              to={`/digital-receptions/${screenId}/settings/entrance-agreements/${agreementId}/files/${id}`}
            >
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            {t(
              "digitalReceptionEntranceAgreement:entranceAgreementFiles.modal.title",
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(
            "digitalReceptionEntranceAgreement:entranceAgreementFiles.modal.body",
            {
              title: item.title,
            },
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface FieldValues {
  title: string;
  disabled: boolean;
  showFirst: boolean;
  validFrom?: Date;
  validUntil?: Date;
}

const EntranceAgreement = React.memo(() => {
  const [visible, setVisible] = useToggle(false);

  const history = useHistory();

  const { t } = useTranslation(["digitalReceptionEntranceAgreement", "common"]);

  const { screenId, agreementId } = useParams<{
    screenId: string;
    agreementId?: string;
  }>();

  const schema = useMemo(() => {
    return yup.object().shape({
      title: yup
        .string()
        .required(
          t("digitalReceptionEntranceAgreement:entranceAgreement.yup.title"),
        ),
      disabled: yup.boolean().required(),
      showFirst: yup.boolean().required(),
      validFrom: yup
        .date()
        .transform((v, o) => (o === null ? undefined : v))
        .test(
          "allow",
          t("digitalReceptionEntranceAgreement:entranceAgreement.yup.invalid"),
          function validate(value) {
            if (value instanceof Date) {
              return dayjs(value).isAfter(dayjs(new Date(Date.now())));
            }
            if (!value) {
              return true;
            }
            return false;
          },
        ),
      validUntil: yup
        .mixed()
        .transform((v, o) => (o === null ? undefined : v))
        .test(
          "allow",
          t("digitalReceptionEntranceAgreement:entranceAgreement.yup.invalid"),
          function validate(value) {
            if (value instanceof Date) {
              return (
                this.parent.validFrom &&
                dayjs.utc(value).isAfter(dayjs(this.parent.validFrom))
              );
            }
            return true;
          },
        ),
    });
  }, [t]);

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      title: "",
      disabled: false,
      showFirst: true,
      validFrom: undefined,
      validUntil: undefined,
    },
  });

  const { data: entranceAgreementData, loading } = useQuery<{
    digitalReception: { entranceAgreement: IEntranceAgreement };
  }>(QUERY_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT, {
    variables: {
      id: screenId,
    },
    skip: !screenId,
  });

  const entranceAgreementFiles = useMemo(
    () =>
      entranceAgreementData?.digitalReception?.entranceAgreement?.files ?? [],
    [entranceAgreementData],
  );

  useEffect(() => {
    if (entranceAgreementData?.digitalReception?.entranceAgreement) {
      const {
        digitalReception: {
          entranceAgreement: { validFrom, validUntil, ...rest },
        },
      } = entranceAgreementData;

      methods.reset({
        ...rest,
        validFrom: validFrom ? new Date(validFrom) : undefined,
        validUntil: validUntil ? new Date(validUntil) : undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entranceAgreementData]);

  const [onUpdate] = useMutation(
    MUTATION_UPDATE_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT,
    {
      refetchQueries: [
        {
          query: QUERY_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT,
          variables: { id: screenId },
        },
      ],
    },
  );
  const [onCreate] = useMutation(
    MUTATION_CREATE_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT,
    {
      refetchQueries: [
        {
          query: QUERY_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT,
          variables: { id: screenId },
        },
      ],
    },
  );
  const [onDelete] = useMutation(
    MUTATION_DELETE_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT,
    {
      refetchQueries: [
        {
          query: QUERY_DIGITAL_RECEPTION_ENTRANCE_AGREEMENT,
          variables: { id: screenId },
        },
      ],
    },
  );

  const onBeforeDelete = useCallback(() => {
    return onDelete({
      variables: {
        id: agreementId,
      },
    })
      .then(() => {
        history.replace(`/digital-receptions/${screenId}/settings`);
        toast.success<string>(
          t(
            "digitalReceptionEntranceAgreement:entranceAgreement.toast.deleted",
          ),
        );
      })
      .catch(() => {
        toast.error<string>(
          t(
            "digitalReceptionEntranceAgreement:entranceAgreement.toast.deletedError",
          ),
        );
      });
  }, [history, onDelete, agreementId, screenId, t]);

  const onSubmit = useCallback(
    (values: FieldValues) => {
      const { title, disabled, showFirst, validFrom, validUntil } = values;

      const input = {
        title,
        disabled,
        showFirst,
        validFrom: validFrom ?? null,
        validUntil: validUntil ?? null,
      };

      if (!!agreementId) {
        return onUpdate({
          variables: {
            input: {
              id: agreementId,
              ...input,
            },
          },
        })
          .then(() => {
            toast.success<string>(
              t(
                "digitalReceptionEntranceAgreement:entranceAgreement.toast.updated",
              ),
            );
          })
          .catch(() => {
            toast.error<string>(
              t(
                "digitalReceptionEntranceAgreement:entranceAgreement.toast.updatedError",
              ),
            );
          });
      }

      return onCreate({
        variables: {
          input: {
            ...input,
            screen: screenId,
          },
        },
      })
        .then(
          ({
            data: {
              createEntranceAgreement: { id: entranceAgreementId },
            },
          }) => {
            toast.success<string>(
              t(
                "digitalReceptionEntranceAgreement:entranceAgreement.toast.created",
              ),
            );

            history.replace(
              `/digital-receptions/${screenId}/settings/entrance-agreements/${entranceAgreementId}`,
            );
          },
        )
        .catch(() => {
          toast.error<string>(
            t(
              "digitalReceptionEntranceAgreement:entranceAgreement.toast.createdError",
            ),
          );
        });
    },
    [history, screenId, onUpdate, onCreate, agreementId, t],
  );

  return (
    <div className="container-fluid">
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="form-group">
              <label htmlFor="title">
                {t(
                  "digitalReceptionEntranceAgreement:entranceAgreement.form.title",
                )}
              </label>
              <Input
                name="title"
                className="form-control"
                disabled={methods.watch("disabled")}
              />
            </div>
            <Controller
              name="showFirst"
              render={({ field: { value, name, onChange, ...rest } }) => {
                return (
                  <div className="form-check my-3">
                    <input
                      id={name}
                      {...rest}
                      type="checkbox"
                      className="form-check-input"
                      checked={value === true}
                      onChange={() => onChange(!value)}
                      disabled={methods.watch("disabled")}
                    />
                    <label className="form-check-label" htmlFor={name}>
                      {t(
                        "digitalReceptionEntranceAgreement:entranceAgreement.form.showFirst",
                      )}
                    </label>
                  </div>
                );
              }}
            />
            <div>
              <label htmlFor="validFrom">
                {t(
                  "digitalReceptionEntranceAgreement:entranceAgreement.form.validFrom",
                )}
              </label>
              <div className="mb-2">
                <Controller
                  name="validFrom"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <div
                        className={clsx("", {
                          "is-invalid": !!error,
                        })}
                      >
                        <DatePicker
                          className={clsx("form-control", {
                            "is-invalid": !!error,
                          })}
                          // showTimeSelect
                          selected={value}
                          onChange={onChange}
                          filterDate={(date) =>
                            dayjs() <= dayjs(date).add(1, "day")
                          }
                          // filterTime={(time) => dayjs() <= dayjs(time)}
                          // timeIntervals={30}
                          // dateFormat="dd/MM/yyyy p"
                          dateFormat="dd/MM/yyyy"
                          shouldCloseOnSelect
                          disabled={methods.watch("disabled")}
                        />
                      </div>

                      <div className="invalid-feedback">
                        {error?.message ?? "Invalid"}
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
            <div>
              <label htmlFor="validUntil">
                {t(
                  "digitalReceptionEntranceAgreement:entranceAgreement.form.validUntil",
                )}
              </label>
              <div>
                <Controller
                  name="validUntil"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <div
                        className={clsx("mb3", {
                          "is-invalid": !!error,
                        })}
                      >
                        <DatePicker
                          className={clsx("form-control", {
                            "is-invalid": !!error,
                          })}
                          // showTimeSelect
                          selected={value}
                          onChange={onChange}
                          filterDate={(date) =>
                            dayjs(methods.getValues("validFrom")) <=
                            dayjs(date).add(1, "day")
                          }
                          // filterTime={(time) =>
                          //   dayjs(methods.getValues("validFrom")) < dayjs(time)
                          // }
                          // timeIntervals={30}
                          // dateFormat="dd/MM/yyyy p"
                          dateFormat="dd/MM/yyyy"
                          shouldCloseOnSelect
                          disabled={methods.watch("disabled")}
                        />
                      </div>

                      <div className="invalid-feedback">
                        {error?.message ?? "Invalid"}
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
            <Controller
              name="disabled"
              render={({ field: { value, name, onChange, ...rest } }) => {
                return (
                  <div className="form-check my-3">
                    <input
                      id={name}
                      {...rest}
                      type="checkbox"
                      className="form-check-input"
                      checked={value === true}
                      onChange={() => onChange(!value)}
                    />
                    <label className="form-check-label" htmlFor={name}>
                      {t(
                        "digitalReceptionEntranceAgreement:entranceAgreement.form.disabled",
                      )}
                    </label>
                  </div>
                );
              }}
            />
            <div className="mb-4">
              <button
                type="submit"
                className="btn btn-primary mt-4"
                disabled={loading}
              >
                {t(
                  `digitalReceptionEntranceAgreement:entranceAgreement.form.button.${
                    !agreementId ? "create" : "update"
                  }`,
                )}
              </button>
              {!!agreementId && (
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();

                    setVisible(true);
                  }}
                  className="btn btn-danger ml-3 mt-4"
                >
                  {t("common:delete")}
                </button>
              )}
            </div>
          </div>
          {!!agreementId && (
            <div className="col-lg-8 col-md-6 col-sm-12">
              <div className="d-flex justify-content-between align-items-center">
                <p>
                  {t(
                    "digitalReceptionEntranceAgreement:entranceAgreementFiles.title",
                    { count: 2 },
                  )}
                </p>
                <Link
                  to={`/digital-receptions/${screenId}/settings/entrance-agreements/${agreementId}/files/new`}
                  type="button"
                  className="btn btn-primary"
                  style={{ marginTop: "-1.5rem" }}
                >
                  {t(
                    "digitalReceptionEntranceAgreement:entranceAgreementFiles.button.add",
                  )}
                </Link>
              </div>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th scope="col">
                      {t(
                        "digitalReceptionEntranceAgreement:entranceAgreementFiles.table.th.title",
                      )}
                    </th>
                    <th scope="col">
                      {t(
                        "digitalReceptionEntranceAgreement:entranceAgreementFiles.table.th.text",
                      )}
                    </th>
                    <th scope="col">
                      {t(
                        "digitalReceptionEntranceAgreement:entranceAgreementFiles.table.th.approvalRequired",
                      )}
                    </th>
                    <th scope="col">
                      {t(
                        "digitalReceptionEntranceAgreement:entranceAgreementFiles.table.th.disabled",
                      )}
                    </th>
                    <th scope="col">
                      {t(
                        "digitalReceptionEntranceAgreement:entranceAgreementFiles.table.th.actions",
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!!entranceAgreementFiles.length &&
                    entranceAgreementFiles.map((item) => (
                      <TableRow
                        key={item.id}
                        item={item}
                        screenId={screenId}
                        agreementId={agreementId}
                      />
                    ))}
                  {!entranceAgreementFiles.length && (
                    <th colSpan={5} scope="row" className="text-center">
                      {t(
                        "digitalReceptionEntranceAgreement:entranceAgreementFiles.table.noFiles",
                      )}
                    </th>
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </form>
      </FormProvider>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            {t(
              "digitalReceptionEntranceAgreement:entranceAgreement.modal.title",
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("digitalReceptionEntranceAgreement:entranceAgreement.modal.body")}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default EntranceAgreement;
