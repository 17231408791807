import React, { useMemo, useEffect } from "react";

import * as yup from "yup";

import { pickBy } from "lodash";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useTranslation } from "react-i18next";

import Input from "../../../components/Input";

import { PropertyType } from "../../../config/const/property";
import { QUERY_PROPERTY_COMPANY_CONTACT } from "../../../config/graphql/query";
import { UPDATE_PROPERTY_COMPANY } from "../../../config/graphql/mutation";

interface FieldValues {
  website?: string;
  email?: string;
  phone?: string;
  fax?: string;
}

const CompanyContactForm = React.memo(() => {
  const { t } = useTranslation(["company", "general"]);

  const {
    // @ts-ignore
    params: { id },
  } = useRouteMatch();

  const schema = useMemo(
    () =>
      yup.object().shape({
        website: yup.string().url(t("company:contact.yup.website")).nullable(),
        email: yup.string().email(t("company:contact.yup.email")).nullable(),
        phone: yup.string().nullable(),
        fax: yup.string().nullable(),
      }),
    [t],
  );

  const [onUpdate] = useMutation(UPDATE_PROPERTY_COMPANY);

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
  });

  const { data: propertyData } = useQuery(QUERY_PROPERTY_COMPANY_CONTACT, {
    skip: !id,
    variables: { id },
  });

  useEffect(() => {
    methods.reset(propertyData?.property);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyData]);

  const onSubmit = (values: FieldValues) => {
    const input = {
      // @ts-ignore
      ...pickBy(values),
      id,
      type: PropertyType.Company,
    };

    return onUpdate({ variables: { input } })
      .then(() => {
        toast.success<string>(t("company:contact.toast.updated"));
      })
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );
      });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="website">
                {t("company:contact.form.website")}
              </label>
              <Input name="website" />
            </div>
            <div className="form-group">
              <label htmlFor="email">{t("company:contact.form.email")}</label>
              <Input type="email" name="email" />
            </div>
            <div className="form-group">
              <label htmlFor="phone">{t("company:contact.form.phone")}</label>
              <Input name="phone" />
              <small className="form-text text-muted">
                {t("company:contact.form.phone-help")}
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="fax">{t("company:contact.form.fax")}</label>
              <Input name="fax" />
              <small className="form-text text-muted">
                {t("company:contact.form.phone-help")}
              </small>
            </div>
          </div>
          <div className="col-lg-4" />
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </FormProvider>
    </>
  );
});

export default CompanyContactForm;
