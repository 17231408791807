import React, { useCallback, useMemo, useState } from "react";

import qs from "query-string";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import map from "lodash/map";
import useToggle from "react-use/lib/useToggle";
import { useTranslation } from "react-i18next";

import { useDebounce } from "react-use";
import { useRouteMatch, Link, useLocation, useHistory } from "react-router-dom";

import { useQuery, useMutation } from "@apollo/client";

import RoleBlock from "../../../../components/RoleBlock";
import Pagination from "../../../../components/Pagination";

import { QUERY_NAMEBOARD_ITEMS } from "../../../../config/graphql/query";
import { DELETE_NAMEBOARD_ITEM } from "../../../../config/graphql/mutation";

const limit = 10;

const TableRow = ({
  item,
  variables,
}: {
  item: INameboardItem;
  variables: any;
}) => {
  const { url } = useRouteMatch();

  const { id, entity, sort } = item;

  const { t } = useTranslation(["screens", "common"]);

  const propertiesTitle = map(entity.properties, "title").join(", ");

  const [visible, setVisible] = useToggle(false);

  const [onDelete] = useMutation(DELETE_NAMEBOARD_ITEM, {
    refetchQueries: [{ query: QUERY_NAMEBOARD_ITEMS, variables }],
    variables: { id },
  });

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr className="">
        <th scope="row">{entity?.title}</th>
        <td>{propertiesTitle}</td>
        <RoleBlock roles={["ADMIN"]}>
          <td>{sort}</td>
        </RoleBlock>
        <td>
          <div className="d-flex">
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>
            <Link to={`${url}/${id}`}>
              <Button
                size="sm"
                variant="primary"
                onClick={() => setVisible(false)}
              >
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("screens:screen.items.items.modal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("screens:screen.items.items.modal.body", { item: item.title })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const BoardItemsRoute = React.memo(() => {
  const history = useHistory();
  const location = useLocation();
  const {
    url,
    params: { screenId },
  }: {
    url: string;
    path: string;
    params: { screenId: string };
  } = useRouteMatch();

  const { t } = useTranslation(["screens", "common"]);

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
      },
    [location.search],
  );

  const [search, setSearch] = useState(query.search);

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);

  const variables = useMemo(
    () => ({
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
      filter: {
        nameboard: screenId,
        ...(query.search
          ? {
              title: query.search.toString(),
            }
          : {}),
        sort: 1,
      },
    }),
    [page, screenId, query.search],
  );

  const { data } = useQuery<{
    nameboardItems: INameboardItem[];
    nameboardItemsCount: number;
  }>(QUERY_NAMEBOARD_ITEMS, {
    variables,
    fetchPolicy: "network-only",
  });

  const items = useMemo(() => data?.nameboardItems ?? [], [data]);

  const itemsCount = data?.nameboardItemsCount ?? 0;

  const renderItem = useCallback(
    (item: INameboardItem) => (
      <TableRow key={`screens-${item.id}`} item={item} variables={variables} />
    ),
    [],
  );

  useDebounce(
    () => {
      history.push({
        search: qs.stringify({
          page: 1,
          ...(search && { search }),
        }),
      });
    },
    500,
    [search],
  );

  return (
    <>
      <div className="d-flex flex-wrap justify-content-between mt-4">
        <form className="form-inline mr-2">
          <div className="mr-5 mb-4">
            <label htmlFor="search" className="sr-only">
              {t("common:search")}
            </label>
            <input
              id="search"
              type="search"
              className="form-control"
              placeholder={t("common:search")}
              value={search}
              onChange={({ target: { value } }) => {
                setSearch(value);
              }}
            />
          </div>
        </form>
        <Link to={`${url}/new`} type="button" className="btn btn-primary mb-4">
          {t("screens:screen.items.items.button.addItem")}
        </Link>
      </div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">{t("screens:screen.items.items.th.entities")}</th>
            <th scope="col">{t("screens:screen.items.items.th.properties")}</th>
            <RoleBlock roles={["ADMIN"]}>
              <th scope="col">{t("screens:screen.items.items.th.sort")}</th>
            </RoleBlock>
            <th scope="col">{t("screens:screen.items.items.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{map(items, renderItem)}</tbody>
      </table>
      <Pagination documentsCount={itemsCount} limit={limit} />
    </>
  );
});

export default BoardItemsRoute;
