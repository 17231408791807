import React, { useCallback, useMemo, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { useDebounce } from "react-use";

import dayjs from "dayjs";
import qs from "query-string";

import Select from "react-select";

import Table from "../../../components/Table";
import Pagination from "../../../components/Pagination";

import {
  QUERY_ACCESS_EVENT_TYPE_LIST,
  QUERY_CHECKED_IN,
  QUERY_MEMBER_GROUPS,
} from "../../../config/graphql/query";
import { TAccessType } from "../AccessEvents/AccessEvents";
import { reactSelectCustomStyles } from "../../Employees/Employee/Information";

import { getOwnerField } from "../AccessEvents/AccessEvent";

const limit = 10;

const TableRow = ({ item }: { item: IAccessEvent }) => {
  const { type, owner, createdAt } = item;

  const { t } = useTranslation(["accessEvent"]);

  const fullName = getOwnerField(owner, "fullName");
  const email = getOwnerField(owner, "email");
  const phone = getOwnerField(owner, "phone");

  return (
    <>
      <tr>
        <td>{fullName}</td>
        <td>{email}</td>
        <td>{phone}</td>
        <td>{t(`accessEvent:type.${type}`)}</td>
        <td>{dayjs(createdAt).local().format("DD-MM-YYYY / HH:mm")}</td>
      </tr>
    </>
  );
};

const CheckedIn = React.memo(() => {
  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation(["checedIn", "common"]);

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
        accessType?: TAccessType;
        memberGroup?: string;
      },
    [location.search],
  );

  const [search, setSearch] = useState(query.search);

  const [accessType, setAccessType] = useState<TAccessType | undefined>(
    query.accessType,
  );

  const [memberGroup, setMemberGroup] = useState<string | undefined>(
    query.memberGroup,
  );

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);

  const variables = useMemo(() => {
    return {
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
      filter: {
        ...(query.search && {
          owner: {
            fullName: query.search.toString(),
            email: query.search.toString(),
          },
        }),
        ...(query.accessType && {
          type: query.accessType,
        }),
        ...(query.memberGroup && {
          memberGroup: query.memberGroup,
        }),
      },
    };
  }, [page, query.search, query.accessType, query.memberGroup]);

  const { data } = useQuery<{
    currentlyCheckedIn?: {
      events: Array<IAccessEvent>;
      eventsCount: number;
    };
  }>(QUERY_CHECKED_IN, {
    fetchPolicy: "network-only",
    variables,
  });

  const accessEvents: Array<IAccessEvent> =
    data?.currentlyCheckedIn?.events ?? [];
  const accessEventsCount: number = data?.currentlyCheckedIn?.eventsCount ?? 0;

  const { data: accessEventTypeListData } = useQuery<{
    accessEventTypeList: TAccessType[];
  }>(QUERY_ACCESS_EVENT_TYPE_LIST);

  const accessEventTypeOptions = useMemo(
    () =>
      accessEventTypeListData?.accessEventTypeList.map((type) => ({
        value: type,
        label: type.split(/(?=[A-Z])/).join(" "),
      })) ?? [],
    [accessEventTypeListData],
  );

  const { data: groupsData } = useQuery<{
    memberGroups: IMemberGroup[];
  }>(QUERY_MEMBER_GROUPS, {
    fetchPolicy: "network-only",
  });

  const groupsSelectOptions = useMemo(
    () =>
      groupsData?.memberGroups.map((group: { id: any; title: any }) => ({
        value: group.id,
        label: group.title,
      })) ?? [],
    [groupsData],
  );

  const renderItem = useCallback(
    (item: IAccessEvent) => <TableRow key={item.id} item={item} />,
    [],
  );

  useDebounce(
    () => {
      history.push({
        search: qs.stringify({
          page: 1,
          ...(search && { search }),
          ...(accessType && { accessType }),
          ...(memberGroup && { memberGroup }),
        }),
      });
    },
    500,
    [search, accessType, memberGroup],
  );

  return (
    <div className="container-fluid">
      <div className="mt-3 mb-4 justify-content-between">
        <p className="my-3">{t("checkedIn:info")}</p>
        <form className="form-inline ">
          <div className="form-group">
            <label htmlFor="search" className="sr-only">
              {t("common:search")}
            </label>
            <input
              id="search"
              type="search"
              className="form-control"
              placeholder={t("common:search")}
              value={search}
              onChange={({ target: { value } }) => {
                setSearch(value);
              }}
            />
          </div>
          <div className="d-flex ml-5">
            <label htmlFor="roles" className="mb-0 mr-2 align-self-center">
              {t("accessEvent:accessEvents.type.label")}
            </label>
            <div style={{ width: "13rem" }}>
              <Select
                isClearable
                options={accessEventTypeOptions}
                value={accessEventTypeOptions.find(
                  (option) => option.value === accessType,
                )}
                onChange={(value) => {
                  setAccessType(value?.value);
                }}
                styles={reactSelectCustomStyles(false)}
              />
            </div>
          </div>

          <div className="form-group ml-5">
            <label htmlFor="groups" className="mb-0 mr-2 align-self-center">
              {t("accessEvent:accessEvents.memberGroup.label")}
            </label>
            <div style={{ width: "13rem" }}>
              <Select
                isClearable
                options={groupsSelectOptions}
                value={groupsSelectOptions.find(
                  (option) => option.value === memberGroup,
                )}
                onChange={(value) => {
                  setMemberGroup(value?.value);
                }}
                styles={reactSelectCustomStyles(false)}
                isDisabled={accessType !== "Employee"}
              />
            </div>
          </div>
        </form>
      </div>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th scope="col">{t("checkedIn:th.fullName")}</th>
            <th scope="col">{t("checkedIn:th.email")}</th>
            <th scope="col">{t("checkedIn:th.phone")}</th>
            <th scope="col">{t("checkedIn:th.type")}</th>
            <th scope="col">{t("checkedIn:th.time")}</th>
          </tr>
        </thead>
        <tbody>{accessEvents.map(renderItem)}</tbody>
      </Table>
      <Pagination documentsCount={accessEventsCount} limit={limit} />
    </div>
  );
});

export default CheckedIn;
