import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import useHasRole from "../../lib/hooks/useHasRole";

type PreferencesFieldValues = {
  preferences?:
    | IMemberGroupPreferences
    | IClientPreferences
    | ICompanyPreferences; // remove this later
};

export const FormCheckbox = (props: {
  form: {
    field: ControllerRenderProps<PreferencesFieldValues, any>;
    fieldState: ControllerFieldState;
  };
  disabled?: boolean;
  label: React.ReactNode;
}) => {
  const {
    form: {
      field: { value, name, onChange },
      fieldState: { error },
    },
    label,
    disabled,
  } = props;

  return (
    <div className="form-group form-check mb-1">
      <input
        id={name}
        type="checkbox"
        className="form-check-input"
        checked={value}
        disabled={disabled}
        onChange={onChange}
      />
      <label className="form-check-label user-select-none" htmlFor={name}>
        {label}
      </label>
      {!!error && <div className="invalid-feedback">{error.message}</div>}
    </div>
  );
};

const PreferencesChannelSettings = (props: {
  name: string;
  parent?: IPreferenceChannels;
  disabled?: boolean;
  isAuto?: boolean;
}) => {
  const { t } = useTranslation(["preferenceChannels"]);

  const isAdmin = useHasRole(["ADMIN"]);

  const { name, parent, disabled, isAuto } = props;

  const enabled = useWatch({ name: `${name}.enabled` });

  const autoCall = useWatch({ name: `${name}.autoChannel.call` });
  const autoPing = useWatch({ name: `${name}.autoChannel.ping` });

  if (!enabled) {
    return null;
  }

  return (
    <div className="ml-4">
      <h6>{t(`preferenceChannels:title.${isAuto ? "auto" : "default"}`)}</h6>
      {!!isAuto && (
        <Controller
          name={`${name}.autoChannel.email`}
          defaultValue={false}
          render={(form) => (
            <FormCheckbox
              form={form}
              label={t("preferenceChannels:email")}
              disabled={disabled || parent?.email === false}
            />
          )}
        />
      )}
      <Controller
        name={`${name}.${isAuto ? "autoChannel" : "channel"}.sms`}
        defaultValue={false}
        render={(form) => (
          <FormCheckbox
            form={form}
            label={t("preferenceChannels:sms")}
            disabled={disabled || parent?.sms === false}
          />
        )}
      />
      <Controller
        name={`${name}.${isAuto ? "autoChannel" : "channel"}.call`}
        defaultValue={false}
        render={(form) => (
          <FormCheckbox
            form={form}
            label={t("preferenceChannels:call")}
            disabled={
              disabled ||
              parent?.call === false ||
              (isAuto && autoPing === true)
            }
          />
        )}
      />
      <Controller
        name={`${name}.${isAuto ? "autoChannel" : "channel"}.ping`}
        defaultValue={false}
        render={(form) => (
          <FormCheckbox
            form={form}
            label={t("preferenceChannels:ping")}
            disabled={
              disabled ||
              parent?.ping === false ||
              (isAuto && autoCall === true)
            }
          />
        )}
      />
      {!isAuto && isAdmin && (
        <Controller
          name={`${name}.channel.videoCall`}
          defaultValue={false}
          render={(form) => (
            <FormCheckbox
              form={form}
              label={t("preferenceChannels:videoCall")}
              disabled={disabled || parent?.videoCall === false}
            />
          )}
        />
      )}
    </div>
  );
};

export const PreferencesController = (props: {
  name: string;
  label?: string;
  parent?: IPreference;
  disabled?: boolean;
  autoDisabled?: boolean;
}) => {
  const { name, label, parent, disabled, autoDisabled } = props;

  return (
    <>
      <Controller
        name={`${name}.enabled`}
        defaultValue={false}
        render={(form) => (
          <FormCheckbox
            form={form}
            label={label}
            disabled={disabled || parent?.enabled === false}
          />
        )}
      />
      <div className="d-flex">
        <PreferencesChannelSettings
          name={name}
          parent={parent?.channel}
          disabled={disabled}
        />
        <PreferencesChannelSettings
          name={name}
          parent={parent?.autoChannel}
          disabled={autoDisabled}
          isAuto
        />
      </div>
    </>
  );
};
