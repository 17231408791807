import React, { useCallback, useState } from "react";

import clsx from "clsx";
import get from "lodash/get";
import useToggle from "react-use/lib/useToggle";
import { useTranslation } from "react-i18next";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { useQuery, useMutation } from "@apollo/client";
import { useRouteMatch, Link } from "react-router-dom";

import Table from "../../../components/Table";
import RoleBlock from "../../../components/RoleBlock";

import {
  QUERY_CLIENT_WITH_TOKEN,
  QUERY_SCREENS,
} from "../../../config/graphql/query";
import { DELETE_DIGITAL_RECEPTION } from "../../../config/graphql/mutation";

import useSocket from "../../../lib/hooks/useSocket";
import useHasRole from "../../../lib/hooks/useHasRole";
import { useCurrentClient } from "../../../context/Client";

type ScreenMessage = {
  id: string;
  client: string;
  title: string;
  status: boolean;
};

const TableRow = (props: {
  item: IDigitalReception;
  status: boolean;
  restart: () => void;
}) => {
  const { item, status, restart } = props;

  const { id, title, template } = item;

  const [visible, setVisible] = useToggle(false);

  const { t } = useTranslation(["screens", "common"]);

  const onClickPreview = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.stopPropagation();
  };

  const [onDelete] = useMutation(DELETE_DIGITAL_RECEPTION, {
    refetchQueries: [
      {
        query: QUERY_SCREENS,
        variables: {
          filter: { type: { EQ: "DigitalReception" } },
          sort: { title: "ASC" },
        },
      },
    ],
    variables: { id },
  });

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr>
        <th scope="row">{title}</th>
        <td>{template}</td>
        <th
          className={clsx({
            "text-success": status,
            "text-danger": !status,
          })}
        >
          {status ? "Online" : "Offline"}
        </th>
        <td>
          <div className="d-flex">
            <RoleBlock roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}>
              <Button
                size="sm"
                variant="danger"
                className="mr-2"
                onClick={() => setVisible(true)}
              >
                {t("common:delete")}
              </Button>
              <Button
                size="sm"
                variant="danger"
                className="mr-2"
                disabled={!status}
                onClick={restart}
              >
                {t("common:restart")}
              </Button>
              <a
                href={item.previewUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onClickPreview}
                className="mr-2"
              >
                <Button size="sm" variant="primary">
                  {t("screens:screensRouter.button.preview")}
                </Button>
              </a>
            </RoleBlock>
            <RoleBlock roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}>
              <Link to={`/digital-receptions/${id}`}>
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => setVisible(false)}
                >
                  {t("common:view")}
                </Button>
              </Link>
            </RoleBlock>
          </div>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("screens:screensRouter.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("screens:screensRouter.modal.body", { title: item.title })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const ScreensRoute = React.memo(() => {
  const match = useRouteMatch();

  const { t } = useTranslation(["screens", "common"]);

  const client = useCurrentClient();
  const isAdmin = useHasRole(["ADMIN"]);

  const { data: clientData, loading } = useQuery(QUERY_CLIENT_WITH_TOKEN, {
    fetchPolicy: "network-only",
    variables: {
      id: client?.id,
    },
    skip: !client?.id,
    context: {
      headers: {
        ClientId: client?.id,
      },
    },
  });

  const { data } = useQuery<{ screens: IDigitalReception[] }>(QUERY_SCREENS, {
    variables: {
      filter: { type: { EQ: "DigitalReception" } },
      sort: { title: "ASC" },
    },
  });

  const [status, setStatus] = useState<{
    [key: string]: ScreenMessage;
  }>({});

  const onMessage = useCallback((data: { [key: string]: ScreenMessage }) => {
    setStatus(data);
  }, []);

  const socket = useSocket<{ [key: string]: ScreenMessage }>(
    `/network/status`,
    {
      message: onMessage,
    },
  );

  const screens = data?.screens ?? [];

  const renderScreen = useCallback(
    (item: IDigitalReception) => (
      <TableRow
        key={`screen-${item.id}`}
        item={item}
        status={get(status, [item.id, "status"], false)}
        restart={() =>
          socket?.send(
            JSON.stringify({
              action: "restart",
              screen: item.id,
            }),
          )
        }
      />
    ),
    [socket, status],
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("screens:screensRouter.nav.screens")}
          </li>
        </ol>
      </nav>
      {!loading && clientData.client.tokens.length === 0 && (
        <div className="ml-3">
          <div>{t("screens:screensRouter.noToken.title")}</div>
          {!isAdmin && <div>{t("screens:screensRouter.noToken.contact")}</div>}
        </div>
      )}
      {!loading && clientData.client.tokens.length !== 0 && (
        <>
          <RoleBlock roles={["ADMIN"]}>
            <div className="d-flex justify-content-end align-items-center mb-4">
              <div>
                <Link
                  to={`${match.path}/new`}
                  type="button"
                  className="btn btn-primary"
                >
                  {t("screens:screensRouter.addScreen")}
                </Link>
              </div>
            </div>
          </RoleBlock>
          <Table>
            <thead>
              <tr>
                <th scope="col">{t("screens:screensRouter.th.title")}</th>
                <th scope="col">{t("screens:screensRouter.th.template")}</th>
                <th scope="col">{t("screens:screensRouter.th.status")}</th>
                <th scope="col">{t("screens:screensRouter.th.actions")}</th>
              </tr>
            </thead>
            <tbody>{screens.map(renderScreen)}</tbody>
          </Table>
        </>
      )}
    </div>
  );
});

export default ScreensRoute;
