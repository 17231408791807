import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import clsx from "clsx";
import dayjs from "dayjs";

import DatePicker from "react-datepicker";
import { isValidPhoneNumber } from "libphonenumber-js/min";

import { useMutation, useQuery } from "@apollo/client";

import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Link, useHistory, useParams } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useToggle } from "react-use";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Input from "../../../components/Input";
import PlanBlock from "../../../components/PlanBlock";

import {
  QUERY_VISITOR,
  QUERY_VISITORS,
  QUERY_CODE_CHECK,
} from "../../../config/graphql/query";
import {
  MUTATION_CREATE_MEMBER_ACCESS,
  MUTATION_CREATE_VISITOR,
  MUTATION_RESEND_MEMBER_ACCESS,
  MUTATION_UPDATE_VISITOR,
} from "../../../config/graphql/mutation";

type FieldValues = {
  fullName: string;
  email: string;
  phone?: string;
  access: {
    id?: string;
    fallbackCode?: string;
    validFrom?: Date;
    validUntil?: Date;
  };
};

const Visitor = React.memo(() => {
  const history = useHistory();

  const [show, setShow] = useToggle(false);

  const { t } = useTranslation(["visitor", "common"]);

  const { id } = useParams<{ id: string }>();

  const { refetch } = useQuery(QUERY_CODE_CHECK, { skip: true });

  const schema = useMemo(() => {
    return yup.object().shape({
      fullName: yup
        .string()
        .required(t("visitor:visitor.yup.fullName.required")),
      email: yup
        .string()
        .email(t("visitor:visitor.yup.email.invalid"))
        .required(t("visitor:visitor.yup.email.required")),
      phone: yup
        .string()
        .nullable()
        .transform((v, o) => (o === "" ? null : v))
        .test(
          "validPhone",
          t("visitor:visitor.yup.phone.invalid"),
          function validate(value) {
            if (value) {
              if (isValidPhoneNumber(value)) {
                return true;
              }
              return false;
            }
            return true;
          },
        ),
      ...(!id
        ? {
            access: yup.object().shape({
              fallbackCode: yup
                .string()
                .transform((v, o) => (o === "" ? undefined : v))
                .matches(
                  /^[0-9]+$/,
                  t("visitor:visitor.yup.access.fallbackCode.digits"),
                )
                .min(6, t("visitor:visitor.yup.access.fallbackCode.length"))
                .max(6, t("visitor:visitor.yup.access.fallbackCode.length"))
                .test(
                  "verified",
                  t("visitor:visitor.yup.access.fallbackCode.verified"),
                  async (value) => {
                    if (value?.length === 6) {
                      const { data } = await refetch({ code: value });

                      return data?.fallbackCodeCheck.available;
                    }
                    return true;
                  },
                ),
              validFrom: yup
                .date()
                .transform((v, o) => (o === null ? undefined : v))
                .test(
                  "allow",
                  t("visitor:visitor.yup.access.validFrom.invalid"),
                  function validate(value) {
                    if (value instanceof Date) {
                      return dayjs(value).isAfter(dayjs(new Date(Date.now())));
                    }
                    if (!value) {
                      return true;
                    }
                    return false;
                  },
                ),
              validUntil: yup
                .mixed()
                .transform((v, o) => (o === null ? undefined : v))
                .test(
                  "allow",
                  t("visitor:visitor.yup.access.validUntil.invalid"),
                  function validate(value) {
                    if (value instanceof Date) {
                      return (
                        this.parent.validFrom &&
                        dayjs.utc(value).isAfter(dayjs(this.parent.validFrom))
                      );
                    }
                    return true;
                  },
                ),
            }),
          }
        : {}),
    });
  }, [id, t, refetch]);

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {
      fullName: "",
      email: "",
      phone: "",
      access: {
        id: undefined,
        fallbackCode: "",
        validFrom: undefined,
        validUntil: undefined,
      },
    },
  });

  const { data: visitorData } = useQuery<{ visitor: IMember }>(QUERY_VISITOR, {
    skip: !id,
    variables: { id },
    onCompleted: ({ visitor }) => {
      methods.reset({ ...visitor, phone: visitor.phone ?? "" });
    },
  });

  const [onUpdate] = useMutation(MUTATION_UPDATE_VISITOR, {
    refetchQueries: [
      {
        query: QUERY_VISITORS,
      },
    ],
  });

  const [onCreate, { loading: isSaving }] = useMutation(
    MUTATION_CREATE_VISITOR,
    {
      refetchQueries: [
        {
          query: QUERY_VISITORS,
        },
      ],
    },
  );

  const [resendAccess, { loading }] = useMutation(
    MUTATION_RESEND_MEMBER_ACCESS,
    {
      onCompleted: () => {
        toast.success<string>(t("visitor:visitor.toast.resendCredentials"));
        setShow(false);
      },
      onError() {
        toast.success<string>(
          t("visitor:visitor.toast.resendCredentialsError"),
        );
        setShow(false);
      },
    },
  );

  const [onCreateMemberAccess] = useMutation(MUTATION_CREATE_MEMBER_ACCESS, {
    onCompleted: () => {
      toast.success<string>(t("visitor:visitor.toast.createCredentials"));
    },
    onError() {
      toast.error<string>(t("visitor:visitor.toast.createCredentialsError"));
    },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: QUERY_VISITOR, variables: { id } }],
  });

  const onSendCredentials = (accessId: string) => {
    resendAccess({ variables: { id: accessId } });
  };

  const onSubmit = async (variables: FieldValues) => {
    const { access, ...restOfVariables } = variables;

    if (id) {
      return onUpdate({
        variables: {
          input: { id, ...restOfVariables },
        },
      })
        .then(
          ({
            data: {
              updateVisitor: { fullName },
            },
          }) => {
            toast.success<string>(
              t("visitor:visitor.toast.updated", { fullName }),
            );
          },
        )
        .catch(() => {
          toast.error<string>(t("visitor:visitor.toast.updatedError"));
        });
    }

    const isEmptyAccess =
      Object.keys(access).length === 0 && access.constructor === Object;

    const input = {
      ...(!isEmptyAccess && { access }),
      ...restOfVariables,
    };

    return onCreate({ variables: { input } })
      .then(
        ({
          data: {
            createVisitor: { id, fullName },
          },
        }) => {
          toast.success<string>(
            t("visitor:visitor.toast.created", { fullName }),
          );
          history.replace(`/visitors/${id}`);
        },
      )
      .catch(() => {
        toast.error<string>(t("visitor:visitor.toast.createdError"));
      });
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/visitors">
              {t("visitor:visitor.nav.visitor", { count: 2 })}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("visitor:visitor.nav.visitor", { count: 1 })}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <h6>{t("visitor:visitor.heading.primary")}</h6>
              <div className="form-group">
                <label htmlFor="fullName">
                  {t("visitor:visitor.form.fullName")}
                </label>
                <Input name="fullName" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="email">{t("visitor:visitor.form.email")}</label>
                <Input
                  name="email"
                  className="form-control"
                  autoComplete="email"
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">{t("visitor:visitor.form.phone")}</label>
                <Input name="phone" className="form-control" />
                <small className="form-text text-muted small">
                  {t("visitor:visitor.form.phone-help")}
                </small>
              </div>

              {!id && (
                <PlanBlock include={["enterprise"]}>
                  <div>
                    <h6>{t("visitor:visitor.heading.secondary")}</h6>
                    <div className="form-group">
                      <label htmlFor="validFrom">
                        {t("visitor:visitor.form.validFrom")}
                      </label>
                      <Controller
                        name="access.validFrom"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <div
                              className={clsx("", {
                                "is-invalid": !!error,
                              })}
                            >
                              <DatePicker
                                showTimeSelect
                                selected={value}
                                onChange={onChange}
                                dateFormat="dd/MM/yyyy p"
                                className={clsx("form-control", {
                                  "is-invalid": !!error,
                                })}
                              />
                            </div>

                            <small className="form-text text-muted small">
                              {t("visitor:visitor.form.code.validFromHelp")}
                            </small>

                            <div className="invalid-feedback">
                              {error?.message ?? "Invalid"}
                            </div>
                          </>
                        )}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="validUntil">
                        {t("visitor:visitor.form.validUntil")}
                      </label>
                      <div>
                        <Controller
                          name="access.validUntil"
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <>
                              <div
                                className={clsx("", {
                                  "is-invalid": !!error,
                                })}
                              >
                                <DatePicker
                                  showTimeSelect
                                  selected={value}
                                  onChange={onChange}
                                  dateFormat="dd/MM/yyyy p"
                                  className={clsx("form-control", {
                                    "is-invalid": !!error,
                                  })}
                                />
                              </div>

                              <small className="form-text text-muted small">
                                {t("visitor:visitor.form.code.validToHelp")}
                              </small>

                              <div className="invalid-feedback">
                                {error?.message ?? "Invalid"}
                              </div>
                            </>
                          )}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="fallbackCode">
                        {t("visitor:visitor.form.fallbackCode")}
                      </label>
                      <Input
                        name="access.fallbackCode"
                        className="form-control"
                      />
                      <small className="form-text text-muted small">
                        {t("visitor:visitor.form.code.pinHelp")}
                      </small>
                    </div>
                  </div>
                </PlanBlock>
              )}
              <div className="row">
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary mt-2 mb-4"
                    disabled={isSaving}
                  >
                    {t("visitor:visitor.button.save")}
                  </button>
                </div>
              </div>
            </div>
            {id && (
              <PlanBlock exclude={["free"]}>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <h6>{t("visitor:visitor.heading.tertiary")}</h6>
                  {visitorData?.visitor.access?.id && (
                    <>
                      <div>{t("visitor:visitor.form.fallbackCode")}</div>
                      <div className="mb-2">
                        {visitorData?.visitor.access?.fallbackCode}
                      </div>
                      {visitorData?.visitor.access?.file?.absolutePath && (
                        <>
                          <div className="mb-1">
                            {t("visitor:visitor.form.qrCode")}
                          </div>
                          <div
                            style={{
                              width: "200px",
                              height: "200px",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              src={
                                visitorData?.visitor.access?.file?.absolutePath
                              }
                              width="270px"
                              height="270px"
                              style={{ transform: "translate(-35px,-35px)" }}
                              alt={t("visitor:visitor.form.qrCode")}
                            />
                          </div>
                        </>
                      )}
                      <div
                        className="btn btn-primary mt-4"
                        aria-disabled={!!loading}
                        onClick={setShow}
                      >
                        {t("visitor:visitor.button.sendCredentials")}
                      </div>
                    </>
                  )}
                  {!visitorData?.visitor.access?.id && (
                    <div
                      className="btn btn-primary mt-4"
                      onClick={() =>
                        onCreateMemberAccess({ variables: { input: { id } } })
                      }
                    >
                      {t("visitor:visitor.button.createCredentials")}
                    </div>
                  )}
                </div>
              </PlanBlock>
            )}
          </div>
        </form>
      </FormProvider>
      <Modal
        show={show}
        onHide={setShow}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("visitor:visitor.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("visitor:visitor.modal.body", {
            fullName: visitorData?.visitor.fullName,
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setShow}>
            {t("common:cancel")}
          </Button>
          <Button
            variant="primary"
            disabled={!!loading}
            onClick={() => {
              if (visitorData?.visitor.access?.id) {
                onSendCredentials(visitorData?.visitor.access?.id);
              }
            }}
          >
            {t("common:resend")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default Visitor;
