import React, { useContext } from "react";

import { useHistory } from "react-router-dom";
import { ApolloClient, useApolloClient } from "@apollo/client";

import { QUERY_USER_LOGIN } from "../config/graphql/query";
import { MUTATION_USER_LOGIN_2FA } from "../config/graphql/mutation";

type AuthValue = {
  login: (email: string, password: string) => Promise<any>;
  logout: () => void;
  verify2fa: (input: { code: string; token: string }) => Promise<any>;
};

const AuthContext = React.createContext({});

const { Consumer, Provider } = AuthContext;

export function useAuth() {
  return useContext(AuthContext) as AuthValue;
}

function updateQueryAuthCache(client: ApolloClient<object>, token: string) {
  window.localStorage.setItem("Authorization", token);

  client.refetchQueries({
    updateCache: (cache) => {
      cache.evict({ fieldName: "token" });
    },
    include: ["QUERY_AUTH"],
  });
}

const AuthProvider = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    const { push } = useHistory();

    const client = useApolloClient();

    function login(email: string, password: string) {
      return client
        .mutate({
          mutation: QUERY_USER_LOGIN,
          variables: { input: { email, password } },
        })
        .then((data) => {
          const {
            data: {
              login: { token: userToken, requires2fa },
            },
          } = data;

          window.localStorage.removeItem("Authorization");

          if (!requires2fa) {
            updateQueryAuthCache(client, userToken);
          }

          return data;
        });
    }

    async function logout() {
      window.localStorage.removeItem("Client");
      window.localStorage.removeItem("Authorization");

      await client.clearStore();

      return push("/login");
    }

    function verify2fa(input: { code: string; token: string }) {
      const { code, token } = input;

      return client
        .mutate({
          mutation: MUTATION_USER_LOGIN_2FA,
          variables: { input: { code } },
          context: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        })
        .then((data) => {
          const {
            data: {
              login2faCode: { token },
            },
          } = data;

          updateQueryAuthCache(client, token);
        });
    }

    return (
      <Provider
        value={{
          login,
          logout,
          verify2fa,
        }}
      >
        {children}
      </Provider>
    );
  },
);

export { AuthContext, AuthProvider, Consumer as AuthConsumer };

export default AuthContext;
