import React, { useMemo, useEffect, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";

// import get from "lodash/get";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { pickBy } from "lodash";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { useRouteMatch, useHistory } from "react-router-dom";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "../../../components/Input";
import TextArea from "../../../components/Textarea";
import {
  UploadInput,
  UploadPreview,
  useFiles,
} from "../../../components/FileUpload";

import { PropertyType } from "../../../config/const/property";
import {
  QUERY_PROPERTIES,
  QUERY_PROPERTY_COMPANY_INFORMATION,
} from "../../../config/graphql/query";
import {
  CREATE_COMPANY,
  UPDATE_PROPERTY_COMPANY,
  DELETE_COMPANY,
} from "../../../config/graphql/mutation";
import RoleBlock from "../../../components/RoleBlock";

interface FieldValues {
  title?: string;
  postbox?: string;
  country?: string;
  city?: string;
  address?: string;
  zipCode?: string;
  description?: string;
  emailLanguage?: string;
}

const CompanyInformationForm = React.memo(() => {
  const [show, setShow] = useToggle(false);
  const history = useHistory();

  const { t } = useTranslation(["company", "common"]);

  const {
    // @ts-ignore
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const [files, { onUpload: onUploadLogo }] = useFiles("Logo");

  const [onUpdate] = useMutation(UPDATE_PROPERTY_COMPANY, {
    refetchQueries: [
      {
        query: QUERY_PROPERTIES,
      },
    ],
  });
  const [onCreate] = useMutation(CREATE_COMPANY, {
    refetchQueries: [
      {
        query: QUERY_PROPERTIES,
      },
    ],
  });
  const [onDelete] = useMutation(DELETE_COMPANY, {
    refetchQueries: [
      {
        query: QUERY_PROPERTIES,
      },
    ],
  });

  const schema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required(t("company:information.yup.title")),
        postbox: yup.string().nullable(),
        country: yup.string().nullable(),
        city: yup.string().nullable(),
        address: yup.string().nullable(),
        zipCode: yup.string().nullable(),
        description: yup.string().nullable(),
        emailLanguage: yup.string().oneOf(["en", "de", "fr", "it"]),
      }),
    [t],
  );

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
  });

  // const {
  //   formState: { errors },
  // } = methods;

  const { data: propertyData } = useQuery(QUERY_PROPERTY_COMPANY_INFORMATION, {
    skip: !id,
    variables: { id },
    onError: () => history.replace("/company"),
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (propertyData?.property) {
      methods.reset(propertyData?.property);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyData]);

  const onRemoveCompany = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      return onDelete({ variables: { id } })
        .then(() => {
          history.replace(`/company`);
          toast.success<string>(t("company:information.toast.deleted"));
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [history, onDelete, id, t],
  );

  const onBeforeRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      setShow(true);
    },
    [setShow],
  );

  const onSubmit = async (values: FieldValues) => {
    let input = {
      ...(id && { id }),
      // @ts-ignore
      ...pickBy(values),
      type: PropertyType.Company,
    };

    if (Array.isArray(files) && files.length > 0) {
      const uploadedFiles = await onUploadLogo();

      if (uploadedFiles && uploadedFiles[0]) {
        input = {
          ...input,
          // @ts-ignore
          logo: uploadedFiles[0]?.file?.id,
        };
      }
    }

    if (id) {
      return onUpdate({ variables: { input } })
        .then(() => {
          toast.success<string>(t("company:information.toast.updated"));
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    }

    return onCreate({ variables: { input } })
      .then(
        ({
          data: {
            addCompany: { id },
          },
        }) => {
          toast.success<string>(t("company:information.toast.created"));

          history.replace(`/company/${id}`);
        },
      )
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );
      });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="title">
                {t("company:information.form.title")}
              </label>
              <Input.Translatable name="title" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="postbox">
                {t("company:information.form.postbox")}
              </label>
              <Input name="postbox" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="country">
                {t("company:information.form.country")}
              </label>
              <Input name="country" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="city">{t("company:information.form.city")}</label>
              <Input name="city" className="form-control" />
            </div>
            <div className="form-row">
              <div className="col-md-9 mb-3">
                <label htmlFor="address">
                  {t("company:information.form.address")}
                </label>
                <Input name="address" className="form-control" />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="zipCode">
                  {t("company:information.form.zipCode")}
                </label>
                <Input type="text" name="zipCode" className="form-control" />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="description">
                {t("company:information.form.description")}
              </label>
              <TextArea.Translatable
                name="description"
                className="form-control"
              />
            </div>
            {/* <div className="form-group">
              <label htmlFor="emailLanguage">
                {t("company:information.form.emailLanguage.label")}
              </label>
              <select
                {...methods.register("emailLanguage")}
                className="custom-select"
              >
                <option value="en">
                  {t("company:information.form.emailLanguage.options.en")}
                </option>
                <option value="de">
                  {t("company:information.form.emailLanguage.options.de")}
                </option>
                <option value="fr">
                  {t("company:information.form.emailLanguage.options.fr")}
                </option>
                <option value="it">
                  {t("company:information.form.emailLanguage.options.it")}
                </option>
              </select>
              <small id="emailHelp" className="form-text text-muted">
                {t("company:information.form.emailLanguage.emailHelp")}
              </small>
              {!!get(errors, "emailLanguage") && (
                <div className="invalid-feedback">
                  {get(errors, `emailLanguage.message`)}
                </div>
              )}
            </div> */}
            <div className="form-group">
              <label htmlFor="file">{t("company:information.form.logo")}</label>
              <UploadInput name="Logo" />
              <UploadPreview
                name="Logo"
                placeholder={propertyData?.property?.logo?.absolutePath}
                // placeholder={propertyData?.property?.logo?.path}
              />
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
            <RoleBlock roles={["ADMIN", "CLIENT_ADMIN"]}>
              {id && (
                <button
                  onClick={onBeforeRemove}
                  className="btn btn-danger ml-3"
                >
                  {t("common:delete")}
                </button>
              )}
            </RoleBlock>
          </div>
        </form>
      </FormProvider>
      <Modal show={show} onHide={setShow} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t("company:information.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("company:information.modal.body")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setShow}>
            {t("common:cancel")}
          </Button>
          <Button variant="danger" onClick={onRemoveCompany}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default CompanyInformationForm;
