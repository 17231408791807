import React, { useMemo, useCallback, useEffect, useRef } from "react";

import * as yup from "yup";

import map from "lodash/map";
import find from "lodash/find";
import last from "lodash/last";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Select, { createFilter } from "react-select";
import useCopyToClipboard from "react-use/lib/useCopyToClipboard";

import { Link, useParams, useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  useForm,
  useFieldArray,
  FieldArrayWithId,
  useFormContext,
} from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { Button } from "react-bootstrap";

import Input from "../../../components/Input";

import {
  QUERY_SCREEN,
  QUERY_SCREENS,
  QUERY_ENTITIES,
} from "../../../config/graphql/query";

import {
  CREATE_FLOOR_INFO,
  UPDATE_FLOOR_INFO,
} from "../../../config/graphql/mutation";

import {
  FLOOR_INFO_TEMPLATES,
  THE_BASE_LETTERS,
} from "../../../config/const/common";

interface FieldValues {
  title: string;
  template: string;
  floors: IFloorSettings[];
  metadata?: {
    letter?: string;
  };
}

interface FilterOptionOption<Option> {
  readonly label: string;
  readonly value: string;
  readonly data: Option;
}

const FloorItem = (props: {
  field: FieldArrayWithId<FieldValues, "floors", "key">;
  index: number;
  remove: (index: number) => void;
}) => {
  const { field, index, remove } = props;

  const { t } = useTranslation(["screens", "common"]);

  const form = useFormContext();

  useEffect(() => {
    form.register(`floors.${index}.floor`, { value: field.floor });
  }, []);

  const title = `${field?.floor?.title} (${field?.floor?.parent?.title})`;

  return (
    <Draggable key={field.key} draggableId={field.key} index={index}>
      {(provided, snapshot) => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            userSelect: "none",
            display: snapshot.isDragging ? "table" : undefined,
            ...provided.draggableProps.style,
          }}
          className={snapshot.isDragging ? "bg-gradient-dark" : ""}
        >
          <th>
            <span>&#9776;</span>
          </th>
          <td>{title}</td>
          <td>
            <Button size="sm" variant="danger" onClick={() => remove(index)}>
              {t("floorInfos:floors.button.delete")}
            </Button>
          </td>
        </tr>
      )}
    </Draggable>
  );
};

const FloorsList = () => {
  const { t } = useTranslation(["screens", "common"]);

  const { data: floorsData } = useQuery<{ entities: IFloor[] }>(
    QUERY_ENTITIES,
    {
      variables: {
        filter: { type: "Floor" },
      },
      nextFetchPolicy: "network-only",
    },
  );

  const {
    move,
    append,
    remove,
    fields: floorsFields,
  } = useFieldArray<FieldValues, "floors", "key">({
    name: "floors",
    keyName: "key",
  });

  const selectRef = useRef<any>(null);

  const renderItem = useCallback(
    (field: FieldArrayWithId<FieldValues, "floors", "key">, index: number) => (
      <FloorItem field={field} index={index} remove={remove} />
    ),
    [remove],
  );

  const filterOption = useCallback(
    (option: FilterOptionOption<IFloor>, rawInput: string) => {
      const selected = find(
        floorsFields,
        (field) => field?.floor?.id === option?.data?.id,
      );

      if (selected) {
        return false;
      }

      return createFilter({
        trim: true,
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: "any",
      })(option, rawInput);
    },
    [floorsFields],
  );

  return (
    <>
      <div className="form-group">
        <label htmlFor="floors">
          {t("screens:screen.floorInfo.form.floors")}
        </label>
        <Select
          ref={selectRef}
          getOptionLabel={({ title, parent }) =>
            parent ? `${title} (${parent.title})` : title
          }
          placeholder=""
          value={null as unknown as IFloor}
          getOptionValue={({ id }) => id}
          options={floorsData?.entities ?? []}
          onChange={(option) => {
            if (!option) {
              return;
            }

            const lastSort = last(floorsFields)?.sort;

            append({
              floor: {
                id: option?.id,
                title: option?.title,
                parent: option?.parent,
              },
              sort: lastSort ? lastSort + 1 : 1,
            });

            selectRef.current?.setValue?.(null);
          }}
          filterOption={filterOption}
        />
      </div>

      <DragDropContext
        onDragEnd={(result) => {
          const { source, destination } = result;

          if (typeof destination?.index !== "number") {
            return;
          }

          move(source.index, destination.index);
        }}
      >
        <Droppable droppableId="droppable">
          {(provided) => (
            <table
              className="table"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <thead>
                <tr>
                  <th>{t("floorInfos:floors.th.sort")}</th>
                  <th>{t("floorInfos:floors.th.floor")}</th>
                  <th>{t("floorInfos:floors.th.actions")}</th>
                </tr>
              </thead>
              <tbody>
                {floorsFields.map(renderItem)}
                {provided.placeholder}
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

const Information = React.memo(() => {
  const { screenId } = useParams<{ screenId: string }>();

  const [, copyToClipboard] = useCopyToClipboard();

  const history = useHistory();

  const { t } = useTranslation(["screens", "common"]);

  const schema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required(t("screens:screen.screenRoute.yup.title")),
        template: yup
          .string()
          .required(t("screens:screen.screenRoute.yup.template")),
        floors: yup
          .array()
          .of(
            yup.object().shape({
              floor: yup.object().shape({
                id: yup.string(),
              }),
            }),
          )
          .required(t("screens:screen.screenRoute.yup.floors")),
      }),
    [t],
  );

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {
      floors: [],
    },
  });

  const { data: screenData } = useQuery<{ screen: IFloorInfo }>(QUERY_SCREEN, {
    skip: !screenId,
    variables: {
      id: screenId,
    },
    onError: () => history.replace("/floorinfo"),
  });

  useEffect(() => {
    if (screenData) {
      methods.reset({
        title: screenData?.screen?.title,
        template: screenData?.screen?.template,
        floors: screenData?.screen?.floors ?? [],
        metadata: screenData?.screen?.metadata,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenData]);

  const [onSave] = useMutation(
    screenId ? UPDATE_FLOOR_INFO : CREATE_FLOOR_INFO,
    {
      refetchQueries: [
        {
          query: QUERY_SCREENS,
          variables: {
            filter: { type: { EQ: "FloorInfo" } },
            sort: { title: "ASC" },
          },
        },
      ],
    },
  );

  const onSubmit = useCallback(
    (values: FieldValues) => {
      const input = {
        ...values,
        ...(screenId && { id: screenId }),
        floors: map(values.floors, ({ floor }, index) => ({
          floor: floor.id,
          sort: index,
        })),
      };

      return onSave({ variables: { input } })
        .then(({ data }) => {
          if (data?.addFloorInfo?.id) {
            history.replace(`/floorInfo/${screenId}`);

            toast.success<string>(
              t("screens:screen.screenRoute.toast.created"),
            );
            return;
          }

          toast.success<string>(t("screens:screen.screenRoute.toast.updated"));
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [history, screenId, onSave, t],
  );

  return (
    <FormProvider {...methods}>
      <div className="tab-pane active" role="tabpanel">
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="title">
                {t("screens:screen.screenRoute.form.title")}
              </label>
              <Input name="title" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="type">
                {t("screens:screen.screenRoute.form.template")}
              </label>
              <select
                {...methods.register("template")}
                className="custom-select"
              >
                {FLOOR_INFO_TEMPLATES.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="type">
                {t("screens:screen.floorInfo.form.logoNumber")}
              </label>
              <select
                {...methods.register("metadata.letter")}
                className="custom-select"
              >
                {THE_BASE_LETTERS.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <FloorsList />
          </div>
          <div className="col-lg-8 row">
            <div className="col-12 mb-2">
              <iframe
                title={`screen-${screenId}`}
                className="border border-dark"
                src={screenData?.screen?.previewUrl}
                style={{
                  width: 600 / (4 / 3),
                  height: 600,
                }}
                frameBorder={0}
                marginHeight={0}
                marginWidth={0}
              />
            </div>
            <div className="col-12">
              <a
                href={screenData?.screen?.previewUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary mr-2"
              >
                {t("screens:screen.screenRoute.form.preview")}
              </a>
              <a
                href={screenData?.screen?.playerUrl}
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();

                  copyToClipboard(e.currentTarget.href);

                  toast.success<string>("Link copied");
                }}
              >
                {t("screens:screen.screenRoute.form.copyURL")}
              </a>
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </div>
    </FormProvider>
  );
});

const ScreenRoute = React.memo(() => {
  const { t } = useTranslation(["screens", "common"]);

  const { screenId } = useParams<{ screenId: string }>();

  const { data } = useQuery(QUERY_SCREEN, {
    skip: !screenId,
    variables: { id: screenId },
    nextFetchPolicy: "network-only",
  });

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/floorInfo">
              {t("floorInfos:floorInfo.route.nav.floorInfo")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {data?.screen?.title ?? t("screens:screen.screenRoute.nav.screen")}
          </li>
        </ol>
      </nav>

      <div className="tab-content">
        <Information />
      </div>
    </div>
  );
});

export default ScreenRoute;
