import React, { useCallback } from "react";

import clsx from "clsx";
import useToggle from "react-use/lib/useToggle";

import { useTranslation } from "react-i18next";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { useMutation, useQuery } from "@apollo/client";
import { useRouteMatch, Link } from "react-router-dom";

import Table from "../../components/Table";

import { QUERY_VISIONECT_DEVICES } from "../../config/graphql/query";
import { DELETE_VISIONECT_DEVICE } from "../../config/graphql/mutation";

const TableRow = ({ item }: { item: IVisionectDevice }) => {
  const {
    id,
    name,
    deviceId,
    deviceData: { State, Displays },
  } = item;

  const { t } = useTranslation(["screens", "wayfinding", "common"]);

  const [visible, setVisible] = useToggle(false);

  const [onDelete] = useMutation(DELETE_VISIONECT_DEVICE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: QUERY_VISIONECT_DEVICES,
      },
    ],
    variables: { id },
  });

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr>
        <th scope="row">{name ?? "/"}</th>
        <td>{item?.deviceData?.Options?.Name || item?.deviceData?.Uuid}</td>
        <td>
          {t(
            `wayfinding:wayfinding.wayfindingForm.form.rotation.${Displays?.[0]?.Rotation}`,
          )}
        </td>
        <th
          className={clsx({
            "text-success": State === "charging",
            "text-danger": State === "offline",
          })}
        >
          {State}
        </th>
        <td>
          <div className="d-flex">
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>
            <a
              href={`https://eink.castit.nl/devices/${deviceId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="mr-2"
            >
              <Button size="sm" variant="primary">
                {t("wayfinding:wayfinding.wayfindingForm.form.preview.preview")}
              </Button>
            </a>
            <Link to={`/visionects/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("screens:screensRouter.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("screens:screensRouter.modal.body", { title: name })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const VisionectsRouter = React.memo(() => {
  const match = useRouteMatch();

  const { t } = useTranslation(["visionects", "common"]);

  const { data } = useQuery(QUERY_VISIONECT_DEVICES);

  const screens: Array<IVisionectDevice> = data?.visionectDevices ?? [];

  const renderScreen = useCallback(
    (item: IVisionectDevice) => (
      <TableRow key={`visionect-device-${item.id}`} item={item} />
    ),
    [],
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("visionects:title")}
          </li>
        </ol>
      </nav>

      <div className="d-flex justify-content-end align-items-center mb-4">
        <div>
          <Link
            to={`${match.path}/new`}
            type="button"
            className="btn btn-primary"
          >
            {t("visionects:button.create")}
          </Link>
        </div>
      </div>

      <Table>
        <thead>
          <tr>
            <th scope="col">{t("visionects:list.th.name")}</th>
            <th scope="col">{t("visionects:list.th.deviceName")}</th>
            <th scope="col">{t("visionects:list.th.orientation")}</th>
            <th scope="col">{t("visionects:list.th.state")}</th>
            <th scope="col">{t("visionects:list.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{screens.map(renderScreen)}</tbody>
      </Table>
    </div>
  );
});

export default VisionectsRouter;
