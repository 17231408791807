import React, { useCallback, useState } from "react";

import clsx from "clsx";
import get from "lodash/get";
import useToggle from "react-use/lib/useToggle";
import { useTranslation } from "react-i18next";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { useQuery, useMutation } from "@apollo/client";
import { useRouteMatch, Link } from "react-router-dom";

import { QUERY_SCREENS } from "../../../config/graphql/query";
import { DELETE_SCREEN } from "../../../config/graphql/mutation";

import useSocket from "../../../lib/hooks/useSocket";
import Table from "../../../components/Table";
import RoleBlock from "../../../components/RoleBlock";

type ScreenMessage = {
  id: string;
  client: string;
  title: string;
  status: boolean;
};

const TableRow = ({ item, status }: { item: IPostbox; status: boolean }) => {
  const { id, title, buildings } = item;

  const [visible, setVisible] = useToggle(false);

  const { t } = useTranslation(["screens", "common"]);

  const onClickPreview = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.stopPropagation();
  };

  const [onDelete] = useMutation(DELETE_SCREEN, {
    refetchQueries: [
      {
        query: QUERY_SCREENS,
        variables: {
          filter: { type: { EQ: "Postbox" } },
          sort: { title: "ASC" },
        },
      },
    ],
    variables: { id },
  });

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr>
        <th scope="row">{title}</th>
        <td>{buildings?.map((building) => building.title).join(", ")}</td>
        <th
          className={clsx({
            "text-success": status,
            "text-danger": !status,
          })}
        >
          {status ? "Online" : "Offline"}
        </th>
        <td>
          <div className="d-flex">
            <RoleBlock roles={["ADMIN"]}>
              <Button
                size="sm"
                variant="danger"
                className="mr-2"
                onClick={() => setVisible(true)}
              >
                {t("common:delete")}
              </Button>
            </RoleBlock>
            <a
              href={item.previewUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onClickPreview}
              className="mr-2"
            >
              <Button size="sm" variant="primary">
                {t("screens:screensRouter.button.preview")}
              </Button>
            </a>
            <Link to={`/postbox/${id}`}>
              <Button
                size="sm"
                variant="primary"
                onClick={() => setVisible(false)}
              >
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("screens:screensRouter.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("screens:screensRouter.modal.body", { title: item.title })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const PostboxRoutes = React.memo(() => {
  const match = useRouteMatch();

  const { t } = useTranslation(["screens", "common"]);

  const { data } = useQuery(QUERY_SCREENS, {
    variables: {
      filter: { type: { EQ: "Postbox" } },
      sort: { title: "ASC" },
    },
  });

  const [status, setStatus] = useState<{
    [key: string]: ScreenMessage;
  }>({});

  const onMessage = useCallback((data: { [key: string]: ScreenMessage }) => {
    console.log("onMessage: ", data);

    setStatus(data);
  }, []);

  useSocket<{ [key: string]: ScreenMessage }>(`/network/status`, {
    message: onMessage,
  });

  const postBoxes: Array<IPostbox> = data?.screens ?? [];

  const renderScreen = useCallback(
    (item: IPostbox) => (
      <TableRow
        key={`screen-${item.id}`}
        item={item}
        status={get(status, [item.id, "status"], false)}
      />
    ),
    [status],
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("screens:screensRouter.nav.screens")}
          </li>
        </ol>
      </nav>
      <RoleBlock roles={["ADMIN"]}>
        <div className="d-flex justify-content-end align-items-center mb-4">
          <div>
            <Link
              to={`${match.path}/new`}
              type="button"
              className="btn btn-primary"
            >
              {t("screens:screensRouter.addScreen")}
            </Link>
          </div>
        </div>
      </RoleBlock>
      <Table>
        <thead>
          <tr>
            <th scope="col">{t("screens:screensRouter.th.title")}</th>
            <th scope="col">{t("screens:screensRouter.th.entities")}</th>
            <th scope="col">{t("screens:screensRouter.th.status")}</th>
            <th scope="col">{t("screens:screensRouter.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{postBoxes.map(renderScreen)}</tbody>
      </Table>
    </div>
  );
});

export default PostboxRoutes;
