import React, { useCallback, useMemo, useState } from "react";

import qs from "query-string";
import useToggle from "react-use/lib/useToggle";

import { toast } from "react-toastify";
import {
  useQuery,
  useMutation,
  OperationVariables,
  ApolloQueryResult,
} from "@apollo/client";

import { useTranslation } from "react-i18next";
import { useRouteMatch, Link, useLocation, useHistory } from "react-router-dom";
import { useDebounce } from "react-use";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Table from "../../../components/Table";
import Pagination from "../../../components/Pagination";

import { QUERY_MEMBER_GROUPS } from "../../../config/graphql/query";
import { MUTATION_DELETE_MEMBER_GROUP } from "../../../config/graphql/mutation";

const limit = 10;

const TableRow = ({
  item,
  refetch,
}: {
  item: IMemberGroup;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      memberGroups: IMemberGroup[];
      memberGroupsCount: number;
    }>
  >;
}) => {
  const { id, title } = item;

  const match = useRouteMatch();

  const [visible, setVisible] = useToggle(false);

  const [deleting, setDeleting] = useState<boolean>(false);

  const { t } = useTranslation(["memberGroups", "common"]);

  const [onDelete] = useMutation(MUTATION_DELETE_MEMBER_GROUP, {
    variables: { id },
  });

  const onBeforeDelete = async () => {
    setDeleting(true);

    onDelete()
      .then(
        ({
          data: {
            deleteMemberGroup: { title },
          },
        }) => {
          setTimeout(() => {
            toast.success<string>(t("memberGroups:toast.deleted", { title }));
            refetch();
          }, 2000);
          setVisible(false);
          setDeleting(false);
        },
      )
      .catch(() => {
        toast.error<string>(t("memberGroups:toast.deletedError"));

        setVisible(false);
        setDeleting(false);
      });
  };

  return (
    <>
      <tr>
        <th scope="row">{title}</th>
        <td className="text-nowrap">
          <div className="d-flex">
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>

            <Link to={`${match.path}/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("memberGroups:dialog.beforeDelete.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("memberGroups:dialog.beforeDelete.description", { title })}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setVisible(false)}>
            {t("memberGroups:dialog.beforeDelete.button.dismiss")}
          </Button>
          <Button variant="danger" onClick={onBeforeDelete} disabled={deleting}>
            {t("memberGroups:dialog.beforeDelete.button.submit")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const MemberGroups = React.memo(() => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const { t } = useTranslation(["memberGroups", "common"]);

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
      },
    [location.search],
  );

  const [search, setSearch] = useState(query.search);

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);

  const regex = useMemo(() => {
    return {
      REGEX: query.search ? query.search.toString().replace(/\+/g, "[+]") : "",
      OPTIONS: "i",
    };
  }, [query.search]);

  const variables = useMemo(
    () => ({
      sort: {
        title: "ASC",
      },
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
      filter: {
        ...(regex.REGEX
          ? {
              title: regex,
            }
          : {}),
      },
    }),
    [page, regex],
  );

  const { data, refetch } = useQuery<{
    memberGroups: IMemberGroup[];
    memberGroupsCount: number;
  }>(QUERY_MEMBER_GROUPS, {
    fetchPolicy: "network-only",
    variables,
  });

  const memberGroups = data?.memberGroups ?? [];
  const memberGroupsCount = data?.memberGroupsCount ?? 0;

  const renderMemberGroup = useCallback(
    (item: IMemberGroup) => (
      <TableRow key={item.id} item={item} refetch={refetch} />
    ),
    [refetch],
  );

  useDebounce(
    () => {
      if (search !== undefined) {
        history.push({
          search: qs.stringify({
            page: 1,
            ...(search && { search }),
          }),
        });
      }
    },
    500,
    [search],
  );

  return (
    <div className="container-fluid">
      <div className="d-flex flex-wrap justify-content-between mt-4">
        <form className="form-inline">
          <div className="mr-5 mb-4">
            <label htmlFor="search" className="sr-only">
              {t("memberGroups:input.label.search")}
            </label>
            <input
              id="search"
              type="search"
              className="form-control"
              placeholder={t("memberGroups:input.placeholder.search")}
              value={search}
              onChange={({ target: { value } }) => {
                setSearch(value);
              }}
            />
          </div>
        </form>
        <Link
          to={`${match.path}/new`}
          type="button"
          className="btn btn-primary mb-4"
        >
          {t("memberGroups:button.create")}
        </Link>
      </div>

      <Table striped bordered hover responsive size="lg">
        <thead>
          <tr>
            <th scope="col" className="col-10">
              {t("memberGroups:list.th.title")}
            </th>
            <th scope="col" className="col-2">
              {t("memberGroups:list.th.actions")}
            </th>
          </tr>
        </thead>
        <tbody>{memberGroups.map(renderMemberGroup)}</tbody>
      </Table>
      <Pagination documentsCount={memberGroupsCount} limit={limit} />
    </div>
  );
});

export default MemberGroups;
