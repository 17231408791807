import React, { useMemo, useCallback } from "react";

import * as yup from "yup";

import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import get from "lodash/get";

import { useParams } from "react-router-dom";

import { UPDATE_NAMEBOARD_SETTINGS } from "../../../../config/graphql/mutation";
import { QUERY_NAMEBOARD_SETTINGS } from "../../../../config/graphql/query";

import Input from "../../../../components/Input";
import RoleBlock from "../../../../components/RoleBlock";
import CodeEditor from "../../../../components/CodeEditor";

const SettingsRoute = React.memo(() => {
  const { t } = useTranslation(["screens", "common"]);

  const { screenId } = useParams<{ screenId: string }>();

  const schema = useMemo(
    () =>
      yup.object().shape({
        layout: yup.string().oneOf(["", "grid", "list"]),
        plugins: yup.object().shape({
          feed: yup.object().shape({
            url: yup.string().url().nullable(),
          }),
          twitter: yup.object().shape({
            username: yup.string().nullable(),
          }),
          externalVisitors: yup.object().shape({
            id: yup.string().nullable(),
            url: yup.string().url().nullable(),
          }),
        }),
        metadata: yup.object().typeError(t("screenSettings:yup.metadata")),
      }),
    [t],
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
  });

  const [onUpdate] = useMutation(UPDATE_NAMEBOARD_SETTINGS);

  useQuery<{ nameboard: INameboard }>(QUERY_NAMEBOARD_SETTINGS, {
    variables: {
      id: screenId,
    },
    onCompleted: ({ nameboard: { settings, metadata } }) => {
      methods.reset({
        layout: settings.layout || "",
        plugins: settings.plugins,
        metadata: JSON.stringify(metadata || {}, null, "\t"),
      });
    },
  });

  const onSubmit = useCallback((variables: INameboardSettings) => {
    const { layout, plugins, metadata } = variables;

    let settings: any = {
      plugins: {},
    };

    if (layout) {
      settings = {
        ...settings,
        layout,
      };
    }

    if (plugins?.feed?.url) {
      settings = {
        ...settings,
        plugins: {
          ...settings.plugins,
          feed: plugins.feed,
        },
      };
    }

    if (plugins?.twitter?.username) {
      settings = {
        ...settings,
        plugins: {
          ...settings.plugins,
          twitter: plugins.twitter,
        },
      };
    }

    if (plugins?.externalVisitors?.id) {
      settings = {
        ...settings,
        plugins: {
          ...settings.plugins,
          externalVisitors: plugins.externalVisitors,
        },
      };
    }

    onUpdate({
      variables: {
        input: {
          id: screenId,
          settings,
          metadata,
        },
      },
    })
      .then(() => {
        toast.success<string>("Success");
      })
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );
      });
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <form className="row pl-3" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="layout" className="h6">
                {t("screenSettings:select.label.layout")}
              </label>
              <select {...methods.register("layout")} className="custom-select">
                <option value="" hidden>
                  {t("screenSettings:select.option.default")}
                </option>
                <option value="list">
                  {t("screenSettings:select.option.list")}
                </option>
                <option value="grid">
                  {t("screenSettings:select.option.grid")}
                </option>
              </select>
              {!!get(methods, "errors.layout") && (
                <div className="invalid-feedback">
                  {(get(methods, "errors.layout") as any)?.message}
                </div>
              )}
            </div>
            <h6 className="mt-5">
              {t("screenSettings:input.label.plugins.externalVisitors.title")}
            </h6>
            <hr />
            <div className="form-group">
              <label htmlFor="entity">
                {t("screenSettings:input.label.plugins.externalVisitors.id")}
              </label>
              <Input
                name="plugins.externalVisitors.id"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="entity">
                {t("screenSettings:input.label.plugins.externalVisitors.url")}
              </label>
              <Input
                name="plugins.externalVisitors.url"
                className="form-control"
              />
            </div>
            <h6 className="mt-4">
              {t("screenSettings:input.label.plugins.twitter.title")}
            </h6>
            <hr />
            <div className="form-group">
              <label htmlFor="entity">
                {t("screenSettings:input.label.plugins.twitter.username")}
              </label>
              <Input name="plugins.twitter.username" className="form-control" />
            </div>
            <h6 className="mt-4">
              {t("screenSettings:input.label.plugins.feed.title")}
            </h6>
            <hr />
            <div className="form-group mb-4">
              <label htmlFor="entity">
                {t("screenSettings:input.label.plugins.feed.url")}
              </label>
              <Input name="plugins.feed.url" className="form-control" />
            </div>
          </div>
          <div className="col-lg-4">
            <RoleBlock roles={["ADMIN"]}>
              <div className="form-group">
                <label htmlFor="metadata">
                  {t("screenSettings:input.label.metadata")}
                </label>
                <Controller
                  name="metadata"
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <CodeEditor {...field} />
                      {!!error && (
                        <div style={{ color: "red", marginTop: "0.5rem" }}>
                          {error?.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
            </RoleBlock>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </FormProvider>
    </>
  );
});

export default SettingsRoute;
