import React, { useCallback, useEffect, useMemo, useState } from "react";

import useToggle from "react-use/lib/useToggle";
import { toast } from "react-toastify";
import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useRouteMatch, Link, useLocation, useHistory } from "react-router-dom";
import { useDebounce } from "react-use";
import qs from "query-string";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Table from "../../../components/Table";
import Pagination from "../../../components/Pagination";

import { QUERY_VISITORS } from "../../../config/graphql/query";
import {
  MUTATION_DELETE_VISITOR,
  MUTATION_DELETE_VISITORS,
} from "../../../config/graphql/mutation";

const limit = 10;

const TableRow = ({
  item,
  variables,
  index,
  checked,
  onCheck,
}: {
  item: IMember;
  variables: any;
  index: number;
  checked: boolean;
  onCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { id, fullName, email, phone } = item;

  const [visible, setVisible] = useToggle(false);

  const { t } = useTranslation(["visitor", "common"]);

  const [onDelete, { loading }] = useMutation(MUTATION_DELETE_VISITOR, {
    refetchQueries: [
      { query: QUERY_VISITORS, variables },
      { query: QUERY_VISITORS },
    ],
    variables: { id },
  });

  const onBeforeDelete = async () => {
    onDelete()
      .then(
        ({
          data: {
            deleteVisitor: { fullName },
          },
        }) => {
          toast.success<string>(
            t("visitor:visitors.toast.deleted", { count: 1, fullName }),
          );
          setVisible(false);
        },
      )
      .catch(() => {
        toast.error<string>(
          t("visitor:visitors.toast.deletedError", { count: 1 }),
        );
        setVisible(false);
      });
  };

  return (
    <>
      <tr>
        <td className="text-center">
          <input
            value={id}
            checked={checked}
            type="checkbox"
            onChange={onCheck}
          />
        </td>
        <td className="text-center">{index + 1}</td>
        <td className="h6">{fullName}</td>
        <td>{email}</td>
        <td>{phone}</td>
        <td className="text-nowrap">
          <div className="d-flex">
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>
            <Link to={`/visitors/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("visitor:visitors.modal.title", { count: 1 })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("visitor:visitors.modal.body", { count: 1, fullName })}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button variant="danger" onClick={onBeforeDelete} disabled={loading}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Visitors = React.memo(() => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const { t } = useTranslation(["visitor", "common"]);

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
      },
    [location.search],
  );

  const [search, setSearch] = useState(query.search);

  const [modal, setModal] = useToggle(false);

  const [checked, setChecked] = useState<string[]>([]);

  const onCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let updatedList = [...checked];
      if (event.target.checked) {
        updatedList = [...checked, event.target.value];
      } else {
        updatedList.splice(checked.indexOf(event.target.value), 1);
      }
      setChecked(updatedList);
    },
    [checked],
  );

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);

  const variables = useMemo(
    () => ({
      sort: {
        fullName: "ASC",
      },
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
      ...(query.search
        ? {
            filter: {
              fullName: {
                REGEX: query.search,
                OPTIONS: "i",
              },
            },
          }
        : {}),
    }),
    [page, query.search],
  );

  const { data } = useQuery<{
    visitors?: Array<IMember>;
    visitorsCount: number;
  }>(QUERY_VISITORS, {
    fetchPolicy: "network-only",
    variables,
  });

  const visitors: Array<IMember> = useMemo(() => data?.visitors ?? [], [data]);
  const visitorsCount: number = data?.visitorsCount ?? 0;

  const renderVisitor = useCallback(
    (item: IMember, index: number) => (
      <TableRow
        key={item.id}
        item={item}
        variables={variables}
        index={(page - 1) * limit + index}
        checked={checked.includes(item.id)}
        onCheck={onCheck}
      />
    ),
    [variables, page, checked, onCheck],
  );

  const onAllCheck = useCallback(() => {
    const checkList = visitors.map((visitor) => visitor.id);
    setChecked(checkList);
  }, [visitors]);

  const onAllUncheck = useCallback(() => {
    setChecked([]);
  }, []);

  useEffect(() => {
    setChecked([]);
  }, [page]);

  useDebounce(
    () => {
      if (search !== undefined) {
        history.push({
          search: qs.stringify({ page: 1, search }),
        });
      }
    },
    500,
    [search],
  );

  const [onDelete] = useMutation(MUTATION_DELETE_VISITORS, {
    refetchQueries: [{ query: QUERY_VISITORS, variables }],
  });

  const onDeleteSelected = () => {
    onDelete({ variables: { ids: checked } })
      .then(() => {
        toast.success<string>(
          t("visitor:visitors.toast.deleted", { count: 2 }),
        );
        setModal(false);
        setChecked([]);
      })
      .catch(() => {
        toast.error<string>(
          t("visitor:visitors.toast.deletedError", { count: 2 }),
        );
        setModal(false);
      });
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("visitor:visitors.nav.visitors")}
          </li>
        </ol>
      </nav>
      <div className="d-flex flex-wrap justify-content-between mt-4">
        <div>
          <Button className="mr-2 mb-4" onClick={onAllCheck}>
            {t("visitor:visitors.button.selectAll")}
          </Button>
          <Button className="mr-2 mb-4" onClick={onAllUncheck}>
            {t("visitor:visitors.button.unselectAll")}
          </Button>
          <Button
            className="mr-2 mb-4"
            variant="danger"
            disabled={!checked.length}
            onClick={() => {
              setModal(true);
            }}
          >
            {t("visitor:visitors.button.deleteSelected")}
          </Button>
        </div>
        <form className="form-inline mr-2">
          <div className="mr-5 mb-4">
            <label htmlFor="search" className="sr-only">
              {t("common:search")}
            </label>
            <input
              id="search"
              type="search"
              className="form-control"
              placeholder={t("visitor:visitors.placeholder.search")}
              value={search}
              onChange={({ target: { value } }) => {
                setSearch(value);
              }}
            />
          </div>
        </form>
        <Link
          to={`${match.path}/new`}
          type="button"
          className="btn btn-primary mb-4"
        >
          {t("visitor:visitors.button.addvisitor")}
        </Link>
      </div>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th scope="col" style={{ width: "3.5rem" }}>
              {" "}
            </th>
            <th scope="col" style={{ width: "3.5rem", textAlign: "center" }}>
              #
            </th>
            <th scope="col">{t("visitor:visitors.th.fullName")}</th>
            <th scope="col">{t("visitor:visitors.th.email")}</th>
            <th scope="col">{t("visitor:visitors.th.phone")}</th>
            <th scope="col">{t("visitor:visitors.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{visitors.map(renderVisitor)}</tbody>
      </Table>
      <Pagination documentsCount={visitorsCount} limit={limit} />
      <Modal
        show={modal}
        onHide={setModal}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("visitor:visitors.modal.title", { count: 2 })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("visitor:visitors.modal.body", { count: 2 })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setModal(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onDeleteSelected}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default Visitors;
