import "./scss/index.scss";
import "react-toastify/dist/ReactToastify.css";

import "./lib/dayjs";
import "./lib/i18n";

import { createRoot } from "react-dom/client";

import App from "./containers/Root";

const container = document.getElementById("root");

const root = createRoot(container!);

root.render(<App />);
