import { useCallback, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import qs from "query-string";
import filter from "lodash/filter";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { useMutation, useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";

import {
  QUERY_ACTIVE_DIRECTORIES,
  QUERY_ACTIVE_DIRECTORIES_SYNCS,
} from "../../../../config/graphql/query";
import { MUTATION_DELETE_ACTIVE_DIRECTORY_SYNC } from "../../../../config/graphql/mutation";

import { useCurrentClient } from "../../../../context/Client";

function getIntegrationDisplayName(item: IActiveDirectorySync) {
  return [item?.activeDirectory?.email, item?.group?.displayName]
    .filter(Boolean)
    .join(" - ");
}

const ActiveDirectory = () => {
  const { t } = useTranslation(["company", "common"]);

  const client = useCurrentClient();

  const [integration, setIntegration] = useState<IActiveDirectorySync>();

  const { id: companyId } = useParams<{ id: string }>();

  const { data: activeDirectoriesData, loading: isLoadingIntegration } =
    useQuery<{
      activeDirectories: IActiveDirectory[];
    }>(QUERY_ACTIVE_DIRECTORIES, {
      fetchPolicy: "network-only",
    });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: activeDirectoriesSyncData, loading: isLoadingIntegrations } =
    useQuery<{
      activeDirectorySyncs: IActiveDirectorySync[];
    }>(QUERY_ACTIVE_DIRECTORIES_SYNCS);

  const integrations = useMemo(
    () =>
      filter(
        activeDirectoriesSyncData?.activeDirectorySyncs,
        ({ company }) => company?.id === companyId,
      ),
    [activeDirectoriesSyncData?.activeDirectorySyncs, companyId],
  );

  const [onDelete] = useMutation(MUTATION_DELETE_ACTIVE_DIRECTORY_SYNC, {
    refetchQueries: [{ query: QUERY_ACTIVE_DIRECTORIES_SYNCS }],
    awaitRefetchQueries: true,
  });

  const integrationOAuthURL = useMemo(
    () =>
      qs.stringifyUrl(
        {
          url: `${process.env.REACT_APP_API_URL}/api/v1/activedirectory/auth/login`,
          query: {
            client: client?.id,
            redirectUri: window.location.href,
          },
        },
        { encode: true },
      ),
    [client?.id],
  );

  const renderItem = useCallback(
    (item: IActiveDirectorySync) => (
      <li
        key={item.id}
        className="list-group-item d-flex flex-row align-items-center"
      >
        <span>{getIntegrationDisplayName(item)}</span>
        <div className="d-flex flex-row flex-grow-1 align-items-center justify-content-end">
          <Link
            className="btn btn-sm btn-primary mr-2"
            to={`integrations/activedirectory/${item.id}`}
          >
            {t("common:update")}
          </Link>
          <button
            className="btn btn-sm btn-danger"
            onClick={() => {
              setIntegration(item);
            }}
          >
            {t("common:remove")}
          </button>
        </div>
      </li>
    ),
    [t],
  );

  if (isLoadingIntegration || isLoadingIntegrations) {
    return (
      <div className="card mb-3">
        <div className="card-body">
          <h5 className="card-title">
            {t("company:integrations.activeDirectory.section.title")}
          </h5>
        </div>
      </div>
    );
  }

  if (
    !(
      activeDirectoriesData?.activeDirectories &&
      Array.isArray(activeDirectoriesData?.activeDirectories) &&
      activeDirectoriesData?.activeDirectories.length > 0
    )
  ) {
    return (
      <div className="card mb-3">
        <div className="card-body">
          <h5 className="card-title">
            {t("company:integrations.activeDirectory.section.title")}
          </h5>
          <p className="card-text">
            {t("company:integrations.activeDirectory.section.description")}
          </p>
        </div>
        <div className="card-body">
          <a className="btn btn-primary" href={integrationOAuthURL}>
            {t("company:integrations.activeDirectory.section.button.create")}
          </a>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <h5 className="card-title">
            {t("company:integrations.activeDirectory.section.title")}
          </h5>
          <p className="card-text">
            {t("company:integrations.activeDirectory.section.description")}
          </p>
        </div>
        <ul className="list-group list-group-flush">
          {integrations.map(renderItem)}
        </ul>
        <div className="card-body">
          <Link className="btn btn-primary" to="integrations/activedirectory">
            {t("company:integrations.activeDirectory.section.button.create")}
          </Link>
        </div>
      </div>
      <Modal
        // size="sm"
        show={!!integration}
        onHide={() => setIntegration(undefined)}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("company:integrations.activeDirectory.modal.delete.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("company:integrations.activeDirectory.modal.delete.body")}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setIntegration(undefined)}>
            {t(
              "company:integrations.activeDirectory.modal.delete.button.dismiss",
            )}
          </Button>
          <Button
            size="sm"
            variant="danger"
            onClick={() => {
              setIntegration(undefined);

              return onDelete({ variables: { id: integration?.id } });
            }}
          >
            {t(
              "company:integrations.activeDirectory.modal.delete.button.submit",
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Integrations = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12">
          <ActiveDirectory />
        </div>
      </div>
    </>
  );
};

export default Integrations;
