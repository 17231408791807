import React from "react";

import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import AccessEvent from "./AccessEvent";
import AccessEvents from "./AccessEvents";
import AccessEventsExport from "./AccessEventsExport";

const AccessEventsRoute = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <AccessEvents />
      </Route>
      <Route exact path={`${path}/export`}>
        <AccessEventsExport />
      </Route>
      <Route path={`${path}/:id`}>
        <AccessEvent />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default AccessEventsRoute;
