import React, { useMemo, useContext } from "react";

import get from "lodash/get";

import { useQuery, useApolloClient } from "@apollo/client";

import {
  QUERY_AUTH,
  QUERY_CLIENT,
  QUERY_CURRENT_USER,
} from "../config/graphql/query";

type ClientContextValue = {
  client?: IClient;
};

const ClientContext = React.createContext<ClientContextValue>(undefined!);

const { Provider } = ClientContext;

export const useCurrentClient = () => {
  const { client } = useContext(ClientContext);

  return client;
};

export const ClientProvider = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    const { cache } = useApolloClient();

    const { data: authData } = useQuery<{ clientId?: string; token: string }>(
      QUERY_AUTH,
    );

    const { data: userData } = useQuery<{ me: IUser }>(QUERY_CURRENT_USER, {
      onCompleted: ({ me }) => {
        if (authData?.clientId) {
          return;
        }

        cache.writeQuery({
          query: QUERY_AUTH,
          data: {
            clientId: me?.client?.id,
          },
        });
      },
    });

    const defaultClient = useMemo(() => userData?.me?.client, [userData]);

    const { data: clientData, loading: isLoadingClient } = useQuery<{
      client: IClient;
    }>(QUERY_CLIENT, {
      variables: { skip: !authData?.clientId, id: authData?.clientId },
    });

    const client = useMemo(() => {
      if (isLoadingClient) {
        return undefined;
      }

      const selectedClient = get(clientData, "client");

      return selectedClient || defaultClient;
    }, [clientData, defaultClient, isLoadingClient]);

    if (!client) {
      return null;
    }

    return <Provider value={{ client }}>{children}</Provider>;
  },
);
