import { useMutation, useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useMemo } from "react";
import Button from "react-bootstrap/Button";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";

import * as yup from "yup";
import Input from "../../../components/Input";
import LoadingSpinner from "../../../components/Spinner";
import {
  MUTATION_RESEND_VERIFICATION_EMAIL,
  UPDATE_USER,
} from "../../../config/graphql/mutation";
import { QUERY_AUTH, QUERY_CURRENT_USER } from "../../../config/graphql/query";
import { UserFieldValues } from "../../Users/User";

const SettingsRouter = React.memo(() => {
  const { t } = useTranslation(["user", "common"]);
  const schema = useMemo(() => {
    return yup.object().shape({
      email: yup.string(),
      firstName: yup.string(),
      lastName: yup.string(),
      password: yup
        .string()
        .nullable()
        .transform((v, o) => (o === "" ? null : v))
        .min(8, t("user:user.yup.password.min", { count: 8 }))
        .matches(/[a-z]+/, t("user:user.yup.password.lowercase"))
        .matches(/[A-Z]+/, t("user:user.yup.password.uppercase"))
        .matches(/\d+/, t("user:user.yup.password.number"))
        .matches(/[@$!%*#?&]+/, t("user:user.yup.password.special")),
      passwordConfirm: yup
        .string()
        .test(
          "passwords-match",
          t("resetPassword:yup.confirmPassword.match"),
          function validate(value) {
            if (this.parent.password) {
              return this.parent.password === value;
            }
            if (value) {
              return false;
            }
            return true;
          },
        ),
      language: yup.string().oneOf(["en", "nl"]).required(),
    });
  }, [t]);

  const methods = useForm<UserFieldValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
    mode: "onChange",
  });

  const { data: authData, loading } = useQuery(QUERY_AUTH);

  const { data: userData } = useQuery<{ me: IUser }>(QUERY_CURRENT_USER, {
    skip: !authData?.token,
    onCompleted: ({ me }) => {
      methods.reset(me);
    },
  });

  const user = useMemo(() => userData?.me, [userData]);

  const emailVerified = useMemo(() => user?.emailVerified, [user]);

  const [onUpdate] = useMutation(UPDATE_USER);

  const [onResendVerificationEmail] = useMutation(
    MUTATION_RESEND_VERIFICATION_EMAIL,
    {
      onCompleted: () => {
        toast.success<string>(t("user:user.toast.resendVerificationEmail"));
      },
      onError() {
        toast.error<string>(t("user:user.toast.resendVerificationEmailError"));
      },
    },
  );

  const onSubmit = useCallback(
    (values: UserFieldValues) => {
      const { passwordConfirm: _passwordConfirm, ...restOfVariables } = values;

      const input = {
        ...restOfVariables,
        id: user?.id,
      };

      return onUpdate({ variables: { input } })
        .then(() => {
          toast.success<string>(t("user:user.toast.updated"));
        })
        .catch((error: any) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [user, onUpdate, t],
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("profile:nav.profile")}
          </li>
        </ol>
      </nav>

      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <NavLink exact className="nav-link" to="/profile/settings">
            {t("profile:tab.profile")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink exact className="nav-link" to="/profile/setup-2fa">
            {t("activity:tab.twofactor")}
          </NavLink>
        </li>
      </ul>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="firstName">
                  {t("user:user.form.firstName")}
                </label>
                <Input name="firstName" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">{t("user:user.form.lastName")}</label>
                <Input name="lastName" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="email">{t("user:user.form.email")}</label>
                <div className="d-flex">
                  <div className="w-100">
                    <Input
                      name="email"
                      className="form-control"
                      autoComplete="email"
                    />
                  </div>
                  {!!user?.id && (
                    <Link to={`/users/${user.id}/email`}>
                      <Button variant="primary" className="ml-3">
                        {t("common:change")}
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
              <div>
                {loading ? (
                  <div className="my-4">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <div>
                    {emailVerified ? (
                      <p>{t("user:user.form.emailVerified")}</p>
                    ) : (
                      <div>
                        {user?.id && (
                          <Button
                            onClick={() => {
                              onResendVerificationEmail({
                                variables: {
                                  input: {
                                    id: user.id,
                                  },
                                },
                              });
                            }}
                          >
                            {t("user:user.form.resendEmailVerification")}
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="password">{t("user:user.form.password")}</label>
                <Input
                  name="password"
                  type="password"
                  className="form-control"
                  autoComplete="new-password"
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">
                  {t("user:user.form.confirmPassword")}
                </label>
                <Input
                  name="passwordConfirm"
                  type="password"
                  className="form-control"
                  autoComplete="new-password"
                />
              </div>

              <div className="form-group">
                <label htmlFor="language">{t("user:user.form.language")}</label>
                <select
                  {...methods.register("language")}
                  id="language"
                  className="custom-select"
                >
                  <option value="en">EN</option>
                  <option value="nl">NL</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <input type="submit" className="btn btn-primary" />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
});

export default SettingsRouter;
