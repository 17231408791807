import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import qs from "query-string";
import clsx from "clsx";

import DatePicker from "react-datepicker";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";

import Pagination from "../../../../../../components/Pagination";
import LoadingSpinner from "../../../../../../components/Spinner";

import {
  QUERY_TWILIO_CLIENT_USAGE,
  QUERY_TWILIO_CLIENT_LOGS,
} from "../../../../../../config/graphql/query";

const limit = 10;

const TableRow = ({
  category,
  item,
}: {
  category: "calls" | "sms";
  item: ITwilioUsageLog;
}) => {
  const { from, to, duration, price, priceUnit, status, dateCreated } = item;

  return (
    <>
      <tr>
        <td>{from.startsWith("+") ? from : "Call attempt"}</td>
        <td>{to}</td>
        {category === "calls" && <td>{duration}</td>}
        <td>
          {price ? -price.toFixed(3) : 0} {priceUnit}
        </td>
        <td>{status}</td>
        <td>{dayjs(dateCreated).format("DD/MM/YYYY h:mm A")}</td>
      </tr>
    </>
  );
};

const TwilioClientUsage = React.memo(() => {
  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation(["client"]);

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        page?: number;
        month?: Date;
      },
    [location.search],
  );

  const [month, setMonth] = useState(() =>
    query.month
      ? dayjs(query.month).toDate()
      : dayjs().startOf("month").toDate(),
  );

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);

  const { id, category } = useParams<{
    id: string;
    category: "calls" | "sms";
  }>();

  const { data: usageData } = useQuery(QUERY_TWILIO_CLIENT_USAGE, {
    skip: !(id && month),
    variables: { id, category },
  });

  const totalCount: number = usageData?.twilioClientUsage.count ?? 0;

  const totalPrice: number = usageData?.twilioClientUsage.price.toFixed(3) ?? 0;
  const totalPriceUnit: string =
    usageData?.twilioClientUsage.priceUnit ?? "USD";

  const totalUsage: number = usageData?.twilioClientUsage.usage ?? 0;
  const totalUsageUnit: number =
    usageData?.twilioClientUsage.usageUnit ?? "minutes";

  const variables = useMemo(
    () => ({
      id,
      category,
      filter: {
        startDate: dayjs(month).toDate(),
        endDate: dayjs(month).endOf("month").toDate(),
      },
    }),
    [id, category, month],
  );

  const { data: logsData, loading } = useQuery(QUERY_TWILIO_CLIENT_LOGS, {
    skip: !(id && month),
    variables,
  });

  const logs: Array<ITwilioUsageLog> =
    logsData?.twilioClientLogs.logs.slice((page - 1) * limit, page * limit) ??
    [];

  const logsCount: number = logsData?.twilioClientLogs.logsCount ?? 0;

  const logsPrice: number = logsData?.twilioClientLogs.price.toFixed(3) ?? 0;

  const logsPriceUnit: string = logsData?.twilioClientLogs.priceUnit ?? "USD";

  const renderItem = useCallback(
    (item: ITwilioUsageLog) => (
      <TableRow key={Math.random()} category={category} item={item} />
    ),
    [category],
  );

  useEffect(() => {
    history.push({
      search: qs.stringify({
        page: 1,
        month,
      }),
    });
  }, [month, history, category]);

  return (
    <div>
      <Card className="mb-3 p-2">
        {t(`client:client.twilio.${category}.count`)}
        {totalCount}
      </Card>
      <Card className="mb-3 p-2 flex-row">
        {t(`client:client.twilio.${category}.price`)}
        <span className="mx-1 text-primary">{totalPrice}</span>
        {totalPriceUnit}
      </Card>
      {category === "calls" ? (
        <Card className="mb-3 p-2">
          {t("client:client.twilio.calls.usage", { unit: totalUsageUnit })}
          {totalUsage}
        </Card>
      ) : null}
      <div className="d-flex flex-wrap justify-content-between mt-3 mx-3">
        <form className="form-inline">
          <div className="d-flex mb-3">
            <div className="d-flex mr-4">
              <div className="mr-2 d-flex align-items-center">
                {t("client:client.twilio.month")}
              </div>
              <DatePicker
                selected={month}
                onChange={(month: Date) => {
                  setMonth(month);
                }}
                dateFormat="MM/yyyy"
                className={clsx("form-control", {
                  "is-invalid": !month,
                })}
                showMonthYearPicker
                filterDate={(date) => dayjs() > dayjs(date)}
              />
            </div>
          </div>
        </form>
        <div className="pe-3 mb-3 text-decoration-underline">
          {t("client:client.twilio.price", { category })}
          <span className="mr-1 text-primary">{logsPrice}</span>
          {logsPriceUnit}
        </div>
      </div>
      {loading ? (
        <div className="my-5">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th scope="col">{t("client:client.twilio.th.from")}</th>
                <th scope="col">{t("client:client.twilio.th.to")}</th>
                {category === "calls" && (
                  <th scope="col">{t("client:client.twilio.th.duration")}</th>
                )}
                <th scope="col">{t("client:client.twilio.th.price")}</th>
                <th scope="col">{t("client:client.twilio.th.status")}</th>
                <th scope="col">{t("client:client.twilio.th.date")}</th>
              </tr>
            </thead>
            <tbody>{logs.map(renderItem)}</tbody>
          </Table>
          <Pagination documentsCount={logsCount} limit={limit} />
        </>
      )}
    </div>
  );
});

export default TwilioClientUsage;
