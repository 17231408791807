import React, { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useMutation, useQuery } from "@apollo/client";

import { toast } from "react-toastify";
import { Link, useHistory, useParams } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";

import Button from "react-bootstrap/Button";

import Input from "../../../components/Input";
import RoleBlock from "../../../components/RoleBlock";

import {
  QUERY_CURRENT_USER,
  QUERY_USER,
  QUERY_USER_EMAIL_CHANGE_REQUEST,
} from "../../../config/graphql/query";
import {
  MUTATION_EMAIL_CHANGE_REQUEST,
  MUTATION_RESEND_VERIFICATION_EMAIL_CHANGE,
} from "../../../config/graphql/mutation";
import { UserRolesPriority } from "../../../config/const/common";

type FieldValues = {
  email: string;
  emailVerified: boolean;
};

const UserEmailChange = React.memo(() => {
  const history = useHistory();

  const { t } = useTranslation(["user", "common"]);

  const { id } = useParams<{ id: string }>();

  const { data: currentUserData } = useQuery<{ me: IUser }>(QUERY_CURRENT_USER);

  const currentUserRoles = useMemo(
    () => currentUserData?.me?.roles || [],
    [currentUserData],
  );

  const { data: userData } = useQuery<{ user: IUser }>(QUERY_USER, {
    variables: { id },
  });

  const userRoles = useMemo(() => userData?.user?.roles || [], [userData]);

  const canUpdateUser = useMemo(() => {
    return (
      Math.min(...currentUserRoles.map((role) => UserRolesPriority[role])) <=
      Math.min(...userRoles.map((role) => UserRolesPriority[role]))
    );
  }, [currentUserRoles, userRoles]);

  useEffect(() => {
    if (!canUpdateUser) {
      history.replace(`/users/${id}`);
    }
  }, [id, canUpdateUser, history]);

  const schema = useMemo(() => {
    return yup.object().shape({
      email: yup.string().email().required(),
    });
  }, []);

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      emailVerified: false,
    },
  });

  const { data: userEmailChangeRequestData } = useQuery<{
    userEmailForChange: { id: string; email: string };
  }>(QUERY_USER_EMAIL_CHANGE_REQUEST, {
    variables: { user: id },
  });

  const emailForResend = useMemo(
    () => userEmailChangeRequestData?.userEmailForChange?.email,
    [userEmailChangeRequestData],
  );

  const [onEmailChangeRequest] = useMutation(MUTATION_EMAIL_CHANGE_REQUEST, {
    refetchQueries: [
      {
        query: QUERY_USER,
        variables: {
          id,
        },
      },
      {
        query: QUERY_USER_EMAIL_CHANGE_REQUEST,
        variables: {
          user: id,
        },
      },
    ],
  });

  const [onResendVerificationEmailChange] = useMutation(
    MUTATION_RESEND_VERIFICATION_EMAIL_CHANGE,
    {
      onCompleted: () => {
        toast.success<string>(t("user:user.toast.resendVerificationEmail"));
      },
      onError() {
        toast.error<string>(t("user:user.toast.resendVerificationEmailError"));
      },
    },
  );

  const onSubmit = (variables: FieldValues) => {
    return onEmailChangeRequest({
      variables: {
        input: {
          user: id,
          ...variables,
        },
      },
    })
      .then(() => {
        toast.success<string>(
          t(
            `user:user.toast.${
              variables.emailVerified ? "emailChanged" : "emailChangeRequest"
            }`,
          ),
        );
        history.replace(`/users/${id}`);
      })
      .catch(() => {
        toast.error<string>(t("user:user.toast.emailChangeRequestError"));
        history.replace(`/users/${id}`);
      });
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/users">{t("user:user.nav.user", { count: 2 })}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to={`/users/${id}`}>
              {t("user:user.nav.user", { count: 1 })}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("user:user.nav.changeEmail")}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        {emailForResend && (
          <div className="py-4 mb-4 border-bottom">
            <p>
              {t("user:user.emailChange.title-1", { email: emailForResend })}
            </p>
            <Button
              onClick={() => {
                onResendVerificationEmailChange({
                  variables: {
                    input: {
                      id: userEmailChangeRequestData?.userEmailForChange?.id,
                    },
                  },
                });
              }}
            >
              {t("user:user.form.resendEmailVerification")}
            </Button>
          </div>
        )}
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <h6 className="mb-3">{t("user:user.emailChange.title-2")}</h6>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="email">{t("user:user.form.email")}</label>
                <Input
                  name="email"
                  className="form-control"
                  autoComplete="email"
                />
              </div>
              <RoleBlock roles={["ADMIN"]}>
                <div className="form-group mb-2">
                  <Controller
                    name="emailVerified"
                    render={({ field: { value, onChange } }) => {
                      return (
                        <div className="form-group form-check">
                          <input
                            type="checkbox"
                            id="emailVerified"
                            name="emailVerified"
                            className="form-check-input"
                            checked={value}
                            onChange={() => onChange(!value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="emailVerified"
                          >
                            {t("user:user.form.autoEmailVerification")}
                          </label>
                        </div>
                      );
                    }}
                  />
                </div>
              </RoleBlock>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <input type="submit" className="btn btn-primary" />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
});

export default UserEmailChange;
