import React, { useCallback } from "react";

// import clsx from "clsx";
import useToggle from "react-use/lib/useToggle";

import { useTranslation } from "react-i18next";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { useMutation, useQuery } from "@apollo/client";
import { useRouteMatch, Link } from "react-router-dom";

import Table from "../../components/Table";

import { QUERY_MINEW_DEVICES } from "../../config/graphql/query";
import { DELETE_MINEW_DEVICE } from "../../config/graphql/mutation";

const TableRow = ({ item }: { item: IMinewDevice }) => {
  const {
    id,
    name,
    mac,
    storeId,
    productId,
    // deviceData: { State },
  } = item;

  const { t } = useTranslation(["screens", "wayfinding", "common"]);

  const match = useRouteMatch();

  const [visible, setVisible] = useToggle(false);

  const [onDelete] = useMutation(DELETE_MINEW_DEVICE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: QUERY_MINEW_DEVICES,
      },
    ],
    variables: { id },
  });

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr>
        <th scope="row">{name ?? "/"}</th>
        <td>{mac}</td>
        <td>{storeId}</td>
        <td>{productId}</td>
        {/* <td>{State}</td> */}
        <td>
          <div className="d-flex">
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>
            <Link to={`${match.path}/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("screens:screensRouter.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("screens:screensRouter.modal.body", { title: name })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const MinewsRouter = React.memo(() => {
  const match = useRouteMatch();

  const { t } = useTranslation(["minews", "common"]);

  const { data } = useQuery(QUERY_MINEW_DEVICES);

  const devices: Array<IMinewDevice> = data?.minewDevices ?? [];

  const renderDevice = useCallback(
    (item: IMinewDevice) => (
      <TableRow key={`minew-device-${item.id}`} item={item} />
    ),
    [],
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("minews:title")}
          </li>
        </ol>
      </nav>

      <div className="d-flex justify-content-end align-items-center mb-4">
        <div>
          <Link
            to={`${match.path}/new`}
            type="button"
            className="btn btn-primary"
          >
            {t("minews:button.create")}
          </Link>
        </div>
      </div>

      <Table>
        <thead>
          <tr>
            <th scope="col">{t("minews:list.th.name")}</th>
            <th scope="col">{t("minews:list.th.mac")}</th>
            <th scope="col">{t("minews:list.th.storeId")}</th>
            <th scope="col">{t("minews:list.th.productId")}</th>
            {/* <th scope="col">{t("minews:list.th.state")}</th> */}
            <th scope="col">{t("minews:list.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{devices.map(renderDevice)}</tbody>
      </Table>
    </div>
  );
});

export default MinewsRouter;
