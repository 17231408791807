import React, { useMemo } from "react";

import * as yup from "yup";

import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { FormProvider, Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useTranslation } from "react-i18next";

import CodeEditor from "../../../../components/CodeEditor";

import { PropertyType } from "../../../../config/const/property";
import { QUERY_PROPERTY_COMPANY_METADATA } from "../../../../config/graphql/query";
import { UPDATE_PROPERTY_COMPANY } from "../../../../config/graphql/mutation";

type Variables = {
  metadata: string;
};

const CompanySettingsPage = React.memo(() => {
  const { t } = useTranslation(["company", "general"]);

  const { id } = useParams<{ id: string }>();

  const schema = useMemo(
    () =>
      yup.object().shape({
        metadata: yup.object().shape({}),
      }),
    [],
  );

  const [onUpdate] = useMutation(UPDATE_PROPERTY_COMPANY);

  const methods = useForm<Variables>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
  });

  useQuery(QUERY_PROPERTY_COMPANY_METADATA, {
    skip: !id,
    variables: { id },
    onCompleted: ({ property }) => {
      methods.reset({
        metadata: JSON.stringify(property?.metadata || {}, null, "\t"),
      });
    },
  });

  const onSubmit = (values: Variables) => {
    const input = {
      id,
      type: PropertyType.Company,
      ...values,
    };

    return onUpdate({ variables: { input } })
      .then(() => {
        toast.success<string>(t("company:contact.toast.updated"));
      })
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );
      });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-12 mb-2">
            <Controller
              name="metadata"
              render={({ field }) => <CodeEditor {...field} />}
            />
          </div>

          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </FormProvider>
    </>
  );
});

export default CompanySettingsPage;
