/* eslint-disable react/destructuring-assignment */

import CodeBlock, { CodeBlockProps } from "./CodeBlock";
import RoleBlock from "./RoleBlock";

export type ClientBlockProps = CodeBlockProps & {
  roles: UserRole[];
  children: React.ReactNode;
};

const ClientBlock = (props: ClientBlockProps) => {
  const { roles, children, ...rest } = props;

  return (
    <RoleBlock roles={roles}>
      <CodeBlock {...rest}>{children}</CodeBlock>
    </RoleBlock>
  );
};

export default ClientBlock;
