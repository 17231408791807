import React, { useMemo } from "react";

import clsx from "clsx";

import {
  Link,
  Switch,
  NavLink,
  useParams,
  useRouteMatch,
  Redirect,
  useLocation,
} from "react-router-dom";

import { useQuery } from "@apollo/client";

import { useTranslation } from "react-i18next";

import { QUERY_DIGITAL_RECEPTION } from "../../../config/graphql/query";
import { CUSTOMIZABLE_THEME_RECEPTIONS } from "../../../config/const/common";

import InformationRoute from "./Information";
import ThemeRoute from "./Theme";
import SettingsRoute from "./Settings";
import MetadataRoute from "./Metadata";

import Route from "../../../components/Route";
import RoleBlock from "../../../components/RoleBlock";

import EventTracking from "./UsageAnalytics/EventTracking";
import PageTracking from "./UsageAnalytics/PageTracking";

const ScreenRoute = React.memo(() => {
  const { url, path } = useRouteMatch();

  const location = useLocation();

  const { t } = useTranslation(["screens", "common"]);

  const { screenId } = useParams<{ screenId: string }>();

  const { data } = useQuery<{ digitalReception: IDigitalReception }>(
    QUERY_DIGITAL_RECEPTION,
    {
      skip: !screenId,
      variables: { id: screenId },
      nextFetchPolicy: "network-only",
    },
  );

  const template = data?.digitalReception?.template;
  const isThemeCustomizable = useMemo(
    () => CUSTOMIZABLE_THEME_RECEPTIONS.includes(template || ""),
    [template],
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/digital-receptions">
              {t("screens:screen.screenRoute.nav.screen")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {data?.digitalReception?.title ??
              t("screens:screen.screenRoute.nav.screen")}
          </li>
        </ol>
      </nav>

      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <NavLink exact className="nav-link" to={url}>
            {t("screens:screen.screenRoute.nav.information")}
          </NavLink>
        </li>

        {isThemeCustomizable && (
          <li className="nav-item">
            <NavLink
              className={clsx("nav-link", {
                disabled: !screenId,
              })}
              to={`${url}/theme`}
            >
              {t("screens:screen.screenRoute.nav.theme")}
            </NavLink>
          </li>
        )}

        <RoleBlock roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}>
          <li className="nav-item">
            <NavLink
              className={clsx("nav-link", {
                disabled: !screenId,
              })}
              to={`${url}/settings`}
            >
              {t("screens:screen.screenRoute.nav.settings")}
            </NavLink>
          </li>
        </RoleBlock>

        <li className="nav-item">
          <NavLink
            className={clsx("nav-link", {
              disabled: !screenId,
            })}
            to={`${url}/metadata`}
          >
            {t("screens:screen.screenRoute.nav.metadata")}
          </NavLink>
        </li>

        {template === "digitalreception" && (
          <RoleBlock roles={["ADMIN"]}>
            <li className="nav-item">
              <NavLink
                className={clsx("nav-link", {
                  disabled: !screenId,
                })}
                to={`${url}/screen-events`}
                isActive={() => {
                  return location.pathname.includes("screen-events");
                }}
              >
                {t("screens:screen.screenRoute.nav.usageAnalytics")}
              </NavLink>
            </li>
          </RoleBlock>
        )}
      </ul>

      <div className="tab-content">
        <Route.Role
          path={[`${path}/screen-events`, `${path}/page-screen-events`]}
          roles={["ADMIN"]}
        >
          <ul className="nav nav-tabs mb-4">
            <li className="nav-item">
              <NavLink exact className="nav-link" to={`${url}/screen-events`}>
                {t("screens:screen.screenRoute.nav.eventTracking")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to={`${url}/page-screen-events`}>
                {t("screens:screen.screenRoute.nav.pageTracking")}
              </NavLink>
            </li>
          </ul>
        </Route.Role>

        <Switch>
          <Route.Private exact path={path}>
            <InformationRoute />
          </Route.Private>
          <Route.Private path={`${path}/theme`}>
            <ThemeRoute />
          </Route.Private>

          <Route.Role
            path={`${path}/settings`}
            roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
            redirect={`/digital-receptions/${screenId}`}
          >
            <SettingsRoute />
          </Route.Role>
          <Route.Role
            exact
            path={`${path}/metadata`}
            roles={["ADMIN", "CLIENT_ADMIN"]}
            redirect={`/digital-receptions/${screenId}`}
          >
            <MetadataRoute />
          </Route.Role>
          {template === "digitalreception" && (
            <Route.Role
              path={`${path}/screen-events`}
              roles={["ADMIN"]}
              redirect={`/digital-receptions/${screenId}`}
            >
              <EventTracking />
            </Route.Role>
          )}
          {template === "digitalreception" && (
            <Route.Role
              path={`${path}/page-screen-events`}
              roles={["ADMIN"]}
              redirect={`/digital-receptions/${screenId}`}
            >
              <PageTracking />
            </Route.Role>
          )}
          <Redirect to={path} />
        </Switch>
      </div>
    </div>
  );
});

export default ScreenRoute;
