import React from "react";

import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import Device from "./Device";
import DeviceList from "./DeviceList";

const DeviceRouter = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <DeviceList />
      </Route>
      <Route path={`${path}/:entityId`}>
        <Device />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default DeviceRouter;
