import React from "react";

import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import Visionect from "./Visionect";
import Visionects from "./Visionects";

const VisionectsRouter = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Visionects />
      </Route>
      <Route exact path={`${path}/new`}>
        <Visionect />
      </Route>
      <Route path={`${path}/:id`}>
        <Visionect />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default VisionectsRouter;
