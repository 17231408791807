/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, useState } from "react";

import { useQuery } from "@apollo/client";
import { useDebounce } from "react-use";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";

import qs from "query-string";
import dayjs from "dayjs";

import Select from "react-select";
import DatePicker from "react-datepicker";

import Table from "../../../../../components/Table";
import Pagination from "../../../../../components/Pagination";
import { QUERY_SCREEN_EVENTS } from "../../../../../config/graphql/query";

import { reactSelectCustomStyles } from "../../../../Employees/Employee/Information";

const limit = 10;

const screenEventTypeOptions = [
  "code_scan",
  "qr_code_scan",
  "package_delivery",
  "food_delivery",
  "manual_checkin",
  "message_send",
  "calling",
  "home_button_click",
  "language_button_click",
  "notify_button_click",
  "input_focus",
  "scan_button_click",
  "contact_select",
  "back_button_click",
  "chekin_button_click",
  "contact_button_click",
].map((type) => ({
  value: type,
  label: type.replaceAll("_", " "),
}));

const screenEventPreviewOptions = [true, false].map((type) => ({
  value: type,
  label: type.toString(),
}));

const TableRow = ({ item }: { item: IScreenEvent }) => {
  const { type, value, createdAt, preview } = item;

  return (
    <>
      <tr>
        <td>{type.replaceAll("_", " ")}</td>
        <td>{value.replaceAll("_", " ")}</td>
        <td>{preview.toString()}</td>
        <td>{dayjs(createdAt).local().format("DD-MM-YYYY / HH:mm")}</td>
      </tr>
    </>
  );
};

const EventTracking = React.memo(() => {
  const location = useLocation();
  const history = useHistory();

  const { screenId } = useParams<{ screenId: string }>();

  const { t } = useTranslation(["screens", "common"]);

  const query = useMemo(
    () =>
      qs.parse(location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }) as {
        page?: number;
        type?: TScreenEventType;
        preview?: boolean;
        startDate?: Date;
        endDate?: Date;
      },
    [location.search],
  );

  const [startDate, setStartDate] = useState(
    query.startDate
      ? new Date(dayjs(query.startDate).local().format())
      : undefined,
  );
  const [endDate, setEndDate] = useState(
    query.endDate ? new Date(dayjs(query.endDate).local().format()) : undefined,
  );

  const [type, setType] = useState<string | undefined>(query.type);
  const [preview, setPreview] = useState<boolean | undefined>(query.preview);

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);

  const variables = useMemo(() => {
    return {
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
      filter: {
        screen: screenId,
        tracking: "event",
        ...((query.startDate || query.endDate) && {
          createdAt: {
            ...(query.startDate && { GTE: dayjs(query.startDate) }),
            ...(query.endDate && {
              LTE: dayjs(query.endDate).add(1, "day"),
            }),
          },
        }),
        ...(query.type && {
          type: query.type,
        }),
        ...(typeof query.preview === "boolean" && {
          preview: query.preview,
        }),
      },
    };
  }, [
    page,
    query.startDate,
    query.endDate,
    query.type,
    query.preview,
    screenId,
  ]);

  const { data } = useQuery<{
    screenEvents?: {
      events: Array<IScreenEvent>;
      eventsCount: number;
    };
  }>(QUERY_SCREEN_EVENTS, {
    fetchPolicy: "network-only",
    variables,
  });

  const screenEvents: Array<IScreenEvent> = data?.screenEvents?.events ?? [];
  const screenEventsCount: number = data?.screenEvents?.eventsCount ?? 0;

  const renderItem = useCallback(
    (item: IScreenEvent) => <TableRow key={item.id} item={item} />,
    [],
  );

  useDebounce(
    () => {
      history.push({
        search: qs.stringify({
          page: 1,

          ...(startDate && { startDate }),
          ...(endDate && { endDate }),
          ...(type && { type }),
          ...(typeof preview === "boolean" && { preview }),
        }),
      });
    },
    500,
    [startDate, endDate, preview],
  );

  return (
    <div className="container-fluid">
      <div className="d-flex flex-wrap justify-content-between mt-4">
        <form className="form-inline">
          <div className="d-flex mb-4 mr-0 mr-sm-5">
            <div className="d-flex mr-4">
              <div className="mr-2 d-flex align-items-center">
                {t("screens:screen.usage.form.date.from")}
              </div>
              <DatePicker
                isClearable
                selected={startDate}
                onChange={(date: Date) => {
                  setStartDate(date);
                  if (
                    date.toDateString() === new Date().toDateString() ||
                    dayjs(date).isAfter(dayjs(endDate))
                  ) {
                    setEndDate(date);
                  }
                }}
                filterDate={(date) => dayjs() > dayjs(date)}
                placeholderText="Start Date"
                dateFormat="MM/dd/yyyy"
                className="form-control"
                todayButton="Today"
              />
            </div>
            <div className="d-flex">
              <div className="mr-2 d-flex align-items-center">
                {t("screens:screen.usage.form.date.to")}
              </div>
              <DatePicker
                isClearable
                selected={endDate}
                onChange={(date: Date) => {
                  setEndDate(date);
                }}
                filterDate={(date) => {
                  if (!startDate) {
                    return dayjs() > dayjs(date);
                  }
                  return (
                    dayjs() > dayjs(date) && dayjs(date) >= dayjs(startDate)
                  );
                }}
                placeholderText="End Date"
                dateFormat="MM/dd/yyyy"
                className="form-control"
              />
            </div>
          </div>
          <div className="d-flex flex-column flex-sm-row">
            <div className="d-flex mb-4 mr-5">
              <label htmlFor="roles" className="mb-0 mr-2 align-self-center">
                {t("screens:screen.usage.form.type")}
              </label>
              <div style={{ width: "13rem" }}>
                <Select
                  isClearable
                  options={screenEventTypeOptions}
                  value={screenEventTypeOptions.find(
                    (option) => option.value === type,
                  )}
                  onChange={(value) => {
                    setType(value?.value);
                  }}
                  styles={reactSelectCustomStyles(false)}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-sm-row">
            <div className="d-flex mb-4 mr-5">
              <label htmlFor="roles" className="mb-0 mr-2 align-self-center">
                {t("screens:screen.usage.form.preview")}
              </label>
              <div style={{ width: "13rem" }}>
                <Select
                  isClearable
                  options={screenEventPreviewOptions}
                  value={screenEventPreviewOptions.find(
                    (option) => option.value === preview,
                  )}
                  onChange={(value) => {
                    setPreview(value?.value);
                  }}
                  styles={reactSelectCustomStyles(false)}
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th scope="col">{t("screens:screen.usage.table.th.type")}</th>
            <th scope="col">{t("screens:screen.usage.table.th.value")}</th>
            <th scope="col">{t("screens:screen.usage.table.th.preview")}</th>
            <th scope="col">
              {t("screens:screen.usage.table.th.trackingTime")}
            </th>
          </tr>
        </thead>
        <tbody>{screenEvents.map(renderItem)}</tbody>
      </Table>
      <Pagination documentsCount={screenEventsCount} limit={limit} />
    </div>
  );
});

export default EventTracking;
