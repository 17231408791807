import React, { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import axios from "axios";

import DatePicker from "react-datepicker";
import clsx from "clsx";
import dayjs from "dayjs";
import Select from "react-select";

import { useQuery } from "@apollo/client";

import {
  QUERY_COMPANIES,
  QUERY_EMPLOYEES,
} from "../../../../config/graphql/query";

import { useCurrentClient } from "../../../../context/Client";

import { reactSelectCustomStyles } from "../../../Employees/Employee/Information";

type FieldValues = {
  company: { id: string | undefined };
  month: Date;
  employees: boolean;
  employee: { id: string | undefined };
};

const AccessEventsExport = React.memo(() => {
  const { t } = useTranslation(["accessEvent"]);

  const schema = useMemo(() => {
    return yup.object().shape({
      company: yup
        .object()
        .shape({
          id: yup.string(),
        })
        .default(undefined)
        .required(t("accessEvent:export.yup.company.required")),
      employees: yup.boolean(),
      employee: yup
        .object()
        .shape({
          id: yup.string(),
        })
        .default(undefined)
        .test(
          "allow",
          t("accessEvent:export.yup.employee.required"),
          function validate(this: any, value: { id: number }) {
            return this.parent.employees || value?.id;
          },
        ),
      month: yup.date().required(t("accessEvent:export.yup.month.required")),
    });
  }, [t]);

  const client = useCurrentClient();

  const { data: companiesData } = useQuery<{ companies: ICompany[] }>(
    QUERY_COMPANIES,
    {
      nextFetchPolicy: "network-only",
    },
  );

  const companyOptions = useMemo(
    () => companiesData?.companies ?? [],
    [companiesData],
  );

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      company: companyOptions[0],
      month: undefined,
      employees: false,
      employee: undefined,
    },
  });

  useEffect(() => {
    if (companyOptions.length === 1) {
      methods.setValue("company", companyOptions[0]);
    }
  }, [companyOptions, methods]);

  const company = methods.watch("company");

  const { data: employeesData } = useQuery<{ employees?: IMember[] }>(
    QUERY_EMPLOYEES,
    {
      skip: !company,
      nextFetchPolicy: "network-only",
      variables: {
        filter: {
          company: {
            id: {
              EQ: company?.id,
            },
          },
        },
      },
    },
  );

  const employeeOptions = useMemo(
    () => employeesData?.employees ?? [],
    [employeesData],
  );

  const isAllEmployees = methods.watch("employees");

  const onSubmit = async (variables: FieldValues) => {
    const { company, employees, employee, month } = variables;

    const startOfMonth = dayjs(month).format("YYYY.MM.DD");
    const endOfMonth = dayjs(month).endOf("month").format("YYYY.MM.DD");

    const onlyNames = client?.code === "pando";

    let url = `client/${client?.id}/export/company/${company.id}/employees`;

    if (!employees) {
      url += `/${employee.id}`;
    }

    if (onlyNames) {
      url += "/only-name";
    }

    url += `?start=${startOfMonth}&end=${endOfMonth}`;

    axios({
      method: "get",
      url,
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      },
      responseType: "blob",
    })
      .then((response) => {
        const fileName = "export.csv";

        const href = URL.createObjectURL(response.data);

        const link = document.createElement("a");

        link.href = href;
        link.setAttribute("download", fileName);

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        URL.revokeObjectURL(href);
      })
      .catch(console.log);
  };

  return (
    <div className="container-fluid">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <h6 className="mb-3">
                {t("accessEvent:export.heading.primary")}
              </h6>

              <Controller
                name="company"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className="form-group">
                    <label htmlFor="company">
                      {t("accessEvent:export.form.company")}
                    </label>
                    <Select
                      getOptionLabel={({ title }) => title}
                      getOptionValue={({ id }) => id}
                      options={companyOptions}
                      onChange={(value) => onChange(value)}
                      value={value}
                      className={clsx({
                        "is-invalid": !!error,
                      })}
                      styles={reactSelectCustomStyles(!!error)}
                      isDisabled={companyOptions.length === 1}
                    />
                    <div className="invalid-feedback">{error?.message}</div>
                  </div>
                )}
              />
              <div className="form-check mb-3">
                <input
                  id="employees-check"
                  type="checkbox"
                  {...methods.register("employees")}
                  name="employees"
                  onChange={(e) => {
                    methods.setValue("employees", e.target.checked);
                    methods.setValue(
                      "employee",
                      { id: undefined },
                      {
                        shouldValidate: true,
                      },
                    );
                  }}
                  className="form-check-input"
                />
                <label className="form-check-label" htmlFor="employees-check">
                  {t("accessEvent:export.form.employees")}
                </label>
              </div>
              <Controller
                name="employee"
                render={({ field: { value }, fieldState: { error } }) => (
                  <div className="form-group">
                    <label htmlFor="employee">
                      {t("accessEvent:export.form.employee")}
                      {!isAllEmployees && " *"}
                    </label>
                    <Select
                      getOptionLabel={({ fullName }) => fullName}
                      getOptionValue={({ id }) => id}
                      options={employeeOptions}
                      onChange={(value) =>
                        methods.setValue("employee", value, {
                          shouldValidate: true,
                        })
                      }
                      value={value}
                      className={clsx({
                        "is-invalid": !!error,
                      })}
                      styles={reactSelectCustomStyles(!!error)}
                      isDisabled={isAllEmployees}
                    />
                    <div className="invalid-feedback">{error?.message}</div>
                  </div>
                )}
              />
              <div className="form-group">
                <label htmlFor="month">
                  {t("accessEvent:export.form.month")}
                </label>
                <Controller
                  name="month"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <div
                        className={clsx("", {
                          "is-invalid": !!error,
                        })}
                      >
                        <DatePicker
                          selected={value}
                          onChange={onChange}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          filterDate={(date) => dayjs() > dayjs(date)}
                          className={clsx("form-control", {
                            "is-invalid": !!error,
                          })}
                        />
                      </div>

                      <div className="invalid-feedback">
                        {error?.message ?? "Invalid"}
                      </div>
                    </>
                  )}
                />
              </div>
              <div className="row">
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary mt-2 mb-4"
                    disabled={methods.formState.isSubmitting}
                  >
                    {t("accessEvent:export.form.submitBtn")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
});

export default AccessEventsExport;
