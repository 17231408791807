import React, { useCallback, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { get } from "lodash";
import { toast } from "react-toastify";
import { Link, useHistory, useParams } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Input from "../../../../components/Input";
import {
  QUERY_ENTITIES_DECEASED,
  QUERY_ENTITY_DECEASED,
  QUERY_MINEW_ITEMS,
  QUERY_VISIONECT_ITEMS,
} from "../../../../config/graphql/query";
import {
  CREATE_DECEASED,
  UPDATE_DECEASED,
  DELETE_DECEASED,
  CREATE_ENTITY_PROPERTIES,
  RESTART_VISIONECT,
  RESTART_MINEW,
} from "../../../../config/graphql/mutation";
import { useCurrentClient } from "../../../../context/Client";

type Gender = "male" | "female";

type DeviceFieldValues = {
  title: string;
  gender: Gender;
};

const Device = React.memo(() => {
  const [modal, setModal] = useState<"remove" | "force-update" | null>(null);
  const history = useHistory();
  const { t } = useTranslation(["minewClients", "common"]);
  const { entityId } = useParams<{ entityId: string }>();

  const methods = useForm<DeviceFieldValues>({
    shouldFocusError: false,
    shouldUnregister: true,
    mode: "onChange",
    defaultValues: {
      gender: "male", // Set default value for gender
    },
  });

  const { data } = useQuery<{
    entity: IEntityWithDeceased;
  }>(QUERY_ENTITY_DECEASED, {
    variables: { id: entityId },
    onCompleted: ({ entity: { properties } }) => {
      methods.reset(properties[0]);
    },
  });

  const deceasedId = get(data, "entity.properties[0].id");
  const deceasedName = get(data, "entity.properties[0].title");

  const { data: visionectItems } = useQuery(QUERY_VISIONECT_ITEMS, {
    variables: {
      filter: {
        entity: entityId,
      },
    },
  });

  const { data: minewItems } = useQuery(QUERY_MINEW_ITEMS, {
    variables: {
      filter: {
        entity: entityId,
      },
    },
  });

  const visionectId = get(visionectItems, "visionectItems[0].visionect.id");
  const minewId = get(minewItems, "minewItems[0].minew.id");

  const [onUpdate] = useMutation(UPDATE_DECEASED);
  const [onCreate] = useMutation(CREATE_DECEASED);
  const [onEntityAttachProperties] = useMutation(CREATE_ENTITY_PROPERTIES);
  const [onDelete, { loading: deleteLoading }] = useMutation(DELETE_DECEASED, {
    refetchQueries: [
      {
        query: QUERY_ENTITIES_DECEASED,
      },
    ],
  });

  const [onRestartVisionect, { loading: restartLoading }] =
    useMutation(RESTART_VISIONECT);

  const [onRestartMinew] = useMutation(RESTART_MINEW);
  const currentClient = useCurrentClient();

  const onForceUpdate = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();

      try {
        if (currentClient?.code === "a1-demo") {
          await onRestartMinew({ variables: { id: minewId } });
          toast.success<string>(t("Minew successfully restarted"));
        } else {
          await onRestartVisionect({ variables: { id: visionectId } });
          toast.success<string>(t("deceased:toast.forcedUpdate"));
        }
      } catch (error) {
        toast.error<string>(t("deceased:toast.forcedUpdateError"));
      }
      setModal(null);
    },
    [onRestartVisionect, visionectId, t, minewId, onRestartMinew],
  );

  const onBeforeForceUpdate = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      setModal("force-update");
    },
    [],
  );

  const onRemove = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();

      try {
        await onDelete({ variables: { id: deceasedId } });

        toast.success<string>(
          t("minewClients:toast.deleted", {
            deceasedName,
          }),
        );

        history.replace(`/devices`);
      } catch (error) {
        toast.error<string>(t("minewClients:toast.deletedError"));
      }
      setModal(null);
    },
    [onDelete, deceasedId, deceasedName, history, t],
  );

  const onBeforeRemove = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      setModal("remove");
    },
    [],
  );

  const onSubmit = async (variables: DeviceFieldValues) => {
    if (deceasedId) {
      try {
        await onUpdate({
          variables: { input: { id: deceasedId, ...variables } },
        });

        toast.success<string>(t("minewClients:toast.updated"));
      } catch (error) {
        toast.error<string>(t("deceased:toast.updatedError"));
      }
    } else {
      try {
        const {
          data: {
            createDeceased: { id, title },
          },
        } = await onCreate({ variables: { input: variables } });

        await onEntityAttachProperties({
          variables: {
            input: {
              id: entityId,
              properties: [id],
            },
          },
        });

        toast.success<string>(
          t("minewClients:toast.created", { deceasedName: title }),
        );
      } catch (error) {
        toast.error<string>(t("minewClients:toast.createdError"));
      }
    }
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/devices">{t("device:nav.deceasedList")}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {deceasedName ?? t("devices:nav.device")}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="title">{t("device:form.deviceName")}</label>
                <Input
                  name="title"
                  className="form-control"
                  rules={{
                    required: {
                      value: true,
                      message: t("device:form.error.deviceName.required"),
                    },
                  }}
                />
                <Controller
                  name="gender"
                  render={({ field }) => (
                    <input type="hidden" {...field} value="male" />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <input type="submit" className="btn btn-primary" />
              {deceasedId && (
                <button
                  onClick={onBeforeRemove}
                  className="btn btn-danger ml-3"
                >
                  {t("common:delete")}
                </button>
              )}
              <button
                className="btn btn-secondary ml-3"
                onClick={onBeforeForceUpdate}
              >
                {t("deceased:forceUpdate")}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
      <Modal
        show={!!modal}
        onHide={() => setModal(null)}
        backdrop="static"
        centered
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t(`deceased:modal.${modal}.title`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(`deceased:modal.${modal}.body`, {
            deceasedName,
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModal(null)}>
            {t("common:cancel")}
          </Button>
          <Button
            variant="danger"
            disabled={restartLoading || deleteLoading}
            onClick={(e) => {
              if (modal === "force-update") {
                onForceUpdate(e);
              }
              if (modal === "remove") {
                onRemove(e);
              }
            }}
          >
            {modal === "remove" && t("common:delete")}
            {modal === "force-update" && t("common:restart")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default Device;
