import React, { useMemo, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";
import Select from "react-select";
import clsx from "clsx";

import * as yup from "yup";

import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FormProvider, useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "../../../../components/Input";

import {
  QUERY_CLIENT,
  QUERY_CLIENTS_FOR_SIDEBAR,
} from "../../../../config/graphql/query";
import {
  CREATE_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
} from "../../../../config/graphql/mutation";
import RoleBlock from "../../../../components/RoleBlock";
import { useClientId } from "../hooks/useClient";
import { reactSelectCustomStyles } from "../../../Employees/Employee/Information";

type FieldValues = IClient & { generateToken?: boolean };

const ClientInformation = React.memo(() => {
  const [visible, setVisible] = useToggle(false);

  const { t } = useTranslation(["client", "common"]);

  const history = useHistory();

  const match = useRouteMatch();
  const clientId = useClientId();

  const isNewClient = useMemo(() => match.url.includes("/new"), [match.url]);

  const schema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required(t("client:client.information.yup.name")),
        email: yup.string().required(t("client:client.information.yup.email")),
        plan: yup
          .string()
          .oneOf(["free", "basic", "enterprise"])
          .required(t("client:client.information.yup.plan")),
      }),
    [t],
  );

  const [onUpdate] = useMutation(UPDATE_CLIENT);
  const [onCreate] = useMutation(CREATE_CLIENT);
  const [onDelete] = useMutation(DELETE_CLIENT);

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
    ...(isNewClient && { defaultValues: { generateToken: true } }),
  });

  const { data } = useQuery(QUERY_CLIENT, {
    skip: !(clientId && !isNewClient),
    variables: { id: clientId },
    onCompleted: ({ client }) => methods.reset(client),
  });

  const { data: clientsData } = useQuery<{ clients: IClient[] }>(
    QUERY_CLIENTS_FOR_SIDEBAR,
  );

  const clientsOptions = useMemo(
    () =>
      clientsData?.clients.filter((client) => client.id !== data?.client?.id) ??
      [],
    [clientsData, data],
  );

  const onBeforeDelete = useCallback(() => {
    return onDelete({ variables: { id: clientId } })
      .then(() => {
        history.replace(`/clients`);
        toast.success<string>(t("client:client.information.toast.deleted"));
      })
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );
      });
  }, [history, onDelete, clientId, t]);

  const onSubmit = useCallback(
    (values: FieldValues) => {
      const { parent, ...restOfValues } = values;

      const input: IClientInput = {
        ...restOfValues,
        parent: !!parent ? parent?.id : null,
      };

      if (clientId && !isNewClient) {
        return onUpdate({
          variables: {
            input: {
              ...input,
              id: clientId,
            },
          },
        })
          .then(() => {
            toast.success<string>(t("client:client.information.toast.updated"));
          })
          .catch((error) => {
            toast.error<string>(
              error?.networkError?.result?.errors?.[0]?.message ??
                error?.message,
            );
          });
      }

      return onCreate({ variables: { input } })
        .then(
          ({
            data: {
              addClient: { id: clientId },
            },
          }) => {
            toast.success<string>(t("client:client.information.toast.created"));

            history.replace(`/clients/${clientId}`);
          },
        )
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [clientId, history, isNewClient, onCreate, onUpdate, t],
  );

  return (
    <>
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="title">
                {t("client:client.information.form.name")}
              </label>
              <Input name="title" className="form-control" />
            </div>
            <RoleBlock roles={["ADMIN"]}>
              <div className="form-group">
                <label htmlFor="code">
                  {t("client:client.information.form.code")}
                </label>
                <Input name="code" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="plan">{t("user:user.form.plan")}</label>
                <Controller
                  name="plan"
                  render={({
                    field: { onChange, value, ...props },
                    fieldState: { error },
                  }) => (
                    <>
                      <Select
                        options={[
                          { label: "free", value: "free" },
                          { label: "basic", value: "basic" },
                          { label: "enterprise", value: "enterprise" },
                        ]}
                        onChange={(value) => onChange(value?.value)}
                        {...props}
                        value={value ? { value, label: value } : undefined}
                        className={clsx({
                          "is-invalid": !!error,
                        })}
                        styles={reactSelectCustomStyles(!!error)}
                      />
                      <div className="invalid-feedback">
                        {error?.message ?? "Invalid"}
                      </div>
                    </>
                  )}
                />
              </div>
              <div className="form-group">
                <label htmlFor="parent">{t("user:user.form.parent")}</label>
                <Controller
                  name="parent"
                  render={({
                    field: { onChange, ...props },
                    fieldState: { error },
                  }) => (
                    <>
                      <Select
                        isClearable
                        getOptionLabel={({ title }) => title}
                        getOptionValue={({ id }) => id}
                        options={clientsOptions}
                        onChange={(value) => onChange(value)}
                        {...props}
                        className={clsx({
                          "is-invalid": !!error,
                        })}
                        styles={reactSelectCustomStyles(false)}
                      />
                      <div className="invalid-feedback">
                        {error?.message ?? "Invalid"}
                      </div>
                    </>
                  )}
                />
              </div>
            </RoleBlock>
            <div className="form-group">
              <label htmlFor="email">
                {t("client:client.information.form.email")}
              </label>
              <Input name="email" className="form-control" />
            </div>
            <RoleBlock roles={["ADMIN"]}>
              <div className="form-group">
                <label htmlFor="domain">
                  {t("client:client.information.form.domain")}
                </label>
                <Input name="domain" className="form-control" />
              </div>
              {isNewClient && (
                <div className="form-group mb-2">
                  <Controller
                    name="generateToken"
                    render={({ field: { value, onChange } }) => {
                      return (
                        <div className="form-group form-check">
                          <input
                            type="checkbox"
                            id="generateToken"
                            name="generateToken"
                            className="form-check-input"
                            checked={value}
                            onChange={() => onChange(!value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="generateToken"
                          >
                            {t("client:client.information.form.generateToken")}
                          </label>
                        </div>
                      );
                    }}
                  />
                </div>
              )}
            </RoleBlock>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
            <RoleBlock roles={["ADMIN"]}>
              {clientId && (
                <button
                  onClick={(e) => {
                    e.preventDefault();

                    setVisible(true);
                  }}
                  className="btn btn-danger ml-3"
                >
                  {t("common:delete")}
                </button>
              )}
            </RoleBlock>
          </div>
        </form>
      </FormProvider>
      <RoleBlock roles={["ADMIN"]}>
        <Modal
          size="sm"
          show={visible}
          onHide={setVisible}
          centered
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {t("client:client.information.modal.title")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t("client:client.information.modal.title", {
              title: data?.client?.title,
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" onClick={() => setVisible(false)}>
              {t("common:cancel")}
            </Button>
            <Button size="sm" variant="danger" onClick={onBeforeDelete}>
              {t("common:delete")}
            </Button>
          </Modal.Footer>
        </Modal>
      </RoleBlock>
    </>
  );
});

export default ClientInformation;
