import React, { useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import useMedia from "react-use/lib/useMedia";
import useToggle from "react-use/lib/useToggle";
import useLockBodyScroll from "react-use/lib/useLockBodyScroll";

const SidebarContext = React.createContext<{
  visible: boolean;
  toggle: (visible?: boolean) => void;
}>({
  visible: false,
  toggle: () => {
    //
  },
});

interface SidebarProviderProps {
  children?: React.ReactNode;
}

export function useSidebarState() {
  return useContext(SidebarContext);
}

const SidebarProvider = React.memo((props: SidebarProviderProps) => {
  const { children } = props;

  const { pathname } = useLocation();

  const defaultVisible = useMedia("(min-width: 768px)");

  const [visible, toggle] = useToggle(defaultVisible);

  /**
   * Autoclose sidebar on mobile
   */
  useEffect(() => {
    if (pathname && !defaultVisible) {
      toggle(false);
    }
  }, [defaultVisible, pathname, toggle]);

  const value = useMemo(
    () => ({
      visible,
      toggle,
    }),
    [visible, toggle],
  );

  useLockBodyScroll(visible && !defaultVisible);

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
});

export { SidebarProvider };
