import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

import qs from "query-string";

import { useApolloClient, useMutation } from "@apollo/client";
import { MUTATION_VERIFY_EMAIL } from "../../config/graphql/mutation";

const EmailVerification = React.memo(() => {
  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation(["emailVerification"]);

  const client = useApolloClient();

  const [mutateAsync, { error, data: tokenResponse }] = useMutation<{
    verifyEmail: AuthorizationToken;
  }>(MUTATION_VERIFY_EMAIL);

  const status = useMemo(() => {
    if (error) {
      return "error";
    }

    if (tokenResponse) {
      return "success";
    }

    return "loading";
  }, [error, tokenResponse]);

  const query = useMemo(
    () =>
      qs.parse(location.search, {
        parseNumbers: true,
        parseBooleans: true,
      }) as {
        code?: string;
        email?: string;
        update?: boolean;
      },
    [location.search],
  );

  useEffect(() => {
    if (!query.code) {
      history.push("/login");
    }
  }, [query.code, history]);

  useEffect(() => {
    if (!!query?.code) {
      mutateAsync({
        variables: {
          input: {
            code: query.code,
            update: query.update,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(() => {
    if (!tokenResponse?.verifyEmail || error) {
      history.replace("/login");

      return;
    }

    const {
      verifyEmail: { token },
    } = tokenResponse;

    window.localStorage.setItem("Authorization", token);

    client.refetchQueries({
      updateCache: (cache) => {
        cache.evict({ fieldName: "token" });
      },
      include: ["QUERY_AUTH"],
    });

    history.replace("/");
  }, [client, error, history, tokenResponse]);

  if (status === "loading") {
    return null;
  }

  return (
    <div className="row vh-100 justify-content-center align-items-center">
      <div className="col-6 d-flex flex-column">
        <h5 className="text-center mb-3">
          {t(`emailVerification:${status}.title`)}
        </h5>
        <p className="text-center mb-3">
          {t(`emailVerification:${status}.subtitle`)}
        </p>
        <button
          type="submit"
          className="btn btn-primary m-auto"
          onClick={onSubmit}
        >
          {t("resetPassword:button.submit")}
        </button>
      </div>
    </div>
  );
});

export default EmailVerification;
