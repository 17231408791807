import React from "react";

import { Route, Switch, useRouteMatch } from "react-router-dom";

import FileUploadProvider from "../../../../components/FileUpload";

import ItemRoute from "./Item";
import ItemsRoute from "./Items";

const PathRouter = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <ItemsRoute />
      </Route>
      <Route exact path={`${path}/new`}>
        <FileUploadProvider>
          <ItemRoute />
        </FileUploadProvider>
      </Route>
      <Route exact path={`${path}/:id`}>
        <FileUploadProvider>
          <ItemRoute />
        </FileUploadProvider>
      </Route>
    </Switch>
  );
});

export default PathRouter;
