import * as L from "leaflet";

const basePolygonStyle = {
  fillOpacity: 0.3,
  color: "#000",
  opacity: 1,
  weight: 1,
};

export const polygonStyle = {
  ...basePolygonStyle,
  fillColor: "#17a2b8",
};

export const polygonActiveStyle = {
  ...basePolygonStyle,
  ...polygonStyle,
  fillOpacity: 0.8,
};

export const polygonDisabledStyle = {
  ...basePolygonStyle,
  fillColor: "#6c757d",
};

export const polygonDisabledActiveStyle = {
  ...polygonDisabledStyle,
  fillOpacity: 0.8,
};

export const drawControlOptions: L.Control.DrawConstructorOptions = {
  position: "topright",
  draw: {
    polyline: false,
    rectangle: {
      metric: true,
      shapeOptions: polygonStyle,
    },
    polygon: {
      allowIntersection: false,
      showArea: true,
      drawError: {
        color: "#b00b00",
        timeout: 1000,
      },
      shapeOptions: polygonStyle,
    },

    circlemarker: false,
    circle: false,
    marker: false,
  },
};
