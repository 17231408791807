import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuth } from "../../context/Auth";

const Verify2fa = React.memo(() => {
  const [code, setCode] = useState("");

  const { t } = useTranslation(["login", "common"]);

  const { verify2fa } = useAuth();

  const { state } = useLocation<{ token?: string }>();

  const token = state?.token ?? "";

  const onSubmit = useCallback(
    (e: any) => {
      e.preventDefault();

      return verify2fa({ code, token }).catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );
      });
    },
    [code, token, verify2fa],
  );

  if (!token) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="row vh-100 justify-content-center align-items-center">
      <div className="col-6">
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="code">{t("verify2fa:form.code.label")}</label>
            <input
              id="email"
              type="number"
              className="form-control"
              value={code}
              onChange={({ target: { value } }) => setCode(value)}
            />
          </div>
          <div className="d-flex justify-content-between">
            <button type="submit" className="btn btn-primary">
              {t("verify2fa:button.submit")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
});

export default Verify2fa;
