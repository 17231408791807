import React, { useMemo, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";

import clsx from "clsx";
import map from "lodash/map";
import find from "lodash/find";

import Select from "react-select";

import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory, useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FormProvider, Controller, useForm } from "react-hook-form";

import Input from "../../../../components/Input";

import {
  QUERY_CLIENT_SENDGRID_TEMPLATE,
  QUERY_EMAIL_TEMPLATE_OPTIONS,
} from "../../../../config/graphql/query";
import {
  CREATE_CLIENT_SENDGRID_TEMPLATE,
  UPDATE_CLIENT_SENDGRID_TEMPLATE,
  DELETE_CLIENT_SENDGRID_TEMPLATE,
} from "../../../../config/graphql/mutation";

import { useClientId } from "../hooks/useClient";

import { reactSelectCustomStyles } from "../../../Employees/Employee/Information";

interface FieldValues {
  title: string;
  templateId: string;
  template: string;
}

const EmailTemplate = React.memo(() => {
  const [show, setShow] = useToggle(false);

  const { t } = useTranslation(["client", "common"]);
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const clientId = useClientId();

  const methods = useForm<FieldValues>({
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {},
  });

  const { data: emailTemplateOptionsData, loading: isLoadingOptions } =
    useQuery<{
      emailTemplateOptions: string[];
      availableSendGridClientTemplates: { id: string; name: string }[];
    }>(QUERY_EMAIL_TEMPLATE_OPTIONS, {
      context: {
        headers: {
          ClientId: clientId,
        },
      },
    });

  const { data: emailTemplateData } = useQuery<{
    emailTemplate: IClientSendGridTemplate;
  }>(QUERY_CLIENT_SENDGRID_TEMPLATE, {
    skip: !id,
    variables: { id },
    onCompleted: ({ emailTemplate }) => methods.reset(emailTemplate),
    context: {
      headers: {
        ClientId: clientId,
      },
    },
  });

  const data = emailTemplateData?.emailTemplate;

  const emailTemplateOptions = useMemo(() => {
    const nextOptions = map(
      emailTemplateOptionsData?.emailTemplateOptions,
      (key) => ({
        value: key,
        label: key,
      }),
    );

    return nextOptions;
  }, [emailTemplateOptionsData?.emailTemplateOptions]);

  const availableSendGridTemplateOptions = useMemo(() => {
    const nextOptions =
      emailTemplateOptionsData?.availableSendGridClientTemplates ?? [];

    if (!data) {
      return nextOptions;
    }

    return [...nextOptions, { id: data.templateId, name: data.template }];
  }, [emailTemplateOptionsData?.availableSendGridClientTemplates, data]);

  const [onUpdate] = useMutation(UPDATE_CLIENT_SENDGRID_TEMPLATE, {
    refetchQueries: [
      {
        query: QUERY_CLIENT_SENDGRID_TEMPLATE,
        variables: { id },
        context: {
          headers: {
            ClientId: clientId,
          },
        },
      },
      {
        query: QUERY_EMAIL_TEMPLATE_OPTIONS,
        context: {
          headers: {
            ClientId: clientId,
          },
        },
      },
    ],
    context: {
      headers: {
        ClientId: clientId,
      },
    },
  });

  const [onCreate] = useMutation(CREATE_CLIENT_SENDGRID_TEMPLATE, {
    refetchQueries: [
      {
        query: QUERY_EMAIL_TEMPLATE_OPTIONS,
        context: {
          headers: {
            ClientId: clientId,
          },
        },
      },
    ],
    context: {
      headers: {
        ClientId: clientId,
      },
    },
  });
  const [onDelete] = useMutation(DELETE_CLIENT_SENDGRID_TEMPLATE, {
    refetchQueries: [
      {
        query: QUERY_EMAIL_TEMPLATE_OPTIONS,
        context: {
          headers: {
            ClientId: clientId,
          },
        },
      },
    ],
    context: {
      headers: {
        ClientId: clientId,
      },
    },
  });

  const onBeforeDelete = useCallback(() => {
    return onDelete({ variables: { input: { id, client: clientId } } })
      .then(() => {
        toast.success<string>(t("client:client.information.toast.deleted"));

        history.replace(`/clients/${clientId}/sendgrid-email-templates`);
      })
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );

        history.replace(`/clients/${clientId}/sendgrid-email-templates`);
      });
  }, [history, onDelete, clientId, id, t]);

  const onSubmit = useCallback(
    (values: FieldValues) => {
      if (!clientId) {
        return toast.error<string>(
          t("client:client.sendgrid.error.missingclient"),
        );
      }

      const input = {
        ...(id ? { id } : {}),
        ...(clientId ? { client: clientId } : {}),
        ...values,
      };

      if (id) {
        return onUpdate({ variables: { input } })
          .then(({ data: { updateEmailTemplate } }) => {
            methods.reset(updateEmailTemplate);
          })
          .then(() => {
            toast.success<string>(t("client:client.information.toast.updated"));
          })
          .catch((error) => {
            toast.error<string>(
              error?.networkError?.result?.errors?.[0]?.message ??
                error?.message,
            );
          });
      }

      return onCreate({ variables: { input } })
        .then(() => {
          toast.success<string>(t("client:client.information.toast.created"));
          history.replace(`/clients/${clientId}/sendgrid-email-templates`);
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [data],
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/clients">{t("client:client.token.nav.clients")}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to={`/clients/${clientId}`}>
              {data?.client?.title ?? "Client"}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to={`/clients/${clientId}/sendgrid-email-templates`}>
              {t("client:client.sendgrid.nav.sendgridtemplates")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {data?.title ?? "Title"}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="title">
                {t("client:client.sendgrid.form.title")}
              </label>
              <Input
                name="title"
                className="form-control"
                rules={{
                  required: {
                    value: true,
                    message: t("client:client.sendgrid.template.yup.title"),
                  },
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="templateId">
                {t("client:client.sendgrid.template.form.templateid")}
              </label>
              <Controller
                name="templateId"
                render={({
                  field: { onChange, value: propsValue, ...props },
                  fieldState: { error },
                }) => {
                  const value = propsValue
                    ? find(availableSendGridTemplateOptions, { id: propsValue })
                    : undefined;

                  return (
                    <>
                      <Select
                        getOptionLabel={({ id, name }) => `${name} (${id})`}
                        getOptionValue={({ id }) => id}
                        options={availableSendGridTemplateOptions}
                        value={value}
                        onChange={(value) => onChange(value?.id)}
                        isLoading={isLoadingOptions}
                        className={clsx({
                          "is-invalid": !!error,
                        })}
                        styles={reactSelectCustomStyles(!!error)}
                        {...props}
                      />
                      <div className="invalid-feedback">{error?.message}</div>
                    </>
                  );
                }}
                rules={{
                  required: {
                    value: true,
                    message: t(
                      "client:client.sendgrid.template.yup.templateid",
                    ),
                  },
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="type">
                {t("screens:screen.screenRoute.form.template")}
              </label>
              <Controller
                name="template"
                render={({
                  field: { onChange, value: propsValue, ...props },
                  fieldState: { error },
                }) => {
                  const value = propsValue
                    ? find(emailTemplateOptions, { value: propsValue })
                    : undefined;

                  return (
                    <>
                      <Select
                        options={emailTemplateOptions}
                        value={value}
                        onChange={(value) => onChange(value?.value)}
                        isLoading={isLoadingOptions}
                        className={clsx({
                          "is-invalid": !!error,
                        })}
                        styles={reactSelectCustomStyles(!!error)}
                        {...props}
                      />{" "}
                      <div className="invalid-feedback">{error?.message}</div>
                    </>
                  );
                }}
                rules={{
                  required: {
                    value: true,
                    message: t("client:client.sendgrid.template.yup.template"),
                  },
                }}
              />
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
            {clientId && !window.location.pathname.endsWith("/new") && (
              <button
                onClick={(e) => {
                  e.preventDefault();

                  setShow(true);
                }}
                className="btn btn-danger ml-3"
              >
                {t("common:delete")}
              </button>
            )}
          </div>
        </form>
      </FormProvider>
      <Modal
        size="sm"
        show={show}
        onHide={setShow}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("client:client.information.modal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("client:client.information.modal.title", {
            title: data?.client?.title,
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setShow(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default EmailTemplate;
