import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import qs from "query-string";
import ReactPaginate from "react-paginate";

const Pagination = (
  props: { documentsCount: number; limit: number; initialPage?: number } = {
    documentsCount: 1,
    limit: 10,
    initialPage: 1,
  },
) => {
  const { location, push } = useHistory();
  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
      },
    [location.search],
  );

  const pages = useMemo(
    () => Math.ceil(props.documentsCount / props.limit),
    [props.documentsCount, props.limit],
  );

  const page = useMemo(() => (query.page ? query.page - 1 : 0), [query.page]);

  return (
    <ReactPaginate
      {...props}
      previousLabel="previous"
      nextLabel="next"
      breakLabel="..."
      pageCount={pages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      forcePage={page}
      onPageChange={(data) =>
        push({ search: qs.stringify({ ...query, page: data.selected + 1 }) })
      }
      containerClassName="pagination"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      activeClassName="active"
      activeLinkClassName="active"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      breakClassName="page-item"
      breakLinkClassName="page-link"
    />
  );
};

export default Pagination;
