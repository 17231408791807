import React, { useCallback, useMemo } from "react";

import qs from "query-string";

import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";

import { QUERY_FILES } from "../../../config/graphql/query";

import Table from "../../../components/Table";
import Pagination from "../../../components/Pagination";

const limit = 10;

const TableRow = ({ item }: { item: IFile }) => {
  const { id, filename, path, createdAt, absolutePath } = item;

  return (
    <>
      <tr>
        <th>{id}</th>
        <td>{filename}</td>
        <td>{path}</td>
        <td>
          <a href={absolutePath} rel="noreferrer" target="_blank">
            {absolutePath}
          </a>
        </td>
        <td>{createdAt}</td>
      </tr>
    </>
  );
};

const AssetsRoute = React.memo(() => {
  const location = useLocation();

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
      },
    [location.search],
  );

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);
  const initialPage = useMemo(() => Math.max(query.page || 1, 1) - 1, []);

  const { data } = useQuery(QUERY_FILES, {
    onError: console.log,
    fetchPolicy: "network-only",
    variables: {
      sort: {
        filename: "ASC",
      },
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
    },
  });

  const files: Array<IFile> = data?.files ?? [];
  const filesCount = data?.filesCount ?? 0;

  const renderFile = useCallback(
    (item: IFile) => <TableRow key={item.id} item={item} />,
    [],
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            Files
          </li>
        </ol>
      </nav>

      <Table>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Filename</th>
            <th scope="col">Path</th>
            <th scope="col">Absolute Path</th>
            <th scope="col">Created At</th>
          </tr>
        </thead>
        <tbody>{files.map(renderFile)}</tbody>
      </Table>
      <Pagination
        documentsCount={filesCount}
        limit={limit}
        initialPage={initialPage}
      />
    </div>
  );
});

export default AssetsRoute;
