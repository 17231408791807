import React from "react";
import { useQuery } from "@apollo/client";
import { useParams, Link } from "react-router-dom";

import map from "lodash/map";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import { QUERY_LOG } from "../../../config/graphql/query";

const Log = React.memo(() => {
  const { id, type } = useParams<{ id: string; type: LevelInfoType }>();

  const { t } = useTranslation(["logs", "common"]);

  const { data } = useQuery<{ log?: ILog }>(QUERY_LOG, {
    skip: !id,
    variables: { id, type },
  });

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/log">{t("logs:log.nav.log", { count: 2 })}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("logs:log.nav.log", { count: 1 })}
          </li>
        </ol>
      </nav>
      <Card className="mb-3 p-3">
        {t("logs:log.card.created")}

        {dayjs(data?.log?.createdAt).local().format("DD-MM-YYYY / HH:mm") ?? ""}
      </Card>
      <Card className="mb-3 p-3">
        {t("logs:log.card.ip")}
        {data?.log?.ip ?? ""}
      </Card>
      {data?.log?.user && (
        <>
          <Card className="mb-3 p-3">
            {t("logs:log.card.firstName")}
            {data?.log?.user?.firstName ?? ""}
          </Card>
          <Card className="mb-3 p-3">
            {t("logs:log.card.lastName")}
            {data?.log?.user?.lastName ?? ""}
          </Card>
        </>
      )}

      <Accordion defaultActiveKey="0" className="mb-3">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            {t("logs:log.card.endpoint")}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>{data?.log?.endpoint ?? ""}</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            {t("logs:log.card.rawHeaders")}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              {map(
                JSON.parse(data?.log?.rawHeaders ?? "[]"),
                (header, index: number) => (
                  <p className={index % 2 === 0 ? "mb-0" : ""}>{header}</p>
                ),
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            {t("logs:log.card.payload")}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <pre>
                {JSON.stringify(
                  JSON.parse(data?.log?.payload ?? "{}"),
                  null,
                  2,
                )}
              </pre>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            {t("logs:log.card.duration")}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>{data?.log?.responseDuration ?? ""}</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
});

export default Log;
