import { gql } from "@apollo/client";

export const FRAGMENT_TORO_MEETING = gql`
  fragment FragmentToroMeeting on ToroMeeting {
    id
    title
    description
    dateStart
    dateEnd
    externalId
    emailLanguage
    attendees {
      id
      name
      email
      badge
      qrPass {
        id
        fallbackCode
      }
    }
    createdAt
    updatedAt
    company {
      id
      title
    }
    building {
      id
      title
    }
    host {
      id
      email
      name
      qrPass {
        id
        fallbackCode
      }
    }
  }
`;

export const FRAGMENT_TORO_VISITOR = gql`
  fragment FragmentToroVisitor on ToroVisitor {
    id
    createdAt
    updatedAt
    company {
      id
      title
    }
    building {
      id
      title
    }
    host {
      id
      email
      name
    }
    invitedBy {
      id
      firstName
      lastName
    }
    editedBy {
      id
    }
    badge
    name
    email
    phone
    dateStart
    allDay
    meeting {
      id
      title
      description
      dateEnd
      attendees {
        id
        name
      }
    }
    checkedInAt
    checkedOutAt
  }
`;

export const MUTATION_TORO_CREATE_MEETING = gql`
  mutation toroCreateMeeting($input: ToroCreateMeeting!) {
    toroCreateMeeting(input: $input) {
      ...FragmentToroMeeting
    }
  }
  ${FRAGMENT_TORO_MEETING}
`;

export const MUTATION_TORO_UPDATE_MEETING = gql`
  mutation toroUpdateMeeting($input: ToroUpdateMeeting!) {
    toroUpdateMeeting(input: $input) {
      ...FragmentToroMeeting
    }
  }
  ${FRAGMENT_TORO_MEETING}
`;

export const MUTATION_TORO_DELETE_MEETING = gql`
  mutation toroDeleteMeeting($id: ID!) {
    toroDeleteMeeting(id: $id) {
      id
    }
  }
`;

export const QUERY_TORO_VISITORS = gql`
  query visitors(
    $filter: VisitorFilterType
    $sort: VisitorSortType
    $pagination: PaginationType
  ) {
    visitors(filter: $filter, sort: $sort, pagination: $pagination) {
      ...FragmentToroVisitor
    }
    visitorsCount(filter: $filter, sort: $sort, pagination: $pagination)
  }
  ${FRAGMENT_TORO_VISITOR}
`;

export const QUERY_TORO_VISITOR = gql`
  query toroVisitor($id: ID!) {
    toroVisitor(id: $id) {
      ...FragmentToroVisitor
    }
  }
  ${FRAGMENT_TORO_VISITOR}
`;

export const QUERY_TORO_MEETINGS = gql`
  query toroMeetings(
    $filter: ToroMeetingFilterType
    $sort: ToroMeetingSortType
    $pagination: PaginationType
  ) {
    toroMeetings(filter: $filter, sort: $sort, pagination: $pagination) {
      ...FragmentToroMeeting
    }
    toroMeetingsCount(filter: $filter, sort: $sort, pagination: $pagination)
  }
  ${FRAGMENT_TORO_MEETING}
`;

export const QUERY_TORO_MEETING = gql`
  query toroMeeting($id: ID!) {
    toroMeeting(id: $id) {
      ...FragmentToroMeeting
    }
  }
  ${FRAGMENT_TORO_MEETING}
`;
