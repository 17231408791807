import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import qs from "query-string";

import { useMutation } from "@apollo/client";
import { MUTATION_RESET_PASSWORD } from "../../config/graphql/mutation";

import Input from "../../components/Input";
import { useAuth } from "../../context/Auth";

type FieldValues = {
  password: string;
  confirmPassword: string;
};

const ResetPassword = React.memo(() => {
  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation("resetPassword");

  const { logout } = useAuth();

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        code?: string;
      },
    [location.search],
  );

  useEffect(() => {
    if (!query.code) {
      history.push("/login");
    }
  }, [query.code, history]);

  const schema = useMemo(() => {
    return yup.object().shape({
      password: yup
        .string()
        .required(t("resetPassword:yup.password.required"))
        .min(8, t("resetPassword:yup.password.min", { count: 8 }))
        .matches(/[a-z]+/, t("resetPassword:yup.password.lowercase"))
        .matches(/[A-Z]+/, t("resetPassword:yup.password.uppercase"))
        .matches(/\d+/, t("resetPassword:yup.password.number"))
        .matches(/[@$!%*#?&]+/, t("resetPassword:yup.password.special")),
      confirmPassword: yup
        .string()
        .required(t("resetPassword:yup.confirmPassword.required"))
        .test(
          "match",
          t("resetPassword:yup.confirmPassword.match"),
          function validate(value) {
            return this.parent.password === value;
          },
        ),
    });
  }, [t]);

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: { password: "", confirmPassword: "" },
  });

  const [onResetPassword, { loading }] = useMutation(MUTATION_RESET_PASSWORD);

  const onSubmit = useCallback(
    (input: FieldValues) => {
      return onResetPassword({
        variables: { input: { code: query.code, password: input.password } },
      })
        .then(() => {
          toast.success<string>(t("resetPassword:toast.success"));

          logout();
        })
        .catch((error) => {
          const errorMessage =
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message;

          toast.error<string>(errorMessage || t("resetPassword:toast.error"));
        });
    },
    [onResetPassword, query.code, t, logout],
  );

  return (
    <FormProvider {...methods}>
      <div className="row vh-100 justify-content-center align-items-center">
        <div className="col-6">
          <h5 className="text-center mb-3">{t("resetPassword:title")}</h5>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="password">
                {t("resetPassword:form.password.label")}
              </label>
              <Input
                name="password"
                type="password"
                placeholder={t("resetPassword:form.password.placeholder")}
                className="form-control"
                autoComplete="new-password"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">
                {t("resetPassword:form.confirmPassword.label")}
              </label>
              <Input
                name="confirmPassword"
                type="password"
                placeholder={t(
                  "resetPassword:form.confirmPassword.placeholder",
                )}
                className="form-control"
                autoComplete="new-password"
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {t("resetPassword:button.submit")}
            </button>
          </form>
        </div>
      </div>
    </FormProvider>
  );
});

export default ResetPassword;
