import React from "react";

import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import Deceased from "./Deceased";
import DeceasedList from "./DeceasedList";

const DeceasedRouter = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <DeceasedList />
      </Route>
      <Route path={`${path}/:entityId`}>
        <Deceased />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default DeceasedRouter;
