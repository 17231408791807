/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo } from "react";

import qs from "query-string";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import useToggle from "react-use/lib/useToggle";
import { useTranslation } from "react-i18next";

import { useMutation, useQuery } from "@apollo/client";
import { useRouteMatch, Link } from "react-router-dom";

import { QUERY_ACTIVE_DIRECTORIES } from "../../../../config/graphql/query";
import { MUTATION_DELETE_ACTIVE_DIRECTORY_INTEGRATION } from "../../../../config/graphql/mutation";

import Table from "../../../../components/Table";
import { useClientId } from "../hooks/useClient";

const TableRow = ({ item }: { item: IActiveDirectory }) => {
  const { t } = useTranslation(["client", "common"]);

  const { url } = useRouteMatch();

  const { id, name, email, client } = item;

  const [visible, setVisible] = useToggle(false);

  const [onDelete] = useMutation(MUTATION_DELETE_ACTIVE_DIRECTORY_INTEGRATION, {
    refetchQueries: [
      {
        query: QUERY_ACTIVE_DIRECTORIES,
        variables: {
          id: client?.id,
        },
      },
    ],
    variables: { id },
  });

  const onBeforeDelete = () => {
    onDelete();

    return setVisible(false);
  };

  return (
    <>
      <tr>
        <td>{id}</td>
        <td>{name}</td>
        <td>{email}</td>
        <td>
          <div className="d-flex">
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>
            {/* <Link to={`${url}/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link> */}
          </div>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("client:client.integrations.activeDirectory.modal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("client:client.integrations.activeDirectory.modal.body", {
            id,
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const ClientIntegrationRoute = React.memo(() => {
  const clientId = useClientId();

  const { t } = useTranslation(["client", "common"]);

  const { data } = useQuery<{ activeDirectories: IActiveDirectory[] }>(
    QUERY_ACTIVE_DIRECTORIES,
    {
      fetchPolicy: "network-only",
      context: {
        headers: {
          ClientId: clientId,
        },
      },
    },
  );

  const items = data?.activeDirectories ?? [];

  const renderItem = useCallback(
    (item: IActiveDirectory) => <TableRow key={item.id} item={item} />,
    [],
  );

  const integrationOAuthURL = useMemo(
    () =>
      qs.stringifyUrl(
        {
          url: `${process.env.REACT_APP_API_URL}/api/v1/activedirectory/auth/login`,
          query: {
            client: clientId,
            redirectUri: window.location.href,
          },
        },
        { encode: true },
      ),
    [clientId],
  );

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-end align-items-center my-4">
        <a href={integrationOAuthURL} className="btn btn-primary">
          {t("client:client.integrations.activeDirectory.button.generate")}
        </a>
      </div>
      <>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th scope="col">
                {t("client:client.integrations.activeDirectory.th.id")}
              </th>
              <th scope="col">
                {t("client:client.integrations.activeDirectory.th.name")}
              </th>
              <th scope="col">
                {t("client:client.integrations.activeDirectory.th.email")}
              </th>
              <th scope="col">
                {t("client:client.integrations.activeDirectory.th.actions")}
              </th>
            </tr>
          </thead>
          <tbody>{items.map(renderItem)}</tbody>
        </Table>
      </>
    </div>
  );
});

export default ClientIntegrationRoute;
