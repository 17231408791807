import React from "react";

import { useTranslation } from "react-i18next";
import clsx from "clsx";

import {
  useRouteMatch,
  useParams,
  NavLink,
  Switch,
  Link,
  Redirect,
  useHistory,
} from "react-router-dom";
import { useQuery } from "@apollo/client";

import { QUERY_EMPLOYEE } from "../../../config/graphql/query";

import InformationRoute from "./Information";
import PreferencesRoute from "./Preferences";

import Route from "../../../components/Route";
import { useCurrentHasPlan } from "../../../components/PlanBlock";

const EmployeesRoute = React.memo(() => {
  const { t } = useTranslation(["employees"]);

  const history = useHistory();

  const match = useRouteMatch();

  const { id } = useParams<{ id: string }>();

  const { data } = useQuery<{ employee: IMemberEmployee }>(QUERY_EMPLOYEE, {
    skip: !id,
    variables: { id },
    nextFetchPolicy: "network-only",
    onError: () => {
      history.replace("/employees");
    },
  });

  const isFree = useCurrentHasPlan(["free"]);

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/employees">{t("employees:title")}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {data?.employee?.fullName ?? t("employee:title")}
          </li>
        </ol>
      </nav>

      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <NavLink exact className="nav-link" to={match.url}>
            {t("employee:tab.information")}
          </NavLink>
        </li>
        {!isFree && (
          <li className="nav-item">
            <NavLink
              exact
              className={clsx("nav-link", {
                disabled: !id,
              })}
              to={`${match.url}/preferences`}
            >
              {t("employee:tab.preferences")}
            </NavLink>
          </li>
        )}
      </ul>

      <div className="tab-content">
        <Switch>
          <Route.Private exact path={match.path}>
            <div className="tab-pane active" role="tabpanel">
              <InformationRoute />
            </div>
          </Route.Private>
          {!isFree && (
            <Route.Private exact path={`${match.path}/preferences`}>
              <div className="tab-pane active" role="tabpanel">
                <PreferencesRoute />
              </div>
            </Route.Private>
          )}
          <Redirect to={match.url} />
        </Switch>
      </div>
    </div>
  );
});

export default EmployeesRoute;
