import React, { useMemo } from "react";

import { useCurrentClient } from "../context/Client";

type CodeType = string[] | ((code: string) => boolean);

export type CodeBlockProps = {
  include?: CodeType;
  exclude?: CodeType;
};

export const useHasCode = (client: IClient | undefined, code: CodeType) => {
  const clientCode = client?.code;

  return useMemo((): boolean => {
    if (typeof clientCode !== "string") {
      return false;
    }

    if (typeof code === "function") {
      return code(clientCode);
    }

    if (Array.isArray(code) && code.length > 0) {
      return code.includes(clientCode);
    }

    return true;
  }, [clientCode, code]);
};

const CodeBlock = React.memo(
  (props: React.PropsWithChildren<CodeBlockProps>) => {
    const { children } = props;

    const client = useCurrentClient();

    const rules = useMemo(
      () =>
        "include" in props && props.include ? props.include : props.exclude,
      [props],
    );

    const hasCode = useHasCode(client, rules ?? []);

    if (!rules) {
      return <>{children}</>;
    }

    if ("include" in props && props.include && hasCode) {
      return <>{children}</>;
    }

    if ("exclude" in props && props.exclude && !hasCode) {
      return <>{children}</>;
    }

    return null;
  },
);

export default CodeBlock;
