import React, { useCallback, useEffect, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import map from "lodash/map";
import clsx from "clsx";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  useRouteMatch,
  useHistory,
  Route,
  Link,
  Switch,
} from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { EntityType } from "../../../config/const/entity";
import {
  QUERY_ENTITIES_PARENTS,
  QUERY_MINEW_ITEM,
  QUERY_MINEW_ITEMS,
} from "../../../config/graphql/query";
import {
  CREATE_MINEW_ITEM,
  UPDATE_MINEW_ITEM,
  DELETE_MINEW_ITEM,
} from "../../../config/graphql/mutation";
import Table from "../../../components/Table";
import RoleBlock from "../../../components/RoleBlock";

const MinewDevicesItemsList = () => {
  const history = useHistory();
  const {
    url,
    // @ts-ignore
    params: { minewId: id },
  } = useRouteMatch();
  const { t } = useTranslation(["wayfinding, common"]);
  const { data } = useQuery(QUERY_MINEW_ITEMS, {
    variables: {
      filter: {
        minew: id,
      },
    },
  });
  const items = data?.minewItems ?? [];
  const renderItem = useCallback(
    ({ id, entity }: IMinewItem) => {
      const propertiesTitle = map(entity.properties, "title").join(", ");
      return (
        <tr key={`minew-${id}`} onClick={() => history.push(`${url}/${id}`)}>
          <th scope="row">{id}</th>
          <td>{entity?.title}</td>
          <td>{propertiesTitle}</td>
        </tr>
      );
    },
    [history, url],
  );
  return (
    <>
      <div className="d-flex justify-content-between align-items-center my-4">
        <div>
          <Link to={`${url}/new`} type="button" className="btn btn-primary">
            {t("wayfinding:wayfinding.wayfindingItemList.button.add")}
          </Link>
        </div>
      </div>
      <Table>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">
              {t("wayfinding:wayfinding.wayfindingItemList.th.entity")}
            </th>
            <th scope="col">
              {t("wayfinding:wayfinding.wayfindingItemList.th.properties")}
            </th>
          </tr>
        </thead>
        <tbody>{items.map(renderItem)}</tbody>
      </Table>
    </>
  );
};
const MinewItem = () => {
  const [show, setShow] = useToggle(false);
  const { t } = useTranslation(["wayfinding, common"]);
  const history = useHistory();
  const {
    // @ts-ignore
    params: { minewId: minew, id },
  } = useRouteMatch();
  const graphql = useApolloClient();
  const schema = useMemo(
    () =>
      yup.object().shape({
        entity: yup
          .object()
          .shape({
            id: yup
              .string()
              .required(t("wayfinding:wayfinding.wayfindingItem.yup.entity")),
            title: yup.string(),
          })
          .required(),
      }),
    [t],
  );
  const methods = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
  });
  const { data: itemData, loading } = useQuery(QUERY_MINEW_ITEM, {
    skip: !id,
    variables: { id },
  });
  useEffect(() => {
    methods.reset(itemData?.minewItem ?? {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemData]);
  const [onSave, { loading: saving }] = useMutation(
    id ? UPDATE_MINEW_ITEM : CREATE_MINEW_ITEM,
    {
      refetchQueries: [
        {
          query: QUERY_MINEW_ITEMS,
          variables: {
            filter: {
              minew,
            },
          },
        },
      ],
    },
  );
  const [onDelete] = useMutation(DELETE_MINEW_ITEM, {
    refetchQueries: [
      {
        query: QUERY_MINEW_ITEMS,
        variables: {
          filter: {
            minew,
          },
        },
      },
    ],
  });
  const onRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      return onDelete({ variables: { id } })
        .then(() => {
          history.replace(history.location.pathname.replace(`/${id}`, ""));
          toast.success<string>(
            t("wayfinding:wayfinding.wayfindingItem.toast.deleted"),
          );
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [onDelete, id, history, t],
  );
  const onBeforeRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      setShow(true);
    },
    [setShow],
  );
  const onSubmit = useCallback(
    ({ entity, ...rest }: any) => {
      const input = {
        ...rest,
        entity: entity.id,
        ...(!!id && { id }),
        ...(!id && { minew }),
      };
      return onSave({ variables: { input } })
        .then(({ data }) => {
          if (data?.createMinewItem?.id) {
            history.replace(`/minew-device/${minew}/item/`);
            toast.success<string>(
              t("wayfinding:wayfinding.wayfindingItem.toast.created"),
            );
            return;
          }
          toast.success<string>(
            t("wayfinding:wayfinding.wayfindingItem.toast.updated"),
          );
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [id, minew, onSave, history, t],
  );
  const getOptionLabel = useCallback(
    ({ title, properties, parents }: TEntity) => {
      const propertiesString =
        map(properties, "title").filter(Boolean).join(", ") || "N/A";
      const entityTitle = [...map(parents, "title").reverse(), title]
        .filter(Boolean)
        .join(" - ");
      return [propertiesString, `(${entityTitle})`].filter(Boolean).join(" - ");
    },
    [],
  );
  const onLoadOptions = useCallback(
    (value: string) => {
      return graphql
        .query({
          query: QUERY_ENTITIES_PARENTS,
          variables: {
            filter: {
              title: value,
              type: EntityType.Space,
            },
          },
          fetchPolicy: "network-only",
        })
        .then(({ data }) => data?.entities ?? [])
        .catch(() => {
          return [];
        });
    },
    [graphql],
  );
  return (
    <>
      <FormProvider {...methods}>
        <div className="tab-pane active" role="tabpanel">
          <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="col-lg-4">
              <Controller
                control={methods.control}
                name="entity"
                render={({ field, fieldState: { error } }) => (
                  <div className="form-group">
                    <label htmlFor="entity">
                      {t("minew:minew.minewItem.form.entity")}
                    </label>
                    <AsyncSelect
                      defaultOptions
                      className={clsx({
                        "is-invalid": !!error,
                      })}
                      getOptionLabel={getOptionLabel}
                      getOptionValue={({ id }) => id}
                      loadOptions={onLoadOptions}
                      {...field}
                    />
                    {!!error && (
                      <div className="invalid-feedback">{error.message}</div>
                    )}
                    <small id="companyHelp" className="form-text text-muted">
                      {t("minew:minew.minewItem.form.companyHelp")}
                    </small>
                  </div>
                )}
              />
            </div>
            <div className="col-12">
              <input
                type="submit"
                className="btn btn-primary"
                disabled={saving || loading}
              />
              <RoleBlock roles={["ADMIN"]}>
                {id && (
                  <button
                    onClick={onBeforeRemove}
                    className="btn btn-danger ml-3"
                  >
                    {t("common:delete")}
                  </button>
                )}
              </RoleBlock>
            </div>
          </form>
        </div>
      </FormProvider>
      <Modal show={show} onHide={setShow} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t("minew:minew.minewItem.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("minew:minew.minewItem.modal.body")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setShow}>
            {t("common:cancel")}
          </Button>
          <Button variant="danger" onClick={onRemove}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
const MinewItems = React.memo(() => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <MinewDevicesItemsList />
      </Route>
      <Route exact path={`${path}/new`}>
        <MinewItem />
      </Route>
      <Route exact path={`${path}/:id`}>
        <MinewItem />
      </Route>
    </Switch>
  );
});
export default MinewItems;
