import i18n from "i18next";

import { initReactI18next } from "react-i18next";

import en from "./en.json";
import nl from "./nl.json";

i18n.use(initReactI18next).init({
  resources: { en, nl },

  debug: process.env.NODE_ENV === "development",
  lng: "en",
  fallbackLng: "en",

  keySeparator: ".",

  interpolation: {
    escapeValue: false,
    format: (value, format) => {
      switch (format) {
        case "uppercase": {
          return value.toUpperCase();
        }

        default: {
          return value;
        }
      }
    },
  },
});

export default i18n;
