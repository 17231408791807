import { useMemo } from "react";

import { useHistory } from "react-router-dom";
import qs, { ParsedQuery } from "query-string";

const useSearch = (): ParsedQuery => {
  const history = useHistory();

  return useMemo(
    () => qs.parse(history.location.search, { arrayFormat: "comma" }),
    [history.location.search],
  );
};

export default useSearch;
